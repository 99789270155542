import React from 'react'
class StoreNotSale extends React.Component {
  render() {
    let notSaleTitle_text = "現在為非銷售期或商品正在準備中喔！"
    let notSaleTitle = <h1 className='section-title'>{notSaleTitle_text}</h1>
    return (
      <div>
        <main className="lsEmptyState store-not-sale mb-5" role="main">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-8 col-xl-7">
                {/* <i aria-hidden="true" className="fa fa-exclamation"></i> */}
                <div className="default-no_sale_yet mx-auto my-3">
                  <img src={this.props.img_placeholder} className="w-100" alt={notSaleTitle_text}/>
                </div>
                {notSaleTitle}
                <p className="section-detail">欲想查詢訂單可以登入『訂單查詢』或主動聯繫客服。</p>
                <a href="/orders" className="btn btn-primary">訂單查詢</a>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }
}
export default StoreNotSale
