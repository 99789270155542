// source: general/member/first.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var atom_member_first_pb = require('../../atom/member/first_pb.js');
goog.object.extend(proto, atom_member_first_pb);
goog.exportSymbol('proto.pan.general.member.AccessTokenRequest', null, global);
goog.exportSymbol('proto.pan.general.member.AccessTokenResponse', null, global);
goog.exportSymbol('proto.pan.general.member.InitGenderRequest', null, global);
goog.exportSymbol('proto.pan.general.member.InitGenderResponse', null, global);
goog.exportSymbol('proto.pan.general.member.LoginRequest', null, global);
goog.exportSymbol('proto.pan.general.member.LoginRequest.Phase', null, global);
goog.exportSymbol('proto.pan.general.member.LoginResponse', null, global);
goog.exportSymbol('proto.pan.general.member.LoginResponse.CodeResult', null, global);
goog.exportSymbol('proto.pan.general.member.LoginResponse.LoginType', null, global);
goog.exportSymbol('proto.pan.general.member.MakeLoginTokenRequest', null, global);
goog.exportSymbol('proto.pan.general.member.MakeLoginTokenResponse', null, global);
goog.exportSymbol('proto.pan.general.member.MeRequest', null, global);
goog.exportSymbol('proto.pan.general.member.MeResponse', null, global);
goog.exportSymbol('proto.pan.general.member.ReadObjectRequest', null, global);
goog.exportSymbol('proto.pan.general.member.ReadObjectResponse', null, global);
goog.exportSymbol('proto.pan.general.member.RegisterRequest', null, global);
goog.exportSymbol('proto.pan.general.member.RegisterRequest.Phase', null, global);
goog.exportSymbol('proto.pan.general.member.RegisterResponse', null, global);
goog.exportSymbol('proto.pan.general.member.RegisterResponse.CodeResult', null, global);
goog.exportSymbol('proto.pan.general.member.ValidateAccessTokenRequest', null, global);
goog.exportSymbol('proto.pan.general.member.ValidateAccessTokenResponse', null, global);
goog.exportSymbol('proto.pan.general.member.ValidateLoginTokenRequest', null, global);
goog.exportSymbol('proto.pan.general.member.ValidateLoginTokenResponse', null, global);
goog.exportSymbol('proto.pan.general.member.ValidatePhoneRequest', null, global);
goog.exportSymbol('proto.pan.general.member.ValidatePhoneRequest.Phase', null, global);
goog.exportSymbol('proto.pan.general.member.ValidatePhoneResponse', null, global);
goog.exportSymbol('proto.pan.general.member.ValidatePhoneResponse.CodeResult', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.MeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.MeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.MeRequest.displayName = 'proto.pan.general.member.MeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.MeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.MeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.MeResponse.displayName = 'proto.pan.general.member.MeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.RegisterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.RegisterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.RegisterRequest.displayName = 'proto.pan.general.member.RegisterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.RegisterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.RegisterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.RegisterResponse.displayName = 'proto.pan.general.member.RegisterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.LoginRequest.displayName = 'proto.pan.general.member.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.LoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.LoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.LoginResponse.displayName = 'proto.pan.general.member.LoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.AccessTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.AccessTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.AccessTokenRequest.displayName = 'proto.pan.general.member.AccessTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.AccessTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.AccessTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.AccessTokenResponse.displayName = 'proto.pan.general.member.AccessTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.ValidateAccessTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.ValidateAccessTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.ValidateAccessTokenRequest.displayName = 'proto.pan.general.member.ValidateAccessTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.ValidateAccessTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.ValidateAccessTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.ValidateAccessTokenResponse.displayName = 'proto.pan.general.member.ValidateAccessTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.ValidatePhoneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.ValidatePhoneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.ValidatePhoneRequest.displayName = 'proto.pan.general.member.ValidatePhoneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.ValidatePhoneResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.ValidatePhoneResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.ValidatePhoneResponse.displayName = 'proto.pan.general.member.ValidatePhoneResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.InitGenderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.InitGenderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.InitGenderRequest.displayName = 'proto.pan.general.member.InitGenderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.InitGenderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.InitGenderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.InitGenderResponse.displayName = 'proto.pan.general.member.InitGenderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.ReadObjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pan.general.member.ReadObjectRequest.repeatedFields_, null);
};
goog.inherits(proto.pan.general.member.ReadObjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.ReadObjectRequest.displayName = 'proto.pan.general.member.ReadObjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.ReadObjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pan.general.member.ReadObjectResponse.repeatedFields_, null);
};
goog.inherits(proto.pan.general.member.ReadObjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.ReadObjectResponse.displayName = 'proto.pan.general.member.ReadObjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.MakeLoginTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.MakeLoginTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.MakeLoginTokenRequest.displayName = 'proto.pan.general.member.MakeLoginTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.MakeLoginTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.MakeLoginTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.MakeLoginTokenResponse.displayName = 'proto.pan.general.member.MakeLoginTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.ValidateLoginTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.ValidateLoginTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.ValidateLoginTokenRequest.displayName = 'proto.pan.general.member.ValidateLoginTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.general.member.ValidateLoginTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.general.member.ValidateLoginTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.general.member.ValidateLoginTokenResponse.displayName = 'proto.pan.general.member.ValidateLoginTokenResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.MeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.MeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.MeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.MeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.MeRequest}
 */
proto.pan.general.member.MeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.MeRequest;
  return proto.pan.general.member.MeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.MeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.MeRequest}
 */
proto.pan.general.member.MeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.MeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.MeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.MeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.MeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.pan.general.member.MeRequest.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.MeRequest} returns this
 */
proto.pan.general.member.MeRequest.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.MeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.MeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.MeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.MeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && atom_member_first_pb.Object.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.MeResponse}
 */
proto.pan.general.member.MeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.MeResponse;
  return proto.pan.general.member.MeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.MeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.MeResponse}
 */
proto.pan.general.member.MeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new atom_member_first_pb.Object;
      reader.readMessage(value,atom_member_first_pb.Object.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.MeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.MeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.MeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.MeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      atom_member_first_pb.Object.serializeBinaryToWriter
    );
  }
};


/**
 * optional pan.atom.member.Object user = 1;
 * @return {?proto.pan.atom.member.Object}
 */
proto.pan.general.member.MeResponse.prototype.getUser = function() {
  return /** @type{?proto.pan.atom.member.Object} */ (
    jspb.Message.getWrapperField(this, atom_member_first_pb.Object, 1));
};


/**
 * @param {?proto.pan.atom.member.Object|undefined} value
 * @return {!proto.pan.general.member.MeResponse} returns this
*/
proto.pan.general.member.MeResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pan.general.member.MeResponse} returns this
 */
proto.pan.general.member.MeResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pan.general.member.MeResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.RegisterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.RegisterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.RegisterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.RegisterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    phase: jspb.Message.getFieldWithDefault(msg, 1, 0),
    phaseSessionValid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phaseSessionToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phaseCheckExistenceValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phaseInputCodeValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phaseInputNameValue: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phaseCheckExistenceCountryCode: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.RegisterRequest}
 */
proto.pan.general.member.RegisterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.RegisterRequest;
  return proto.pan.general.member.RegisterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.RegisterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.RegisterRequest}
 */
proto.pan.general.member.RegisterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pan.general.member.RegisterRequest.Phase} */ (reader.readEnum());
      msg.setPhase(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseSessionValid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseSessionToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseCheckExistenceValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseInputCodeValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseInputNameValue(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseCheckExistenceCountryCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.RegisterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.RegisterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.RegisterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.RegisterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPhaseSessionValid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhaseSessionToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhaseCheckExistenceValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhaseInputCodeValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhaseInputNameValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhaseCheckExistenceCountryCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.pan.general.member.RegisterRequest.Phase = {
  PHASE_DEFAULT: 0,
  PHASE_CHECK_EXISTENCE: 1,
  PHASE_INPUT_CODE: 2,
  PHASE_INPUT_NAME: 3,
  PHASE_DONE: 4
};

/**
 * optional Phase phase = 1;
 * @return {!proto.pan.general.member.RegisterRequest.Phase}
 */
proto.pan.general.member.RegisterRequest.prototype.getPhase = function() {
  return /** @type {!proto.pan.general.member.RegisterRequest.Phase} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pan.general.member.RegisterRequest.Phase} value
 * @return {!proto.pan.general.member.RegisterRequest} returns this
 */
proto.pan.general.member.RegisterRequest.prototype.setPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string phase_session_valid = 2;
 * @return {string}
 */
proto.pan.general.member.RegisterRequest.prototype.getPhaseSessionValid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.RegisterRequest} returns this
 */
proto.pan.general.member.RegisterRequest.prototype.setPhaseSessionValid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phase_session_token = 3;
 * @return {string}
 */
proto.pan.general.member.RegisterRequest.prototype.getPhaseSessionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.RegisterRequest} returns this
 */
proto.pan.general.member.RegisterRequest.prototype.setPhaseSessionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phase_check_existence_value = 4;
 * @return {string}
 */
proto.pan.general.member.RegisterRequest.prototype.getPhaseCheckExistenceValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.RegisterRequest} returns this
 */
proto.pan.general.member.RegisterRequest.prototype.setPhaseCheckExistenceValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phase_input_code_value = 5;
 * @return {string}
 */
proto.pan.general.member.RegisterRequest.prototype.getPhaseInputCodeValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.RegisterRequest} returns this
 */
proto.pan.general.member.RegisterRequest.prototype.setPhaseInputCodeValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phase_input_name_value = 6;
 * @return {string}
 */
proto.pan.general.member.RegisterRequest.prototype.getPhaseInputNameValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.RegisterRequest} returns this
 */
proto.pan.general.member.RegisterRequest.prototype.setPhaseInputNameValue = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string phase_check_existence_country_code = 7;
 * @return {string}
 */
proto.pan.general.member.RegisterRequest.prototype.getPhaseCheckExistenceCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.RegisterRequest} returns this
 */
proto.pan.general.member.RegisterRequest.prototype.setPhaseCheckExistenceCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.RegisterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.RegisterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.RegisterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.RegisterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    phase: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextPhase: jspb.Message.getFieldWithDefault(msg, 3, 0),
    phaseInputCodeResult: jspb.Message.getFieldWithDefault(msg, 5, 0),
    phaseInputCodeTimeRemaining: jspb.Message.getFieldWithDefault(msg, 6, 0),
    phaseDoneAuthToken: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phaseSessionToken: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.RegisterResponse}
 */
proto.pan.general.member.RegisterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.RegisterResponse;
  return proto.pan.general.member.RegisterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.RegisterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.RegisterResponse}
 */
proto.pan.general.member.RegisterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {!proto.pan.general.member.RegisterRequest.Phase} */ (reader.readEnum());
      msg.setPhase(value);
      break;
    case 3:
      var value = /** @type {!proto.pan.general.member.RegisterRequest.Phase} */ (reader.readEnum());
      msg.setNextPhase(value);
      break;
    case 5:
      var value = /** @type {!proto.pan.general.member.RegisterResponse.CodeResult} */ (reader.readEnum());
      msg.setPhaseInputCodeResult(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPhaseInputCodeTimeRemaining(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseDoneAuthToken(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseSessionToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.RegisterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.RegisterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.RegisterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.RegisterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNextPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPhaseInputCodeResult();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPhaseInputCodeTimeRemaining();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPhaseDoneAuthToken();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhaseSessionToken();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.pan.general.member.RegisterResponse.CodeResult = {
  CODE_RESULT_DEFAULT: 0,
  CODE_RESULT_OK: 1,
  CODE_RESULT_RESENT: 3
};

/**
 * optional RegisterRequest.Phase phase = 2;
 * @return {!proto.pan.general.member.RegisterRequest.Phase}
 */
proto.pan.general.member.RegisterResponse.prototype.getPhase = function() {
  return /** @type {!proto.pan.general.member.RegisterRequest.Phase} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pan.general.member.RegisterRequest.Phase} value
 * @return {!proto.pan.general.member.RegisterResponse} returns this
 */
proto.pan.general.member.RegisterResponse.prototype.setPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional RegisterRequest.Phase next_phase = 3;
 * @return {!proto.pan.general.member.RegisterRequest.Phase}
 */
proto.pan.general.member.RegisterResponse.prototype.getNextPhase = function() {
  return /** @type {!proto.pan.general.member.RegisterRequest.Phase} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pan.general.member.RegisterRequest.Phase} value
 * @return {!proto.pan.general.member.RegisterResponse} returns this
 */
proto.pan.general.member.RegisterResponse.prototype.setNextPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional CodeResult phase_input_code_result = 5;
 * @return {!proto.pan.general.member.RegisterResponse.CodeResult}
 */
proto.pan.general.member.RegisterResponse.prototype.getPhaseInputCodeResult = function() {
  return /** @type {!proto.pan.general.member.RegisterResponse.CodeResult} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.pan.general.member.RegisterResponse.CodeResult} value
 * @return {!proto.pan.general.member.RegisterResponse} returns this
 */
proto.pan.general.member.RegisterResponse.prototype.setPhaseInputCodeResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 phase_input_code_time_remaining = 6;
 * @return {number}
 */
proto.pan.general.member.RegisterResponse.prototype.getPhaseInputCodeTimeRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pan.general.member.RegisterResponse} returns this
 */
proto.pan.general.member.RegisterResponse.prototype.setPhaseInputCodeTimeRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string phase_done_auth_token = 7;
 * @return {string}
 */
proto.pan.general.member.RegisterResponse.prototype.getPhaseDoneAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.RegisterResponse} returns this
 */
proto.pan.general.member.RegisterResponse.prototype.setPhaseDoneAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phase_session_token = 8;
 * @return {string}
 */
proto.pan.general.member.RegisterResponse.prototype.getPhaseSessionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.RegisterResponse} returns this
 */
proto.pan.general.member.RegisterResponse.prototype.setPhaseSessionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    phase: jspb.Message.getFieldWithDefault(msg, 1, 0),
    phaseSessionValid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phaseSessionToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phaseCheckExistenceValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phaseInputCodeValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phaseInputCodeQuesIndex: jspb.Message.getFieldWithDefault(msg, 6, 0),
    phaseInputCodeQuesValue: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phaseCheckExistenceCountryCode: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.LoginRequest}
 */
proto.pan.general.member.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.LoginRequest;
  return proto.pan.general.member.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.LoginRequest}
 */
proto.pan.general.member.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pan.general.member.LoginRequest.Phase} */ (reader.readEnum());
      msg.setPhase(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseSessionValid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseSessionToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseCheckExistenceValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseInputCodeValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPhaseInputCodeQuesIndex(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseInputCodeQuesValue(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseCheckExistenceCountryCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPhaseSessionValid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhaseSessionToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhaseCheckExistenceValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhaseInputCodeValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhaseInputCodeQuesIndex();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPhaseInputCodeQuesValue();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhaseCheckExistenceCountryCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.pan.general.member.LoginRequest.Phase = {
  PHASE_DEFAULT: 0,
  PHASE_CHECK_EXISTENCE: 1,
  PHASE_INPUT_CODE: 2,
  PHASE_DONE: 3
};

/**
 * optional Phase phase = 1;
 * @return {!proto.pan.general.member.LoginRequest.Phase}
 */
proto.pan.general.member.LoginRequest.prototype.getPhase = function() {
  return /** @type {!proto.pan.general.member.LoginRequest.Phase} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pan.general.member.LoginRequest.Phase} value
 * @return {!proto.pan.general.member.LoginRequest} returns this
 */
proto.pan.general.member.LoginRequest.prototype.setPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string phase_session_valid = 2;
 * @return {string}
 */
proto.pan.general.member.LoginRequest.prototype.getPhaseSessionValid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.LoginRequest} returns this
 */
proto.pan.general.member.LoginRequest.prototype.setPhaseSessionValid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phase_session_token = 3;
 * @return {string}
 */
proto.pan.general.member.LoginRequest.prototype.getPhaseSessionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.LoginRequest} returns this
 */
proto.pan.general.member.LoginRequest.prototype.setPhaseSessionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phase_check_existence_value = 4;
 * @return {string}
 */
proto.pan.general.member.LoginRequest.prototype.getPhaseCheckExistenceValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.LoginRequest} returns this
 */
proto.pan.general.member.LoginRequest.prototype.setPhaseCheckExistenceValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phase_input_code_value = 5;
 * @return {string}
 */
proto.pan.general.member.LoginRequest.prototype.getPhaseInputCodeValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.LoginRequest} returns this
 */
proto.pan.general.member.LoginRequest.prototype.setPhaseInputCodeValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 phase_input_code_ques_index = 6;
 * @return {number}
 */
proto.pan.general.member.LoginRequest.prototype.getPhaseInputCodeQuesIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pan.general.member.LoginRequest} returns this
 */
proto.pan.general.member.LoginRequest.prototype.setPhaseInputCodeQuesIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string phase_input_code_ques_value = 7;
 * @return {string}
 */
proto.pan.general.member.LoginRequest.prototype.getPhaseInputCodeQuesValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.LoginRequest} returns this
 */
proto.pan.general.member.LoginRequest.prototype.setPhaseInputCodeQuesValue = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phase_check_existence_country_code = 8;
 * @return {string}
 */
proto.pan.general.member.LoginRequest.prototype.getPhaseCheckExistenceCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.LoginRequest} returns this
 */
proto.pan.general.member.LoginRequest.prototype.setPhaseCheckExistenceCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.LoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.LoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.LoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.LoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    phase: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextPhase: jspb.Message.getFieldWithDefault(msg, 3, 0),
    phaseCheckExistenceLoginType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    phaseCheckExistenceQuestion: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    phaseInputCodeResult: jspb.Message.getFieldWithDefault(msg, 5, 0),
    phaseInputCodeTimeRemaining: jspb.Message.getFieldWithDefault(msg, 6, 0),
    phaseInputQuesResult: jspb.Message.getFieldWithDefault(msg, 7, 0),
    phaseDoneAuthToken: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phaseSessionToken: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.LoginResponse}
 */
proto.pan.general.member.LoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.LoginResponse;
  return proto.pan.general.member.LoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.LoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.LoginResponse}
 */
proto.pan.general.member.LoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {!proto.pan.general.member.LoginRequest.Phase} */ (reader.readEnum());
      msg.setPhase(value);
      break;
    case 3:
      var value = /** @type {!proto.pan.general.member.LoginRequest.Phase} */ (reader.readEnum());
      msg.setNextPhase(value);
      break;
    case 4:
      var value = /** @type {!proto.pan.general.member.LoginResponse.LoginType} */ (reader.readEnum());
      msg.setPhaseCheckExistenceLoginType(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhaseCheckExistenceQuestion(value);
      break;
    case 5:
      var value = /** @type {!proto.pan.general.member.LoginResponse.CodeResult} */ (reader.readEnum());
      msg.setPhaseInputCodeResult(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPhaseInputCodeTimeRemaining(value);
      break;
    case 7:
      var value = /** @type {!proto.pan.general.member.LoginResponse.CodeResult} */ (reader.readEnum());
      msg.setPhaseInputQuesResult(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseDoneAuthToken(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseSessionToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.LoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.LoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.LoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.LoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNextPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPhaseCheckExistenceLoginType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPhaseCheckExistenceQuestion();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPhaseInputCodeResult();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPhaseInputCodeTimeRemaining();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPhaseInputQuesResult();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPhaseDoneAuthToken();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhaseSessionToken();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.pan.general.member.LoginResponse.LoginType = {
  LOGIN_TYPE_DEFAULT: 0,
  LOGIN_TYPE_PHONE: 1,
  LOGIN_TYPE_EMAIL: 2
};

/**
 * @enum {number}
 */
proto.pan.general.member.LoginResponse.CodeResult = {
  CODE_RESULT_DEFAULT: 0,
  CODE_RESULT_OK: 1,
  CODE_RESULT_RESENT: 3
};

/**
 * optional LoginRequest.Phase phase = 2;
 * @return {!proto.pan.general.member.LoginRequest.Phase}
 */
proto.pan.general.member.LoginResponse.prototype.getPhase = function() {
  return /** @type {!proto.pan.general.member.LoginRequest.Phase} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pan.general.member.LoginRequest.Phase} value
 * @return {!proto.pan.general.member.LoginResponse} returns this
 */
proto.pan.general.member.LoginResponse.prototype.setPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional LoginRequest.Phase next_phase = 3;
 * @return {!proto.pan.general.member.LoginRequest.Phase}
 */
proto.pan.general.member.LoginResponse.prototype.getNextPhase = function() {
  return /** @type {!proto.pan.general.member.LoginRequest.Phase} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pan.general.member.LoginRequest.Phase} value
 * @return {!proto.pan.general.member.LoginResponse} returns this
 */
proto.pan.general.member.LoginResponse.prototype.setNextPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional LoginType phase_check_existence_login_type = 4;
 * @return {!proto.pan.general.member.LoginResponse.LoginType}
 */
proto.pan.general.member.LoginResponse.prototype.getPhaseCheckExistenceLoginType = function() {
  return /** @type {!proto.pan.general.member.LoginResponse.LoginType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.pan.general.member.LoginResponse.LoginType} value
 * @return {!proto.pan.general.member.LoginResponse} returns this
 */
proto.pan.general.member.LoginResponse.prototype.setPhaseCheckExistenceLoginType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool phase_check_existence_question = 10;
 * @return {boolean}
 */
proto.pan.general.member.LoginResponse.prototype.getPhaseCheckExistenceQuestion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pan.general.member.LoginResponse} returns this
 */
proto.pan.general.member.LoginResponse.prototype.setPhaseCheckExistenceQuestion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional CodeResult phase_input_code_result = 5;
 * @return {!proto.pan.general.member.LoginResponse.CodeResult}
 */
proto.pan.general.member.LoginResponse.prototype.getPhaseInputCodeResult = function() {
  return /** @type {!proto.pan.general.member.LoginResponse.CodeResult} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.pan.general.member.LoginResponse.CodeResult} value
 * @return {!proto.pan.general.member.LoginResponse} returns this
 */
proto.pan.general.member.LoginResponse.prototype.setPhaseInputCodeResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 phase_input_code_time_remaining = 6;
 * @return {number}
 */
proto.pan.general.member.LoginResponse.prototype.getPhaseInputCodeTimeRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pan.general.member.LoginResponse} returns this
 */
proto.pan.general.member.LoginResponse.prototype.setPhaseInputCodeTimeRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional CodeResult phase_input_ques_result = 7;
 * @return {!proto.pan.general.member.LoginResponse.CodeResult}
 */
proto.pan.general.member.LoginResponse.prototype.getPhaseInputQuesResult = function() {
  return /** @type {!proto.pan.general.member.LoginResponse.CodeResult} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.pan.general.member.LoginResponse.CodeResult} value
 * @return {!proto.pan.general.member.LoginResponse} returns this
 */
proto.pan.general.member.LoginResponse.prototype.setPhaseInputQuesResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string phase_done_auth_token = 8;
 * @return {string}
 */
proto.pan.general.member.LoginResponse.prototype.getPhaseDoneAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.LoginResponse} returns this
 */
proto.pan.general.member.LoginResponse.prototype.setPhaseDoneAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string phase_session_token = 9;
 * @return {string}
 */
proto.pan.general.member.LoginResponse.prototype.getPhaseSessionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.LoginResponse} returns this
 */
proto.pan.general.member.LoginResponse.prototype.setPhaseSessionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.AccessTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.AccessTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.AccessTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.AccessTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.AccessTokenRequest}
 */
proto.pan.general.member.AccessTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.AccessTokenRequest;
  return proto.pan.general.member.AccessTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.AccessTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.AccessTokenRequest}
 */
proto.pan.general.member.AccessTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.AccessTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.AccessTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.AccessTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.AccessTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_token = 1;
 * @return {string}
 */
proto.pan.general.member.AccessTokenRequest.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.AccessTokenRequest} returns this
 */
proto.pan.general.member.AccessTokenRequest.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.AccessTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.AccessTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.AccessTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.AccessTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.AccessTokenResponse}
 */
proto.pan.general.member.AccessTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.AccessTokenResponse;
  return proto.pan.general.member.AccessTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.AccessTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.AccessTokenResponse}
 */
proto.pan.general.member.AccessTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.AccessTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.AccessTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.AccessTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.AccessTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string access_token = 2;
 * @return {string}
 */
proto.pan.general.member.AccessTokenResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.AccessTokenResponse} returns this
 */
proto.pan.general.member.AccessTokenResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.ValidateAccessTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.ValidateAccessTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.ValidateAccessTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ValidateAccessTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.ValidateAccessTokenRequest}
 */
proto.pan.general.member.ValidateAccessTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.ValidateAccessTokenRequest;
  return proto.pan.general.member.ValidateAccessTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.ValidateAccessTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.ValidateAccessTokenRequest}
 */
proto.pan.general.member.ValidateAccessTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.ValidateAccessTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.ValidateAccessTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.ValidateAccessTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ValidateAccessTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.pan.general.member.ValidateAccessTokenRequest.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidateAccessTokenRequest} returns this
 */
proto.pan.general.member.ValidateAccessTokenRequest.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.ValidateAccessTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.ValidateAccessTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.ValidateAccessTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ValidateAccessTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.ValidateAccessTokenResponse}
 */
proto.pan.general.member.ValidateAccessTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.ValidateAccessTokenResponse;
  return proto.pan.general.member.ValidateAccessTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.ValidateAccessTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.ValidateAccessTokenResponse}
 */
proto.pan.general.member.ValidateAccessTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.ValidateAccessTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.ValidateAccessTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.ValidateAccessTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ValidateAccessTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.ValidatePhoneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.ValidatePhoneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ValidatePhoneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    phase: jspb.Message.getFieldWithDefault(msg, 1, 0),
    phaseSessionValid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phaseSessionToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phaseCheckExistenceValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phaseInputCodeValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phaseInputNameValue: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phaseCheckExistenceCountryCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    accessToken: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.ValidatePhoneRequest}
 */
proto.pan.general.member.ValidatePhoneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.ValidatePhoneRequest;
  return proto.pan.general.member.ValidatePhoneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.ValidatePhoneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.ValidatePhoneRequest}
 */
proto.pan.general.member.ValidatePhoneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pan.general.member.ValidatePhoneRequest.Phase} */ (reader.readEnum());
      msg.setPhase(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseSessionValid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseSessionToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseCheckExistenceValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseInputCodeValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseInputNameValue(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseCheckExistenceCountryCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.ValidatePhoneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.ValidatePhoneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ValidatePhoneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPhaseSessionValid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhaseSessionToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhaseCheckExistenceValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhaseInputCodeValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhaseInputNameValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhaseCheckExistenceCountryCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.pan.general.member.ValidatePhoneRequest.Phase = {
  PHASE_DEFAULT: 0,
  PHASE_CHECK_EXISTENCE: 1,
  PHASE_INPUT_CODE: 2,
  PHASE_INPUT_NAME: 3,
  PHASE_DONE: 4
};

/**
 * optional Phase phase = 1;
 * @return {!proto.pan.general.member.ValidatePhoneRequest.Phase}
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.getPhase = function() {
  return /** @type {!proto.pan.general.member.ValidatePhoneRequest.Phase} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pan.general.member.ValidatePhoneRequest.Phase} value
 * @return {!proto.pan.general.member.ValidatePhoneRequest} returns this
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.setPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string phase_session_valid = 2;
 * @return {string}
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.getPhaseSessionValid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidatePhoneRequest} returns this
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.setPhaseSessionValid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phase_session_token = 3;
 * @return {string}
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.getPhaseSessionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidatePhoneRequest} returns this
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.setPhaseSessionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phase_check_existence_value = 4;
 * @return {string}
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.getPhaseCheckExistenceValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidatePhoneRequest} returns this
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.setPhaseCheckExistenceValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phase_input_code_value = 5;
 * @return {string}
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.getPhaseInputCodeValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidatePhoneRequest} returns this
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.setPhaseInputCodeValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phase_input_name_value = 6;
 * @return {string}
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.getPhaseInputNameValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidatePhoneRequest} returns this
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.setPhaseInputNameValue = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string phase_check_existence_country_code = 7;
 * @return {string}
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.getPhaseCheckExistenceCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidatePhoneRequest} returns this
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.setPhaseCheckExistenceCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string access_token = 8;
 * @return {string}
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidatePhoneRequest} returns this
 */
proto.pan.general.member.ValidatePhoneRequest.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.ValidatePhoneResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.ValidatePhoneResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.ValidatePhoneResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ValidatePhoneResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    phase: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nextPhase: jspb.Message.getFieldWithDefault(msg, 2, 0),
    phaseInputCodeResult: jspb.Message.getFieldWithDefault(msg, 3, 0),
    phaseInputCodeTimeRemaining: jspb.Message.getFieldWithDefault(msg, 4, 0),
    phaseSessionToken: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.ValidatePhoneResponse}
 */
proto.pan.general.member.ValidatePhoneResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.ValidatePhoneResponse;
  return proto.pan.general.member.ValidatePhoneResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.ValidatePhoneResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.ValidatePhoneResponse}
 */
proto.pan.general.member.ValidatePhoneResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pan.general.member.ValidatePhoneRequest.Phase} */ (reader.readEnum());
      msg.setPhase(value);
      break;
    case 2:
      var value = /** @type {!proto.pan.general.member.ValidatePhoneRequest.Phase} */ (reader.readEnum());
      msg.setNextPhase(value);
      break;
    case 3:
      var value = /** @type {!proto.pan.general.member.ValidatePhoneResponse.CodeResult} */ (reader.readEnum());
      msg.setPhaseInputCodeResult(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPhaseInputCodeTimeRemaining(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhaseSessionToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.ValidatePhoneResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.ValidatePhoneResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.ValidatePhoneResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ValidatePhoneResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNextPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPhaseInputCodeResult();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPhaseInputCodeTimeRemaining();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPhaseSessionToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.pan.general.member.ValidatePhoneResponse.CodeResult = {
  CODE_RESULT_DEFAULT: 0,
  CODE_RESULT_OK: 1,
  CODE_RESULT_RESENT: 3
};

/**
 * optional ValidatePhoneRequest.Phase phase = 1;
 * @return {!proto.pan.general.member.ValidatePhoneRequest.Phase}
 */
proto.pan.general.member.ValidatePhoneResponse.prototype.getPhase = function() {
  return /** @type {!proto.pan.general.member.ValidatePhoneRequest.Phase} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pan.general.member.ValidatePhoneRequest.Phase} value
 * @return {!proto.pan.general.member.ValidatePhoneResponse} returns this
 */
proto.pan.general.member.ValidatePhoneResponse.prototype.setPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ValidatePhoneRequest.Phase next_phase = 2;
 * @return {!proto.pan.general.member.ValidatePhoneRequest.Phase}
 */
proto.pan.general.member.ValidatePhoneResponse.prototype.getNextPhase = function() {
  return /** @type {!proto.pan.general.member.ValidatePhoneRequest.Phase} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pan.general.member.ValidatePhoneRequest.Phase} value
 * @return {!proto.pan.general.member.ValidatePhoneResponse} returns this
 */
proto.pan.general.member.ValidatePhoneResponse.prototype.setNextPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional CodeResult phase_input_code_result = 3;
 * @return {!proto.pan.general.member.ValidatePhoneResponse.CodeResult}
 */
proto.pan.general.member.ValidatePhoneResponse.prototype.getPhaseInputCodeResult = function() {
  return /** @type {!proto.pan.general.member.ValidatePhoneResponse.CodeResult} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pan.general.member.ValidatePhoneResponse.CodeResult} value
 * @return {!proto.pan.general.member.ValidatePhoneResponse} returns this
 */
proto.pan.general.member.ValidatePhoneResponse.prototype.setPhaseInputCodeResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 phase_input_code_time_remaining = 4;
 * @return {number}
 */
proto.pan.general.member.ValidatePhoneResponse.prototype.getPhaseInputCodeTimeRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pan.general.member.ValidatePhoneResponse} returns this
 */
proto.pan.general.member.ValidatePhoneResponse.prototype.setPhaseInputCodeTimeRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string phase_session_token = 6;
 * @return {string}
 */
proto.pan.general.member.ValidatePhoneResponse.prototype.getPhaseSessionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidatePhoneResponse} returns this
 */
proto.pan.general.member.ValidatePhoneResponse.prototype.setPhaseSessionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.InitGenderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.InitGenderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.InitGenderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.InitGenderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.InitGenderRequest}
 */
proto.pan.general.member.InitGenderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.InitGenderRequest;
  return proto.pan.general.member.InitGenderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.InitGenderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.InitGenderRequest}
 */
proto.pan.general.member.InitGenderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = /** @type {!proto.pan.atom.member.Gender} */ (reader.readEnum());
      msg.setGender(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.InitGenderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.InitGenderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.InitGenderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.InitGenderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.pan.general.member.InitGenderRequest.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.InitGenderRequest} returns this
 */
proto.pan.general.member.InitGenderRequest.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional pan.atom.member.Gender gender = 2;
 * @return {!proto.pan.atom.member.Gender}
 */
proto.pan.general.member.InitGenderRequest.prototype.getGender = function() {
  return /** @type {!proto.pan.atom.member.Gender} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pan.atom.member.Gender} value
 * @return {!proto.pan.general.member.InitGenderRequest} returns this
 */
proto.pan.general.member.InitGenderRequest.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.InitGenderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.InitGenderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.InitGenderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.InitGenderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.InitGenderResponse}
 */
proto.pan.general.member.InitGenderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.InitGenderResponse;
  return proto.pan.general.member.InitGenderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.InitGenderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.InitGenderResponse}
 */
proto.pan.general.member.InitGenderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.InitGenderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.InitGenderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.InitGenderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.InitGenderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pan.general.member.ReadObjectRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.ReadObjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.ReadObjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.ReadObjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ReadObjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.ReadObjectRequest}
 */
proto.pan.general.member.ReadObjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.ReadObjectRequest;
  return proto.pan.general.member.ReadObjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.ReadObjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.ReadObjectRequest}
 */
proto.pan.general.member.ReadObjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setUidsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.ReadObjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.ReadObjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.ReadObjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ReadObjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUidsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 uids = 1;
 * @return {!Array<number>}
 */
proto.pan.general.member.ReadObjectRequest.prototype.getUidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.pan.general.member.ReadObjectRequest} returns this
 */
proto.pan.general.member.ReadObjectRequest.prototype.setUidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.pan.general.member.ReadObjectRequest} returns this
 */
proto.pan.general.member.ReadObjectRequest.prototype.addUids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pan.general.member.ReadObjectRequest} returns this
 */
proto.pan.general.member.ReadObjectRequest.prototype.clearUidsList = function() {
  return this.setUidsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pan.general.member.ReadObjectResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.ReadObjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.ReadObjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.ReadObjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ReadObjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    atom_member_first_pb.Object.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.ReadObjectResponse}
 */
proto.pan.general.member.ReadObjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.ReadObjectResponse;
  return proto.pan.general.member.ReadObjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.ReadObjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.ReadObjectResponse}
 */
proto.pan.general.member.ReadObjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new atom_member_first_pb.Object;
      reader.readMessage(value,atom_member_first_pb.Object.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.ReadObjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.ReadObjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.ReadObjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ReadObjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      atom_member_first_pb.Object.serializeBinaryToWriter
    );
  }
};


/**
 * repeated pan.atom.member.Object objects = 1;
 * @return {!Array<!proto.pan.atom.member.Object>}
 */
proto.pan.general.member.ReadObjectResponse.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.pan.atom.member.Object>} */ (
    jspb.Message.getRepeatedWrapperField(this, atom_member_first_pb.Object, 1));
};


/**
 * @param {!Array<!proto.pan.atom.member.Object>} value
 * @return {!proto.pan.general.member.ReadObjectResponse} returns this
*/
proto.pan.general.member.ReadObjectResponse.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pan.atom.member.Object=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pan.atom.member.Object}
 */
proto.pan.general.member.ReadObjectResponse.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pan.atom.member.Object, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pan.general.member.ReadObjectResponse} returns this
 */
proto.pan.general.member.ReadObjectResponse.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.MakeLoginTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.MakeLoginTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.MakeLoginTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.MakeLoginTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appSecret: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.MakeLoginTokenRequest}
 */
proto.pan.general.member.MakeLoginTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.MakeLoginTokenRequest;
  return proto.pan.general.member.MakeLoginTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.MakeLoginTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.MakeLoginTokenRequest}
 */
proto.pan.general.member.MakeLoginTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppSecret(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.MakeLoginTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.MakeLoginTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.MakeLoginTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.MakeLoginTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.pan.general.member.MakeLoginTokenRequest.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.MakeLoginTokenRequest} returns this
 */
proto.pan.general.member.MakeLoginTokenRequest.prototype.setAppId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string app_secret = 2;
 * @return {string}
 */
proto.pan.general.member.MakeLoginTokenRequest.prototype.getAppSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.MakeLoginTokenRequest} returns this
 */
proto.pan.general.member.MakeLoginTokenRequest.prototype.setAppSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string auth_url = 3;
 * @return {string}
 */
proto.pan.general.member.MakeLoginTokenRequest.prototype.getAuthUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.MakeLoginTokenRequest} returns this
 */
proto.pan.general.member.MakeLoginTokenRequest.prototype.setAuthUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.MakeLoginTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.MakeLoginTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.MakeLoginTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.MakeLoginTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    loginToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.MakeLoginTokenResponse}
 */
proto.pan.general.member.MakeLoginTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.MakeLoginTokenResponse;
  return proto.pan.general.member.MakeLoginTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.MakeLoginTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.MakeLoginTokenResponse}
 */
proto.pan.general.member.MakeLoginTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.MakeLoginTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.MakeLoginTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.MakeLoginTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.MakeLoginTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoginToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string login_token = 1;
 * @return {string}
 */
proto.pan.general.member.MakeLoginTokenResponse.prototype.getLoginToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.MakeLoginTokenResponse} returns this
 */
proto.pan.general.member.MakeLoginTokenResponse.prototype.setLoginToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.ValidateLoginTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.ValidateLoginTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.ValidateLoginTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ValidateLoginTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    loginToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    appSecret: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.ValidateLoginTokenRequest}
 */
proto.pan.general.member.ValidateLoginTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.ValidateLoginTokenRequest;
  return proto.pan.general.member.ValidateLoginTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.ValidateLoginTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.ValidateLoginTokenRequest}
 */
proto.pan.general.member.ValidateLoginTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.ValidateLoginTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.ValidateLoginTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.ValidateLoginTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ValidateLoginTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoginToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAppSecret();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string login_token = 1;
 * @return {string}
 */
proto.pan.general.member.ValidateLoginTokenRequest.prototype.getLoginToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidateLoginTokenRequest} returns this
 */
proto.pan.general.member.ValidateLoginTokenRequest.prototype.setLoginToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string app_id = 2;
 * @return {string}
 */
proto.pan.general.member.ValidateLoginTokenRequest.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidateLoginTokenRequest} returns this
 */
proto.pan.general.member.ValidateLoginTokenRequest.prototype.setAppId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string app_secret = 3;
 * @return {string}
 */
proto.pan.general.member.ValidateLoginTokenRequest.prototype.getAppSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidateLoginTokenRequest} returns this
 */
proto.pan.general.member.ValidateLoginTokenRequest.prototype.setAppSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.general.member.ValidateLoginTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.general.member.ValidateLoginTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.general.member.ValidateLoginTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ValidateLoginTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.general.member.ValidateLoginTokenResponse}
 */
proto.pan.general.member.ValidateLoginTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.general.member.ValidateLoginTokenResponse;
  return proto.pan.general.member.ValidateLoginTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.general.member.ValidateLoginTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.general.member.ValidateLoginTokenResponse}
 */
proto.pan.general.member.ValidateLoginTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.general.member.ValidateLoginTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.general.member.ValidateLoginTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.general.member.ValidateLoginTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.general.member.ValidateLoginTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_url = 1;
 * @return {string}
 */
proto.pan.general.member.ValidateLoginTokenResponse.prototype.getAuthUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.general.member.ValidateLoginTokenResponse} returns this
 */
proto.pan.general.member.ValidateLoginTokenResponse.prototype.setAuthUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.pan.general.member);
