import React from 'react'
import i18n from '../../components/i18next/i18n'
import {Luminous} from 'luminous-lightbox'
import {onPlus, onMinus, onInput, maxQuantity} from './price_handler'

const MyLuminous = Luminous
class AdditionalPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: this.props.quantity,
      removed: false
    }
    this.plusHandler = this.plusHandler.bind(this)
    this.minusHandler = this.minusHandler.bind(this)
    this.clearItem = this.clearItem.bind(this)
    this.changeQuantity = this.changeQuantity.bind(this)
  }
  componentDidMount() {
    if (this.refs.medium_photo) {
      new Luminous(this.refs.medium_photo);
    }
  }
  plusHandler(event) {
    let quantity = onPlus(this.state.quantity, this.props.item, this.props.item, []);
    this.props.onAdditionalPurchaseChange(this.props.item.id, quantity);
    this.setState({quantity: quantity})
  }
  minusHandler(event) {
    let quantity = onMinus(this.state.quantity);
    this.props.onAdditionalPurchaseChange(this.props.item.id, quantity);
    // }
    this.setState({quantity: quantity})
  }
  clearItem() {
    this.props.onAdditionalPurchaseChange(this.props.item.id, 0);
    this.setState({removed: false, quantity: 0})
  }
  changeQuantity(event) {
    let quantity = onInput(parseInt(event.currentTarget.value), this.props.item, this.props.item, []);
    event.target.value = quantity
    this.props.onAdditionalPurchaseChange(this.props.item.id, quantity);
    this.setState({quantity: quantity});
  }
  render() {
    let minus_disabled = false;
    let plus_disabled = false;
    if (this.state.quantity <= 0)
      minus_disabled = true;
    if (this.state.quantity >= maxQuantity(this.props.item, this.props.item, []))
      plus_disabled = true;

    var quantity_control;
    var thumbnail = ''
    if (this.state.removed || this.props.item.stock <= 0) {
      return null
    }
    if (this.props.item.thumbnail != null) {
      thumbnail = <a className="zoom-in" href={this.props.item.medium} ref="medium_photo"><img className="img-fluid" src={this.props.item.thumbnail}/></a>
    } else {
      thumbnail = <img className="img-fluid" src="/img/no-thumb.png"/>
    }
    if (this.state.quantity == 0) {
      return (
        <div className={`cart-item-list ${this.props.expand ? '' : 'd-none'}`}>
          <div className="item-thumbnail">{thumbnail}</div>
          <div className="item-detail">
            <div className="variant-title additional">{this.props.item.title}</div>
            <div className="variant-price">
              <span className='currency'>NT$</span>{this.props.item.price}
            </div>
            <a className="select-quantity form-control additional" onClick={this.plusHandler}>{i18n.t("cart.click_additional")}</a>
          </div>
        </div>
      );
    }
    quantity_control = (
      <div className="select-quantity form-group">
        <input className="qty-input form-control"
               type="number" title="Qty" min="0" size="4" pattern="\d*" autoComplete="off"
               value={this.state.quantity} onChange={this.changeQuantity}/>
        <div className="qty-adjust">
          <span className={'plus' + (plus_disabled ? ' disabled' : '')} onClick={this.plusHandler}>
            <i className="fa fa-plus"></i>
          </span>
          <span className={'minus'+ (minus_disabled ? ' disabled' : '')} onClick={this.minusHandler}>
            <i className="fa fa-minus"></i>
          </span>
        </div>
      </div>
    )
    return (
      <div className="cart-item-list">
        <div className="item-thumbnail">{thumbnail}</div>
        <div className="item-detail">
          <div className="variant-title additional">{this.props.item.title}</div>
          <div className="variant-price">
            <span className="currency">NT$</span>{this.props.item.price}
          </div>
          {quantity_control}
        </div>
        <a className="item-delete" data-variant-id={this.props.item.id} onClick={this.clearItem}>
          <span className="delete-icon"></span>
          <span className="delete-txt">{i18n.t("remove")}</span>
        </a>
      </div>
    );
  }
}

export default AdditionalPurchase