import React from 'react'
import StoreCollection from './collection'
import { buildMenu, getLink } from '../../components/common'

export default class StoreCollectionMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu_items: buildMenu(this.props.menu_items)
    }
  }
  render() {
    let _menu_items = this.state.menu_items.filter(function(item) {
      if (item.children.length > 0) {
        let _children = item.children.filter(_item => _item.menu_type==='menu' || _item.menu_type==='collection')
        item.children = _children
      }
      if (item.menu_type == 'menu' || item.menu_type == 'collection') {
        return true
      } else {
        return false
      }
    })
    let menu_items = _menu_items.map((item, index) => {
      let item_html = null
      let pathname = location.pathname
      if(item.children.length > 0) {
        let children = item.children.map((sub_item, sub_item_index) => {
          // let item_pathname = ''
          // switch (sub_item.menu_type) {
          //   case 'product':
          //     item_pathname = `/products/${sub_item.product_token}`
          //     break;
          //   case 'collection':
          //     item_pathname = `/collections/${sub_item.collection_token}`
          //     break;
          //   case 'link':
          //     item_pathname = sub_item.link
          //     break;
          // }
          // return (
          //     <li className="secondary-item" key={sub_item_index} >
          //       <a className={"menu-link secondary " + (item_pathname == pathname ? 'active' : '')} href={item_pathname} title={sub_item.title} target={sub_item.menu_type == 'link' ? "_blank" : "_self"}>
          //         <span className="text">{sub_item.title}</span>
          //       </a>
          //     </li>
          //   )
          let item_pathname = getLink(sub_item)
          return (
              <li className="secondary-item" key={sub_item_index} >
                <a className={"menu-link secondary " + (item_pathname.replace(/\?menu_id=[0-9]+/g, '') == pathname ? 'active' : '')} href={item_pathname} title={sub_item.title} target={sub_item.menu_type == 'link' ? "_blank" : "_self"}>
                  <span className="text">{sub_item.title}</span>
                </a>
              </li>
            )
        })
        item_html =
        <li className="menu-item has-submenu" key={index}>
          <h5 aria-expanded="false" aria-haspopup="true" className="menu-title"><span className="text">{item.title}</span></h5>
          <ul className="secondary-menu" role="menu">
            {children}
          </ul>
        </li>
      } else if (item.menu_type == 'collection') {
        let item_pathname = item.collection ? `/collections/${item.collection.token}?menu_id=${item.id}` : "#0"
        item_html =
        <li className="menu-item" key={index}>
          <a className={"menu-title " + (item_pathname == pathname ? 'active' : '')} href={item_pathname} title={item.title}>{item.title}</a>
        </li>
      }
      return item_html
    })
    let collection = <StoreCollection {...this.props.collection} is_login={this.props.is_login} shop_token={this.props.shop_token} cart_items={this.props.cart_items} shop_customer={this.props.shop_customer} authenticity_token={this.props.authenticity_token} distribution={this.props.distribution} title_show={false} limit="30" distribution_percentage={this.props.distribution_percentage} vip_percentage={this.props.vip_percentage} agent_token={this.props.agent_token} shop_vips={this.props.shop_vips} />

    return (
      <div className="ls-store-collection pt-0" id="lsStoreCollection">
        <nav aria-label="breadcrumb" className="mb-5 d-none d-md-block">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">主頁</a></li>
              <li className="breadcrumb-item active">{this.props.collection && this.props.collection.title ? this.props.collection.title : '' }</li>
            </ol>
          </div>
        </nav>
        <div className="collection-section-container">
          {/*<hgroup className="section-hgroup collection">
            <h5 className="section-subtitle handwriting">collection</h5>
            <h2 className="section-title">{this.props.collection.title}</h2>
          </hgroup>*/}
          <div className="collection-row page-main-content">
            {/* JD 移除側邊 Collection 選單 */}
            {/* <aside className="sidebar collection-aside aside-menu">
              <ul className="menu" id="collectionMenu">
                {menu_items}
              </ul>
            </aside> */}
            <main className="collecion-content">
              {collection}
            </main>
          </div>
        </div>
      </div>
    )
  }
}