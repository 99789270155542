/**
 * @fileoverview gRPC-Web generated client stub for pan.general.member
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var atom_member_first_pb = require('../../atom/member/first_pb.js')
const proto = {};
proto.pan = {};
proto.pan.general = {};
proto.pan.general.member = require('./first_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pan.general.member.MemberServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pan.general.member.MemberServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pan.general.member.MeRequest,
 *   !proto.pan.general.member.MeResponse>}
 */
const methodDescriptor_MemberService_Me = new grpc.web.MethodDescriptor(
  '/pan.general.member.MemberService/Me',
  grpc.web.MethodType.UNARY,
  proto.pan.general.member.MeRequest,
  proto.pan.general.member.MeResponse,
  /**
   * @param {!proto.pan.general.member.MeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.MeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pan.general.member.MeRequest,
 *   !proto.pan.general.member.MeResponse>}
 */
const methodInfo_MemberService_Me = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pan.general.member.MeResponse,
  /**
   * @param {!proto.pan.general.member.MeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.MeResponse.deserializeBinary
);


/**
 * @param {!proto.pan.general.member.MeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pan.general.member.MeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pan.general.member.MeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pan.general.member.MemberServiceClient.prototype.me =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pan.general.member.MemberService/Me',
      request,
      metadata || {},
      methodDescriptor_MemberService_Me,
      callback);
};


/**
 * @param {!proto.pan.general.member.MeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pan.general.member.MeResponse>}
 *     A native promise that resolves to the response
 */
proto.pan.general.member.MemberServicePromiseClient.prototype.me =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pan.general.member.MemberService/Me',
      request,
      metadata || {},
      methodDescriptor_MemberService_Me);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pan.general.member.RegisterRequest,
 *   !proto.pan.general.member.RegisterResponse>}
 */
const methodDescriptor_MemberService_Register = new grpc.web.MethodDescriptor(
  '/pan.general.member.MemberService/Register',
  grpc.web.MethodType.UNARY,
  proto.pan.general.member.RegisterRequest,
  proto.pan.general.member.RegisterResponse,
  /**
   * @param {!proto.pan.general.member.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.RegisterResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pan.general.member.RegisterRequest,
 *   !proto.pan.general.member.RegisterResponse>}
 */
const methodInfo_MemberService_Register = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pan.general.member.RegisterResponse,
  /**
   * @param {!proto.pan.general.member.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.RegisterResponse.deserializeBinary
);


/**
 * @param {!proto.pan.general.member.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pan.general.member.RegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pan.general.member.RegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pan.general.member.MemberServiceClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pan.general.member.MemberService/Register',
      request,
      metadata || {},
      methodDescriptor_MemberService_Register,
      callback);
};


/**
 * @param {!proto.pan.general.member.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pan.general.member.RegisterResponse>}
 *     A native promise that resolves to the response
 */
proto.pan.general.member.MemberServicePromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pan.general.member.MemberService/Register',
      request,
      metadata || {},
      methodDescriptor_MemberService_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pan.general.member.LoginRequest,
 *   !proto.pan.general.member.LoginResponse>}
 */
const methodDescriptor_MemberService_Login = new grpc.web.MethodDescriptor(
  '/pan.general.member.MemberService/Login',
  grpc.web.MethodType.UNARY,
  proto.pan.general.member.LoginRequest,
  proto.pan.general.member.LoginResponse,
  /**
   * @param {!proto.pan.general.member.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.LoginResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pan.general.member.LoginRequest,
 *   !proto.pan.general.member.LoginResponse>}
 */
const methodInfo_MemberService_Login = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pan.general.member.LoginResponse,
  /**
   * @param {!proto.pan.general.member.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.pan.general.member.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pan.general.member.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pan.general.member.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pan.general.member.MemberServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pan.general.member.MemberService/Login',
      request,
      metadata || {},
      methodDescriptor_MemberService_Login,
      callback);
};


/**
 * @param {!proto.pan.general.member.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pan.general.member.LoginResponse>}
 *     A native promise that resolves to the response
 */
proto.pan.general.member.MemberServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pan.general.member.MemberService/Login',
      request,
      metadata || {},
      methodDescriptor_MemberService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pan.general.member.AccessTokenRequest,
 *   !proto.pan.general.member.AccessTokenResponse>}
 */
const methodDescriptor_MemberService_AccessToken = new grpc.web.MethodDescriptor(
  '/pan.general.member.MemberService/AccessToken',
  grpc.web.MethodType.UNARY,
  proto.pan.general.member.AccessTokenRequest,
  proto.pan.general.member.AccessTokenResponse,
  /**
   * @param {!proto.pan.general.member.AccessTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.AccessTokenResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pan.general.member.AccessTokenRequest,
 *   !proto.pan.general.member.AccessTokenResponse>}
 */
const methodInfo_MemberService_AccessToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pan.general.member.AccessTokenResponse,
  /**
   * @param {!proto.pan.general.member.AccessTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.AccessTokenResponse.deserializeBinary
);


/**
 * @param {!proto.pan.general.member.AccessTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pan.general.member.AccessTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pan.general.member.AccessTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pan.general.member.MemberServiceClient.prototype.accessToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pan.general.member.MemberService/AccessToken',
      request,
      metadata || {},
      methodDescriptor_MemberService_AccessToken,
      callback);
};


/**
 * @param {!proto.pan.general.member.AccessTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pan.general.member.AccessTokenResponse>}
 *     A native promise that resolves to the response
 */
proto.pan.general.member.MemberServicePromiseClient.prototype.accessToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pan.general.member.MemberService/AccessToken',
      request,
      metadata || {},
      methodDescriptor_MemberService_AccessToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pan.general.member.ValidateAccessTokenRequest,
 *   !proto.pan.general.member.ValidateAccessTokenResponse>}
 */
const methodDescriptor_MemberService_ValidateAccessToken = new grpc.web.MethodDescriptor(
  '/pan.general.member.MemberService/ValidateAccessToken',
  grpc.web.MethodType.UNARY,
  proto.pan.general.member.ValidateAccessTokenRequest,
  proto.pan.general.member.ValidateAccessTokenResponse,
  /**
   * @param {!proto.pan.general.member.ValidateAccessTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.ValidateAccessTokenResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pan.general.member.ValidateAccessTokenRequest,
 *   !proto.pan.general.member.ValidateAccessTokenResponse>}
 */
const methodInfo_MemberService_ValidateAccessToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pan.general.member.ValidateAccessTokenResponse,
  /**
   * @param {!proto.pan.general.member.ValidateAccessTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.ValidateAccessTokenResponse.deserializeBinary
);


/**
 * @param {!proto.pan.general.member.ValidateAccessTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pan.general.member.ValidateAccessTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pan.general.member.ValidateAccessTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pan.general.member.MemberServiceClient.prototype.validateAccessToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pan.general.member.MemberService/ValidateAccessToken',
      request,
      metadata || {},
      methodDescriptor_MemberService_ValidateAccessToken,
      callback);
};


/**
 * @param {!proto.pan.general.member.ValidateAccessTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pan.general.member.ValidateAccessTokenResponse>}
 *     A native promise that resolves to the response
 */
proto.pan.general.member.MemberServicePromiseClient.prototype.validateAccessToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pan.general.member.MemberService/ValidateAccessToken',
      request,
      metadata || {},
      methodDescriptor_MemberService_ValidateAccessToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pan.general.member.ValidatePhoneRequest,
 *   !proto.pan.general.member.ValidatePhoneResponse>}
 */
const methodDescriptor_MemberService_ValidatePhone = new grpc.web.MethodDescriptor(
  '/pan.general.member.MemberService/ValidatePhone',
  grpc.web.MethodType.UNARY,
  proto.pan.general.member.ValidatePhoneRequest,
  proto.pan.general.member.ValidatePhoneResponse,
  /**
   * @param {!proto.pan.general.member.ValidatePhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.ValidatePhoneResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pan.general.member.ValidatePhoneRequest,
 *   !proto.pan.general.member.ValidatePhoneResponse>}
 */
const methodInfo_MemberService_ValidatePhone = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pan.general.member.ValidatePhoneResponse,
  /**
   * @param {!proto.pan.general.member.ValidatePhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.ValidatePhoneResponse.deserializeBinary
);


/**
 * @param {!proto.pan.general.member.ValidatePhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pan.general.member.ValidatePhoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pan.general.member.ValidatePhoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pan.general.member.MemberServiceClient.prototype.validatePhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pan.general.member.MemberService/ValidatePhone',
      request,
      metadata || {},
      methodDescriptor_MemberService_ValidatePhone,
      callback);
};


/**
 * @param {!proto.pan.general.member.ValidatePhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pan.general.member.ValidatePhoneResponse>}
 *     A native promise that resolves to the response
 */
proto.pan.general.member.MemberServicePromiseClient.prototype.validatePhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pan.general.member.MemberService/ValidatePhone',
      request,
      metadata || {},
      methodDescriptor_MemberService_ValidatePhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pan.general.member.InitGenderRequest,
 *   !proto.pan.general.member.InitGenderResponse>}
 */
const methodDescriptor_MemberService_InitGender = new grpc.web.MethodDescriptor(
  '/pan.general.member.MemberService/InitGender',
  grpc.web.MethodType.UNARY,
  proto.pan.general.member.InitGenderRequest,
  proto.pan.general.member.InitGenderResponse,
  /**
   * @param {!proto.pan.general.member.InitGenderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.InitGenderResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pan.general.member.InitGenderRequest,
 *   !proto.pan.general.member.InitGenderResponse>}
 */
const methodInfo_MemberService_InitGender = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pan.general.member.InitGenderResponse,
  /**
   * @param {!proto.pan.general.member.InitGenderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.InitGenderResponse.deserializeBinary
);


/**
 * @param {!proto.pan.general.member.InitGenderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pan.general.member.InitGenderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pan.general.member.InitGenderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pan.general.member.MemberServiceClient.prototype.initGender =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pan.general.member.MemberService/InitGender',
      request,
      metadata || {},
      methodDescriptor_MemberService_InitGender,
      callback);
};


/**
 * @param {!proto.pan.general.member.InitGenderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pan.general.member.InitGenderResponse>}
 *     A native promise that resolves to the response
 */
proto.pan.general.member.MemberServicePromiseClient.prototype.initGender =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pan.general.member.MemberService/InitGender',
      request,
      metadata || {},
      methodDescriptor_MemberService_InitGender);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pan.general.member.ReadObjectRequest,
 *   !proto.pan.general.member.ReadObjectResponse>}
 */
const methodDescriptor_MemberService_ReadObject = new grpc.web.MethodDescriptor(
  '/pan.general.member.MemberService/ReadObject',
  grpc.web.MethodType.UNARY,
  proto.pan.general.member.ReadObjectRequest,
  proto.pan.general.member.ReadObjectResponse,
  /**
   * @param {!proto.pan.general.member.ReadObjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.ReadObjectResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pan.general.member.ReadObjectRequest,
 *   !proto.pan.general.member.ReadObjectResponse>}
 */
const methodInfo_MemberService_ReadObject = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pan.general.member.ReadObjectResponse,
  /**
   * @param {!proto.pan.general.member.ReadObjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.ReadObjectResponse.deserializeBinary
);


/**
 * @param {!proto.pan.general.member.ReadObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pan.general.member.ReadObjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pan.general.member.ReadObjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pan.general.member.MemberServiceClient.prototype.readObject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pan.general.member.MemberService/ReadObject',
      request,
      metadata || {},
      methodDescriptor_MemberService_ReadObject,
      callback);
};


/**
 * @param {!proto.pan.general.member.ReadObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pan.general.member.ReadObjectResponse>}
 *     A native promise that resolves to the response
 */
proto.pan.general.member.MemberServicePromiseClient.prototype.readObject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pan.general.member.MemberService/ReadObject',
      request,
      metadata || {},
      methodDescriptor_MemberService_ReadObject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pan.general.member.MakeLoginTokenRequest,
 *   !proto.pan.general.member.MakeLoginTokenResponse>}
 */
const methodDescriptor_MemberService_MakeLoginToken = new grpc.web.MethodDescriptor(
  '/pan.general.member.MemberService/MakeLoginToken',
  grpc.web.MethodType.UNARY,
  proto.pan.general.member.MakeLoginTokenRequest,
  proto.pan.general.member.MakeLoginTokenResponse,
  /**
   * @param {!proto.pan.general.member.MakeLoginTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.MakeLoginTokenResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pan.general.member.MakeLoginTokenRequest,
 *   !proto.pan.general.member.MakeLoginTokenResponse>}
 */
const methodInfo_MemberService_MakeLoginToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pan.general.member.MakeLoginTokenResponse,
  /**
   * @param {!proto.pan.general.member.MakeLoginTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.MakeLoginTokenResponse.deserializeBinary
);


/**
 * @param {!proto.pan.general.member.MakeLoginTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pan.general.member.MakeLoginTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pan.general.member.MakeLoginTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pan.general.member.MemberServiceClient.prototype.makeLoginToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pan.general.member.MemberService/MakeLoginToken',
      request,
      metadata || {},
      methodDescriptor_MemberService_MakeLoginToken,
      callback);
};


/**
 * @param {!proto.pan.general.member.MakeLoginTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pan.general.member.MakeLoginTokenResponse>}
 *     A native promise that resolves to the response
 */
proto.pan.general.member.MemberServicePromiseClient.prototype.makeLoginToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pan.general.member.MemberService/MakeLoginToken',
      request,
      metadata || {},
      methodDescriptor_MemberService_MakeLoginToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pan.general.member.ValidateLoginTokenRequest,
 *   !proto.pan.general.member.ValidateLoginTokenResponse>}
 */
const methodDescriptor_MemberService_ValidateLoginToken = new grpc.web.MethodDescriptor(
  '/pan.general.member.MemberService/ValidateLoginToken',
  grpc.web.MethodType.UNARY,
  proto.pan.general.member.ValidateLoginTokenRequest,
  proto.pan.general.member.ValidateLoginTokenResponse,
  /**
   * @param {!proto.pan.general.member.ValidateLoginTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.ValidateLoginTokenResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pan.general.member.ValidateLoginTokenRequest,
 *   !proto.pan.general.member.ValidateLoginTokenResponse>}
 */
const methodInfo_MemberService_ValidateLoginToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pan.general.member.ValidateLoginTokenResponse,
  /**
   * @param {!proto.pan.general.member.ValidateLoginTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pan.general.member.ValidateLoginTokenResponse.deserializeBinary
);


/**
 * @param {!proto.pan.general.member.ValidateLoginTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pan.general.member.ValidateLoginTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pan.general.member.ValidateLoginTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pan.general.member.MemberServiceClient.prototype.validateLoginToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pan.general.member.MemberService/ValidateLoginToken',
      request,
      metadata || {},
      methodDescriptor_MemberService_ValidateLoginToken,
      callback);
};


/**
 * @param {!proto.pan.general.member.ValidateLoginTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pan.general.member.ValidateLoginTokenResponse>}
 *     A native promise that resolves to the response
 */
proto.pan.general.member.MemberServicePromiseClient.prototype.validateLoginToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pan.general.member.MemberService/ValidateLoginToken',
      request,
      metadata || {},
      methodDescriptor_MemberService_ValidateLoginToken);
};


module.exports = proto.pan.general.member;

