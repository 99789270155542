import React from 'react'

class CustomizedInput extends React.Component {
  render() {
    // console.log("#==== this.props.v:", this.props.v)
    switch (this.props.detail.input_type) {
      case "text":
      case "tel":
      case "email":
        return (
          <div className="form-group ls-form-style-input">
            <label htmlFor={"customized_input_"+this.props.detail.id}>
              {this.props.detail.required ? <small className="asterisk text-danger">*</small> : null}
              {this.props.detail.label}
            </label>
            {/* value={...} => 撼動不了，但 defaultValue 可以 */}
            <input id={"customized_input_"+this.props.detail.id} defaultValue={this.props.v}
                   className="form-control" type={this.props.detail.input_type}
                   name={'customized_inputs['+this.props.detail.id+']'}
                   required={this.props.detail.required ? 'required' : false}
                   maxLength="150"/>
          </div>
        )
      case "number":
        return (
          <div className="form-group ls-form-style-input">
            <label htmlFor={"customized_input_"+this.props.detail.id}>
              {this.props.detail.required ? <small className="asterisk text-danger">*</small> : null}
              {this.props.detail.label}
            </label>
            <input id={"customized_input_"+this.props.detail.id} defaultValue={this.props.v}
                   className="form-control" type={this.props.detail.input_type} name={'customized_inputs['+this.props.detail.id+']'} pattern="\d*" required={this.props.detail.required ? 'required' : false}/>
          </div>
        )
      case "time":
      case "date":
        return (
          <div className="form-group ls-form-style-input-multiple">
            <label htmlFor={"customized_input_"+this.props.detail.id}>
              {this.props.detail.required ? <small className="asterisk text-danger">*</small> : null}
              {this.props.detail.label}
            </label>
            <input id={"customized_input_"+this.props.detail.id} defaultValue={this.props.v}
                   className="form-control" type={this.props.detail.input_type} name={'customized_inputs['+this.props.detail.id+']'} required={this.props.detail.required ? 'required' : false}/>
          </div>
        )
      case "checkbox":
        return (
          <div className="form-check ls-form-style-checkbox">
            <input id={"customized_input_"+this.props.detail.id} defaultChecked={this.props.v}
                   className="form-check-input" type={this.props.detail.input_type} name={'customized_inputs['+this.props.detail.id+']'} defaultValue="是" required={this.props.detail.required ? 'required' : false}/>
            <label htmlFor={"customized_input_"+this.props.detail.id}>
              {this.props.detail.required ? <small className="asterisk text-danger">*</small> : null}
              {this.props.detail.label}
            </label>
          </div>
        )
      case "select":
        var options = this.props.detail.options.map(function(item,key) {
          return <option value={item.value} key={item.id}>{item.value}</option>
        })
        return (
          <div className="form-group ls-form-style-select">
            <label htmlFor={"customized_input_"+this.props.detail.id}>
              {this.props.detail.required ? <small className="asterisk text-danger">*</small> : null}
              {this.props.detail.label}
            </label>
            <select id={"customized_input_"+this.props.detail.id}
                    className="form-control"
                    defaultValue={this.props.v}
                    type={this.props.detail.input_type}
                    name={'customized_inputs['+this.props.detail.id+']'} required={this.props.detail.required ? 'required' : false}>
              <option value="">{'請選擇'+this.props.detail.label}</option>
              {options}
            </select>
          </div>
        )
      }
    }
}

export default CustomizedInput