import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class StoreIndexImgcols extends React.Component {
  componentDidMount() {
  }
  render() {
    let agent_params = this.props.agent_token ? `?u=${this.props.agent_token}` : ''
    let cols = (this.props.banners.length != 5) ? (12 / this.props.banners.length) : 'five'
    let images = this.props.banners.map((banner, index) => {
      if (banner.product) {
        return (
          <div id={"lsImgCols-" + index} className={"col-item col-" + cols} key={"banner-" + index}>
            <div className="card-item">
              <div className="card-image image-animation">
                <a href={"/products/" + banner.product.token + agent_params} >
                  <LazyLoadImage
                    height='100%'
                    width='100%'
                    wrapperClassName="img-fluid"
                    src={banner.photo.url}
                    alt={this.props.title}
                  />
                </a>
              </div>
              {(banner.title || banner.text) ?
                <div className="card-inner">
                  <h5 className='card-heading'><a href={"/products/" + banner.product.token + agent_params} >{banner.title}</a></h5>
                  <p className="card-text"><a href={"/products/" + banner.product.token + agent_params} >{banner.text}</a></p>
                </div>
                : null
              }
            </div>
          </div>
        )
      }
      if (banner.link) {
        return (
          <div id={"lsImgCols-" + index} className={"col-item col-" + cols} key={"banner-" + index}>
            <div className="card-item">
              <div className="card-image image-animation">
                <a href={banner.link} target="_blank">
                  <LazyLoadImage
                    height='100%'
                    width='100%'
                    wrapperClassName="img-fluid"
                    src={banner.photo.url}
                    alt={this.props.title}
                  />
                </a>
              </div>
              {(banner.title || banner.text) ?
                <div className="card-inner">
                  <h5 className='card-heading'><a href={banner.link}>{banner.title}</a></h5>
                  <p className="card-text"><a href={banner.link}>{banner.text}</a></p>
                </div>
                : null
              }
            </div>
          </div>
        )
      } else {
        return (
          <div id={"lsImgCols-" + index} className={"col-item col-" + cols} key={"banner-" + index}>
            <div className="card-item">
              <div className="card-image image-animation">
                <LazyLoadImage
                  height='100%'
                  width='100%'
                  wrapperClassName="img-fluid"
                  src={banner.photo.url}
                  alt={this.props.title}
                />
              </div>
              {(banner.title || banner.text) ?
                <div className="card-inner">
                  <h5 className='card-heading'>{banner.title}</h5>
                  <p className="card-text">{banner.text}</p>
                </div>
                : null
              }
            </div>
          </div>
        )
      }
    })

    let store_imgcols = (
      <div className="row">
        {images}
      </div>
    )

    return (
      <section className="ls-section section-imgcols lsLandingImgCols" >
        <div className="container">
          {store_imgcols}
        </div>
      </section>
    )
  }
}
export default StoreIndexImgcols
