export function salePrice(price, distribution_enabled=true, distribution_percentage=100, vip_percentage=100) {
  distribution_percentage = (distribution_enabled && distribution_percentage) ? distribution_percentage : 100
  return Math.round(price * distribution_percentage * vip_percentage / 10000)
}

export function otherItemCount(cart_items, product_info, variant_id) {
  let count = 0;
  for (let item of cart_items) {
    if ((item.product_token === product_info || item.product_id === product_info) && item.variant_id != variant_id) {
      count += item.quantity;
    }
  }
  return count;
}
export function maxQuantity(variant, product, cart_items) {
  if (!variant || !product) return null;

  let max_quantity = 0;
  if (product.allow_preorder)
    max_quantity = 9999;
  else
    max_quantity = variant.stock;
  if (product.limit_purchase && max_quantity > product.limit_purchase)
    max_quantity = product.limit_purchase;
  if (product.global_limit && max_quantity > product.global_limit-otherItemCount(cart_items, product.token||product.product_id, variant.id||variant.variant_id))
    max_quantity = product.global_limit-otherItemCount(cart_items, product.token||product.product_id, variant.id||variant.variant_id);
  if (product.limit && max_quantity > product.limit) // addition_purchase
    max_quantity = product.limit
  
  return max_quantity;
}
export function onPlus(quantity, variant, product, cart_items) {
  return Math.min(quantity+1, maxQuantity(variant, product, cart_items));
}
export function onMinus(quantity) {
  return Math.max(quantity-1, 0);
}
export function onInput(value, variant, product, cart_items) {
  return Math.max(Math.min(value, maxQuantity(variant, product, cart_items)), 0);
}

export function getLimitPurchase(product) {
  let limit = 0;
  if (product.limit_purchase)
    limit = product.limit_purchase;
  if (product.global_limit)
    limit = product.global_limit;

  return limit!==0 ? limit : null;
}