import React from 'react'
import MemberLayout from './memberLayout'
import i18n from '../../components/i18next/i18n'
const iconCrown = <svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="icon-crown" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
          <path d="M32,22.8128514 L41.7831325,38.1863454 L54.0120482,30.1502008 L48.4216867,54.6080321 L15.5783133,54.6080321 L9.98795181,30.1502008 L22.2168675,38.1863454 L32,22.8128514 Z M6.4939759,17.3855422 C8.42364551,17.3855422 9.98795181,18.9498485 9.98795181,20.8795181 C9.98795181,22.8091877 8.42364551,24.373494 6.4939759,24.373494 C4.5643063,24.373494 3,22.8091877 3,20.8795181 C3,18.9498485 4.5643063,17.3855422 6.4939759,17.3855422 Z M57.5060241,17.3855422 C59.4356937,17.3855422 61,18.9498485 61,20.8795181 C61,22.8091877 59.4356937,24.373494 57.5060241,24.373494 C55.5763545,24.373494 54.0120482,22.8091877 54.0120482,20.8795181 C54.0120482,18.9498485 55.5763545,17.3855422 57.5060241,17.3855422 Z M32.3493976,9 C34.2790672,9 35.8433735,10.5643063 35.8433735,12.4939759 C35.8433735,14.4236455 34.2790672,15.9879518 32.3493976,15.9879518 C30.419728,15.9879518 28.8554217,14.4236455 28.8554217,12.4939759 C28.8554217,10.5643063 30.419728,9 32.3493976,9 Z" id="Combined-Shape" stroke="#000000" strokeWidth={5} />
        </g>
      </svg>
export default class MemberProfile extends MemberLayout {
  constructor(props) {
    super(props);

    let current_shop_customer = this.props.current_shop_customer;
    let today = new Date(new Date().toISOString().split('T')[0])
    let current_vip_level = current_shop_customer && this.props.shop_vips.filter(e => e.id == current_shop_customer.shop_vip_id && new Date(current_shop_customer.vip_expiration_date) >= today)[0]
    let current_vip_level_index = this.props.shop_vips.findIndex(shop_vip => shop_vip===current_vip_level)
    this.state = {
      current_vip_level: current_vip_level || { id: null },
      current_vip_level_index: current_vip_level_index,
      isCollapse: false
    };
    this.vipArea = this.vipArea.bind(this)
    this.onCollapse = this.onCollapse.bind(this)
  }
  componentDidMount() {
    let current_vip_link = document.getElementsByClassName('current_vip');
    if (current_vip_link.length !== 0)
      current_vip_link[0].click();
  }
  onCollapse(){
    this.setState({isCollapse:!this.state.isCollapse})
  }
  vipArea() {
    let current_index = 0
    let next_index = null
    if(this.state.current_vip_level == null){
      next_index = 1
    }
    return this.props.shop_vips.map((shop_vip) => {
      current_index += 1
      if (shop_vip.id == this.state.current_vip_level.id) {
        next_index = current_index + 1
      }
      return <div key={shop_vip.id} className={`card-membership tab-pane fade ${next_index && current_index === next_index - 1 ? 'active show' : 'inactive'}`} id={"vip"+shop_vip.id} role="tabpanel">
        <div className="card-inner">
          <div className="inner-header d-flex">
            <h4 className="mb-0 card-title">
              {shop_vip.title}
              {
                shop_vip.id == this.state.current_vip_level.id ?
                  <small className="status-info badge badge-success ml-2">目前等級</small> : ''
              }
              {/* {
                current_index == next_index ? <small className="status-info badge badge-danger">下個會員等級</small> : ''
              } */}
            </h4>
          </div>
          <div className="d-flex inner-body">
            <div className='benefits mr-2 mr-md-3 text-center'>
              <div className={`discount ${shop_vip.id == this.state.current_vip_level.id ? 'text-danger' : 'text-dark'}`}>
                <span className="big-word">{shop_vip.discount_percentage / 10}</span>
                <small>折</small>
              </div>
              <p className="mb-0 small">每筆消費</p>
            </div>

            <div className="grade">
              {
                shop_vip.id == this.state.current_vip_level.id ?
                  <p className='ml-auto small mb-1 text-dark'>保留目前等級，需於 <b className="text-dark">{shop_vip.vip_base_date} ~  {this.props.current_shop_customer.vip_expiration_date}</b> 完成以下條件</p> : ''
              }
              {
                next_index && current_index >= next_index ?
                  <p className='mb-0 small'>{shop_vip.expiry_days}天內消費達成以下條件，即可升等{shop_vip.title}會員</p> : ''
              }
              {
                shop_vip.vip_rules.map((rule, index)=>{
                  return <div key={rule.id} className="condition-group">
                    <h5 className="group-title">{shop_vip.vip_rules.length > 1 ? `符合條件 ${index+1}：` : `符合條件：`}</h5>
                    {rule.one_order_threshold > 0 ?
                      <p className="condition mb-0">{shop_vip.max_order_price >= rule.one_order_threshold ? <i className='fa fa-check text-success'></i> : ''} 單筆消費金額滿 <b>NT$ {rule.one_order_threshold} </b></p> : ''
                    }
                    {rule.total_order_price_threshold > 0 ?
                      (rule.total_order_price_threshold - shop_vip.orders_total > 0 ?
                        <p className="condition mb-0">累積消費金額還需 <b>NT$ {rule.total_order_price_threshold - shop_vip.orders_total}</b></p>
                        : <p className="condition mb-0"><i className='fa fa-check text-success'></i>累積消費金額滿 <b>NT$ {rule.total_order_price_threshold} </b></p>) : ''
                    }

                    {rule.total_order_count_threshold > 0 ?
                      (rule.total_order_count_threshold - shop_vip.orders_count > 0 ?
                        <p className="condition mb-0">消費次數還需 <b> {rule.total_order_count_threshold - shop_vip.orders_count} 次</b></p>
                        : <p className="condition mb-0"><i className='fa fa-check text-success'></i>消費次數達 <b> {rule.total_order_count_threshold} 次</b></p>) : ''
                    }
                  </div>
                })
              }
              <h5 className="condition-due mb-0">會員權益期限：<b>{shop_vip.expiry_days}</b> 天 </h5>

            </div>
          </div>
        </div>
      </div>
    })
  }
  render_inner() {
    return (
      <>
        {
          this.props.shop_vips.length > 0 ?
          <div>
            <div className="section-head-group d-flex align-items-center justify-content-center mb-3">
              <i className="mr-2" data-feather="award"></i>
              <h1 className="section-subtitle mb-1 mb-md-0">{i18n.t("member.vip")}</h1>
            </div>
            <div className='vip-status-wrapper mb-5 '>
              {
                this.state.current_vip_level.id ?
                  <div className="current-level d-flex align-items-center">
                    <div className="vip-icon">
                      <img src="/img/icon-vip.svg" alt="" className="w-100" />
                    </div>
                    <div className='pl-3'>
                      <h3 className="title mb-0">{
                        this.state.current_vip_level.title
                      }</h3>
                      <p>會員權限期限: <br className="d-block d-sm-none" /><b> {this.props.current_shop_customer.vip_expiration_date}</b></p>
                    </div>
                    {/* <span className="ml-auto p-2" onClick={this.onCollapse}> */}
                    {/* <span className="ml-auto p-2">
                      <span>
                        <a className="btn btn-link collapse-link" >
                          {this.state.isCollapse ?
                            <i className="fa fa-chevron-down"></i> :<i className="fa fa-chevron-up"></i>}
                        </a>
                      </span>
                    </span> */}
                  </div>
                  :
                  <div className="d-flex align-items-center">
                    <div className='pl-3'>
                      <h4 className="title mb-0">
                        您目前尚無會員等級
                      </h4>
                    </div>
                    {/* <span className="ml-auto p-2">
                      <span>
                        <a className="btn btn-link collapse-link">
                          {this.state.isCollapse ?
                            <i className="fa fa-chevron-down"></i> :<i className="fa fa-chevron-up"></i>}
                        </a>
                      </span>
                    </span> */}
                  </div>
              }
              <div className="vipTab-container">
                <ul id="vipTabNavi" className="nav" role="tablist">
                  {
                    this.props.shop_vips.slice(0).map((shop_vip, index) => {
                      return (
                        <li key={shop_vip.id} role="presentation">
                          <a data-toggle="tab" className={"vipTabLink " + ((this.state.current_vip_level_index === index) ? 'current_lv current_vip': '') + ((this.state.current_vip_level_index > index) ? 'done': '')} href={"#vip"+shop_vip.id} role="tab">
                            <i className="icon">{iconCrown}</i>
                            <div>{shop_vip.title}</div>
                          </a>
                        </li>
                      )
                    })
                  }
                </ul>
                <div className={"membership-level tab-content " + (this.state.current_vip_level.id ? "current_lv " : "") + (this.state.isCollapse ? 'hide' : '')} ref="panel_body">
                  {this.vipArea()}
                </div>
              </div>
            </div></div> : null
        }

        <div className="mamber-detail-wrapper">
          <div className="d-sm-flex section-head-group align-items-end mb-3">
            <h2 className="section-subtitle mb-1 mb-md-0">{i18n.t("member.profile")}</h2>
            <p className="small-text ml-auto mb-0">設定正確的會員資料，以確保您的消費權益</p>
          </div>
          <div className="order-detail-wrapper meta-list-group">
            <div className="meta-list d-sm-flex align-items-center border-0">
              <label className="item-title item-label mb-md-0" htmlFor="customer-name">姓名</label>
              <div className="item-detail">
                <input id="customer-name" className="form-control" type="text" name="customer[name]" required='required' defaultValue={this.props.current_customer.name}></input>
              </div>
            </div>

            {/*<div className="meta-list d-sm-flex align-items-center border-0">
              <label className="item-title item-label mb-md-0" htmlFor="customer-email">Email</label>
              <div className="item-detail">
                <input id="customer-email" className="form-control" type="email" name="customer[email]" defaultValue={this.props.current_customer.email} disabled></input>
              </div>
            </div>*/}
            <div className="meta-list d-sm-flex align-items-center border-0">
              <label className="item-title item-label mb-md-0" htmlFor="customer-phone">電話</label>
              <div className="item-detail">
                <input id="customer-phone" className="form-control" type="tel" pattern="((?=(09))[0-9]{10})$" name="customer[phone]" required='required' defaultValue={this.props.current_customer.phone} disabled></input>
              </div>
            </div>
            {/* <div className="meta-list d-sm-flex align-items-center border-0">
              <label className="item-title item-label mb-md-0" htmlFor="customer-gender">性別</label>
              <div className="item-detail">
                <select id='customer-gender' className="custom-select" id="exampleFormControlSelect1" name="customer[gender]" defaultValue={1}>
                  <option>請選擇</option>
                  <option defaultValue="1" >男</option>
                  <option defaultValue="2">女</option>
                </select>
              </div>
            </div>*/}
            {
              this.props.isStore
                ? <div className="meta-list d-sm-flex align-items-center border-0">
                    <label className="item-title item-label mb-md-0" htmlFor="customer-birthday">生日</label>
                    <div className="item-detail">
                      <input
                        id="customer-birthday" className="form-control" type="date" name="customer[birthday]" required
                        defaultValue={this.props.current_shop_customer ? this.props.current_shop_customer.birthday : ''}
                        disabled={!!this.props.current_shop_customer && !!this.props.current_shop_customer.birthday}
                      >
                      </input>
                    </div>
                  </div>
                : null
            }

            {/*<div className="meta-list d-sm-flex align-items-start border-0">
              <label className="item-title item-label mb-md-0 pt-1" htmlFor="customer-birthday">電子報</label>
              <div className="item-detail">
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="gender_male" name="customer-gender" className="custom-control-input"/>
                  <label className="custom-control-label" htmlFor="gender_male">訂閱</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="gender_female" name="customer-gender" className="custom-control-input"/>
                  <label className="custom-control-label" htmlFor="gender_female">不訂閱</label>
                </div>
                <small className='form-text text-muted'>勾選訂閱電子報，可不定期取得最新商品資訊與優惠活動</small>
              </div>
            </div>*/}
            <div className="meta-list d-sm-flex align-items-center border-0">
              <div className="item-detail offset-title">
                <button type="submit" className="btn btn-primary"><i className="icon icon-edit mr-1"></i> 更新個人資料</button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
