import React from 'react'
import MemberLayout from './memberLayout'
import MemberAddressItem from './memberAddressItem'
import AddressModal from './addressModal'
import i18n from '../../components/i18next/i18n'

export default class MemberAddress extends MemberLayout {
  constructor(props) {
    super(props);
    this.handleModalOpen = this.handleModalOpen.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.state = {
      isAddAddressModalShow: false,
      modalTitle: ''
    }
  }
  handleModalOpen(event) {
    this.setState({isAddAddressModalShow: true})
  }
  handleModalClose(event) {
    this.setState({isAddAddressModalShow: false})
  }
  render_inner() {
    let emptyContent = <div className="p-5 placeholder-image">
        <div className='icon'>
          <img src={this.props.placeholder_img_url} alt={i18n.t('cart.no_address')} className='w-100'/>
        </div>
        <p className="mb-0 text-muted text-center">{i18n.t('cart.no_address')}</p>
      </div>
    let addressContent = this.props.addresses.length !== 0 ? this.props.addresses.map(
      (address, index) => (
        <MemberAddressItem key={index} address={address} authenticity_token={this.props.authenticity_token}/>
      )
    ) : emptyContent

    return (
      <>
        <div className="section-head-group d-sm-flex align-items-end  mb-3">
          <h1 className="section-subtitle mb-1 mb-md-0"><i className="icon icon-truckk"></i> {i18n.t("member.address")}</h1>
          <div className="d-flex ml-auto">
            <button className="btn btn-sm btn-default" type="button" onClick={this.handleModalOpen}>+ 新增地址</button>
          </div>
        </div>
        {addressContent}
        <AddressModal isOpen={this.state.isAddAddressModalShow} handleModalClose={this.handleModalClose} authenticity_token={this.props.authenticity_token}/>
      </>
    )
  }
}