import React from 'react'
import CustomizedInput from './customized_input'
import InternationalAddress from './international_address'
import i18n from '../../components/i18next/i18n'
import * as pan from '../jkf/general/member/first_pb'
import ConfirmSignIn from './confirm_signin_modal'

import { MemberServicePromiseClient } from '../jkf/general/member/first_grpc_web_pb'
const panService = new MemberServicePromiseClient('https://jkf.hare200.com/gapi')

class CheckoutForm extends React.Component {
  constructor(props) {
    /* cart_info 為由 checkout.jsx 傳下來的值，此值存在 $redis */
    super(props)
    var address;
    this.cellphoneTypeRef = React.createRef();
    this.normalTypeRef = React.createRef();
    this.carrierTypeRef = React.createRef();
    if (this.props.customer != null && this.props.customer.default_address) {
      address = this.props.customer.default_address;
    }
    this.form_sent = false
    let invoice_share_address
    if (!!window.performance && window.performance.navigation.type === 2 && localStorage.getItem('invoice_share_address')) {
      invoice_share_address = localStorage.getItem('invoice_share_address');
      localStorage.removeItem('invoice_share_address')
    }
    else {
      invoice_share_address = false;
    }
    this.state = {
      subscribe: false,
      address: address,
      force_cvs: false,
      error_message: null,
      invoice_share_address: invoice_share_address,
      isConfirmSigninOpen: false,
      is_give_up_sign_in: false,
      need_gui_number: this.props.cart_info.vat && this.props.cart_info.vat.number,
      invoice_type: this.props.cart_info.vat ? this.props.cart_info.vat.invoice_type : 'cellphone_type',
    }
    this.validateCart = this.validateCart.bind(this)
    this.submitHandler = this.submitHandler.bind(this)
    this.onCarrierInput = this.onCarrierInput.bind(this)
    this.handleSameAddress = this.handleSameAddress.bind(this)
    this.showGuiNumber = this.showGuiNumber.bind(this)
    this.fix_phone = this.fix_phone.bind(this)
    this.cvs_ok = this.cvs_ok.bind(this)
    this.fix_phone = this.fix_phone.bind(this)
    this.valid_payments = this.valid_payments.bind(this)
    this.onInvoiceTypeChange = this.onInvoiceTypeChange.bind(this)
    this.hideConfirmSigninModal = this.hideConfirmSigninModal.bind(this)
    this.openConfirmSigninModal = this.openConfirmSigninModal.bind(this)
    this.give_up_sign_in = this.give_up_sign_in.bind(this)
    this.confirmCvs = this.confirmCvs.bind(this)
    this.handleTypeChecked = this.handleTypeChecked.bind(this)
  }

  validateCart(cart) {
    var cart_item_count = 0;
    $.map(cart, function (_item, i) {
      cart_item_count += _item["quantity"];
    });
    if (cart_item_count == 0) {
      return false;
    }
    if(this.props.selectedShipping.address_type == "cvs" ){
      return this.props.cvs_info && this.props.cvs_info.cvs_store_id && parseInt(this.props.cvs_info.shipping_id) === this.props.selectedShipping.shipping_id
    }
    return true;
  }
  async submitHandler(e) {
    e.preventDefault();

    let access_token = await this.getMe();
    sessionStorage.removeItem('additional_purchases')

    var _cart = this.props.cart;
    var self = this;
    if(this.form_sent){
      return
    }
    $('#cart-form').find('input:invalid').each(function () {
      $(this).parent().after($.prop(this, 'validationMessage'));
    });
    // if ($('input:invalid').length > 0) {
    //   alert("請輸入必填欄位，例如： Email、姓名、電話等")
    //   return
    // }
    if ($('input[name="vat[number]"]').length > 0 && $('input[name="vat[number]"]').val().trim()) {
      if (!isValidGUI($('input[name="vat[number]"]').val().trim())) {
        alert("統一編號輸入錯誤，請檢查")
        return
      }
    }
    $('#cart-form button[type=submit]').attr('disabled', 'disabled')
    if (self.validateCart(_cart)) {
      $('#cart-form button[type=submit]').remove()
      this.form_sent = true

      var _data = $(e.currentTarget).serializeObject();
      _data["items"] = []
      _data["additional_purchase_items"] = []
      _data["access_token"] = access_token;
      _data["is_give_up_sign_in"] = this.state.is_give_up_sign_in
      _cart.forEach(item => {
        if (item["quantity"] > 0) {
          if(item["variant_id"] != null) {
            _data["items"].push({variant_id: item["variant_id"], quantity: item["quantity"], n_pieces: item["n_pieces"]})
          }
          else if(item["bundle_id"] != null) {
            _data["items"].push({bundle_id: item["bundle_id"], quantity: item["quantity"]})
          }
        }
      });
      this.props.additional_purchase_items.forEach(item => {
        if (item["quantity"] > 0) {
          _data["items"].push({variant_id: item["id"], quantity: item["quantity"]})
        }
      });
      _data["gift_campaigns"] = this.props.gift_items

      $.ajax("/orders.json",
        {
          type: 'post',
          data: _data,
          success: function (data) {
            if (data.success) {
              var form = data.form_text;
              $("#liteshop-next-step-form-area").append(form);
              $("#liteshop-next-step-form-area > form").trigger('submit');
            } else {
              $("#liteshop-loadingModal").hide()
              if (data.message == "No Stock") {
                alert("您選購的某項商品剛剛賣完囉～請重新整理頁面試試看。")
              }
              self.setState({error_message: data.message})
            }
            createCookie('began-checkout',false,false);
          }
        }
      );
    }
    else {
      $('#cart-form button[type=submit]').attr('disabled', false)
    }
  }
  onInvoiceTypeChange(event) {
    this.setState({invoice_type: event.target.value})
  }
  onCarrierInput(event) {
    event.target.value = event.target.value.toUpperCase()
  }
  handleSameAddress(event) {
    this.setState({invoice_share_address: event.target.checked},
      () => localStorage.setItem('invoice_share_address', this.state.invoice_share_address))
  }
  showGuiNumber(event) {
    console.log(`%c checked: ${event.target.checked}`, "color:green;")
    this.setState({need_gui_number: event.target.checked})
  }
  handleTypeChecked(e) {
    e.current.checked = 'checked'
  }
  componentDidMount() {
    $.fn.serializeObject = function () {
      var o = {};
      var a = this.serializeArray();
      $.each(a, function () {
        if (o[this.name] !== undefined) {
          if (!o[this.name].push) {
            o[this.name] = [o[this.name]];
          }
          o[this.name].push(this.value || '');
        } else {
          o[this.name] = this.value || '';
        }
      });
      return o;
    };
    $('.form-control').focus(function () {
      $(this).closest('.form-group').addClass('onfocus')
    }).focusout(function () {
      if ($(this).val()) {
        $(this).closest('div.form-group').addClass('onfocus')
      } else {
        $(this).closest('div.form-group').removeClass('onfocus')
      }
    })
    $('.form-control').change(function () {
      var _this = $(this)
      var inputVal = _this.val()
      if (inputVal) {
        _this.closest('div.form-group').addClass('onfocus')
      } else {
        _this.closest('div.form-group').removeClass('onfocus')
      }
    });
    if (this.props.current_shipping.address_type == "twaddress") {
      $('#twzipcode').twzipcode();
      $('#twzipcode select').prop('required', true);
      $('#twzipcode input').prop('required', true);
    }
    $('#cart-form').on('submit', this.submitHandler);
    $(window).keydown(function(event){
      if(event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });
  }
  componentDidUpdate() {
    if (this.props.current_shipping.address_type == "twaddress") {
      $('#twzipcode').twzipcode();
      $('#twzipcode select').prop('required', true);
      $('#twzipcode input').prop('required', true);
    }
    $('.form-control').focus(function () {
      $(this).closest('.form-group').addClass('onfocus')
    }).focusout(function () {
      if ($(this).val()) {
        $(this).closest('div.form-group').addClass('onfocus')
      } else {
        $(this).closest('div.form-group').removeClass('onfocus')
      }
    });
    $('.form-control').change(function () {
      var _this = $(this)
      var inputVal = _this.val()
      if (inputVal) {
        _this.closest('div.form-group').addClass('onfocus')
      } else {
        _this.closest('div.form-group').removeClass('onfocus')
      }
    });
    $('.form-select').unbind("mousedown")
  }

  fix_phone(event) {
    if (event.target.value[0]!= "0") {
      event.target.value = "0" + event.target.value
    }
    event.target.value = event.target.value.replace(/\D/g, '');
  }
  cvs_ok() {
    return this.props.cart.every(function (element) {
      return element.cvs_ok || element.quantity == 0
    })
  }
  has_preorder_items() {
    return this.props.cart.find((element) => element.stock <= 0) !== undefined;
  }

  confirmCvs(){
    let $target = document.getElementById('shippingSelect')
    let $tips = document.getElementById('cvsTips')
    $target.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    $tips.classList.remove('d-none')

  }
  valid_payments(){
    let is_subscription= this.props.is_subscription
    let subscriptions_credit_enabled= this.props.subscriptions_credit_enabled
    let subscriptions_cod_enabled= this.props.subscriptions_cod_enabled
    let cvs_ok= this.cvs_ok()
    let total_price= this.props.total_price
    let current_shipping= this.props.current_shipping
    let payments_count = 0

    let self = this
    let payment_select = this.props.payments.map((payment, index) =>{
      if ((cvs_ok || payment.code != "CVS COD") &&
         (total_price <= 20000 || payment.code != "CVS COD") &&
         (!self.has_preorder_items() || payment.code != "CVS COD") &&
         (!is_subscription || (subscriptions_credit_enabled && payment.code=="Credit" && payment.gateway_code == "Allpay::AIO") || (subscriptions_cod_enabled && (payment.code == "CVS COD" ||payment.code == "Offline COD" )) ) &&
         (current_shipping.address_type != 'cvs' || payment.code != "Offline COD") && !(current_shipping.address_type != 'cvs' && payment.code == 'CVS COD')) {
         payments_count += 1
         let payment_title = ""
         switch (payment.code) {
           case 'Credit':
             payment_title = i18n.t('payment.credit')
             break;
           case 'ATM':
             payment_title = i18n.t('payment.atm')
             break;
           case 'CVS':
             payment_title = i18n.t('payment.cvs')
             break;
           case 'inst':
             payment_title = i18n.t('payment.inst')
             break;
           case 'cvs_cod':
             payment_title = i18n.t('payment.cvs_cod')
             break;
           case 'Credit_3':
             payment_title = i18n.t('payment.Credit_3')
             break;
           case 'Credit_6':
             payment_title = i18n.t('payment.Credit_6')
             break;
           case 'Credit_9':
             payment_title = i18n.t('payment.Credit_9')
             break;
           case 'Credit_12':
             payment_title = i18n.t('payment.Credit_12')
             break;
           case 'COD':
             payment_title = i18n.t('payment.cod')
             break;
           default:
             payment_title = payment.title
             break;
          }
         return (
          <div className="form-check" key={payment.payment_id}>
            <input id={'payment_'+payment.payment_id} className="form-check-input"
                   onChange={this.props.onPaymentSelected}
                   type="radio" name="payment_id"
                   value={payment.payment_id}
                   defaultChecked={(payments_count == 1 || self.props.cart_info.payment_id == payment.payment_id) ? 'checked' : ''}
                   required="required"/>
             <label htmlFor={'payment_' + payment.payment_id}>{payment_title}</label>
          </div>
        )
      }
    }).filter(function (element) {
      return element !== undefined;
    });
    return payment_select
  }
  getMe(){
    return new Promise(resolve => {
      let auth_token = window.localStorage.getItem('auth_token');
      let accessTokenRequest = new pan.AccessTokenRequest()
      accessTokenRequest.setAuthToken(auth_token)
      panService.accessToken(accessTokenRequest).then(accessTokenResponse => {
        window.accessTokenResponse = accessTokenResponse
        resolve(accessTokenResponse.getAccessToken())
      }).catch(resolve())
    });
  }

  hideConfirmSigninModal() {
    this.setState({
      isConfirmSigninOpen: false
    });
  }
  openConfirmSigninModal() {
    this.setState({ isConfirmSigninOpen: true })
  }
  give_up_sign_in() {
    let self = this;
    this.setState({ is_give_up_sign_in: true }, () => {
      self.hideConfirmSigninModal()
    })
  }
  chkName(event) {
    let name = event.target.value
    var zh_reg = /^[\u4E00-\u9FA5]{6}$/
    if (zh_reg.test(name))
      $('input[name="customer[name]"]').val(name.slice(0, 5))

    var eng_reg = /^[a-zA-Z]{11}$/
    if (eng_reg.test(name.replace(/ /g,''))) {
      let space_count = name.split(" ").length - 1;
      $('input[name="customer[name]"]').val(name.slice(0, 10+space_count))
    }
  }
  render() {
    var self = this;
    var address_area;
    var phone_area;
    var next_step;
    let need_shipping = false
    let subscription_area

    let payments_options = this.valid_payments()
    if(this.props.subscriptions_enabled){
      subscription_area =
        <div className={"subscription-checkout form-group " + (this.props.is_subscription ? 'is_subscription' : '')}>
        <div className="form-checkbox-group d-flex align-items-center">
          <div className="toggle-checkbox">
            <input className="toggle-check-input" id="subscription-checkbox" name="is_subscription" type='checkbox' onChange={(e)=>{this.props.onSubscriptionClicked(e.currentTarget.checked)}}/>
            <span className="circle"></span>
          </div>
          <label htmlFor="subscription-checkbox" className="toggle-check-label">
            {i18n.t('subscription')}
            <div className="subscriptions-discount ml-auto text-danger pr-2">
              {
                this.props.subscriptions_discount > 0 ? <div><small>{i18n.t('discount')}</small> {this.props.subscriptions_discount}%<small>OFF</small></div> : ''
              }
            </div>
          </label>
        </div>
        {
          this.props.is_subscription ?
          <div className="form-row-group">
            <div className="form-row">
              <div className="col-12 col-sm-6">
                <div className="form-group ls-form-style-input onfocus">
                  <label htmlFor="subscription-periods">
                    <small className="asterisk">*</small>{i18n.t('numbers_bought')}
                  </label>
                  {
                    this.props.subscriptions_periods && this.props.subscriptions_periods > 0 ?
                    <input id="subscription-periods" className="form-control" type='number' name='subscription[periods]' min="2" readOnly="readonly" defaultValue={this.props.subscriptions_periods} pattern="\d*" required={true}/> :
                    <input id="subscription-periods" className="form-control" type='number' name='subscription[periods]' min="2" pattern="\d*" required={true}/>
                  }
                </div>
              </div>
              <div className="col-12 col-sm-6">
                  <div className="form-group ls-form-style-input onfocus">
                  <label htmlFor="subscription-periods">
                    <small className="asterisk">*</small>{i18n.t('frequency')}
                  </label>
                  {
                    this.props.subscriptions_interval && this.props.subscriptions_interval > 0 ?
                    <input id="subscription-periods" className="form-control" type='number' name='subscription[interval]' readOnly="readonly" defaultValue={this.props.subscriptions_interval} pattern="\d*" min="1" required={true}/> :
                    <input id="subscription-periods" className="form-control" type='number' name='subscription[interval]' pattern="\d*" min="1" required={true}/>
                  }
                </div>
              </div>
            </div>
          </div> :''
        }
      </div>
    }
    for (let product of this.props.cart) {
      need_shipping = need_shipping || (product.need_shipping && product.quantity > 0)
    }

    // console.log('#======this.props.cart_info:', this.props.cart_info);
    // console.log('#======this.props.customized_inputs:', this.props.customized_inputs);
    var customized_inputs = (
      <div className="customized-inputs">
        {$.map(this.props.customized_inputs, function (input) {
          // console.log("input.id:", input.id)
          // console.log("this.props.cart_info.customized_inputs:", this.props.cart_info.customized_inputs)
          // console.log("this.props.cart_info.customized_inputs[input.id]:", this.props.cart_info.customized_inputs[`${input.id}`])
          const v = this.props.cart_info.customized_inputs === undefined ? null : this.props.cart_info.customized_inputs[`${input.id}`]
          return <CustomizedInput key={input.id} detail={input} v={v} />
        }.bind(this))}
      </div>
    )
    var valid_cart = this.validateCart(this.props.cart) ? false : "disabled";
    let error_message_html = ''
    if (this.state.error_message) {
      error_message_html = (<p className="text-danger"><i className="fa fa-exclamation-triangle mr-1"></i>{this.state.error_message}</p>)
    }
    next_step = <div className="checkout-btn-block">
        <input className="text-right" type="number" min={0} max={this.props.maxMoney}
            name="discount_price" defaultValue={this.props.using_money} hidden />
        <button className={"btn btn-brand-primary btn-lg " + (valid_cart && this.props.shipping.address_type === 'cvs' ? 'disabled' : '')}
          type={valid_cart && this.props.shipping.address_type === 'cvs' ? "button" : "submit"}
          onClick={ valid_cart && this.props.shipping.address_type === 'cvs' ? this.confirmCvs : null} >
            {valid_cart && this.props.shipping.address_type === 'cvs' ? '請選擇門市' : i18n.t('confirm_order')}
        </button>
        {error_message_html}
      </div>
    if (this.props.preview) {
      next_step = (
        <div className="checkout-btn-block">
          <a className="btn btn-brand-primary btn-lg" role="button" tabIndex="0" disabled="disabled" href="#!">{i18n.t('preview_only')}</a>
          {error_message_html}
        </div>
      )
    }
    if (this.props.current_shipping.address_type == "twaddress" && need_shipping) {
      var default_address = {};
      if (this.state.address && this.state.address.address_type == "twaddress") {
        default_address = this.state.address;
      }
      address_area = (
        <div>
          <div id="twzipcode">
            <div className="form-row">
              <div className="col-md-4">
                <div className="form-group ls-form-style-select">
                  <label>{i18n.t('recipient.region')}</label>
                  <div id="lsCounty" data-role="county" data-name="city" data-style="form-control" data-value={default_address.city}></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group ls-form-style-select">
                  <label>{i18n.t('recipient.township')}</label>
                  <div id="lsDistrict" data-role="district" data-style="form-control" data-value={default_address.district}></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group ls-form-style-input-multiple">
                  <label>{i18n.t('recipient.zipcode')}</label>
                  <div id="lsZipcode" data-role="zipcode" data-style="form-control" data-value={default_address.zip}></div>
                </div>
              </div>
            </div>
          </div>
          <div className={"form-group ls-form-style-input tw-address "+(default_address.detail ? 'form-default-value' : '')}>
            <label htmlFor="address-input"><small className="asterisk">*</small>{i18n.t('recipient.address')}</label>
            <input id="address-input" className="form-control" type="text" name="address" defaultValue={default_address.detail} required="required"/>
          </div>
        </div>
      )
    }
    /* 飛雷神: 改動範例 */
    const customerInfo = (this.props.cart_info.customer && this.props.cart_info.customer.phone) ||
                         (this.props.customer && this.props.customer.phone )
    phone_area = (
      <div className={"form-group ls-form-style-input "+(customerInfo ? 'form-default-value' : '')}>
        <label htmlFor="phone-input"><small className="asterisk">*</small>{i18n.t('recipient.cellphone')}</label>
        <input id="phone-input" className="form-control"
               type="tel" name="customer[phone]" pattern="^09[0-9]{8}$" onInput={this.fix_phone}
               defaultValue={customerInfo} required="required"/>
      </div>
    )
    if (this.props.current_shipping.address_type == "international") {
      address_area =
        <div>
          <InternationalAddress/>
        </div>
      phone_area = (
        <div className={"form-group ls-form-style-input "+(this.props.customer && this.props.customer.phone ? 'form-default-value' : '')}>
          <label htmlFor="phone-input"><small className="asterisk">*</small>{i18n.t('recipient.cellphone')}</label>
          <input id="phone-input" className="form-control"
                 type="tel" name="customer[phone]"
                 defaultValue={customerInfo} required="required"/>
        </div>
      )
    }
    if (this.props.current_shipping.address_type == "restricted" && need_shipping ) {
      address_area = <div className={"form-group"}>
      <label htmlFor="restricted-address-select"><small className="asterisk">*</small>{i18n.t('recipient.address')}</label>
        {
          this.props.restricted_address && this.props.restricted_address.length > 0 ?
          <select id="restricted-address-select" className="form-control" name="address">
          {
            this.props.restricted_address.map(_addr=>{
              return <option id={_addr.address} key={_addr.address} value={_addr.address}>{_addr.address}</option>
            })
          }
          </select> : '無可用選項'
      }

    </div>

    }

    if (this.props.current_shipping.address_type == "noaddress" && need_shipping) {
      address_area = ''
    }
    var email_input
    const emailInfo = (this.props.cart_info.customer && this.props.cart_info.customer.email) ||
                      (this.props.customer && this.props.customer.email )
    if (this.props.customer) {
      email_input =
        <div className="form-row">
          <div className="col-9 col-xl-10">
            <input id="email-input" className="form-control" type="email" name="customer[email]" value={emailInfo} required="required" readOnly="readonly"/>
          </div>
          <div className="col-3 col-xl-2">
            <a className="btn btn-outline-primary btn-block" href="/customers/sign_out" data-method="delete">{i18n.t('logout')}</a>
          </div>
        </div>
    } else {
      email_input =
        <div className="form-row ls-fb-login">
          <div className="col-12 col-md-7 col-lg-10">
            <div className={"form-group ls-form-style-input " +(emailInfo ? 'form-default-value' : '')} >
              <label htmlFor="email-input"><small className="asterisk">*</small>Email</label>
              <input id="email-input" className="form-control" type="email" name="customer[email]" defaultValue={emailInfo} required="required"/>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-2">
            <div className="form-row">
              <div className="col-12">
                <button type="button" className="btn btn-outline-primary btn-block d-flex align-items-center justify-content-center" data-target="#signInModal" data-toggle="modal">
                  <span>{i18n.t('login')}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    let available_shippings = this.props.shippings.filter(function (shipping) {
      return !(self.state.force_cvs && !(shipping.address_type == 'cvs'))
    })
    let shipping_select = need_shipping ? <ShippingSelect cvs_info={this.props.cvs_info} cart_info={this.props.cart_info} total_price={this.props.total_price} cvs_ok={this.cvs_ok()} shippings={available_shippings} onShippingSelected={this.props.onShippingSelected} shipping={this.props.shipping}/> : ""
    var invoice_input
    /* 飛雷神 */
    if (this.props.invoice_enabled || true) {
      // JDSGN發票不需要填寫地址
      // let same_as_address = '';
      // if (this.props.current_shipping.address_type == 'twaddress') {
      //   same_as_address = (
      //     <div className="form-check ls-form-style-checkbox custom-control-inline">
      //       <input id="same-as-address" className="form-check-input"
      //              type="checkbox" name="vat[invoice_share_address]"
      //              onChange={this.handleSameAddress} defaultValue="yes"
      //              checked={this.state.invoice_share_address ? 'checked' : ''}/>
      //       <label htmlFor="same-as-address">{i18n.t('recipient.same-as-address')}</label>
      //     </div>
      //   );
      // }
      // 填寫統一編號checkbox
      let gui_number = (
        <div className="form-check ls-form-style-checkbox custom-control-inline">
          <input id="gui-number" className="form-check-input"
                 type="checkbox" name="need_gui_number"
                 onChange={this.showGuiNumber} defaultValue="yes"
                 checked={this.state.need_gui_number ? 'checked' : ''}/>
          <label htmlFor="gui-number">{i18n.t('recipient.tax_id')}</label>
        </div>
      );

      const phoneNumber = this.props.cart_info.jr_phone ? this.props.cart_info.jr_phone :
                                                          this.props.cart_info.customer ?
                                                          this.props.cart_info.customer.phone :
                                                          this.props.customer ? this.props.customer.phone : ''

      let invoice_type_select = (
        <div className="form-group ls-form-style-radio no-frame mb-0 einvoice">
          {/* 需要統一編號，需填寫 Email */}
          {this.state.need_gui_number && <div className="form-group ls-form-style-input">
            <label htmlFor="einvoice_email-input"><small className="asterisk">*</small> 請輸入Email</label>
            <input id="einvoice_email-input" className="form-control"
                    type="email" required="required"
                    onFocus={() => this.handleTypeChecked(this.normalTypeRef)} />
          </div>}

          {/* 電子信箱 */}
          {!this.state.need_gui_number && <div className='form-row align-items-center ml-0'>
            <div className="form-check pr-3">
              <input id="normal_type" className="form-check-input"
                   type="radio" name="vat[invoice_type]"
                   defaultValue="normal_type" required checked={this.state.invoice_type == 'normal_type'}
                   onChange={this.onInvoiceTypeChange}
                   ref={this.normalTypeRef}
              />
              <label className="mb-0" htmlFor="normal_type">{i18n.t('recipient.normal_type')}</label>
            </div>
            <div className="form-type">
              <div className="form-group ls-form-style-input">
                <label htmlFor="einvoice_email-input">{this.state.invoice_type == 'normal_type' ? <small className="asterisk">*</small> : null} 請輸入Email</label>
                <input id="einvoice_email-input" className="form-control"
                       defaultValue={this.props.cart_info.jr_email} name="vat[jr_email]"
                       type="email" required={this.state.invoice_type == 'normal_type' ? "required" : null}
                       onFocus={() => this.handleTypeChecked(this.normalTypeRef)} />
              </div>
            </div>
          </div>}

          {/* 手機號碼 */}
          {!this.state.need_gui_number && <div className='form-row align-items-center ml-0'>
            <div className="form-check pr-3">
              <input id="cellphone_type" className="form-check-input"
                     type="radio" name="vat[invoice_type]"
                     defaultValue="cellphone_type" required checked={this.state.invoice_type == 'cellphone_type'}
                     onChange={this.onInvoiceTypeChange}
                     ref={this.cellphoneTypeRef}
              />
              <label className="mb-0" htmlFor="cellphone_type">{i18n.t('recipient.cellphone')}</label>
            </div>
            <div className="form-type">
              <div className={"form-group ls-form-style-input "+((this.props.customer && this.props.customer.phone ) || (this.props.cart_info.customer && this.props.cart_info.customer.phone) ? 'form-default-value' : '')}>
                <label htmlFor="einvoice_phone-input">{this.state.invoice_type == 'cellphone_type' ? <small className="asterisk">*</small> : null} 請輸入手機號碼</label>
                <input id="einvoice_phone-input" className="form-control"
                       type="tel" pattern="^09[0-9]{8}$" onInput={this.fix_phone}
                       onFocus={() => this.handleTypeChecked(this.cellphoneTypeRef)}
                       defaultValue={phoneNumber} name="vat[jr_phone]"
                       required={this.state.invoice_type == 'cellphone_type' ? "required" : null} />
              </div>
            </div>
          </div>}


          {/* 手機條碼 */}
          {!this.state.need_gui_number && <div className='form-row align-items-center ml-0'>
            <div className="form-check pr-3">
              <input id="carrier_type" className="form-check-input"
                     type="radio" name="vat[invoice_type]"
                     defaultValue="carrier_type" required
                     checked={this.state.invoice_type == 'carrier_type'}
                     onChange={this.onInvoiceTypeChange}
                     ref={this.carrierTypeRef}
              />
              <label className="mb-0" htmlFor="carrier_type">{i18n.t('recipient.carrier_type')}</label>
            </div>

            <div className="form-type">
              <div className="form-group ls-form-style-input">
                <label htmlFor="einvoice_carriers-input">{this.state.invoice_type == 'carrier_type' ? <small className="asterisk">*</small> : null}{i18n.t('recipient.carriers')}</label>
                <input id="einvoice_carriers-input" className="form-control" type="text"
                       defaultValue={this.props.cart_info?.jr_carriers} name="vat[jr_carriers]"
                       required={this.state.invoice_type == 'carrier_type' ? "required" : null}
                       onFocus={() => this.handleTypeChecked(this.carrierTypeRef)} />
              </div>
            </div>
          </div>}

          {/* pre-release version */}
          {/* <div className="form-check custom-control-inline">
            <input id="normal_type" className="form-check-input"
                   type="radio" name="vat[invoice_type]"
                   value="normal_type" required checked={this.state.invoice_type == 'normal_type'}
                   onChange={this.onInvoiceTypeChange}
            />
            <label htmlFor="normal_type">{i18n.t('recipient.tax_id')}</label>
          </div>
          <div className="form-check custom-control-inline">
            <input id="carrier_type" className="form-check-input"
                   type="radio" name="vat[invoice_type]"
                   value="carrier_type" required checked={this.state.invoice_type == 'carrier_type'}
                   onChange={this.onInvoiceTypeChange}
            />
            <label htmlFor="carrier_type">{i18n.t('recipient.carrier_type')}</label>
          </div> */}

        </div>
      );

      let address_input = (
        ! this.state.invoice_share_address || this.props.current_shipping.address_type != 'twaddress' ?
          <div className="form-row">
            <div className="col-md-3">
              <div className={`form-group ls-form-style-input ${this.props.cart_info.vat ? 'onfocus' : ''}`}>
                <label htmlFor="vat-zipcode-input"><small className="asterisk">*</small>{i18n.t('recipient.zipcode')}</label>
                <input id="vat-zipcode-input" className="form-control" type="tel" name="vat[zipcode]" defaultValue={this.props.cart_info.vat ? this.props.cart_info.vat.zipcode : ''} maxLength="5" pattern="\d*" required="required"/>
              </div>
            </div>
            <div className="col-md-9">
            <div className={`form-group ls-form-style-input ${this.props.cart_info.vat ? 'onfocus' : ''}`}>
                <label htmlFor="vat-address-input"><small className="asterisk">*</small>{i18n.t('recipient.address_for_winning')}</label>
                <input id="vat-address-input" className="form-control" type="text" name="vat[address]" defaultValue={this.props.cart_info.vat ? this.props.cart_info.vat.address : ''} required="required"/>
              </div>
            </div>
          </div> : ''
      )
      let gui_number_input = (
        this.state.need_gui_number ?
          <div className="form-row">
            <div className="col-md-6">
            <div className={`form-group ls-form-style-input ${this.props.cart_info.vat ? 'onfocus' : ''}`}>
                <label htmlFor="buyer-input"><small className="asterisk">*</small> {i18n.t('recipient.buyer')}</label>
                <input id="buyer-input" className="form-control" type="text" name="vat[customer_name]" defaultValue={this.props.cart_info.vat ? this.props.cart_info.vat.customer_name : ''} required="required"/>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`form-group ls-form-style-input ${this.props.cart_info.vat ? 'onfocus' : ''}`}>
                <label htmlFor="vat-input"><small className="asterisk">*</small>{i18n.t('recipient.tax_id')}</label>
                <input id="vat-input" className="form-control" type="tel" name="vat[number]" maxLength="8" pattern="\d*" defaultValue={this.props.cart_info.vat ? this.props.cart_info.vat.number : ''} required="required"/>
              </div>
            </div>
          </div> : ''
      )
      let carrier_input = (
        this.state.invoice_type ==  ''
      )
      let normal_input = <div>
                          {/*
                            {same_as_address}
                            {gui_number}
                            {address_input}
                            {gui_number_input}
                          */}
                          {gui_number}
                          {gui_number_input}
                        </div>
      invoice_input = (
        <div className="checkout-form-section invoice">
          <h3 className="section-title">{i18n.t('recipient.egui')}</h3>
          {normal_input}
          {invoice_type_select}
          {carrier_input}
        </div>)

    } else {
      invoice_input = ''
    }

    const nameInfo = (this.props.cart_info.customer && this.props.cart_info.customer.name) ||
                     (this.props.customer && this.props.customer.name )
    return (
      <div className="col-lg-7 order-lg-1 col-checkout-form-bottom-left">
        <div className="checkout-content-wrapper checkout-form">
          <input type="hidden" name="authenticity_token" defaultValue={this.props.authenticity_token}/>
          <input type="hidden" name="u" defaultValue={this.props.agent_token || ''}/>
          <input type="hidden" name="promotion_code" defaultValue={this.props.promotion_code ? this.props.promotion_code.code : ''}/>
          {/* <div className="checkout-form-section customer">
            <h3 className="section-title">{i18n.t('login')}</h3>
            {customer_area}
          </div> */}
          <div className="checkout-form-section recipient">
            <h3 className="section-title">{i18n.t('recipient.title')}</h3>
            <div className={"form-group ls-form-style-input "+(nameInfo ? 'form-default-value' : '')}>
              <label htmlFor="name-input"><small className="asterisk">*</small>{i18n.t('recipient.name')}</label>
              <input id="name-input" className="form-control"
                     type="text" name="customer[name]" onChange={this.chkName}
                     defaultValue={nameInfo} required="required" maxLength="30"/>
            </div>
            {phone_area}
            {subscription_area}
            {shipping_select}
            {address_area}
            <PaymentSelect payments_options={payments_options} total_price={this.props.total_price} current_shipping={this.props.current_shipping} />
            {customized_inputs}
            {/* <div className="customized-inputs d-flex align-items-center mb-3">
              <div className="form-check ls-form-style-checkbox">
                <input type="checkbox" id="readTerms" className="form-check-input" name="readTermsService" required="required" value="是" defaultChecked="true" />
                <label htmlFor="readTerms" className="mb-0"><small className="asterisk">*</small>我已詳閱並同意</label>
              </div>
              <a href="/terms-of-service" className="ml-1 text-primary text-link" target="_blank">JDSGN條款</a>
            </div> */}
            {/*<div className="form-group ls-form-style-textarea">
              <label htmlFor="note-input">{i18n.t('recipient.note')}</label>
              <textarea id="note-input" className="form-control" type="text" name="note" maxLength="700" defaultValue={this.props.cart_info.note}>
              </textarea>
            </div>*/}
          </div>
          {invoice_input}
          <div className="form-group ls-form-style-textarea">
            <label htmlFor="note-input">{i18n.t('recipient.note')}</label>
            <textarea id="note-input" className="form-control" type="text" name="note" maxLength="700"></textarea>
          </div>
          {payments_options.length > 0 ? next_step : ''}
          <div id="liteshop-go-pay-form"></div>
        </div>
      </div>
    );
  }
}

class ShippingSelect extends React.Component {
  constructor(props) {
    super(props)
    this.onSelected = this.onSelected.bind(this)
    this.selectCVS = this.selectCVS.bind(this)
  }

  selectCVS(shipping_id){
    $.ajax("/carts/cvs_store_select.json",
    {
      type: 'post',
      data: $("#cart-form").serialize()+`&shipping_id=${shipping_id}`, //override shipping id
      success: function (data) {
        if (data.success) {
          var form = data.form_text;
          $("#cvs-select-form").append(form);
          console.log('============', $("#cart-form").serialize())
          /* 飛雷神: 測試轉址可以先關掉 */
          $("#cvs-select-form > form").trigger('submit');
        } else {
          alert(data.message)
        }
      }
    }
    );

  }

  onSelected(event) {
    let shipping
    shipping = this.props.shippings.find((_shipping) => {
      return _shipping.shipping_id == event.target.value})
    this.props.onShippingSelected(shipping)
  }
  componentDidMount(){
    if(this.props.cart_info.shipping_id ){
      for(let shipping of this.props.shippings){
        if(shipping.shipping_id === parseInt(this.props.cart_info.shipping_id)){
          this.props.onShippingSelected(shipping)
          break
        }
      }
    }
  }
  render() {
    let self = this
    var shipping_select = this.props.shippings.map((shipping,index)=> {
      if (shipping.address_type == "cvs" && (self.props.total_price + (shipping.free_threshold && self.props.total_price >= shipping.free_threshold ? 0 : shipping.price)) >= 20000) {
        return
      }
      if (self.props.cvs_ok || shipping.address_type != "cvs") {
        return (
          <div className={"form-check " + (shipping.address_type == "cvs" ? "cvs" : "")} key={shipping.shipping_id} onChange={self.onSelected}>
            <input id={'shipping_'+shipping.shipping_id} className="form-check-input"
                   type="radio" name="shipping_id"
                   defaultValue={shipping.shipping_id}
                   data-type={shipping.address_type}
                   data-free-threshold={shipping.free_threshold}
                   data-price={shipping.price}
                   defaultChecked={ (!this.props.cart_info.shipping_id && index == 0) || ( parseInt(this.props.cart_info.shipping_id) === shipping.shipping_id) ? 'checked' : ''}
                   required="required"/>
            <label htmlFor={'shipping_'+shipping.shipping_id}>{shipping.title}</label>
            { shipping.address_type == "cvs" && shipping.shipping_id === self.props.shipping.shipping_id ?
              <div className="select-cvs-warpper">
                <p className="tips d-none" id="cvsTips">請選擇門市</p>
                <div className='select-cvs' onClick={()=>this.selectCVS(shipping.shipping_id)}>
                  <i className="icon">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}} xmlSpace="preserve">
                      <path d="M511.5,172.1l-32-128C477.8,37,471.3,32,464,32H48c-7.3,0-13.8,5-15.5,12.1l-32,128C0.2,173.4,0,174.7,0,176
                                    c0,26.8,12.7,50.5,32,65v223c0,8.8,7.2,16,16,16h160c8.8,0,16-7.2,16-16V352h64v112c0,8.8,7.2,16,16,16h160c8.8,0,16-7.2,16-16V241
                                    c19.3-14.5,32-38.2,32-65C512,174.7,511.8,173.4,511.5,172.1z M448,448H320V336c0-8.8-7.2-16-16-16h-96c-8.8,0-16,7.2-16,16v112H64
                                    V254.7c3.9,0.7,7.9,1.3,12,1.3c24.4,0,46.1-12.1,60-30.9c13.9,18.8,35.6,30.9,60,30.9s46.1-12.1,60-30.9
                                    c13.9,18.8,35.6,30.9,60,30.9s46.1-12.1,60-30.9c13.9,18.8,35.6,30.9,60,30.9c4.1,0,8.1-0.6,12-1.3V448z M436,224
                                    c-24.3,0-44-21.5-44-48c0-8.8-7.2-16-16-16s-16,7.2-16,16c0,26.5-19.7,48-44,48s-44-21.5-44-48c0-8.8-7.2-16-16-16s-16,7.2-16,16
                                    c0,26.5-19.7,48-44,48s-44-21.5-44-48c0-8.8-7.2-16-16-16s-16,7.2-16,16c0,26.5-19.7,48-44,48c-23.7,0-43.1-20.5-44-46.1L60.5,64
                                    h391L480,177.9C479.1,203.5,459.7,224,436,224z" />
                    </svg>
                  </i>
                  選擇門市
                </div>
              </div> : null
            }
            {
              this.props.cvs_info && this.props.cvs_info.shipping_id == shipping.shipping_id && this.props.cvs_info.cvs_store_id?
                <div className='cvs_store-address'>
                  <input type='hidden' name="cvs_store_id" value={this.props.cvs_info.cvs_store_id}></input>
                  <input type='hidden' name="cvs_store_name" value={this.props.cvs_info.cvs_store_name}></input>
                  <input type='hidden' name="cvs_address" value={this.props.cvs_info.address}></input>
                  <span className="store_id">[{this.props.cvs_info.cvs_store_id}]</span>
                  <span className="store_name">{this.props.cvs_info.cvs_store_name}</span>
                  <span className="store_address">{this.props.cvs_info.address}</span>
                </div> : null
            }
          </div>
        )
      }
    });
    return (
      <div className="form-group ls-form-style-radio" id="shippingSelect">
        <label><small className="asterisk">*</small>{i18n.t('recipient.delivery')}</label>
        <div className="form-check-group">
          {shipping_select}
        </div>
      </div>
    )
  }
}
class PaymentSelect extends React.Component {
  render() {
    let payment_hint = i18n.t("recipient.no_payment_method")
    if (this.props.current_shipping.address_type == "cvs" && this.props.total_price > 20000) {
      payment_hint = i18n.t("recipient.cannot_exceed")
    }
    return (
      this.props.payments_options.length > 0 ?
      <div className="form-group ls-form-style-radio">
        <label><small className="asterisk">*</small>{i18n.t('recipient.payment')}</label>
        <div className="form-check-group">{this.props.payments_options}</div>
      </div> :
      <div className="form-group ls-form-style-radio">
        <span className="hint text-danger">{payment_hint}</span>
      </div>
    )
  }
}

export default CheckoutForm