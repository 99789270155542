import React from 'react';
import Modal from 'react-modal';
import i18n from '../../components/i18next/i18n';

export default class RRateModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: this.props.is_18plus && (parseInt(readCookie('18plus'))!==1)
    }
    this.onModalCancel = this.onModalCancel.bind(this)
    this.muteModal = this.muteModal.bind(this)
  }
  onModalCancel() {
    this.setState({ isModalOpen: false });
  }
  muteModal(event) {
    createCookie('18plus', event.target.checked ? 1 : 0, 10*365);
  }
  redirected_to_not_allowed() {
    createCookie('18plus', 0, 10*365);
    window.location.href = 'https://google.com';
  }
  render() {
    return (
      <Modal ariaHideApp={false}
        className="liteshopStoreModal RRateModal modal d-block"
        overlayClassName="liteshopStoreModalOverlay"
        shouldCloseOnOverlayClick={false}
        isOpen={this.state.isModalOpen}
        onRequestClose={this.onModalCancel}
      >
        <div className="modal-content">
          <div className="modal-body pb-3">
            <div className="RRate-modal-content">
                <div className="RRate-modal-header d-flex align-items-center">
                  <div className="icon mr-2">
                    <img alt={i18n.t('modal.18plus')} className="modal-icon" src={this.props.r_rate_icon}/>
                  </div>
                  <h5 className='font-weight-bold'>請注意！本頁面為限制級！未成年人請勿進入！</h5>
                </div>
                <div className="custom-control custom-checkbox mt-4">
                  <input className="custom-control-input" id="muteModalInput" type="checkbox" onChange={this.muteModal}/>
                  <label className="custom-control-label" htmlFor="muteModalInput">不再詢問</label>
                </div>
                <div className="form-inline py-3 mb-4">
                  <a href="#!" className="btn btn-danger modal-btn btn-pill" onClick={this.onModalCancel}>是，我已滿18歲</a>
                  <a href="#!" className="btn btn-danger modal-btn btn-pill ml-2" onClick={this.redirected_to_not_allowed}>否</a>
                </div>
                <div className="rrate-text">
                  <p>請您務必確認您已年滿18歲、具有完全行為能力並且同意本網站之使用條款，才可以瀏覽使用本網站，否則謝絕進入。</p>
                  <p>本頁面依iWIN網路內容防護機構之自律標準制定防護措施，並持續優化、調整與改善防護機制。</p>
                </div>
              </div>
          </div>
        </div>
      </Modal>
    )
  }
}
