import React from 'react'
import Modal from 'react-modal'
import i18n from '../../components/i18next/i18n'

export default class AddressModal extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    let default_address = {'city': '新北市','district': 'district', 'zip': '350', 'detail': '台北市 內湖區 內湖路一段360巷8號7樓' }

    return (
      <Modal className="lsDefaultModal" overlayClassName="lsDefaultModalOverlay" isOpen={this.props.isOpen} onRequestClose={this.props.handleModalClose} ariaHideApp={false}>
        <form action="/members/set_address" method="post" acceptCharset="UTF-8">
          <input type="hidden" name="authenticity_token" value={this.props.authenticity_token}/>
          <input type="hidden" name="address[id]" value={this.props.default_address?.id}/>
          <div className="modal-content">
            <div className="modal-header justify-content-center border-0 pb-0 pt-3">
              <h4 id="titleAddress" className="modal-title text-center">{i18n.t("member.address")}</h4>
              <button type="button" className="close" onClick={this.props.handleModalClose}>
                <i aria-hidden="true" className="icon icon-cancel"></i>
                <span className="sr-only">{i18n.t('close')}</span>
              </button>
            </div>
            <div className="modal-body">
              <AddressArea {...this.props.default_address}/>
            </div>
            <div className="modal-footer full-buttons">
              <div className='row no-gutters w-100'>
                <div className="col-6">
                  <button className="btn btn-primary btn-block" name="address[action]" value="update" type="submit">確認</button>
                </div>
                <div className="col-6">
                  <button className="btn btn-default btn-block" onClick={this.props.handleModalClose}>取消</button>
                </div>
              </div>
            </div>
          </div>
         </form> 
      </Modal>
    )
  }
}

class AddressArea extends React.Component {
  componentDidMount(){
    $('#twzipcode').twzipcode();
    $('#twzipcode select').prop('required', true);
    $('#twzipcode input').prop('required', true);
  }
  render() {
    return (
      <div>
        <div id="twzipcode">
          <div className="form-row">
            <div className="col-md-4">
              <div className="form-group ls-form-style-select">
                <label>{i18n.t('recipient.region')}</label>
                <div id="lsCounty" data-role="county" data-name="address[city]" data-style="form-control"
                  data-value={this.props.city ?? ''}
                >
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ls-form-style-select">
                <label>{i18n.t('recipient.township')}</label>
                <div id="lsDistrict" data-role="district" data-name="address[district]" data-style="form-control"
                  data-value={this.props.distinct ?? ''}
                >
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ls-form-style-input-multiple">
                <label>{i18n.t('recipient.zipcode')}</label>
                <div id="lsZipcode" data-role="zipcode" data-name="address[zip]" data-style="form-control"
                  data-value={this.props.zip ?? ''}
                >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"form-group ls-form-style-input tw-address "+(this.props.detail ? 'form-default-value' : '')}>
          <label htmlFor="address-input"><small className="asterisk">*</small>{i18n.t('recipient.address')}</label>
          <input id="address-input" className="form-control" type="text" name="address[detail]" defaultValue={this.props.detail ?? ''} required="required"/>
        </div>
      </div>
    );
  }
}
