export function setCookie(key,value,days = 3) {
  var expires
  var domain = "; domain=" + window.location.hostname
  var path = "; path=/"
  var date = new Date()
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
  expires = "; expires=" + date.toGMTString()
  document.cookie = encodeURIComponent(key) + "=" + encodeURIComponent(value) + expires + domain + path
};

export function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null
}

export function buildMenu(menu_items) {
  let parent_items = menu_items.filter(item => item.parent_id == null)
  let children_items = menu_items.filter(item => item.parent_id !== null)

  let menu = parent_items.sort((a,b)=>a.position - b.position).map(parent_item => {
    let children = parent_item.children.map(child => {
      return children_items.find(c => child.id === c.id)
    }).filter(c => c !== undefined).sort((a,b)=>a.position - b.position);

    let item = parent_item
    item.children = children
    return item
  })
  return menu
}


export function getLink(item) {
  switch (item.menu_type) {
    case 'link':
      return item.link

    // case 'menu':
    // case 'collection':
    //   return item.parent_id ? `/collections?menu_id=${item.parent_id}&selected=${item.id}` : `/collections?menu_id=${item.id}`

    case 'collection':
      return item.collection ? `/collections/${item.collection.token}?menu_id=${item.id}` : "#0"

    case 'product':
      return item.product ? `/products/${item.product.token}?menu_id=${item.id}` : "#0"

    case 'article':
      return item.article ? (item.article.category ? `/article_categories/${item.article.category.token}/articles/${item.article.token}?menu_id=${item.id}` : `/articles/${item.article.token}?menu_id=${item.id}`) : "#0"

    case 'article_category':
      return item.article_category ? `/article_categories/${item.article_category.token}?menu_id=${item.id}` : "#0"

    case 'menu':
    default:
      return "#0"
  }
}