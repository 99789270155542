import { isEqual, filter, isNil } from "lodash";

/* 濾除 bundle */
const filterBundle = items => filter(items, item => isNil(item.bundle_id))

/* qna */
export const qnaFormatter = (qnas) => (
  qnas && qnas.map(qna => ({
            content: qna.content,
            userExist: (typeof(qna.user_id) === 'number'),
            userEmail: qna.user?.email,
            createdAt: qna.created_at.split('T')[0]
          }))
)

/* orderItem */
export const orderItemFormatter = (orderItems, shipmentVariants, defaultPhoto) => (
  filterBundle(orderItems).map(item => {
    let _item = shipmentVariants.find(v => v.variant_id===item.variant.id);
    return ({
      isPreorder: item.is_preorder,
      itemPrice: isEqual(typeof(item.item_price), 'number') ? `$${item.item_price}` :
                 isEqual(typeof(item.item_price), 'string') ? item.item_price : 'FREE',
      productTitle: item.product_title,
      variantTitle: item.variant_title,
      quantity: item.quantity,
      shippedQty: _item?.quantity || 0,
      unshippedQty: (item.quantity - item.shipped),
      variantPhoto: item.variant.v_photo.url ?? defaultPhoto
    })
  })
)

/* 組合商品 */
export const bundleItemFormatter = (bundleItems, shipmentVariants, defaultPhoto) => (
  bundleItems && bundleItems.map(bundle => ({
    productTitle: bundle.title,
    quantity: bundle.quantity,
    itemPrice: bundle.total,
    trackingNumber: bundle?.tracking_number,
    variantPhoto: bundle.bundle_photo ?? defaultPhoto,
    variantTotalDetail: bundle.order_items.map(item => `${item.title} x${item.qty}`),
    variantShippedDetail: bundle.order_items.map(item => {
      let _item = shipmentVariants.find(v => v.variant_id===item.variant_id);
      let qty = _item?.quantity || 0;
      return [`${item.title} x ${qty}`, qty]
    }),
    variantUnshippedDetail: bundle.order_items.map(item =>
      [`${item.title} x ${item.qty-item.shipped}`, item.qty-item.shipped]
    )
  }))
)