export default
{
  "product_description": "Product Details",
  "no_content": "No Content",
  "choose_quantity": "Add to Cart",
  "please_select_variant": "Please Select",
  "buy_now": "Buy Now",
  "checkout": "Proceed to Checkout",
  "checkout_sm": "Checkout",
  "shopping_cart": "Cart",
  "add_cart": "Add to Cart",
  "cancel": "Cancel",
  "update_quantity": "Modify Quantity",
  "additional_purchase": "Additional Purchase Discount",
  "target_gifts": "Gift",
  "freight": "Shipping",
  "plus_freight": "Handling",
  "total": "Total",
  "subtotal": "Subtotal",
  "no_items_in_cart": "No item in cart",
  "remove": "Delete",
  "or_use": "or",
  "logout": "Log out",
  "login": "sign in",
  "signup": "sign up",
  "signup_signin": "sign up | sign in",
  "forget_password": "Forget Password",
  "close": "close",
  "subscription": "Subscriptions",
  "discount": "discount",
  "numbers_bought": "the numbers of items bought",
  "frequency": "frequency(Days)",
  "confirm_order": "Confirm Order",
  "confirm_order_unregister": 'Confirm Order',
  "preview_only": "Preview only",
  "limit_purchase": "(Maximum Purchase Quantity: {{limit}})",
  "helpers": {
    'return': "Return Policy",
    'contact_info': "Contact Info",
    'contact': "Contact Us",
    'home': 'Home'
  },
  "cart": {
    "click_additional": "Add",
    "promocode_successfully": "Promo Code Applied",
    "no_such_code": "Promo Code Invalid",
    "click_collapse": "click collapse",
    "more_style": "more styles",
    "free_shipping": "Free shipping on orders over NT$:{{threshold}}",
    "input_promotion_code": "Add Promo or Gift Card",
    "apply_code": "APPLY",
    "choose_any": "choose any",
    "pieces": "pieces NT$",
    "ntds": "",
    "styles": "kind styles",
    "has": "has",
    "not_yet_eligible": "Not yet eligible",
    "need": "need",
    "to_used": "can be used",
    "sold_out": "Sold Out",
    "pre_order": "Preorder",
    "use_diamond": 'Use Diamond',
    "voucher": "Voucher",
    "use_voucher": 'Use Voucher',
    "promotion_code": "Promotion Codes",
    "no_promotion_code": "No Promotion Code",
    "no_address": "No Pick up address",
    "no_favorite": "No Wish List"
  },
  "recipient": {
    "title": "Ship to",
    "name": "Name",
    "note": "Notes",
    "delivery": "Delivery Option",
    "payment": "Payment Method",
    "region": "Region/County/City",
    "township": "Township/Urban Area",
    "zipcode": "Zip Code",
    "address": "Address",
    "address_for_winning": "Mailing Address",
    "cellphone": "Cellphone Number",
    "same-as-address": "Same as Shipping Address",
    "tax_id": "Company Tax ID",
    "buyer": "Buyer",
    "carriers": "Carriers to get e-invoices(8 characters / Optional)",
    "egui": "e-Government Uniform Invoice",
    "normal_type": "e-Invoice",
    "carrier_type": "Carrier",
    "no_payment_method": "No payment method available, please try another shipping method or adjust the shopping cart items",
    "cannot_exceed": "The amount of payment for in-store pickup cannot exceed NT$20,000"
  },
  "purchaser": {
    "title": "Buyer Information",
    "login": "Log in to manager your orders"
  },
  "payment": {
    "credit" : "Credit Card",
    "atm": "ATM",
    "cvs": "CVS Kiosk",
    "inst": "Installment",
    "CVS_COD": "Convenience Store Pick Up",
    "Credit_3": "Credit Card Installment (3-month)",
    "Credit_6": "Credit Card Installment (6-month)",
    "Credit_9": "Credit Card Installment (9-month)",
    "Credit_12": "Credit Card Installment (12-month)",
    "cod": "Cash On Delivery"
  },
  "member": {
    "member_center": "Member Center",
    "sign_in_with": "Please select login method",
    "profile": "My Profile",
    "order": "My Orders",
    "promotion_code": "My Promotion Codes",
    "change_password": "Change My Password",
    "address": "May Addresses",
    "promotion_code": "My Promotion Codes",
    "voucher": "My Vouchers",
    "favorite": "My Favorites",
    "qna": "My Q&A",
    "vip": "Member VIP",
    "voucher": "My Voucher",
    "sign_in_facebook": "Facebook Login",
    "sign_in_line": "Line Login"
  },
  "orders":{
    "checking": "Orders",
    "your_email": "Email Address",
    "number": "Order number",
    "total_price": "Order Total",
    "status_text": "Order Status",
    "no_orders": "No order",
    "status":{
      "ordered": "Order Completed",
      "new": "New Order",
      "canceled": "Canceled",
      "processing": 'Processing',
      "done": "Done",
      "request": "Request",
      "request_cancel": 'Request Canceled',
      "request_return": 'Request Return',
      "returned": 'Return Completed'
    },
    "payment_status": {
      "payment": 'Payment',
      "waiting": 'Waiting',
      "confirming": 'Confirming',
      "paid": 'Paid',
      "refunded": 'Refunded',
      "cod": 'COD',
    },
    "shipping_status":{
      "text": 'Shipping',
      "ready": 'Ready to be shipped',
      "partial": 'Partial shipped',
      "shipped": "In Transit.",
      "returned": 'Returned',
      "arrived": 'Delivered',
      "received": 'Received'
    },
    "invoice_status":{
      "new": 'New',
      "waiting": 'Held',
      "done": 'Completed',
      "cancel_pending": 'Cancel Pending',
      "canceled": 'Canceled'
    },
    "discount_type":{
      "target_price": "Price Tier Discount"
    },
    "payment_type":{
      "credit": 'Credit Card'
    },
    "shipping_type":{
      "regular": 'Home Delivery',
      "person": 'Face to Face',
      "cvs": '7-11 cod',
      "cvs_fami": 'Family Mart cod',
      "post": 'Mailing',
      "international": 'International Mailing/Delivery'
    },
    "modal": {
      "18plus": '18+'
    }
  }
}