import React from 'react'
import ReactDOM from 'react-dom'

import ProductDetail from './product_detail'
import Checkout from '../checkout/checkout'
import { updateCartLink } from '../checkout/update_cart'

export default class ProductDirect extends React.Component {
  constructor(props) {
    super(props);
    let photo =  this.props.product.medium ? this.props.product.medium : this.props.product.variants[0].medium

    let ready_to_check = null;
    if (!!window.performance && window.performance.navigation.type === 2)
      ready_to_check = false;
    else {
      ready_to_check = readCookie('jkf-login') == 1 ||  false;
      ready_to_check = readCookie('began-checkout')== 1 || false;
    }
    createCookie("jkf-login", 0, 0);
    createCookie('began-checkout',false,false);

    this.state = {
      cart: this.props.cart_array,
      photo: photo,
      ready_to_checkout: ready_to_check,
      cart_token: this.props.cart_token,
      update_gifts: false,
      selected_gifts: this.props.gift_array||{}, // only for JD
      is_unmount: false // only for JD
    }
    this.updateCart = this.updateCart.bind(this)
    this.onVariantPhotoChange = this.onVariantPhotoChange.bind(this)
    this.onCartChange = this.onCartChange.bind(this)
    this.onCheckoutClicked = this.onCheckoutClicked.bind(this)
    this.onCartSet = this.onCartSet.bind(this)
    this.findPiece = this.findPiece.bind(this)
    this.setUpdateGifts = this.setUpdateGifts.bind(this)
    this.onUpdateGifts = this.onUpdateGifts.bind(this)
  }
  onUpdateGifts(item, is_unmount) {
    if (item.quantity!==0 && !!item.threshold) {
      this.state.selected_gifts[item.campaign_token] ||= {}
      this.state.selected_gifts[item.campaign_token][item.threshold] = {variant_id: item.variant_id, quantity: item.quantity};
    }
    else if (is_unmount && !!item.threshold) {
      this.state.selected_gifts[item.campaign_token] ||= {}
      this.state.selected_gifts[item.campaign_token][item.threshold] = {}
    }
    else
      this.state.selected_gifts[item.campaign_token] = {}
    this.setState({ selected_gifts: this.state.selected_gifts, is_unmount: is_unmount })
    this.findPiece = this.findPiece.bind(this)
  }

  findPiece(n_piece_group) {
    return Object.entries(n_piece_group).map(entry => {
      let product = this.props.product.n_piece_group.find(p => p.id===parseInt(entry[0]))
      let variant = product.variants.find(p => p.id===parseInt(entry[1]))
      return {id: entry[1], title: variant.title, product: {title: product.title}}
    })
  }
  updateCart(variant,quantity,n_piece_group={}) {
    let _variant_in_cart = null;
    for (let i = 0; i < this.state.cart.length; i++) {
      if((variant.variant_id && this.state.cart[i].variant_id == variant.variant_id) || (variant.bundle_id && this.state.cart[i].bundle_id == variant.bundle_id)){
        _variant_in_cart = this.state.cart[i];
        break;
      }
    }
    this.findPiece(n_piece_group)
    if (_variant_in_cart == null) {
      variant.n_piece_group = n_piece_group
      variant.n_pieces = Object.values(n_piece_group || {})
      variant.n_pieces_detail = this.findPiece(n_piece_group)
      variant.quantity = quantity;
      this.state.cart.push(variant)
    } else {
      variant.n_piece_group = n_piece_group
      variant.n_pieces = Object.values(n_piece_group || {})
      variant.n_pieces_detail = this.findPiece(n_piece_group)
      _variant_in_cart.quantity = quantity
    }
    this.onCartChange(this.state.cart);
  }
  onVariantPhotoChange (index, photo) {
    $('.gallery-slider').slick('slickGoTo', index);
    this.setState({photo: photo})
  }
  onCartSet(token){
    this.setState({cart_token:token})
  }
  onCartChange (cart) {
    this.setState({cart: cart}, () => {
      updateCartLink(this.state.cart)
      this.setUpdateGifts(true)
    })
  }
  setUpdateGifts(update_gifts) {
    this.setState({ update_gifts: update_gifts });
  }

  // addAnchorButton (target) {
  //   try{
  //     let $target = document.getElementById(target)
  //     let headerHeight = document.querySelector('#pageHeader') ? document.querySelector('#pageHeader').offsetHeight + 20 : 0
  //     let hasAnchorBtn = false;
  //     var observer = new IntersectionObserver(entries => {
  //       entries.forEach(i => {
  //         if (!i.isIntersecting) {
  //           if (!hasAnchorBtn) {
  //             let node = document.createElement('a');
  //             node.setAttribute('href', "#");
  //             node.setAttribute('id', "anchorToCart");
  //             node.classList.add("btn", "rounded-circle", "anchor-to-cart");
  //             document.getElementById('customerService').appendChild(node);
  //             hasAnchorBtn = true;
  //             let btn = document.getElementById('anchorToCart')
  //             btn.addEventListener('click', e => {
  //               e.preventDefault();
  //               window.scrollTo({'behavior': 'smooth', 'top': $target.offsetTop - headerHeight})
  //               // $target.scrollIntoView({ behavior: "smooth" });
  //             })
  //           }
  //         } else if (i.isIntersecting & hasAnchorBtn) {
  //           document.getElementById("anchorToCart").remove()
  //           hasAnchorBtn = false;
  //         }
  //       });
  //     }, {});
  //     observer.observe($target);

  //   }catch{

  //   }
  // }

  componentDidMount() {
    let sent = false
    let self = this
    if (!sent) {
      try {
        fbq('track', 'ViewContent', {
          content_name: self.props.product.title,
          content_type: 'product_group',
          content_ids: self.props.product.variants.map(v=>v.sku).filter(v=>v),
          referrer: document.referrer,
          userAgent: navigator.userAgent,
          language: navigator.language
        },{
          eventID: self.props.fb_event_id
        })
      } catch (e) {
        console.log("Exception")
        console.log(e)
      }
      sent = true
    }
    // this.addAnchorButton('lsProductDetail')
  }
  onCheckoutClicked () {
    if (!this.state.ready_to_checkout) {
      createCookie('began-checkout',1,1)
      try {
        gtag('event', 'begin_checkout', {});
      } catch(e) {
      }
      try {
        fbq('track', 'InitiateCheckout',{EventID: this.props.fb_event_id});
      } catch(e) {
      }
    }
    $('.product-landing-countdown-timer').hide()

    this.setState({ready_to_checkout: true}, () => {
      let target = ReactDOM.findDOMNode(this.refs.checkout.refs.cart_table)
      let headerHeight = document.querySelector('#pageHeader') ? document.querySelector('#pageHeader').offsetHeight + 20 : 0
      if (this.refs.checkout) {
        window.scrollTo({'behavior': 'smooth', 'top': target.offsetTop - headerHeight})
      }
    })
  }
  render () {
    return (
      <div itemScope itemType="http://schema.org/Product">
        <div id="lsProductDetail" className="lsProductDetail pt-0">
          <nav aria-label="breadcrumb" className="mb-5 d-none d-md-block">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">主頁</a></li>
                { this.props.product.collection_title ?
                  <li className="breadcrumb-item">
                    <a href={this.props.product.collection_link ? this.props.product.collection_link : '#!'}>
                      {this.props.product.collection_title}
                    </a>
                  </li> : null }
                <li className="breadcrumb-item active">{this.props.product.title ? this.props.product.title : '' }</li>
              </ol>
            </div>
          </nav>
          <div className="container">
            <ProductDetail cart={this.state.cart}
                           onCartSet={this.onCartSet}
                           photo_url={this.state.photo}
                           product_photos={this.props.product_photos}
                           variant_photos={this.props.variant_photos}
                           product={this.props.product}
                           cart_token={this.props.cart_token}
                           shop_token={this.props.shop_token}
                           show_sold={this.props.show_sold}
                           updateCart={this.updateCart}
                           onCheckoutClicked={this.onCheckoutClicked}
                           onVariantPhotoChange={this.onVariantPhotoChange}
                           agent_token={this.props.agent_token}
                           authenticity_token={this.props.authenticity_token}
                           current_customer={this.props.current_customer}
                           shop_vips={this.props.shop_vips}
                           shop_customer={this.props.shop_customer}
                           shop_show_preorder={this.props.shop_show_preorder}
                           article_statics={this.props.article_statics}
                           ig_images={this.props.ig_images}
                           findPiece={this.findPiece}
                           product_landing={this.props.product_landing}
                           selected_gifts={this.state.selected_gifts}
                           onUpdateGifts={this.onUpdateGifts}
                           gift_campaigns={this.props.gift_campaigns}
                           ref="product_detail"/>
          </div>
        </div>
        <div className="lsCheckout">
          <div className="container">
            {
              this.state.ready_to_checkout && this.state.cart.length > 0  ?
                <Checkout shop_title={this.props.shop_title}
                          jkf_uid={this.props.jkf_uid}
                          jkf_display_name={this.props.jkf_display_name}
                          restricted_address={this.props.restricted_address}
                          cart_array={this.state.cart}
                          shippings={this.props.shippings}
                          payments={this.props.payments}
                          shop_token={this.props.shop_token}
                          email_notify={this.props.email_notify}
                          cart_token={this.state.cart_token}
                          source={this.props.source}
                          agent_token={this.props.agent_token}
                          invoice_enabled={this.props.invoice_enabled}
                          customized_inputs={this.props.customized_inputs}
                          onCartChange={this.onCartChange}
                          target_price_discounts={this.props.target_price_discounts}
                          promotion_code_available={this.props.promotion_code_available}
                          my_promotion_codes={this.props.my_promotion_codes}
                          agent_token={this.props.agent_token}
                          authenticity_token={this.props.authenticity_token}
                          current_customer={this.props.current_customer}
                          additional_purchase_items={this.props.additional_purchase_items}
                          preview={this.props.preview}
                          subscriptions_enabled={this.props.subscriptions_enabled}
                          subscriptions_credit_enabled={this.props.subscriptions_credit_enabled}
                          subscriptions_cod_enabled={this.props.subscriptions_cod_enabled}
                          subscriptions_periods={this.props.subscriptions_periods}
                          subscriptions_interval={this.props.subscriptions_interval}
                          subscriptions_discount={this.props.subscriptions_discount}
                          // diamonds={this.props.diamonds}
                          discount_ratio={this.props.discount_ratio}
                          cvs_info={{}}
                          cart_info={{}}
                          voucher_rule={this.props.voucher_rule}
                          voucher_remain={this.props.voucher_remain}
                          gift_campaigns={this.props.gift_campaigns}
                          update_gifts={this.state.update_gifts}
                          setUpdateGifts={this.setUpdateGifts}
                          selected_gifts={this.state.selected_gifts} // only for JD
                          onUpdateGifts={this.onUpdateGifts} // only for JD
                          gift_array={this.props.gift_array}
                          is_unmount={this.state.is_unmount}
                          checkout_page="product"
                          ref="checkout"/> : null
            }
          </div>
        </div>
      </div>
    )
  }
}
