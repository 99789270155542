import React from 'react'
import MemberLayout from './memberLayout'
import i18n from '../../components/i18next/i18n'
import StoreCollection from '../store/collection'

export default class MemberFavorite extends MemberLayout {
  constructor(props) {
    super(props);
    this.rerender_favorite = this.rerender_favorite.bind(this)
  }
  rerender_favorite(total_count) {
    if (total_count === -1) {
      if (this.state.total_count) {
        let total_count = this.state.total_count;
        this.setState({ total_count: total_count-1 });
      }
    }
    else
      this.setState({ total_count: total_count });
  }
  render_inner() {
    let emptyContent = <div className="p-5 placeholder-image">
        <div className='icon'>
          <img src={this.props.placeholder_img_url} alt={i18n.t('cart.no_favorite')} className='w-100' />
        </div>
        <p className="mb-0 text-muted">{i18n.t('cart.no_favorite')}</p>
      </div>
    return (
      <>
        <div className="section-head-group d-flex align-items-center justify-content-center mb-3">
          <i className="mr-2" data-feather="tag"></i>
          <h1 className="section-subtitle mb-1 mb-md-0">{i18n.t("member.favorite")}</h1>
        </div>
        {
          this.state?.total_count === 0
            ? emptyContent
            : <StoreCollection shop_token={this.props.shop_token} cart_items={this.props.cart_items} shop_customer={this.props.shop_customer} current_page={this.props.current_page} rerender_favorite={this.rerender_favorite} authenticity_token={this.props.authenticity_token} distribution={this.props.distribution} title_show={false} limit="30" distribution_percentage={this.props.distribution_percentage} vip_percentage={this.props.vip_percentage} agent_token={this.props.agent_token}/>
        }
      </>
    )
  }
}
