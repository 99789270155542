import React from "react";
import { isNil } from "lodash";
import { displayDate } from "@/utilities/time";

const NO_RECORD = "No Record";
const TimeList = (props) => {
  const {time, status, active} = props
  const activeClz = `li process_step ${active ? 'complete' : ''}`

  return (
    <li className={activeClz}>
      <div className="timestamp">
      </div>
      <div className="status">
        <div className="step"><span></span></div>
        <h4>{status}</h4>
        <span className="date">
          <div className="order-date">{time !== NO_RECORD && displayDate(time)?.date}</div>
          <div className="order-time">{time !== NO_RECORD && displayDate(time)?.time}</div>
        </span>
      </div>
    </li>
  )
}

/**
 * Reference: https://codepen.io/abhisharma2/pen/vEKWVo
 */
function Timeline({ statuses }) {
  return (
    <div length={statuses.length}>
      <ul className="timeline d-flex align-items-start w-100 row no-gutters">
        {statuses.map((record, index) => <TimeList index={index} key={index}
                                                   status={record.status}
                                                   time={record.time ?? ''}
                                                   active={!isNil(record.time)} />)}
      </ul>
    </div>
  )
}

export default Timeline

