import React from 'react'
import Modal from 'react-modal'
import i18n from '../../components/i18next/i18n'
import {setCookie, readCookie} from '../../components/common'
import {salePrice, onPlus, onMinus, onInput, maxQuantity, getLimitPurchase} from '../checkout/price_handler'

import { LazyLoadImage } from 'react-lazy-load-image-component';

class AddToCartModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      quantity: 1,
      variants: this.filterDuplicate(this.props.variants),
      photo: this.props.product.photo.thumbnail.url || '/img/no-product-img.png'
    }
    this.onCancel = this.onCancel.bind(this)
    this.plusHandler = this.plusHandler.bind(this)
    this.minusHandler = this.minusHandler.bind(this)
    this.changeQuantity = this.changeQuantity.bind(this)
    this.onAddToCart = this.onAddToCart.bind(this)
    this.select_variant = this.select_variant.bind(this)
    this.getQuantity = this.getQuantity.bind(this)
  }
  filterDuplicate(arr) {
    let filter_arr = [];
    arr.reverse().forEach(elem => {
      let e = filter_arr.find(filter_elem => filter_elem.title===elem.title);
      if (e === undefined)
        filter_arr.push(elem);
    });

    return filter_arr.reverse();
  }
  componentDidMount() {
    if (this.state.variants.length === 1)
      this.setState({ selectVariant: this.state.variants[0] });
  }
  onCancel() {
    if (this.state.variants.length > 1)
      this.setState({selectVariant: null})
    this.props.hideAddCartModal()
  }
  onAddToCart() {
    fetch("/carts/modify.json",{
      method: 'POST',
      headers: {
        // 'X-CSRF-Token': this.props.authenticity_token,
        'content-type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        variant_id: this.state.selectVariant.id,
        quantity: this.state.quantity,
        cart_token: readCookie('cart_token'),
        shop_token: this.props.shop_token
      })
    }).then(res=>res.json()).then(data=>{
      if (data.success) {
        if (!readCookie('cart_token'))
          setCookie('cart_token',data.cart_token)
        try {
          fbq('track', 'AddToCart', {
            content_name: this.state.selectVariant.title,
            content_ids: [this.state.selectVariant.sku],
            content_type: 'product',
            value: this.state.quantity * this.state.selectVariant.price,
            currency: 'TWD'
          },{
            eventID: data.event_id
          });
        } catch (e) {
        }

        try {
          ga('send', 'event', '購物車', '加入購物車');
          gtag('event', 'add_to_cart', {
            "items": [
              {
                "id": this.state.selectVariant.sku,
                "name": this.state.selectVariant.title,
                "variant": this.state.selectVariant.title,
                "quantity": this.state.quantity,
                "price": this.state.quantity * this.state.selectVariant.price
              }
            ]
          });
        } catch (e) {
        }
        this.props.onVariantAdded(this.state.selectVariant,this.state.quantity)
      }else{
        // TODO: show alert?
        this.onCancel()
      }
      if (this.state.variants.length > 1)
        this.setState({selectVariant: null, quantity: 1})
    })
  }
  plusHandler() {
    this.setState({ quantity: onPlus(this.state.quantity, this.state.selectVariant, this.props.product, this.props.cart_items) });
  }
  minusHandler() {
    this.setState({ quantity: onMinus(this.state.quantity) });
  }
  changeQuantity(event) {
    let quantity = onInput(parseInt(event.currentTarget.value), this.state.selectVariant, this.props.product, this.props.cart_items);
    quantity = isNaN(quantity) ? 0 : quantity
    this.setState({ quantity: quantity });
    event.target.value = quantity;
  }
  select_variant(e) {
    this.setState({ selectVariant: this.state.variants.find(v => v.id == e.currentTarget.value) }, () => {
      if (this.state.selectVariant) {
        this.setState({ quantity: this.getQuantity() });
        let variant_photo = this.props.product.variants_photos.find(v => v.variant_id===this.state.selectVariant.id)
        if (variant_photo)
          this.setState({ photo: variant_photo.photo.thumbnail.url });
        else
          this.setState({ photo: this.props.product.photo.thumbnail.url || '/img/no-product-img.png' });
      }
      else
        this.setState({ photo: this.props.product.photo.thumbnail.url || '/img/no-product-img.png' });
    })
  }
  getQuantity() {
    let _item = this.props.cart_items.find(item => item.variant_id === this.state.selectVariant.id);
    return _item?.quantity || 1;
  }
  render() {
    let max_quantity = maxQuantity(this.state.selectVariant, this.props.product, this.props.cart_items);

    let minus_disabled = false;
    let plus_disabled = false;
    if (this.state.quantity <= 0)
      minus_disabled = true;
    if (this.state.quantity >= max_quantity)
      plus_disabled = true;

    return (
      <Modal className="selectVariantQuantity" overlayClassName="selectVariantQuantityOverlay" isOpen={this.props.isOpen} onRequestClose={this.onCancel} ariaHideApp={false}>
        <div className="modal-content">
          <div className="modal-body">
            <div className="img-wrapper">
              <LazyLoadImage
                height='100%'
                width='100%'
                wrapperClassName="img-fluid"
                src={this.state.photo}
                alt={this.props.product.title}
              />
            </div>
            <div className="variant-wrapper">
              <div className="variant-title-wrapper">
                <h4 className="variant-title text-center">
                  {this.props.product.title}
                  {/* not using this.state.variants for displaying same text with product page */}
                  {this.props.product.allow_preorder && this.props.variants.find(v => v.stock <= 0) && ' (預購)'}
                </h4>
              </div>
              {
                this.props.product.bundle_threshold && this.props.product.bundle_price &&
                  <div className="variant-price bundle-price animated fadeInDown my-3">
                    <span>
                      {i18n.t('cart.choose_any')}
                      <b>{this.props.product.bundle_threshold}</b>
                      {i18n.t('cart.pieces')}
                      <b>{salePrice(this.props.product.bundle_price, this.props.distribution_enabled, this.props.distribution_percentage, this.props.vip_percentage)}</b>
                      {i18n.t('cart.ntds')}
                    </span>
                  </div>
              }
              {
                this.state.selectVariant ?
                  <p className="variant-price"><span className="currency">NT$</span>
                    {
                      salePrice(this.state.selectVariant.price, this.props.distribution_enabled, this.props.distribution_percentage, this.props.vip_percentage)
                    }
                  </p> : null
              }
              <p>
                {
                  this.state.selectVariant && max_quantity > 0 && max_quantity < 9999
                    ? i18n.t('limit_purchase', { limit: max_quantity }) : ''
                }
              </p>
            </div>
            <div className="form-row">
              <div className={this.state.selectVariant ? "col-md-7 mb-md-0 mb-3" : "col-12 mb-md-0 mb-3" }>
                {
                  this.state.variants.length > 1
                    ? <select className="custom-select" required="" onChange={this.select_variant}>
                        <option defaultValue="">選擇商品樣式</option>
                        {
                          this.state.variants.map(v => <option key={v.id} selected={this.state.selectVariant && this.state.selectVariant.id == v.id} value={v.id}>
                            {v.title}
                            {this.props.product.allow_preorder && v.stock <= 0 && ' (預購)'}
                            {!this.props.product.allow_preorder && v.stock <= 0 && ' (已售完)'}
                          </option>)
                        }
                      </select>
                    : <p className='col-form-label text-dark'>
                        {this.state.selectVariant && this.state.selectVariant.title}
                      </p>
                }
              </div>
              <div className="col-md-5">
                {
                  this.state.selectVariant ? (
                    max_quantity > 0 ?
                      <div className="select-quantity">
                        <input className="qty-input form-control"
                          type="number" title="Qty" min="0" size="4" pattern="\d*" autoComplete="off"
                          value={this.state.quantity ?? 1} onChange={this.changeQuantity}/>
                        <div className="qty-adjust">
                          <span className={"plus"+(plus_disabled ? " disabled": "")} onClick={this.plusHandler} >
                            <i className="fa fa-plus"></i>
                          </span>
                          <span className={"minus"+(minus_disabled ? " disabled": "")} onClick={this.minusHandler}>
                            <i className="fa fa-minus"></i>
                          </span>
                        </div>
                      </div>
                    : (!this.props.product.allow_preorder && this.state.selectVariant.stock <= 0)
                      ? <a className="btn btn-block btn-gray disabled btn-saleout" role="button" tabIndex="0" disabled="disabled">已售完</a>
                      : <a className="btn btn-block btn-gray disabled btn-saleout" role="button" tabIndex="0" disabled="disabled">{`限購${getLimitPurchase(this.props.product)}件`}</a>
                  ) : null
                }
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {
              this.state.selectVariant && max_quantity > 0 && this.state.quantity >= 0 && (this.props.product.allow_preorder || this.state.selectVariant.stock > 0)
                ? this.props.cart_items.find(item => item.variant_id===this.state.selectVariant.id)
                  ? <button className="btn btn-primary btn-block rounded-0" onClick={this.onAddToCart}>{i18n.t('update_quantity')}</button>
                  : <button className="btn btn-brand-secondary btn-block rounded-0" onClick={this.onAddToCart}>{i18n.t('add_cart')}</button>
                : <button className="btn btn-default btn-block rounded-0" onClick={this.onCancel}>{i18n.t('cancel')}</button>
            }
          </div>
        </div>
      </Modal>
    )
  }
}

export default AddToCartModal