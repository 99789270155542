import React from 'react'
import i18n from '../../components/i18next/i18n'
import {onPlus, onMinus, onInput, maxQuantity} from '../checkout/price_handler'

class VariantAddCart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {quantity: this.props.quantity == 0 ? 1 : this.props.quantity}
    this.updateQuantity = this.updateQuantity.bind(this)
    this.plusHandler = this.plusHandler.bind(this)
    this.minusHandler = this.minusHandler.bind(this)
    this.changeQuantity = this.changeQuantity.bind(this)
  }
  componentDidUpdate(prevProps) {
    if (this.props.quantity !== prevProps.quantity)
      this.setState({quantity: this.props.quantity !== 0 ? this.props.quantity : 1})
  }
  changeQuantity(event) {
    let quantity = onInput(parseInt(event.currentTarget.value), this.props.variant, this.props.variant, this.props.cart);
    event.target.value = quantity
    this.setState({quantity: quantity});
  }
  updateQuantity() {
    this.props.updateVariantQuantity(this.props.variant,this.state.quantity)
  }
  plusHandler() {
    this.setState({ quantity: onPlus(this.state.quantity, this.props.variant, this.props.variant, this.props.cart) });
  }
  componentDidUpdate(prevProps) {
    if (this.props.quantity !== prevProps.quantity)
      this.setState({quantity: this.props.quantity !== 0 ? this.props.quantity : 1})
  }
  minusHandler() {
    this.setState({ quantity: onMinus(this.state.quantity) });
  }
  render() {
    let selected_n_piece_variant = (this.props.n_piece_group?.length == this.props.n_pieces_detail?.length)
    let ready_to_add_cart = !!this.props.n_piece_group ? selected_n_piece_variant : this.props.variant
    let add_to_cart_button = (this.props.quantity == 0 && this.props.variant?.stock > 0 ) ?
      <button className="btn btn-secondary btn-block add_cart_button" disabled={ready_to_add_cart ? null : 'disabled' } onClick={this.updateQuantity}>
        {ready_to_add_cart? i18n.t('add_cart') : i18n.t('please_select_variant')}
      </button>
      :
      ( this.props.variant?.stock <= 0 && !this.props.variant?.allow_preorder ?
        <button className="btn btn-secondary btn-block add_cart_button" disabled='disabled' onClick={this.updateQuantity}>
          {i18n.t('cart.sold_out')}
        </button>
        :
        <button className="btn btn-secondary btn-block add_cart_button" disabled={ready_to_add_cart ? null : 'disabled' } onClick={this.updateQuantity}>
          {this.props.variant ? i18n.t('update_quantity') : i18n.t('please_select_variant')}
        </button>
      )

    let minus_disabled = !ready_to_add_cart
    let plus_disabled = !ready_to_add_cart
    if (this.state.quantity <= 0)
      minus_disabled = true;
    if (this.state.quantity >= maxQuantity(this.props.variant, this.props.variant, this.props.cart))
      plus_disabled = true;

    return (
      <div className="add_cart_panel">
        {/* {this.props.limit ? <p> {i18n.t('limit_purchase', { limit: this.props.limit })} {plus_disabled && ready_to_add_cart ? <span className="text-danger">已達購買上限</span> : null}</p>: ''} */}
        <div className='form-row'>
          <div className="col-5 col-md-6 col-xl-4">
            <div className="select-quantity">
              <input className="qty-input form-control"
                      type="number" title="Qty" min="0" size="4" pattern="\d*" autoComplete="off"
                      disabled={ready_to_add_cart ? null : 'disabled'}
                      value={this.state.quantity} onChange={this.changeQuantity}/>
              <div className={"qty-adjust " + (ready_to_add_cart ? '' : 'disabled')}>
                <span className={"plus"+(plus_disabled ? " disabled": "")} onClick={ready_to_add_cart ? this.plusHandler : null}>
                  <i className="fa fa-plus"></i>
                </span>
                <span className={"minus"+(minus_disabled ? " disabled": "")} onClick={ready_to_add_cart ? this.minusHandler : null}>
                  <i className="fa fa-minus"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-7 col-md-6 col-xl-8">
            {add_to_cart_button}
          </div>
        </div>
      </div>
    )
  }
}

export default VariantAddCart