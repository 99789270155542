import React from 'react'
import Campaign from './campaign'
import AddToCartModal from '../../products/add_to_cart_modal'


const LIMIT = 5
class BundleCampaignSet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.onAddToCart = this.onAddToCart.bind(this)
  }

  onAddToCart(variant_id,campaign_token){
    let body = { 
      variant_id: variant_id,
      quantity: 1,
      campaign: campaign_token,
      shop_token: this.props.shop_token,
      cart_token: this.props.cart_token,
    }
    console.log(body)
    fetch('/carts/modify.json',
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    }).then(res=>res.json()).then(data=>{

    })
  }


  render(){
    let { bundle_campaign_set } = this.props
    console.log(bundle_campaign_set)
    return <div>
      {
        bundle_campaign_set.campaigns.map(campaign=> <Campaign key={campaign.id} onAddToCart={this.onAddToCart} campaign={campaign}/>)
      }
    </div>
  }
}

export default BundleCampaignSet