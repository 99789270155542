import React from 'react'
import { buildMenu, getLink } from '../../components/common'
import i18n from '../../components/i18next/i18n'

class StoreMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu_items: buildMenu(this.props.menu_items)
    }
  }
  render() {
    return <>
      <li className='nav-item'>
        <a href='/' className={this.props.is_mobile ? "menu-link" : "nav-link"}>
          <span className='text'>
            {i18n.t('helpers.home')}
          </span>
        </a>
      </li>
      {
        this.state.menu_items.map(item => {
          try {
            if (item.children.length == 0) {
              let link = getLink(item)
              return (
                <li className='nav-item' key={item.id}>
                  <a href={link} className={this.props.is_mobile ? "menu-link" : "nav-link"}>
                    <span className='text'>
                      {item.title}
                    </span>
                  </a>
                </li>
              )
            } else {
              if (this.props.is_mobile)
                return (
                  <li className="nav-item has-submenu" key={item.id}>
                    <a className="nav-link submenu-toggle collapsed" data-toggle="collapse" href={'#collapseMenu_'+item.id} role="button" aria-expanded="false" aria-controls={"collapseMenu"+item.id}>
                      <span className="text">{item.title}</span>
                    </a>
                    <ul className="secondary-menu collapse" role="menu" id={"collapseMenu_"+item.id}>
                      {
                        item.children.map(child => {
                          let link = getLink(child)
                          return (
                            <li className="secondary-item" key={child.id}>
                              <a href={link} className="menu-link menu-lv-2">
                                <span className='text'>
                                  {child.title}
                                </span>
                              </a>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </li>
                )
              else {
                let link = getLink(item)
                return (
                  <li className='nav-item' key={item.id}>
                    <a href={link} className={this.props.is_mobile ? "menu-link" : "nav-link"}>
                      <span className='text'>
                        {item.title}
                      </span>
                    </a>

                    {/*<ul className="dropdown-menu" role="menu">
                      {
                        item.children.map(child => {
                          let link = getLink(child)
                          return (
                            <li className="dropdown-item" key={child.id}>
                              <a href={link} className="menu-link menu-lv-2">
                                <span className='text'>
                                  {child.title}
                                </span>
                              </a>
                            </li>
                          )
                        })
                      }
                    </ul>*/}
                  </li>
                )
              }
            }
          } catch (e) {
            console.log(e)
            return null
          }
        })
      }
    </>
  }
}

export default StoreMenu