const LandingImgOnlyLayout = 'LandingImgOnlyLayout'
const LandingBannerLayout = 'LandingBannerLayout'
const LandingImgColsLayout = 'LandingImgColsLayout'
const LandingLImgRTxtLayout = 'LandingLImgRTxtLayout'
const LandingRImgLTxtLayout = 'LandingRImgLTxtLayout'
const LandingCollectionLayout = 'LandingCollectionLayout'
const LandingTxtOnlyLayout = 'LandingTxtOnlyLayout'
const LandingVideoLayout = 'LandingVideoLayout'
const arrowLeft_circle = <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="36" cy="36" r="35.5" stroke="white"/>
                          <path d="M13.0909 36L58.9091 36" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M45.8182 49.0909L58.9091 36L45.8182 22.9091" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>;

import React from 'react'
import {LeftImageRightTextElement, RightImageLeftTextElement, TextOnlyElement} from './landing_elements'
import StoreCollection from './collection'
import LandingElementBoundary from './landing_element_boundary'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import StoreIndexImgonly from './index_imgonly'
import StoreHeroSlider from './index_heroslider'
import StoreIndexImgcols from './index_imgcols'

const arrowDown = <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2L10 10L18 2" stroke="#2C3542" strokeWidth="3" strokeLinecap="round"/></svg>

export default class StoreLanding extends React.Component {
  constructor(props) {
    super(props)
    this.kvbannerRef = React.createRef();
    this.state = {
      layouts:[]
    }
    this.handleScroll = this.handleScroll.bind(this)
  }
  handleScroll(event){
    const kvRef = this.kvbannerRef.current
    let is_mobile =  /Android|iOS|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    let scrollTop = document.documentElement.scrollTop
    if (is_mobile && scrollTop > 10) {
      kvRef.classList.add('on_active')
    } else {
      kvRef.classList.remove('on_active')
    }
  }
  componentDidMount() {
    fetch('/landing.json',    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
      },
      credentials: 'same-origin'
    }).then(res => res.json()).then(data => {
      this.setState({layouts: data.layouts})
    })

    window.addEventListener('scroll', this.handleScroll);
  }
  render() {
    return <div>
      {/* 客製化主頁Banner */}
      <section className="kv-banner" ref={this.kvbannerRef}>
        <div className="cut-1">
          <div className="btn-wrapper">
            <a href={this.props.slider_goto_link} className="more">
              <div className="text">馬上看看</div>
              {arrowLeft_circle}
            </a>
          </div>
          <div className="title">
            <LazyLoadImage
              className="w-100 d-none d-md-inline-block"
              alt="曹操"
              src="/img/kv/Home_BN_title_mobile@1.5x.png" />
            <LazyLoadImage
              className="w-100 d-inline-block d-md-none"
              alt="曹操"
              src="/img/kv/Home_BN_title_mobile@1x.png" />
          </div>
          <div className="bg">
            <LazyLoadImage
              className="w-100 d-none d-md-inline-block"
              alt="曹操"
              src="/img/kv/kv_bg.png" />
            <LazyLoadImage
              className="w-100 d-inline-block d-md-none"
              alt="曹操"
              src="/img/kv/kv_bg_s.png" />
          </div>
        </div>
      </section>
      {
        this.state.layouts.map((layout,index)=>{
          try{
            switch(layout.layout_type){
              case LandingImgOnlyLayout:
              case LandingBannerLayout:
              case LandingImgColsLayout:
                    let banners = layout.store_landing_elements.map(element=>{
                    let prop = JSON.parse(element.properties) || {}
                    let banner = {position: element.position, link: prop.link, photo: {url:element.photo_url}, title: prop.title, text: prop.text}
                    if (element.product){
                      banner.product = {token: element.product.token, distribution_enabled:element.product.distribution_enabled}
                    }
                    return banner
                  }).sort((a,b)=> a.position - b.position)
                  if(layout.layout_type == LandingImgOnlyLayout){
                    return <StoreIndexImgonly
                    index={index}
                    key={index}
                    agent_token={this.props.agent_token}
                    banners={banners}
                  />
                  }else if(layout.layout_type == LandingBannerLayout){
                    return <StoreHeroSlider
                    index={index}
                    key={index}
                    agent_token={this.props.agent_token}
                    banners={banners}
                  />
                  }else if(layout.layout_type == LandingImgColsLayout){
                    return <StoreIndexImgcols
                    index={index}
                    key={index}
                    agent_token={this.props.agent_token}
                    banners={banners}/>
                  }
              case LandingLImgRTxtLayout:
                return <LeftImageRightTextElement layout={layout} key={index}/>
              case LandingRImgLTxtLayout:
                return <RightImageLeftTextElement layout={layout} key={index}/>
              case LandingTxtOnlyLayout:
                return <TextOnlyElement layout={layout} key={index}/>
              case LandingCollectionLayout:
                return <StoreCollection {...layout.store_landing_elements[0].collection} link={layout.store_landing_elements[0].content} is_frontpage={true} is_login={this.props.is_login} shop_token={this.props.shop_token} cart_items={this.props.cart_items} shop_customer={this.props.shop_customer} authenticity_token={this.props.authenticity_token} distribution={this.props.distribution} distribution_percentage={this.props.distribution_percentage} vip_percentage={this.props.vip_percentage} price_percentage={this.props.price_percentage} key={index} index={index} agent_token={this.props.agent_token} shop_vips={this.props.shop_vips}/>
              case LandingVideoLayout:
                let element = layout.store_landing_elements[0]
                return <section className='ls-section'>
                  <div className='container'>
                    <div className='embed-responsive embed-responsive-16by9'>
                      <iframe className='embed-responsive-item' src={element.content} frameBorder="0" allowFullScreen={true}/>
                    </div>
                  </div>
                </section>
            }
          }catch(e){
            console.log("Render Landing Exception")
            console.log(e)
          }
        }).map((area,index)=><LandingElementBoundary key={index}>{area}</LandingElementBoundary>)
      }
      {/* 首頁靜態內容 */}
      <section className="home-faq">
        <div className="container">
          <hgroup className="section-hgroup">
            <h2 className="section-title no-after">常見問答</h2>
          </hgroup>
          <div className="accordion" id="accordionFaq">
            <div className="card">
              <div className="card-header" id="faq1">
                <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapsefaq1" aria-expanded="true" aria-controls="collapsefaq1">
                  JDSGN 的理念是什麼？
                  <i className="icon">{arrowDown}</i>
                </button>
              </div>

              <div id="collapsefaq1" className="collapse show" aria-labelledby="faq1" data-parent="#accordionFaq">
                <div className="card-body">
                  每項您親自挑選的單品，皆代表您生活的延伸與內心的情感體現，JDSGN 希望能以多年的品牌經驗，帶給您理想生活的新選擇。
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="faq2">
                <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapsefaq2" aria-expanded="false" aria-controls="collapsefaq2">
                  如何聯繫 JDSGN 的客服呢？
                  <i className="icon">{arrowDown}</i>
                </button>
              </div>
              <div id="collapsefaq2" className="collapse" aria-labelledby="faq2" data-parent="#accordionFaq">
                <div className="card-body">
                  為了提供您便利的溝通管道，我們準備了線上客服服務，您也可以寄郵件或來電聯繫我們哦。
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="faq3">
                <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapsefaq3" aria-expanded="false" aria-controls="collapsefaq3">
                  訂單的配送地址填錯了該怎麼辦？
                  <i className="icon">{arrowDown}</i>
                </button>
              </div>
              <div id="collapsefaq3" className="collapse" aria-labelledby="faq3" data-parent="#accordionFaq">
                <div className="card-body">
                  麻煩您立即聯繫客服，線上客服將即刻為您與物流公司聯繫，確認是否能在發貨前更改完成，若為搬家公司配送的大型單品，請提前於搬家公司與您約定日期的前三天與我們通知。
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="faq4">
                <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapsefaq4" aria-expanded="false" aria-controls="collapsefaq4">
                  能選擇什麼樣的付款方式？
                  <i className="icon">{arrowDown}</i>
                </button>
              </div>
              <div id="collapsefaq4" className="collapse" aria-labelledby="faq4" data-parent="#accordionFaq">
                <div className="card-body">
                  平台目前支援銀行轉帳、信用卡付款、超商代碼的付款方式哦。
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="faq5">
                <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapsefaq5" aria-expanded="false" aria-controls="collapsefaq5">
                  請問退款時間大約多久呢？
                  <i className="icon">{arrowDown}</i>
                </button>
              </div>
              <div id="collapsefaq5" className="collapse" aria-labelledby="faq5" data-parent="#accordionFaq">
                <div className="card-body">
                  待我們確認訂單無狀況後退款流程約 7-14 天 (不含六日及國定假日)，若您使用信用卡消費，退款完成後會根據每間銀行作業時間撥款至您的帳戶，如有疑問都可以跟發卡銀行確認！
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="faq6">
                <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapsefaq6" aria-expanded="false" aria-controls="collapsefaq6">
                  發票有哪些開立方式呢？
                  <i className="icon">{arrowDown}</i>
                </button>
              </div>
              <div id="collapsefaq6" className="collapse" aria-labelledby="faq6" data-parent="#accordionFaq">
                <div className="card-body">
                  為響應環保本公司以電子發票為主，電子發票將於結帳時幫您留存，並於當期中獎時寄送給您，您也可以於結帳時存入您的電子載具，若需實體發票，可至 7-11 超商使用 ibon 機列印。
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="faq7">
                <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapsefaq7" aria-expanded="false" aria-controls="collapsefaq7">
                  如果收到的商品有瑕疵如何處理？
                  <i className="icon">{arrowDown}</i>
                </button>
              </div>
              <div id="collapsefaq7" className="collapse" aria-labelledby="faq7" data-parent="#accordionFaq">
                <div className="card-body">
                  麻煩您拍攝產品瑕疵狀況，並聯繫線上客服描述您所遇到的問題，客服將為您處理問題哦。
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="faq8">
                <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapsefaq8" aria-expanded="false" aria-controls="collapsefaq8">
                  如何能得到最新的 JDSGN 相關資訊呢？
                  <i className="icon">{arrowDown}</i>
                </button>
              </div>
              <div id="collapsefaq8" className="collapse" aria-labelledby="faq8" data-parent="#accordionFaq">
                <div className="card-body">
                  如果喜歡我們，歡迎追蹤我們的 Instagram 與 Facebook，或是在 JDSGN 首頁下方輸入您的 E-mail，就能掌握最新的消息哦！
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      {/* 首頁 Instagram */}
      <section className="home-instagram">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 order-5">
              <div className="follow_us">
                <a href="https://www.instagram.com/jdsgncom/" className="text-white" target="_blank"><h3>追蹤我們的 Instagram</h3></a>
                <a href="https://www.instagram.com/jdsgncom/" className="text-white" target="_blank"><p>@jdsgncom</p></a>
              </div>
            </div>
            {
              this.props.ig_images.map(function(item, index){
                return (
                  <div className={"col-lg-3 col-md-4 col-6 " + (index < 4 ? "order-"+(index+1) : "order-"+(index+2) ) } key={index} >
                    <a href={item.link} >
                      <figure className="card card-ig">
                        <img className="w-100 card-photo" src={item.photo ?  item.photo : '/img/no-thumb.png'} alt="" />
                      </figure>
                    </a>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    </div>
  }
}
