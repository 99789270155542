import React from 'react'
import AddressModal from './addressModal'
import i18n from '../../components/i18next/i18n'

export default class MemberAddressItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleModalOpen = this.handleModalOpen.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.state = {
      isEditAddressModalShow: false,
      modalTitle: ''
    }
  }
  handleModalOpen(event) {
    this.setState({isEditAddressModalShow: true})
  }
  handleModalClose(event) {
    this.setState({isEditAddressModalShow: false})
  }
  render() {
    return (
      <div className="order-detail-wrapper narrow-view">
        <form action="/members/set_address" method="post" acceptCharset="UTF-8">
          <input type="hidden" name="authenticity_token" value={this.props.authenticity_token}/>
          <input type="hidden" name="address[id]" value={this.props.address.id}/>
          <div className="list-group-item address-group-item">
            <div className=" row no-gutters">
              <div className="item-content col-xl-10 col-lg-9">
                <h3 className="name d-flex align-items-center">
                  收件地址
                  { this.props.address.is_default ? <span className="badge badge-warning text-white ml-3">預設</span> : null }
                </h3>
                <ul className='address_detail list-unstyled mb-0'>
                  <li><span className="text">{this.props.address.zip} {this.props.address.city} {this.props.address.district}</span></li>
                  <li><span className="text">{this.props.address.detail}</span></li>
                </ul>
              </div>
              <div className="control-area col-xl-2 col-lg-3 mt-4 mt-lg-0">
                <div className="row no-gutters align-items-center">
                  <div className="col-6 col-md-4 col-lg-6">
                    <button className="btn btn-sm btn-link text-success p-0 btn-block" type="button" onClick={this.handleModalOpen}><i className="icon icon-edit"></i>編輯</button>
                  </div>
                  <div className="col-6 col-md-4 col-lg-6">
                    <button className="btn btn-sm btn-link text-danger p-0 btn-block" name="address[action]" value="delete" type="submit"><i className="icon icon-garbage"></i>刪除</button>
                  </div>
                  {
                    !this.props.address.is_default
                      ? <div className="col-12 col-md-4 col-lg-12 mt-4 mt-md-0 mt-lg-2">
                          <button className="btn btn-sm btn-outline-gray mr-2 btn-block" name="address[action]" value="set_default" type="submit">
                            <i className="icon icon-settings"></i>
                            設為預設
                          </button>
                        </div>
                      : null
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
        <AddressModal isOpen={this.state.isEditAddressModalShow} handleModalClose={this.handleModalClose} default_address={this.props.address} authenticity_token={this.props.authenticity_token}/>
      </div>
    )
  }
}