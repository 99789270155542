import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class StoreIndexImgonly extends React.Component {
  componentDidMount() {
  }
  render() {
    let agent_params = this.props.agent_token ? `?u=${this.props.agent_token}` : ''
    let images = this.props.banners.map((banner, index) => {
      let heading = banner.title != null ? (
        <hgroup className="section-hgroup">
          <h5 className='section-title underline'>{banner.title}</h5>
          <p className="section-subtitle">{banner.text}</p>
        </hgroup>
      ) : null
      if (banner.product) {
        return (
          <div id={"lsImgOnly-" + index} className="imgonly-container" key={"banner-" + index}>
            {heading}
            <div className="section-image">
              <a href={"/products/" + banner.product.token + agent_params} >
                <LazyLoadImage
                  height='100%'
                  width='100%'
                  wrapperClassName="img-fluid"
                  src={banner.photo.url}
                  alt={this.props.title}
                />
              </a>
            </div>
          </div>
        )
      }
      if (banner.link) {
        return (
          <div id={"lsImgOnly-" + index} className="imgonly-container" key={"banner-" + index}>
            {heading}
            <div className="section-image">
              <a href={banner.link} >
                <LazyLoadImage
                  height='100%'
                  width='100%'
                  wrapperClassName="img-fluid"
                  src={banner.photo.url}
                  alt={this.props.title}
                />
              </a>
            </div>
          </div>
        )
      } else {
        return (
          <div id={"lsImgOnly-" + index} className="imgonly-container" key={"banner-" + index}>
            {heading}
            <div className="section-image">
              <LazyLoadImage
                height='100%'
                width='100%'
                wrapperClassName="img-fluid"
                src={banner.photo.url}
                alt={this.props.title}
              />
            </div>
          </div>
        )
      }
    })

    let store_imgcols = (
      <div className="">
        {images}
      </div>
    )

    return (
      <section className="ls-section section-imgonly pt-0">
        <div className="container">
          {store_imgcols}
        </div>
      </section>
    )
  }
}

export default StoreIndexImgonly