import React from 'react'
import i18n from '../../components/i18next/i18n'
import {Luminous} from 'luminous-lightbox'
import AdditionalPurchaseContainer from './additional_purchase_container'
import TargetGiftsCampaignSection from './target_gifts_campaign_section'
import {onPlus, onMinus, onInput, maxQuantity} from './price_handler'
import {updateCartLink} from './update_cart'

const MyLuminous = Luminous
const PromotionInput = ({promotion_code,inputRef}) => {
  return <input className={'form-control' + (!!promotion_code ? ' active' : '')} type="text" name="promotioncode" placeholder={i18n.t('cart.input_promotion_code')} autoComplete="promotioncode"  ref={inputRef} />
}

// isNaN(quantity) ? 0 : quantity

class CartTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: this.props.cart,
      additional_shipping_fee: 0,
      show_promotion_code: false
    }
    this.sync_cart = this.sync_cart.bind(this)
    this.clearItem = this.clearItem.bind(this)
    this.onCartChange = this.onCartChange.bind(this)
    this.getPromotionCode = this.getPromotionCode.bind(this)
    this.applyPromotionCode = this.applyPromotionCode.bind(this)
    this.handle_open_myPromotionCode = this.handle_open_myPromotionCode.bind(this)
    this.promotion_code_input = React.createRef()
  }

  componentDidMount(){
    if(this.props.cart_info.promotion_code){
      this.promotion_code_input.value = this.props.cart_info.promotion_code
      this.getPromotionCode()
    }
    this.props.onUpdateGiftCampaigns();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cart !== this.props.cart) {
      this.setState({ cart: this.props.cart });
      this.props.onUpdateGiftCampaigns();
    }
  }
  sync_cart(variant,quantity) {
    let self = this;
    $.ajax('/carts/modify.json',
      {
        type: 'POST',
        data: {
          variant_id: variant.variant_id,
          bundle_id: variant.bundle_id,
          quantity: quantity,
          shop_token: this.props.shop_token,
          cart_token: this.props.cart_token,
          u: this.props.agent_token,
          authenticity_token: this.props.authenticity_token
        },
        dataType: 'json',
        success: function (data) {
          self.props.onUpdateGiftCampaigns();
          let count = 0
          data.items.forEach((item)=>count+=item.quantity)
          updateCartLink(data.items)
        }
      }
    )
  }

  clearItem(event) {
    var variant_id = $(event.currentTarget).data('variant-id');
    var bundle_id = $(event.currentTarget).data('bundle-id');
    var variant = {variant_id: variant_id, bundle_id:bundle_id}
    let newCart = this.state.cart.filter(function (item) {
      if(variant.variant_id) {
        return item.variant_id != variant.variant_id;
      } else if(variant.bundle_id) {
        return item.bundle_id != variant.bundle_id;
      }
    });

    this.sync_cart(variant, 0)
    this.setState({cart: newCart}, () => {
      this.props.onCartChange(this.state.cart)

      let cart_length = this.state.cart.filter(cart_item => cart_item.quantity!==0).length
      if (cart_length <= 0) {
        const items = JSON.parse(sessionStorage.getItem('additional_purchases')||'[]')
        for(let item of items)
          this.props.onAdditionalPurchaseChange(item.item_id, 0)
      }
    })
  }
  onCartChange(item) {
    $.each(this.state.cart, function (index, cart) {
      if (item.variant_id && cart.variant_id == item.variant_id) {
        cart.quantity = isNaN(item.quantity) ? 0 : item.quantity
      } else if (item.bundle_id && cart.bundle_id == item.bundle_id) {
        cart.quantity = isNaN(item.quantity) ? 0 : item.quantity
      }
    })
    this.sync_cart(item,item.quantity)
    this.props.onCartChange(this.state.cart)
  }
  getPromotionCode() {
    var self = this
    if (this.promotion_code_input.value !== '') {
      $.ajax({
        type: 'POST',
        data: {
          shop_token: this.props.shop_token,
          cart_token: this.props.cart_token,
          authenticity_token: this.props.authenticity_token,
          code: this.promotion_code_input.value,
          customer_phone: this.props.current_customer ? this.props.current_customer.phone : ''
        },
        url: '/promotion_codes/',
        success: function (data) {
          if (data.success) {
            self.props.onPromotionCodeSet(data.promotion_code,data.message)
          } else {
            self.props.onPromotionCodeSet(null,data.message)
          }
          self.setState({ show_promotion_code: false }, () => self.props.onUpdateGiftCampaigns())
        }
      })
    }
  }
  applyPromotionCode (code) {
    this.setState({show_promotion_code: false})
    const input = this.promotion_code_input;
    input.value = code;
    input.focus();
    this.getPromotionCode()
  }
  handle_open_myPromotionCode(e){
    e.preventDefault();
    this.setState({ show_promotion_code: !this.state.show_promotion_code })
  }
  render() {
    var self = this;
    let shipping_fee = 0
    let shipping_title =''
    let promotion_input = ''
    let valid_additional_items = []
    let cart_list = this.state.cart

    var item_list = cart_list.map(item => {
      let _key = null;

      if(item.variant_id) {
        let variant_id = item.variant_id
        _key = `variant-${item.variant_id}`
      }
      else if(item.bundle_id) {
        _key = `bundle-${item.bundle_id}`
      }
      return <CartItem cart={self.state.cart} item={item} thumbnail={item.thumbnail} key={_key} clearItem={self.clearItem} onCartChange={self.onCartChange}/>
    });
    // 我的折扣代碼
    let promotion_code_area = this.props.my_promotion_codes !== null ? this.props.my_promotion_codes.map( (item, i) => {
      let products = [];
      let can_use = false;
      //適用商品
      if (item.products !== undefined && item.products.length !== 0) {
        //有限定商品
        for (let j = 0; j < item.products.length; j++) {
          if ( this.state.cart.find(function (cart_p) { return cart_p.product_id === item.products[j].id}) ){
            can_use = true
            products.push(<span className="product_link meet" key={item.products[j].token}>{item.products[j].title}</span>)
          } else {
            products.push(<a href={"/products/" + item.products[j].token} target="_blank" className="product_link" key={item.products[j].token}>{item.products[j].title}</a>)
          }
        }
      } else {
        //沒限定商品
        can_use = true
      }
      let discount = null
      switch (item.discount_type) {
        case 'ratio':
          discount = <p className="mb-0 text-danger text-right"> <strong>{item.discount}</strong><small>% OFF</small></p>
          break;
        case 'fix_number':
          discount = <p className="mb-0 text-danger text-right"><small className="currency">-NT$ </small><strong>{item.discount}</strong></p>
          break;
        case 'free_shipping':
          discount = <p className="mb-0 text-danger text-right"><strong>免運費</strong></p>
          break;
        default:
          break;
      }

      return (
        <div key={i} className="d-flex flex-wrap list-group-item promotion_code-item">
          <div className="mate">
            <h4 className="h6 font-weight-bold mb-1" >{item.title}</h4>
            <p className='mb-0 small'>適用訂單金額(以上):{item.threshold}</p>
            <p className='mb-0 small'>折扣代碼:{item.code}</p>

          </div>
          <div className="discount ml-auto text-right">
            {discount}
            { can_use ?
              <a className="apply_this_code text-white btn btn-success btn-sm mt-1" onClick={() => this.applyPromotionCode(item.code)}>使用此代碼</a>
              :
              <a className="apply_this_code text-white btn btn-secondary btn-sm mt-1 disabled" >無法使用</a>
            }
          </div>
          <div className='product-select'>
            {products !== undefined && products.length !== 0 ? <p className='mb-0 small'>適用商品:{products}</p> : null}
          </div>
        </div>
      )
    }) : null ;

    if (item_list.length == 0) {
      return (
        <div className="col-lg-5 order-lg-2 col-cart-table-top-right">
          <div className="checkout-content-wrapper cart-table empty">
            <p>{i18n.t('no_items_in_cart')}</p>
          </div>
        </div>
      )
    }
    let need_shipping = false
    for (let product of this.state.cart) {
      need_shipping = need_shipping || (product.need_shipping && product.quantity > 0)
    }
    if (need_shipping) {
      shipping_title = this.props.shipping.title;
      if (this.props.shipping.free_threshold && !isNaN(this.props.shipping.free_threshold)) {
        shipping_title += ' / ' + i18n.t('cart.free_shipping', {threshold: this.props.shipping.free_threshold })
      }
      shipping_fee = this.props.shipping_fee
    }
    if (this.props.promotion_code_available) {
      promotion_input =
      <div className="promotion-code">
        <div className="input-group">
          <PromotionInput inputRef={el => this.promotion_code_input = el} promotion_code={this.props.promotion_code}></PromotionInput>

          <div className="input-group-append">
            <button className="btn btn-primary" type="button" onClick={this.getPromotionCode}>{i18n.t("cart.apply_code")}</button>
          </div>
        </div>
        {promotion_code_area !== null ?
          <div className={"promotion_code-lists " + (this.state.show_promotion_code ? 'show' : '')}>
            <div className="lists-container">
              { this.state.show_promotion_code ?
                <div className="lists-header d-flex align-items-center">
                  {this.props.promotion_code_message}
                  <a href="#" className="ml-auto" onClick={this.handle_open_myPromotionCode}>關閉促銷代碼</a>
                </div>
                :
                <div className="d-flex align-items-center pt-2">
                  {this.props.promotion_code_message}
                  <a href="#" className="show-promotion_code ml-auto " onClick={this.handle_open_myPromotionCode}>顯示促銷代碼</a>
                </div>
              }
              {this.state.show_promotion_code ? <div className="lists-body list-group">{this.props.my_promotion_codes.length !== 0 ? promotion_code_area : <div className="mt-3 text-center text-muted"> 目前沒有可以使用的促銷代碼</div>}</div> : null }
            </div>
          </div> : this.props.promotion_code_message
        }

      </div>
    }
    for (let item of this.props.additional_purchase_items) {
      for (let variant of this.props.cart) {
        if (variant.quantity > 0 &&  (item.variants.includes(variant.variant_id)||item.additonal_purchase_accept_all ) && item.stock > 0) {
          valid_additional_items.push(item)
          break
        }
      }
    }
    valid_additional_items = [...new Set(valid_additional_items)]
    let additional_products = []

    for(let item of valid_additional_items){
      let found = false
      for(let additional_product of additional_products){
        if(additional_product.id == item.id){
          //additional_product.limit += additional_product.limit
          found = true
          break
        }
      }
      if(!found){
        additional_products.push(Object.assign({},item))
      }
    }

    let additional_purchase_groups = {}
    for(let additional_product of additional_products.sort((a,b)=>b.position-a.position)){
      additional_purchase_groups[additional_product.option_1] = additional_purchase_groups[additional_product.option_1] || []
      additional_purchase_groups[additional_product.option_1].push(additional_product)
    }
    let additional_products_items = Object.keys(additional_purchase_groups).map(group_key=>{
      return <AdditionalPurchaseContainer items={additional_purchase_groups[group_key]} group_title={group_key}  key={group_key} onAdditionalPurchaseChange={self.props.onAdditionalPurchaseChange}/>
    })

    let target_gifts_area = this.props.target_gift_campaigns.length > 0 ?
      <div className="cart-table-section target_gifts">
        <h4 className="section-title">{i18n.t('target_gifts')}</h4>
        {this.props.target_gift_campaigns.map(item =>
          <TargetGiftsCampaignSection
            key={item.gift_campaign.token}
            campaign_title={item.gift_campaign.title}
            campaign_token={item.gift_campaign.token}
            target={item.target}
            setting={item.setting}
            gift_type={item.gift_campaign.gift_type}
            is_multiple_add={item.gift_campaign.is_multiple_add}
            is_multiple_send={item.gift_campaign.is_multiple_send}
            cart={this.state.cart}
            cart_token={this.props.cart_token}
            shop_token={this.props.shop_token}
            selected_gift={this.props.selected_gifts[item.gift_campaign.token]}
            onAddGiftToCart={this.props.onAddGiftToCart}
            is_unmount={this.props.is_unmount}
          />
        )}
      </div> : null

    let additional_products_area = valid_additional_items.length > 0 ?
      <div className="cart-table-section additional-purchase">
        <h4 className="section-title">{i18n.t('additional_purchase')}</h4>
        {additional_products_items}
      </div> : ''
    let cart_item_area = item_list.length > 0 ?
      <div className="cart-table-section cart-items">
        <h4 className="section-title">{i18n.t('shopping_cart')}</h4>
        {item_list}
      </div> : ''

    console.log("props in cart_table.jsx:", this.props);

    return (
      <div className="col-lg-5 order-lg-2 col-cart-table-top-right">
        <div className="checkout-content-wrapper cart-table">
          {cart_item_area}
          {target_gifts_area}
          {additional_products_area}
          <div className="cart-table-section order-summary">
            {promotion_input}
            <CartFooter subtotal={this.props.subtotal}
                        cart={this.props.cart}
                        shipping_title={shipping_title}
                        discounts={this.props.discounts}
                        need_shipping={need_shipping}
                        additional_shipping_fee={this.props.additional_shipping_fee}
                        shipping_fee={shipping_fee}
                        promotion_product_subtotal={this.props.promotion_product_subtotal}
                        promotion_code={this.props.promotion_code}/>
          </div>
        </div>
      </div>
    )
  }
}

class CartFooter extends React.Component {
  getDiscountText(discount_type, discount_value) {
    if (discount_type == 'free_shipping') {
      return (
        <div>
          <span className="currency"></span>免運費
        </div>
      );
    } else {
      return (
        <div>
          <span className="currency">- NT$</span>{discount_value}
        </div>
      );
    }
  }

  render() {
    var total_discount_value = 0;
    var shipping_text
    let additional_shipping_fee = 0
    var promotion_code_text
    var discounts = this.props.discounts.map(function(discount,index) {
      total_discount_value += discount.amount
      return (
        <div className="total-line" key={index}>
          <div className="title">{discount.title}</div>
          <div className="price">
            <span className="currency">- NT$</span>{discount.amount}
          </div>
        </div>
      )
    })
    if (this.props.need_shipping) {
      additional_shipping_fee = this.props.additional_shipping_fee > 0 ? (
        <div className="total-line">
          <div className="title">{i18n.t('plus_freight')}</div>
          <div className="price">
            <span className="currency">NT$</span>{this.props.additional_shipping_fee}
          </div>
        </div>
      ) : ''
      shipping_text =
      <div className="order-summary-list shipping">
        <div className="total-line">
          <div className="title">{i18n.t('freight')} ({this.props.shipping_title})</div>
          <div className="price">
            <span className="currency">NT$</span>{this.props.shipping_fee}
          </div>
        </div>
        {additional_shipping_fee}
      </div>
    }
    if (this.props.promotion_code) {
      let promotion_code_discount
      switch (this.props.promotion_code.discount_type) {
        case 'fix_number':
          promotion_code_discount = this.props.promotion_code.discount
          break;
        case 'ratio':
          if(this.props.promotion_code.product_ids.length > 0){
            promotion_code_discount = Math.round(this.props.promotion_product_subtotal * this.props.promotion_code.discount / 100)
          }else{
            promotion_code_discount = Math.round(this.props.subtotal * this.props.promotion_code.discount / 100)
          }
          break;
        case 'free_shipping':
          promotion_code_discount = 0
          break;
        default:
          console.log('UNKNOWN TYPE')
      }
      if (this.props.subtotal >= this.props.promotion_code.threshold) {
        if (this.props.subtotal < total_discount_value + promotion_code_discount) {
          promotion_code_discount = this.props.subtotal - total_discount_value
          total_discount_value = this.props.subtotal
        } else {
          total_discount_value += promotion_code_discount
        }
        let promotion_code_product_text
        if(this.props.promotion_code.product_ids.length >0 ){
          let product_titles = []
          for(let variant of this.props.cart){
            if(this.props.promotion_code.product_ids.includes(variant.product_id)){
              product_titles.push(variant.title)
            }
          }
          product_titles = [...new Set(product_titles)]
          promotion_code_product_text = <div className="small text-muted">適用商品 {product_titles.join("/")}</div>
        }
        promotion_code_text =
        <div className="total-line">
          <div className="title">促銷代碼 ({this.props.promotion_code.code})
            {
              promotion_code_product_text
            }

          </div>
          <div className="price">
            {this.getDiscountText(this.props.promotion_code.discount_type, promotion_code_discount)}
          </div>
        </div>
      } else {
        if (this.props.subtotal < total_discount_value + promotion_code_discount) {
          promotion_code_discount = this.props.subtotal - total_discount_value
        }
        promotion_code_text =
        <div className="total-line">
          <div className="title text-muted">
            促銷代碼 ({this.props.promotion_code.code}) <span className="text-primary small"><span className="text-danger">{i18n.t('cart.not_yet_eligible')}</span> *{i18n.t('cart.need')} {this.props.promotion_code.threshold} {i18n.t('cart.to_used')}</span>
          </div>
          <div className="price text-muted">
            <del>
              {this.getDiscountText(this.props.promotion_code.discount_type, promotion_code_discount)}
            </del>
          </div>
        </div>
      }
    }
    return (
      <div>
        <div className="order-summary-list subtotal">
          <div className="total-line">
            <div className="title">{i18n.t('subtotal')}</div>
            <div className="price">
              <span className="currency">NT$</span>{this.props.subtotal}
            </div>
          </div>
          {promotion_code_text}
          {discounts}
        </div>
        {shipping_text}
        <div className="order-summary-list total">
          <div className="total-line total">
            <div className="title">{i18n.t('total')}</div>
            <div className="price">
              <span className="currency">NT$</span>{(this.props.shipping_fee || 0) + (this.props.subtotal || 0) + this.props.additional_shipping_fee - total_discount_value}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class CartItem extends React.Component {
  constructor(props) {
    super(props);
    this.plusHandler = this.plusHandler.bind(this)
    this.minusHandler = this.minusHandler.bind(this)
    this.changeQuantity = this.changeQuantity.bind(this)
    this.checkBundle = this.checkBundle.bind(this)
  }
  componentDidMount() {
    if (this.refs.medium_photo) {
      new MyLuminous(this.refs.medium_photo);
    }
  }
  plusHandler(event) {
    let quantity = onPlus(this.props.item.quantity, this.props.item, this.props.item, this.props.cart);
    let _item = Object.assign({},this.props.item) // ToDo: deep copy
    _item["quantity"] = quantity
    this.props.onCartChange(_item);
  }
  minusHandler(event) {
    let quantity = onMinus(this.props.item.quantity);
    let _item = Object.assign({},this.props.item) // ToDo: deep copy
    _item["quantity"] = quantity
    this.props.onCartChange(_item);
  }
  changeQuantity(event) {
    let quantity = onInput(parseInt(event.currentTarget.value), this.props.item, this.props.item, this.props.cart);
    let _item = Object.assign({}, this.props.item) // ToDo: deep copy
    event.target.value = quantity
    _item["quantity"] = quantity
    this.props.onCartChange(_item);
    this.setState({quantity: quantity});
  }
  checkBundle() {
    let product_id = this.props.item.product_id
    let total = 0
    for (let item of this.props.cart) {
      if (item.product_id == product_id) {
        total += item.quantity
      }
    }
    if (this.props.item.product_bundle_threshold && this.props.item.product_bundle_threshold!= 0 && total >= this.props.item.product_bundle_threshold) {
      return {threshold: this.props.item.product_bundle_threshold, price: this.props.item.product_bundle_price}
    }
    return false
  }
  render() {
    let quantity_control;
    let presale_text;
    if (this.props.item.stock > 0 || this.props.item.allow_preorder) {
      let minus_disabled = false;
      let plus_disabled = false;
      if (this.props.item.quantity <= 0)
        minus_disabled = true;
      if (this.props.item.quantity >= maxQuantity(this.props.item, this.props.item, this.props.cart))
        plus_disabled = true;

      quantity_control = (
        // (this.props.item.type === "Variant") ?
          <div className="select-quantity">
            <input className="qty-input form-control"
                   type="number" title="Qty" min="0" size="4" pattern="\d*" autoComplete="off"
                   value={this.props.item.quantity} onChange={this.changeQuantity}/>
            <div className="qty-adjust">
              <span className={"plus"+(plus_disabled ? " disabled": "")} onClick={this.plusHandler}>
                <i className="fa fa-plus"></i>
              </span>
              <span className={"minus"+(minus_disabled ? " disabled": "")} onClick={this.minusHandler}>
                <i className="fa fa-minus"></i>
              </span>
            </div>
          </div>
        // : <div className="item-quantity"><span className='small'>數量：</span>{this.props.item.quantity}</div>
      )
      // if (this.props.item.stock < this.props.item.quantity) {
      //   presale_text = ' (預購)'
      // }
    } else {
      quantity_control = (<div className="select-quantity form-control sold-out">{i18n.t('cart.sold_out')}</div>);
    }
    var thumbnail = null
    if (this.props.item.thumbnail != null) {
      thumbnail = <a className="zoom-in" href={this.props.item.medium} ref="medium_photo"><img className="img-fluid" src={this.props.item.thumbnail}/></a>
    }
    else if (this.props.item.v_photo?.url != null) {
      thumbnail = <a className="zoom-in" href={this.props.item.v_photo.url} ref="medium_photo"><img className="img-fluid" src={this.props.item.v_photo.thumbnail.url}/></a>
    } else {
      thumbnail = <img className="img-fluid" src="/img/no-thumb.png"/>
    }
    let price_area
    let bundle
    if (bundle = this.checkBundle()) {
      price_area =
        <div className="variant-price bundle-price animated bounceIn">
          <span> {i18n.t('cart.choose_any')} <b>{bundle.threshold}</b> {i18n.t('cart.pieces')} <b>{bundle.price}</b> {i18n.t('cart.ntds')}</span>
        </div>
    } else {
      price_area = this.props.item.price ?
        <div className="variant-price">
          <span className="currency">NT$</span>{this.props.item.price}
        </div> : null
    }
    return (
      <div className="cart-item-list">
        <div className="item-thumbnail">{thumbnail}</div>
        <div className="item-detail">
          <div className="product-title">{this.props.item.product_title || "[贈品]"} {this.props.item.exclude_free_shipping ? <span className="badge badge-danger badge-sm">特價商品，不計入免運金額</span> : ''}</div>
          {
            (this.props.item.n_pieces || []).length > 0
              ? (this.props.item.n_pieces_detail || []).map(p => <div className="variant-title" key={p.id}>{p.product.title}({p.title})</div>)
              : <div className="variant-title">{this.props.item.title} {presale_text}</div>
          }
          {/*<div className="product-title">
            {this.props.item.product_title}
            {this.props.item.stock === 0 && <span>&nbsp;廠商出貨&nbsp;</span>}
          <div className="product-title">
            {this.props.item.product_title}
            {(this.props.item.stock < this.props.item.quantity) && <span className="supplier_shipment ml-2">廠商出貨</span>}
            {this.props.item.exclude_free_shipping ? <span className="badge badge-danger badge-sm">特價商品，不計入免運金額</span> : ''}
          </div>
          <div className="variant-title">{this.props.item.title} {presale_text}</div>*/}

          {price_area}
          {quantity_control}
        </div>
        <a className="item-delete" data-variant-id={this.props.item.variant_id} data-bundle-id={this.props.item.bundle_id} onClick={this.props.clearItem}>
          <span className="delete-icon"></span>
          <span className="delete-txt">{i18n.t('remove')}</span>
        </a>
      </div>
    )
  }
}

export default CartTable