import React from "react"
import {isEqual} from "lodash"
import {orderItemFormatter, bundleItemFormatter} from "@/bundles/member/formatMemberOrder"

export default function ProductItems(props) {
  /* order items */
  const formattedItems = orderItemFormatter(props.order.order_items, props.shipment.shipment_variants || [], props.defaultPhoto)
  const formattedBundleItems = bundleItemFormatter(props.order.bundle_items, props.shipment.shipment_variants || [], props.defaultPhoto)

  return (
    <>
      <div className="order-summary-list d-none d-sm-flex justify-content-between">
        <div className="list-title">商品</div>
        <div className="list-price">單價</div>
      </div>
      <ul className="order-products-row">
        {
          formattedItems.map((item, index) => {
            let qty = props.isShipped ? item.shippedQty : item.unshippedQty;
            return (
              qty !== 0 &&
                <li className="d-flex product-list" key={`item-${index}`}>
                  <div className="item-img">
                    <img src={item.variantPhoto} alt="商品圖" className="w-100" />
                  </div>
                  <div className="item-desc">
                    <div className="item-mate">
                      <h5 className="product-title"><b>{item.productTitle}</b></h5>
                      <div className="product-variant">{item.variantTitle} x {qty}</div>
                    </div>
                    <div className="price">{item.itemPrice}</div>
                  </div>
                </li>
            )
          })
        }
        {
          formattedBundleItems.map((bundle, index) => {
            let shippedQty = bundle.variantShippedDetail.map(v => v[1]);
            let unshippedQty = bundle.variantUnshippedDetail.map(v => v[1]);
            let isBundleShipped = Math.max(...shippedQty)!==0;
            let isBundleUnshipped = Math.max(...unshippedQty)!==0;
            return (
              ((props.isShipped && isBundleShipped) || (!props.isShipped && isBundleUnshipped)) &&
                <li key={`bundle-${index}`} className="d-flex product-list">
                  <div className="item-img">
                    <img src={bundle.variantPhoto} alt="商品圖" className="w-100" />
                  </div>
                  <div className="item-desc">
                    <div className="item-mate">
                      <h5 className="product-title"><b>{bundle.productTitle} x{bundle.quantity}</b></h5>
                      {
                        props.isShipped
                          ? bundle.variantShippedDetail.map((item, i) => <div key={`bundle-${index}-${i}`} className="product-variant">{item[0]}</div>)
                          : bundle.variantUnshippedDetail.map((item, i) => <div key={`bundle-${index}-${i}`} className="product-variant">{item[0]}</div>)
                      }
                    </div>
                    <div className="price">${bundle.itemPrice}</div>
                  </div>
                </li>
            )
          })
        }
      </ul>
    </>
  )
}