import ReactOnRails from 'react-on-rails';
import StoreLanding from '../bundles/store/landing';
import StoreNotSale from '../bundles/store/notSale';
import ProductDirect from '../bundles/products/product_direct';
import Checkout from '../bundles/checkout/checkout';
import StoreHeroSlider from '../bundles/store/index_heroslider';
import RRateModal from '../bundles/store/RRateModal';
import Footer from '../bundles/store/footer';
import StoreCollection from '../bundles/store/collection';
import StoreCollectionMenu from '../bundles/store/collection_menu';
import StoreCollectionsMenu from '../bundles/store/collections_menu';
import MemberForgetPassword from '../bundles/member/memberForgetPassword';
import MemberProfile from '../bundles/member/memberProfile';
import MemberOrder from '../bundles/member/memberOrder';
import MemberPromotionCode from '../bundles/member/memberPromotionCode';
import MemberAddress from '../bundles/member/memberAddress';
import MemberChangePassword from '../bundles/member/memberChangePassword';
import MemberFavorite from '../bundles/member/memberFavorite';
import MemberQna from '../bundles/member/memberQna';
import LowestPrice from '../bundles/store/lowest_price';
import LowestPriceAlert from '../bundles/store/lowest_price_alert';
import BundleCampaignSet from '../bundles/store/bundle_campaign/set'
import SignIn from '../bundles/store/sign_in'
import SignInPage from '../bundles/store/sign_in_page'
import StoreMenu from '../bundles/store/store_menu'
import BrandLanding from '../bundles/store/brand_landing'
import StoreBrandCollectionMenu from '../bundles/store/brand_collection_menu'
import MobileMenu from '../bundles/store/mobile_menu'
// import MemberVoucher from '../bundles/member/memberVoucher';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  StoreLanding,
  StoreNotSale,
  ProductDirect,
  Checkout,
  StoreHeroSlider,
  RRateModal,
  Footer,
  StoreCollection,
  StoreCollectionMenu,
  StoreCollectionsMenu,
  MemberForgetPassword,
  MemberProfile,
  MemberOrder,
  MemberPromotionCode,
  MemberAddress,
  MemberChangePassword,
  MemberFavorite,
  MemberQna,
  BundleCampaignSet,
  // MemberVoucher,
  LowestPrice,
  LowestPriceAlert,
  SignIn,
  SignInPage,
  StoreMenu,
  BrandLanding,
  StoreBrandCollectionMenu,
  MobileMenu
});
