import React from 'react'
import StoreCollection from './collection'

export default class StoreBrandCollectionMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_series_id: null,
    }
    this.selectSeries = this.selectSeries.bind(this)
  }
  componentDidMount() {
    if (this.props.selected)
      this.selectSeries(parseInt(this.props.selected))
  }
  selectSeries(index) {
    this.setState({
      selected_series_id: index
    })
  }
  render() {
    let series = this.props.series.map((item, index) => {

        let link = window.location.href;
        link = link.replace(/&page=[0-9]+/g, '').replace(/\?page=[0-9]+&/g, '?').replace(/(\?|&)page=[0-9]+/g, '')
        link = link.replace(/&series=[0-9]+/g, '').replace(/\?series=[0-9]+&/g, '?').replace(/(\?|&)series=[0-9]+/g, '').replace('#0', '').replace('#!', '')
        link += (link.includes('?') ? ('&series='+item.id) : ('?series='+item.id))

        return (
            <li className="secondary-item" key={index} >
              <a className={"menu-link secondary " + (this.state.selected_series_id===item.id ? 'active' : '')}
                href={link} onClick={event => this.selectSeries(item.id)}
                >
                <span className="text">{item.title}</span>
              </a>
            </li>
          )
        });

    return (
      <div className="ls-store-collection " id="lsStoreCollection">
        <div className="collection-section-container">
          {/* <hgroup className="section-hgroup collection">
            <h2 className="section-title">{this.props.brand.title}</h2>
          </hgroup> */}
          <div className="collection-row page-main-content">
            {/* JD 移除側邊 Collection 選單 */}
            {/* <aside className="sidebar collection-aside aside-menu">
              <h3 className="sidebar-title">{this.props.brand.title}</h3>
              <ul className="menu" id="collectionMenu">
                {series}
              </ul>
            </aside> */}
            <main className="collecion-content">
              <StoreCollection brand={this.props.brand} series_id={this.state.selected_series_id} is_login={this.props.is_login} distribution={this.props.distribution} title_show={false} limit="30" distribution_percentage={this.props.distribution_percentage} vip_percentage={this.props.vip_percentage} agent_token={this.props.agent_token} shop_vips={this.props.shop_vips}/>
            </main>
          </div>
        </div>
      </div>
    )
  }
}