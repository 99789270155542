import React from 'react'
import Modal from 'react-modal';

export default class ConfirmSignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state =  {}
    this.onModalCancel = this.onModalCancel.bind(this)
  }
  componentWillMount() {
    Modal.setAppElement('body');
  }
  onModalCancel() {
    this.props.hideModal()
  }
  render() {

    return (
      <Modal className="selectVariantQuantity" overlayClassName="selectVariantQuantityOverlay" isOpen={this.props.isOpen} onRequestClose={this.onModalCancel}>
        <div className="modal-content">
          <div className="modal-header justify-content-center">
            <h5>優惠提示</h5>
          </div>
          <div className="modal-body pt-0">
            <p className="text-center">登入會員即可享有 <b>會員價</b></p>
          </div>
          <div className="modal-footer">
            <div className="row no-gutters w-100 two-buttons">
              <div className="col-6"><button className="btn btn-secondary btn-block" onClick={this.props.give_up_sign_in}>放棄優惠價格</button></div>
              <div className="col-6"><a href="#0" className="btn btn-brand-secondary btn-block" data-target="#signInModal" data-toggle="modal" onClick={this.props.hideModal}>登入會員結帳</a></div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
