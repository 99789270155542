import React from 'react'

const LandingImgOnlyLayout = 'LandingImgOnlyLayout'
const LandingBannerLayout = 'LandingBannerLayout'
const LandingImgColsLayout = 'LandingImgColsLayout'
const LandingLImgRTxtLayout = 'LandingLImgRTxtLayout'
const LandingRImgLTxtLayout = 'LandingRImgLTxtLayout'
const LandingCollectionLayout = 'LandingCollectionLayout'
const LandingTxtOnlyLayout = 'LandingTxtOnlyLayout'
const LandingVideoLayout = 'LandingVideoLayout'

export default class BrandLanding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      layouts:[]
    }
  }
  componentDidMount() {
    fetch('/brand_landing.json?id='+location.pathname.split('/')[2],    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token
      },
      credentials: 'same-origin'
    }).then(res => res.json()).then(data => {
      this.setState({layouts: data.layouts})
    })
  }
  render() {
    let layouts = [];
    this.state.layouts.map((layout,index)=>{
      try{
        switch(layout.layout_type){
          case LandingImgOnlyLayout:
          case LandingBannerLayout:
          case LandingImgColsLayout:
            let banners = layout.brand_landing_elements.map(element=>{
              let prop = JSON.parse(element.properties) || {}
              let banner = {position: element.position, link: prop.link, photo: {url:element.photo_url}, title: prop.title, text: prop.text}
              if (element.product){
                banner.product = {token: element.product.token, distribution_enabled:element.product.distribution_enabled}
              }
              return banner
            }).sort((a,b)=> a.position - b.position)

            if(layout.layout_type == LandingImgOnlyLayout){
              layouts.push(
                <StoreIndexImgonly
                  index={index}
                  key={index}
                  banners={banners}
                />
              );
            }else if(layout.layout_type == LandingBannerLayout){
              layouts.push(
                <StoreHeroSlider
                  index={index}
                  key={index}
                  banners={banners}
                />
              );
            }else if(layout.layout_type == LandingImgColsLayout){
              layouts.push(
                <StoreIndexImgcols
                  index={index}
                  key={index}
                  banners={banners}
                />
              );
            }
            break;
          case LandingLImgRTxtLayout:
            layouts.push(<BrandLeftImageRightTextElement layout={layout} key={index}/>);
            break;
          case LandingRImgLTxtLayout:
            layouts.push(<BrandRightImageLeftTextElement layout={layout} key={index}/>);
            break;
          case LandingTxtOnlyLayout:
            layouts.push(<BrandTextOnlyElement layout={layout} key={index}/>);
            break;
          // case LandingCollectionLayout:
            // layouts.push(<StoreCollection {...layout.brand_landing_elements[0].collection} distribution={this.props.distribution} price_percentage={this.props.price_percentage} key={index} index={index}/>);
          // case LandingVideoLayout:
          //   let element = layout.brand_landing_elements[0]
          //   layouts.push(
          //     <section className='ls-section' key={index}>
          //       <div className='container'>
          //         <div className='row'>
          //           <div className='embed-responsive embed-responsive-16by9'>
          //             <iframe className='embed-responsive-item' src={element.content} frameBorder="0" allowFullScreen={true}/>
          //           </div>
          //         </div>
          //       </div>
          //     </section>
          //   );
          // break;
        }
      }catch(e){
        console.log("Render Landing Exception")
        console.log(e)
      }
    })
    // console.log(layouts)
    // .map((area,index)=><LandingElementBoundary key={index}>{area}</LandingElementBoundary>)
  
    return(
      <div>
        {layouts}
      </div>
    );
  }
}
