import React from 'react'
import MemberLayout from './memberLayout'
import i18n from '../../components/i18next/i18n'

export default class MemberChangePassword extends MemberLayout {
  render_inner() {
    // let emptyContent = <div className="p-5 placeholder-image row text-center">
    //     <div className="col-10 col-md-6 col-lg-5 mx-auto">
    //       <img src={this.props.placeholder_img_url} alt='no_promotion_code'/>
    //     </div>
    //     <div className='col-12'>
    //       <p className="mb-0 text-muted">{i18n.t('cart.no_promotion_code')}</p>
    //     </div>
    //   </div>

    return (
      <>
        <div className="section-head-group mb-3">
          <h1 className="section-subtitle mb-1 mb-md-0 text-center"><i className="icon icon-truckk"></i> {i18n.t("member.change_password")}</h1>
        </div>

        <div className="member-detail-wrapper change-password">
          <div className="form-horizontal">
            <div className="form-group">
              <label className="item-label">目前密碼</label>
              <input className="form-control" type="password" minLength="8" name="customer[current_password]" placeholder="請輸入目前密碼"/>
              <small className='form-text text-muted'>若以 Facebook 或 Line 登入者可不填</small>
            </div>
            <div className="form-group">
              <label className="item-label">設定新密碼</label>
              <input className="form-control" type="password" minLength="8" name="customer[password]" placeholder='請輸入新密碼' required/>
              <small className='form-text text-muted'>請輸入至少8位數英數混合密碼</small>
            </div>
            <div className="form-group">
              <label className="item-label">再次輸入新密碼</label>
              <input className="form-control" type="password" minLength="8" name="customer[password_confirmation]" placeholder='請再次輸入新密碼' required/>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary">更新密碼</button>
            </div>
          </div>
        </div>
      </>
    )
  }
}