import { isNil, isEqual } from "lodash";
import React, { useState } from "react";
import i18n from '@/components/i18next/i18n'
import { displayDate } from "@/utilities/time";

/* 元件拆分 */
import ProductItems from "@/bundles/member/ProductItems";
import ShippingStatusTimeLine from "@/bundles/member/ShippingStatusTimeLine";
// import Qna from "@/bundles/member/Qna";

/* 貨車icon */
const statusPackage = <svg viewBox="0 0 512 512.00073" xmlns="http://www.w3.org/2000/svg"><path d="m14.804688 302.820312c-19.738282 0-19.738282-29.980468 0-29.980468 62.570312 0 57.964843-6.273438 57.964843 42.972656h205.949219c25.957031 0 47.273438 20.835938 47.957031 46.695312l114.488281-30.679687c67.636719-18.121094 102.160157 82.308594 29.832032 107.816406-84.359375 29.746094-244.289063 86.117188-398.226563 44.53125 0 32.710938-3.433593 27.523438-57.964843 27.523438-19.738282 0-19.738282-29.980469 0-29.980469h27.980468v-178.898438zm296.894531-272.839843v67.179687c0 10.707032-11.34375 18.988282-22.570313 12.933594l-33.394531-16.464844-34.359375 16.941406c-10.058594 4.960938-21.582031-2.554687-21.574219-13.410156l-.035156-67.179687h-49.96875v211.878906h211.875v-211.878906zm-29.980469.003906v43.109375c-29.878906-14.730469-22.222656-14.667969-51.96875 0v-43.109375zm-146.914062-29.984375c-8.28125 0-14.992188 6.710938-14.992188 14.992188v241.855468c0 8.28125 6.710938 14.992188 14.992188 14.992188h241.855468c8.28125 0 14.992188-6.710938 14.992188-14.992188v-241.855468c0-8.28125-6.710938-14.992188-14.992188-14.992188zm-62.039063 345.796875v107.328125c147.222656 43.277344 305.507813-12.523438 388.273437-41.707031 36.257813-12.785157 19.054688-59.019531-12.148437-50.660157l-135.78125 36.382813c-8.734375 9-20.9375 14.617187-34.390625 14.617187h-108.933594c-19.738281 0-19.738281-29.980468 0-29.980468h108.933594c24.039062 0 23.234375-35.980469 0-35.980469zm0 0" fillRule="evenodd"/></svg>
const statusOrder = <svg viewBox="-63 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m371 91h-45.042969v-15c0-8.285156-6.714843-15-15-15h-47.699219c-4.875-34.433594-34.542968-61-70.300781-61-35.753906 0-65.421875 26.566406-70.296875 61h-47.699218c-8.285157 0-15 6.714844-15 15v15h-44.960938c-8.285156 0-15 6.714844-15 15v391c0 8.285156 6.714844 15 15 15h356c8.285156 0 15-6.714844 15-15v-391c0-8.285156-6.714844-15-15-15zm-281.039062 0h47.222656c4.207031 0 8.222656-1.769531 11.0625-4.871094 2.84375-3.105468 4.25-7.257812 3.878906-11.449218-.109375-1.25-.164062-2.488282-.164062-3.679688 0-22.605469 18.390624-41 40.996093-41 22.609375 0 41 18.394531 41 41 0 1.191406-.054687 2.429688-.164062 3.679688-.371094 4.191406 1.035156 8.347656 3.878906 11.449218 2.839844 3.105469 6.851563 4.871094 11.0625 4.871094h47.222656v30.996094h-205.996093zm266.039062 391h-326v-361h29.960938v15.996094c0 8.285156 6.714843 15 15 15h235.996093c8.285157 0 15-6.714844 15-15v-15.996094h30.042969zm0 0"/><path d="m311 182h-236c-8.285156 0-15 6.714844-15 15 0 8.28125 6.714844 15 15 15h236c8.285156 0 15-6.71875 15-15 0-8.285156-6.714844-15-15-15zm0 0"/><path d="m75 272h155c8.285156 0 15-6.71875 15-15 0-8.285156-6.714844-15-15-15h-155c-8.285156 0-15 6.714844-15 15 0 8.28125 6.714844 15 15 15zm0 0"/><path d="m311 302h-236c-8.285156 0-15 6.714844-15 15 0 8.28125 6.714844 15 15 15h236c8.285156 0 15-6.71875 15-15 0-8.285156-6.714844-15-15-15zm0 0"/><path d="m272 362h-197c-8.285156 0-15 6.714844-15 15 0 8.28125 6.714844 15 15 15h197c8.285156 0 15-6.71875 15-15 0-8.285156-6.714844-15-15-15zm0 0"/><path d="m171.5 422h-96.5c-8.285156 0-15 6.714844-15 15 0 8.28125 6.714844 15 15 15h96.5c8.285156 0 15-6.71875 15-15 0-8.285156-6.714844-15-15-15zm0 0"/><path d="m193.71875 51h-1.519531c-8.285157 0-15 6.714844-15 15 0 8.28125 6.714843 15 15 15h1.519531c8.285156 0 15-6.71875 15-15 0-8.285156-6.714844-15-15-15zm0 0"/></svg>
const statusDelivery = <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m224 376h-209c-8.285156 0-15-6.714844-15-15v-346c0-8.285156 6.714844-15 15-15h346c8.285156 0 15 6.714844 15 15v148h121c8.285156 0 15 6.714844 15 15v319c0 8.285156-6.714844 15-15 15h-258c-8.285156 0-15-6.714844-15-15zm138 76h75c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-75c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15zm-62.332031-.5h3c8.285156 0 15-6.714844 15-15 0-8.28125-6.714844-15-15-15h-3c-8.285157 0-15 6.71875-15 15 0 8.285156 6.714843 15 15 15zm62.332031-60h75c8.285156 0 15-6.714844 15-15 0-8.28125-6.714844-15-15-15h-75c-8.285156 0-15 6.71875-15 15 0 8.285156 6.714844 15 15 15zm-62.332031-.496094h3c8.285156 0 15-6.71875 15-15 0-8.285156-6.714844-15-15-15h-3c-8.285157 0-15 6.714844-15 15 0 8.28125 6.714843 15 15 15zm-.667969-138.003906h75c8.285156 0 15-6.714844 15-15 0-8.28125-6.714844-15-15-15h-75c-8.285156 0-15 6.71875-15 15 0 8.285156 6.714844 15 15 15zm63 78.5h75c8.285156 0 15-6.714844 15-15 0-8.28125-6.714844-15-15-15h-75c-8.285156 0-15 6.71875-15 15 0 8.285156 6.714844 15 15 15zm-63 0h3c8.285156 0 15-6.714844 15-15 0-8.28125-6.714844-15-15-15h-3c-8.285156 0-15 6.71875-15 15 0 8.285156 6.714844 15 15 15zm47-168.5v-133h-86.289062v84.0625c0 10.777344-11.492188 19.074219-22.726563 12.859375l-48.984375-24.800781-49.972656 25.300781c-10.066406 5.085937-21.710938-2.449219-21.703125-13.359375l-.035157-84.0625h-86.289062v316h194v-168c0-8.285156 6.714844-15 15-15zm136 30c-76 0-152 0-228 0v289h228zm-252.289062-163h-83.421876v59.695312l34.945313-17.695312c4.097656-2.070312 9.089844-2.238281 13.503906-.011719l34.972657 17.707031zm0 0" fillRule="evenodd"/></svg>

const OrderDetailButton = (props) =>
  <a className="btn btn-outline-primary btn-block btn-detail" href={`/orders/${props.orderToken}`}>
    <span className="text">查看明細</span>
  </a>

const StatusInfo = ({orderStatus, paymentStatus = null, shippingType= null}) =>
    <div className="d-block d-md-flex status-wrapper pt-4 pb-2 pb-md-3">
      <div className="status d-flex pr-4 mb-2 mb-md-0">
        <div className='title d-flex align-items-center mr-2'><i className='icon mr-1'>{statusOrder}</i> 訂單狀態</div>
        <div className='content'>{i18n.t(`orders.status.${orderStatus}`)}</div>
      </div>
      <div className="status d-flex pr-4 mb-2 mb-md-0">
        <div className='title d-flex align-items-center mr-2'><i className='icon mr-1'>{statusDelivery}</i> 付款狀態</div>
        <div className='content'>{i18n.t(`orders.payment_status.${paymentStatus}`)}</div>
      </div>
      <div className="status d-flex">
        <div className='title d-flex align-items-center mr-2'><i className='icon mr-1'>{statusPackage}</i>配送方式</div>
        <div className='content'>{i18n.t(`orders.shipping_type.${shippingType}`)}</div>
      </div>
    </div>
/**
 * Reference: https://trello.com/c/fWSEyc8d/142-%E6%9C%83%E5%93%A1%E4%B8%AD%E5%BF%83
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function OrderCard(props) {
  const {order, openStatus} = props
  const [open, setOpen] = useState(openStatus);
  const isShipped = !isNil(props.order.shipping_status_recordd?.shipped);
  const arrow = open ? 'up' : 'down'
  const handleClick = () => setOpen(!open)

  let timelineAndList = props.order.shipments.map((shipment, index) => (
    <div className="partial-shipping-group" key={index}>
      <div className="order-summary-list partial-title d-flex justify-content-center"><b>物流編號：<span className='text-success'>{`${shipment.company} - ${shipment.tracking_number}`}</span></b></div>
      <ShippingStatusTimeLine order={props.order} shipment={shipment} isShipped={true} key={`shipping-status-timeline-${index}`} />
      <ProductItems order={props.order} shipment={shipment} defaultPhoto={props.defaultPhoto} isShipped={true} />
    </div>
  ))
  if (!isShipped) {
    timelineAndList.push(
      <div className="partial-shipping-group" key="unshipped">
        <div className="order-summary-list partial-title d-flex justify-content-center"><b>尚未出貨</b></div>
        <ShippingStatusTimeLine order={props.order} shipment={{}} isShipped={false} key={`shipping-status-timeline-unshipped`} />
        <ProductItems order={props.order} shipment={{}} defaultPhoto={props.defaultPhoto} isShipped={false} />
      </div>
    )
  }

  return (
    <div className='order-detail-wrapper narrow-view order-history mb-2 pb-0'>
      <div className='list-group list-group-flush'>
        <div className='list-group-item'>
          <div className="wrapper-header d-flex justify-content-between flex-grow-1 w-100">
            <div className="form-row flex-grow-1">
              <div className='status order_number col-xl-4 col-lg-6 order-1'>
                <a href={"/orders/" + order.token}>
                  <b className="list_title">{i18n.t("orders.number")}：</b>
                  <span className="ml-1">{order.name}</span>
                </a>
              </div>
              <div className='status datetime col-xl-4 order-2 order-xl-2'><b className='list_title'>成立時間：</b> {displayDate(order.created_at).date}</div>
              <div className='status total_price col-xl-4 col-lg-6 order-3 order-xl-3'><b className='list_title'>訂單金額：</b><span className="text-danger h6"> <small>NT$</small>&nbsp;{ parseInt(order.price, 10)}</span></div>
            </div>
            <a onClick={handleClick} href="#0" className='btn-toggler'>
              <i className={`fa fa-chevron-${arrow}`}></i>
            </a>
          </div>
        </div>
        {/* 訂單明細展開 */}
        <div className='order-list-collapse'>
          {open && [<StatusInfo key='status-info'
                                orderStatus={props.order.status}
                                paymentStatus={props.order.payment_status}
                                shippingType={props.order.shipping_type} />,
                    ...timelineAndList,
                    // <Qna {...props} key='qna' />,
                    <OrderDetailButton orderToken={order.token} key='order-button' />,
                    // <Qna {...props} key='order-qna' />
                    ]}
          </div>
      </div>
    </div>
  )
}

export default OrderCard