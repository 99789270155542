import React from 'react'
import i18n from '../../components/i18next/i18n'
import {Luminous} from 'luminous-lightbox'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class TargetGift extends React.Component {
  constructor(props) {
    super(props)
    this.gift_checkbox = React.createRef();
    this.state =  {
      is_selected: this.props.is_selected || false
    }
    this.addGiftToCart = this.addGiftToCart.bind(this)
  }
  componentDidMount() {
    if (this.props.is_selected)
      this.props.onAddGiftToCart(this.props.gift.id, this.props.quantity, this.props.campaign_token, this.props.threshold)
  }
  componentDidUpdate(prevProps){
    if (this.props.quantity !== prevProps.quantity)
      this.props.onAddGiftToCart(this.props.gift.id, this.state.is_selected ? this.props.quantity : 0, this.props.campaign_token, this.props.threshold)

    if (this.props.is_selected !== prevProps.is_selected)
      this.setState({ is_selected: this.props.is_selected }, () => {
        this.props.onAddGiftToCart(this.props.gift.id, this.state.is_selected ? this.props.quantity : 0, this.props.campaign_token, this.props.threshold)
      });
  }
  componentWillUnmount() {
    this.props.onAddGiftToCart(this.props.gift.id, 0, this.props.campaign_token, this.props.threshold, true)
  }

  addGiftToCart() {
    let is_selected = event.target.checked;

    this.setState({ is_selected: is_selected }, () => {
      this.props.onAddGiftToCart(this.props.gift.id, this.state.is_selected ? this.props.quantity : 0, this.props.campaign_token, this.props.threshold);
    })
  }
  render() {
    return (
      <div className="cart-item-list d-block">
        <div className="custom-control custom-checkbox gift_checkbox" >
          <input type="checkbox" className="custom-control-input" id={`gift-${this.props.campaign_token}-${this.props.threshold}-${this.props.gift.id}`}
            value={this.props.gift.id} onChange={this.addGiftToCart} key={Math.random()}
            disabled={this.props.quantity <= 0 ? 'disabled' : null}
            checked={this.state.is_selected && this.props.quantity > 0 ? 'checked' : null} ref="gift_checkbox"
          />
          <label htmlFor={`gift-${this.props.campaign_token}-${this.props.threshold}-${this.props.gift.id}`} className="custom-control-label">
            <div className="item-thumbnail">
              <img className='w-100' src={this.props.gift.v_photo.thumbnail.url || '/img/no-thumb.png'} alt={this.props.gift.title} />
            </div>
            <div className='item-detail'>
              <h6 className="variant-title gift">{this.props.gift.title}</h6>
              <p className="mb-0 quantity"> <span className="small text-muted">數量：</span><b>{this.props.quantity}</b></p>
            </div>
          </label>
          {this.props.quantity <= 0 ? <div className="cart-item-mask"><span>贈品已送完</span></div>:null}
        </div>
      </div>
    )
  }
}

