import React from 'react'
import i18n from '../../components/i18next/i18n'

let SIGN_IN = 'sign_in'
let SIGN_UP = 'sign_up'

export default class MemberForgetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.redirect_to_sign_in = this.redirect_to_sign_in.bind(this)
    this.redirect_to_sign_up = this.redirect_to_sign_up.bind(this)
  }
  redirect_to_sign_in() {
    if (this.props.updateStage)
      this.props.updateStage(SIGN_IN)
    else
      window.location.href = "/customers/sign_in"
  }
  redirect_to_sign_up() {
    if (this.props.updateStage)
      this.props.updateStage(SIGN_UP)
    else
      window.location.href = "/customers/sign_up"
  }
  render() {
    return (
      <div className="member-detail-wrapper forget-password">
        <form action="/customers/password" method="post" acceptCharset="UTF-8">
          <input type="hidden" name="authenticity_token" value={this.props.authenticity_token}/>
          <input type="hidden" name="shop_title" value={this.props.shop_title || readCookie('shop_title')}/>
          <input type="hidden" name="store_domain" value={window.location.host}/>
          <div className="form-group">
            <label className="customers">帳號 (Email)</label>
            <input className="form-control" name="customer[email]" type="email" autoFocus={true}/>
          </div>
          <div className="mb-3">
            <button type="submit" className="btn btn-primary btn-block submit">
              發送重設密碼信
            </button>
          </div>

          <div className="small pt-2 text-center">
            <a className="text-link font-weight-bold underline" href="#0" onClick={this.redirect_to_sign_in}>重新登入</a>
            <span>&nbsp;｜&nbsp;</span>
            <a className="text-link font-weight-bold underline" href="#0" onClick={this.redirect_to_sign_up}>註冊會員</a>
          </div>
        </form>
      </div>
    )
  }
}