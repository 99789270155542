import React from 'react'
import {salePrice} from '../checkout/price_handler'
import { updateCartLink } from '../checkout/update_cart'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import i18n from '../../components/i18next/i18n'

export default class StoreProduct extends React.Component {
  constructor(props) {
    super(props)
    this.sale_price = this.sale_price.bind(this)
    this.sold_out = this.sold_out.bind(this)
  }
  sold_out(){
    let sold_out = true
    this.props.variants.forEach(variant => sold_out &= (variant.stock <= 0))
    return sold_out
  }
  sale_price(price) {
    let distribution_percentage = this.props.distribution_enabled && this.props.distribution_percentage ? this.props.distribution_percentage : 100
    let vip_percentage = this.props.vip_percentage
    return Math.round( price * distribution_percentage * vip_percentage / 10000)
  }
  render() {
    let min_price = Math.min.apply(Math,this.props.variants.map(o=> this.sale_price(o.price)))
    let max_price = Math.max.apply(Math,this.props.variants.map(o=> this.sale_price(o.price)))

    // let start_sale_date = this.props.start_sale_date ? new Date(this.props.start_sale_date) : null
    // let vip_discount_percentage = (!this.props.is_login && this.props.shop_vips.length > 0 && this.props.shop_vips[0].discount_percentage) ?  this.props.shop_vips[0].discount_percentage : 100
    let price_text = (min_price != max_price) ? `$${min_price} - $${max_price}` : `$${min_price}`

    let agent_params = this.props.agent_token ? `?u=${this.props.agent_token}` : ''
    let crossline_price_text = null

    if (this.props.variants.length > 0) {
      let max_crossline_price = Math.max.apply(Math, this.props.variants.map(c => c.crossline_price))
      crossline_price_text = (max_crossline_price !== 0) ? `$${max_crossline_price}` : null
    }

    let product_photo = (
      <LazyLoadImage
        wrapperClassName="img-ratio-1by1"
        className="w-100"
        src={this.props.product.photo.url != null ? this.props.product.photo.url : "/img/no-product-img.png"}
        alt={this.props.product.title}
      />
    )
    let pds_clz =  this.props.is_frontpage ? 'store-product' : 'col-6 col-lg-4 col-xl-3 store-product'
    let link = this.props.collection_token ? `/collections/${this.props.collection_token}/products/${this.props.product.token}${agent_params}` : `/products/${this.props.product.token}${agent_params}`
    return (
      <div className={pds_clz}
        data-groups={`[${this.props.tags ? this.props.tags.map(t=>'"'+t.title+'"') : null}]`}
        data-min-price={min_price}
        data-max-price={max_price}
        >
        <a className={'collection-product-wrapper' + (this.sold_out() && !this.props.product.allow_preorder ? ' sold-out' : '')}
          href={link} target="_blank"
        >
          <figure className="collection-product">
            <div className="product-thumbnil">
              {this.props.ranking != null && this.props.show_ranking ?  (<div className="ranking_badge"> <div className="text">{this.props.ranking + 1}</div> <img src="img/ranking_badge.png" alt="排名" className="w-100" /></div>) : null}
              <div className="badge-wrapper">
                {/* {
                  start_sale_date && start_sale_date > new Date() ? <div className="badge sale">{start_sale_date.toLocaleString()}開始銷售</div>  : ''
                } */}
                {/* {this.props.end_sale_date ? <div className="badge sale">限時搶購</div> : ''} */}
                {this.props.bundle_price ? <div className="badge bundle-price">多件優惠</div> : ''}


                {
                  this.props.product.label &&
                    <div className="badge-circle ">{this.props.product.label}</div>
                }
                {!!this.props.product.product_video_photo?.video_link && !!this.props.product.product_video_photo?.photo ?
                <div className="video-icon">
                  <p className="sr-only">商品含影片介紹</p>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.2" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="black"/>
                    <path d="M17.6463 12.2862L9.04744 17.2492C8.5813 17.5179 8 17.1833 8 16.6459V6.69801C8 6.16058 8.5813 5.82606 9.04744 6.09478L17.6463 11.0578C18.1179 11.332 18.1179 12.012 17.6463 12.2862Z" fill="white"/>
                  </svg>
                </div> : null
                }
              </div>
              {product_photo}
            </div>
            <figcaption>
              <h4 className="product-title">{this.props.product.uid ? <span className="mr-1">{this.props.product.uid}</span> : null}{this.props.product.title}</h4>
              <p className='mb-0 price-group'>
                {/* <small>會員價：</small> */}
                <span className="product-price">{price_text}</span>
                {!!crossline_price_text ? (<span className="crossline-price ml-2"> {crossline_price_text} </span>) :  null}
              </p>
            </figcaption>
              <div className="product-action p-2">
                {
                  this.sold_out() && !this.props.product.allow_preorder
                    ? <span className="btn btn-soldout btn-block btn-sm">{i18n.t('cart.sold_out')}</span>
                    : null
                }
              </div>
          </figure>
        </a>
      </div>
    )
  }
}
