import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
const arrowLeft_circle = <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="36" cy="36" r="35.5" stroke="white"/>
                    <path d="M13.0909 36L58.9091 36" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M45.8182 49.0909L58.9091 36L45.8182 22.9091" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>;
class StoreHeroSlider extends React.Component {
  componentDidMount() {
    $('#sliderWrapper-' + this.props.index).slick({
      lazyLoad: 'ondemand',
      dots: false,
      arrows: false,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 3600,
      cssEase: 'ease-in-out'
    })
  }
  render() {
    let agent_params = this.props.agent_token ? `?u=${this.props.agent_token}` : ''
    let images = this.props.banners?.map((banner, index) => {
      if (banner.product) {
        return (
          <div className="banner-item" key={"banner-" + index}>
            <div className="heading-wrapper">
              {/* <h2 className='banner-heading' data-animate="bounceInLeft" data-animated="true">{banner.title}</h2>
              <p className="banner-text">{banner.text}</p> */}
              <div className="btn-wrapper">
                <a href={"/products/" + banner.product.token + agent_params} className="more">
                  <div className="text">馬上看看</div>
                  {arrowLeft_circle}
                </a>
              </div>
            </div>
            <div className="banner-image">
              <a href={"/products/" + banner.product.token + agent_params} >
                <img className="w-100" data-lazy={banner.photo.url} alt={this.props.title} />
              </a>
            </div>
          </div>
        )
      }
      if (banner.link) {
        return (
          <div className="banner-item" key={"banner-" + index}>
            <div className="heading-wrapper">
              {/* <h2 className='banner-heading' data-animate="bounceInLeft" data-animated="true">{banner.title}</h2>
              <p className="banner-text">{banner.text}</p> */}
              <div className="btn-wrapper">
                <a href={banner.link} className="more">
                  <div className="text">馬上看看</div>
                  {arrowLeft_circle}
                </a>
              </div>
            </div>
            <div className="banner-image">
              <a href={banner.link} target="_blank">
                <img className="w-100" data-lazy={banner.photo.url} alt={this.props.title} />
              </a>
            </div>
          </div>
        )
      } else {
        return (
          <div className="banner-item" key={"banner-" + index}>
            {/* <div className="heading-wrapper">
              <h2 className='banner-heading' data-animate="bounceInLeft" data-animated="true">{banner.title}</h2>
              <p className="banner-text">{banner.text}</p>
            </div> */}
            <div className="banner-image">
              <img className="w-100" data-lazy={banner.photo.url} alt={this.props.title} />
            </div>
          </div>
        )
      }
    })

    let lslanding_herobanner = (
      <section className="lsLandingHeroSlider">
        <div className="container-fluid p-0">
          <div id={"sliderWrapper-" + this.props.index} className="slider-wrapper">
            {images}
          </div>
        </div>
      </section>
    )

    return (
      <div>
        {lslanding_herobanner}
      </div>
    )
  }
}

export default StoreHeroSlider