import React from 'react'
import StoreProduct from './product'
import ReactPaginate from 'react-paginate'
import ReactDOM from 'react-dom'

const NONE = 'NONE'
const PRICE_ASC = 'PRICE_ASC'
const PRICE_DESC = 'PRICE_DESC'
const CREATED_AT_ASC = 'CREATED_AT_ASC'
const CREATED_AT_DESC = 'CREATED_AT_DESC'


const DEFAULT_PAGE_SIZE = 20

export default class StoreCollection extends React.Component {
  constructor(props) {
    super(props)
    // let valid_products = this.props.onsale_products.filter(p => {
    //   return (!this.props.distribution || p.distribution_enabled)
    // })
    this.state = {
      limit: this.props.limit ? this.props.limit : DEFAULT_PAGE_SIZE,
      sorting: NONE,
      selected_tags: [],
      valid_products: [],
      valid_variants: [],
      tags: [],
      is_refresh: true,
      offset: 0,
      is_mobile: true
      // is_mobile: /Android|iOS|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
    this.showMore = this.showMore.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.setTag = this.setTag.bind(this)
    this.fetchProduct = this.fetchProduct.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
  }
  showMore() {
    if ((this.state.total_count==undefined && !this.state.is_mobile) || (this.state.valid_products.length < this.state.total_count))
      if (this.state.valid_products.length!==0 || (this.state.selected_tags.length===0 && this.state.price_sorting===NONE && this.state.is_refresh))
        this.fetchProduct()
    if (this.state.is_refresh)
      this.setState({ is_refresh: false })
  }

  fetchProduct(reset = false){
    if (this.state.is_mobile) {
      if (reset)
        this.setState({ selected_tags: [] });
      let fetch_params = `/api/v1/products?offset=${reset ? 0 : this.state.valid_products.length}&keyword=${this.props.keyword ? this.props.keyword: ''}&tags=${reset ? [] : this.state.selected_tags.join(',')}&series_id=${this.props.series_id||''}&collections=${this.props.collections||''}&collection_token=${this.props.token||''}&limit=${this.state.limit}&brand_id=${this.props.brand ? this.props.brand.id : ''}&sorting=${this.state.sorting}&agent=${readCookie("agent_token")}`
      console.log(fetch_params)
      fetch(fetch_params).then(res=>res.json()).then(data=>{
        console.log(data)
        if (reset)
          this.setState({valid_products: [...data.products],tags: data.tags,total_count: data.total_count})
        else
          this.setState({valid_products: [...this.state.valid_products,...data.products],tags: data.tags,total_count: data.total_count})
      })
    }
    else {
      let fetch_params = `/api/v1/products?offset=${this.state.offset}&keyword=${this.props.keyword ? this.props.keyword: ''}&tags=${this.state.selected_tags.join(',')}&series_id=${this.props.series_id||''}&collections=${this.props.collections||''}&collection_token=${this.props.token||''}&limit=${this.state.limit}&brand_id=${this.props.brand ? this.props.brand.id : ''}&sorting=${this.state.sorting}&agent=${readCookie("agent_token")}`
      console.log(fetch_params)
      fetch(fetch_params).then(res=>res.json()).then(data=>{
        console.log(data)
        if (reset)
          this.setState({valid_products: [...data.products],tags: data.tags,total_count: data.total_count})
        else
          this.setState({valid_products: [...this.state.valid_products,...data.products],tags: data.tags,total_count: data.total_count})
      })
    }
  }

  componentDidMount(){
    if (this.state.is_mobile) {
      const show_more = document.querySelector('#show-more')
      var observer = new IntersectionObserver(entries => {
        entries.forEach(i => {
          if (i.isIntersecting)
            this.showMore()
        });
      }, {});
      observer.observe(ReactDOM.findDOMNode(show_more));
    }

    let page = (new URL(window.location.href)).searchParams.get("page")
    let offset = page ? Math.ceil((page-1) * this.state.limit) : 0;

    let series_id = (new URL(window.location.href)).searchParams.get("series") || 0
    if (localStorage.getItem("sorting") || localStorage.getItem("tags")) {
      if (page===null || (parseInt(page) === 1)) {
        // reserve sorting
        if (this.props.collections && this.props.selected_menu_id !== undefined && (parseInt(this.props.selected_menu_id) !== parseInt(localStorage.getItem("selected_menu_id")))) {
          localStorage.removeItem('sorting')
          localStorage.removeItem('tags')
          localStorage.setItem("selected_menu_id", this.props.selected_menu_id);
        }
        else if (this.props.brand && (parseInt(series_id) !== parseInt(localStorage.getItem("series_id")))) {
          localStorage.removeItem('sorting')
          localStorage.removeItem('tags')
          localStorage.setItem("series_id", series_id);
        }
        else {
          if (localStorage.getItem("tags"))
            this.setState({ selected_tags: localStorage.getItem("tags").split(',') });

          this.setState({ sorting: localStorage.getItem("sorting") })
        }
      }
      else {
          if (localStorage.getItem("tags"))
            this.setState({ selected_tags: localStorage.getItem("tags").split(',') });

        // reset sorting
        if (this.props.collections) {
          localStorage.setItem("selected_menu_id", this.props.selected_menu_id);
          localStorage.removeItem('series_id')
        }
        else if (this.props.brand) {
          localStorage.setItem("series_id", series_id);
          localStorage.removeItem('selected_menu_id')
        }
        this.setState({ sorting: localStorage.getItem("sorting") })
      }
    }

    this.setState({ offset: offset }, () => {
      this.fetchProduct(true);
    });
  }
  componentDidUpdate(prevProps){
    if ((this.props.series_id !== prevProps.series_id && prevProps.series_id !== null) || (this.props.collections !== undefined && this.props.collections !== prevProps.collections))
      this.setState({ selected_tags: [], offset: 0 }, () => this.fetchProduct(true))
  }
  setTag(tag) {
    let selected_tags = this.state.selected_tags
    if (selected_tags.includes(tag)) {
      selected_tags.splice(selected_tags.indexOf(tag), 1);
    } else {
      selected_tags.push(tag)
    }
    this.setState({ selected_tags: selected_tags,valid_products:[], offset: 0 },
      ()=>{
        localStorage.setItem("tags", selected_tags)
        this.fetchProduct()
      }
    )
  }

  handleSelect(event) {
    let sorting = event.target.value
    this.setState({ sorting: sorting, valid_products:[], offset: 0 }, () => {
      this.fetchProduct();
      localStorage.setItem("sorting", sorting)
    })
  }
  handlePageClick(data) {
    // $('html,body').animate({scrollTop: 200}, 50);

    let offset = Math.ceil(data.selected * this.state.limit);
    let url = window.location.href;
    url = url.replace(/&page=[0-9]+/g, '').replace(/\?page=[0-9]+&/g, '?').replace(/(\?|&)page=[0-9]+/g, '').replace('#0', '').replace('#!', '')
    window.location.href = url + (url.includes('?') ? ('&page='+(data.selected+1)) : ('?page='+(data.selected+1)))

    // this.setState({ offset: offset }, () => {
    //   this.fetchProduct(true);
    // });
  };
  render() {
    // let filtered_products = this.state.valid_products.filter(p=>{
    //   return (this.state.selected_tags.length == 0 || this.state.selected_tags.some(t=>p.tags.map(p_tag=>p_tag.title).includes(t)))
    // })
    let filtered_products = this.state.valid_products

    // if(this.state.price_sorting != NONE){
    //   filtered_products = filtered_products.sort((a,b)=>{
    //     let min_price_a = Math.min(...a.variants.map(v=>v.price))
    //     let min_price_b = Math.min(...b.variants.map(v=>v.price))
    //     return this.state.price_sorting == PRICE_ASC ? min_price_a - min_price_b : min_price_b - min_price_a
    //   })
    // }

    let products = filtered_products.map((product, index) =>{
      let variants = this.state.valid_variants.filter(v => v.product_id===product.id)
      if (variants.length === 0)
        variants = product.variants
      return <StoreProduct product={product} variants={variants} collection_token={this.props.token} shop_token={this.props.shop_token} cart_items={this.props.cart_items} shop_customer={this.props.shop_customer} current_page={this.props.current_page} rerender_favorite={this.props.rerender_favorite} authenticity_token={this.props.authenticity_token} is_login={this.props.is_login} show_ranking={false} distribution_enabled={product.distribution_enabled} distribution_percentage={this.props.distribution_percentage} vip_percentage={this.props.vip_percentage} agent_token={this.props.agent_token} shop_vips={this.props.shop_vips} key={index} />
    })
    let collection_heading = null;
    if (this.props.keyword)
      collection_heading = (
        <div className="result-header pt-3">
          <h5 className="mb-0">
            搜尋:
            <b className="text-danger mx-2">{this.props.keyword}</b>
          </h5>
          <div className="result-count text-muted">
            共 {this.state.total_count} 項搜尋結果
          </div>
        </div>
      );
    else {
      collection_heading = (this.props.title_show != false && this.props.title) ? (
        <hgroup className="section-hgroup">
          <h2 className="section-title">{this.props.title}</h2>
          <h3 className="section-subtitle">{this.props.subtitle}</h3>
        </hgroup>
      ) : null
    }
    return (
      <section className={`p-md-0 collection-section-container ${this.props.is_frontpage ? "is_frontpage pt-3 pt-lg-5" : ""} `}>
        <div id={"collection-" + this.props.id} className="lsStoreCollectionContainer">

          <div className="collection-container container">
            {collection_heading}

            {/*<div className='collection-tags-panel'>
              <div className="tags-wrapper">
                {
                  this.props.brand
                    ? <a className={"btn btn-sm tag-item menu-tag-item active"}
                        href="#0" key={this.props.brand.id}
                      >
                        {this.props.brand.title}
                      </a>
                    : null
                }
                {
                  this.props.parent_menu_id
                    ? <a className={"btn btn-sm tag-item menu-tag-item active"}
                        href="#0" key={this.props.selected_menu_id}
                      >
                        {this.props.selected_menu_title}
                      </a>
                    : null
                }
                {
                  (this.props.is_frontpage !== true && (this.props.title !== '全部商品' || (new URL(window.location.href)).searchParams.get("selected")!==null)) && this.state.tags.map(tag => {
                    return <a className={`btn btn-sm tag-item ${this.state.selected_tags.includes(tag) ? 'active' : ''}`} href="#0" key={tag} onClick={() => this.setTag(tag)}>
                      {tag}
                    </a>
                  })
                }
              </div>
            </div> */}

            {/* {
              this.props.is_frontpage !== true
                ? <div className='collection-control-panel form-inline input-group-sm'>
                    <select className="custom-select form-control-sm" value={this.state.sorting} onChange={this.handleSelect}>
                      <option value={NONE}>請選擇排序依據</option>
                      <option value={CREATED_AT_ASC}>上架時間最舊</option>
                      <option value={CREATED_AT_DESC}>上架時間最新</option>
                      <option value={PRICE_ASC}>價格低到高</option>
                      <option value={PRICE_DESC}>價格高到低</option>
                    </select>
                  </div>
                : null
            } */}
            <div ref={this.element} className={this.props.is_frontpage ? "scrollable_row" : "row" }>
              {products}
              <div ref={this.sizer} className="col-6 col-lg-4 col-xl-3"></div>
            </div>
            <div className="row">
              {/*
                this.state.valid_products.length < this.state.total_count
                  ? <a className="btn btn-block" onClick={this.showMore}><i className="fa fa-caret-down"></i>顯示更多商品</a>
                  : null
              */}
            </div>
            {
              !this.state.is_mobile && !this.props.is_frontpage
                ? <div className="commentBox">
                    <ReactPaginate
                      previousLabel={'上一頁'}
                      nextLabel={'下一頁'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={this.state.total_count ? this.state.total_count/this.state.limit : 0}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      activeClassName={'active'}
                      forcePage={this.state.offset/this.state.limit}
                    />
                  </div>
                : null
            }
            <div className="row">
              <div id="show-more"></div>
            </div>

            {/*
              !this.props.is_frontpage &&
                <div className="collection-btn-rapper text-center">
                  <a href={this.props.link} className={"btn btn-more-collection btn-brand-third " + (this.props.is_frontpage? 'mb-40' : '')}>更多{this.props.title}</a>
                </div>
            */}
          </div>
        </div>
      </section>
    )
  }
}
