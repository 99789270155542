export default
{
  "product_description": "商品描述",
  "no_content": "目前暫無內容",
  "choose_quantity": "選擇數量",
  "please_select_variant": "請選擇樣式",
  "buy_now": "立即購買",
  "checkout": "結帳",
  "checkout_sm": "結帳",
  "shopping_cart": "購物車",
  "add_cart": "加入購物車",
  "cancel": "取消",
  "update_quantity": "更新數量",
  "additional_purchase": "加價購",
  "target_gifts": "贈品",
  "freight": "運費",
  "plus_freight": "運費加計",
  "total": "總計",
  "subtotal": "小計",
  "no_items_in_cart": "您的購物車沒有商品",
  "remove": "移除",
  "or_use": "或是您也可以使用",
  "logout": "登出",
  "login": "登入",
  "signup": "會員註冊",
  "signup_signin": "會員註冊 | 登入",
  "forget_password": "忘記密碼",
  "close": "關閉",
  "subscription": "自動續訂(訂閱制)",
  "discount": "折扣",
  "numbers_bought": "訂購總次數",
  "frequency": "訂購間距(天數)",
  "confirm_order": "確認訂購",
  "confirm_order_unregister": '非會員結帳',
  "preview_only": "此頁面僅供預覽",
  "limit_purchase": "(限購 {{limit}} 件)",
  "helpers": {
    'return': "退換貨須知",
    'contact_info': "聯絡資訊",
    'contact': "聯絡我們",
    'home': '主頁'
  },
  "cart": {
    "click_additional": "點我加購",
    "promocode_successfully": "成功兌換促銷代碼",
    "no_such_code": "查無此代碼，或不符合條件、兌換完畢囉！",
    "click_collapse": "點我收合",
    "more_style": "更多樣式",
    "free_shipping": "滿 {{threshold}} 免運費",
    "input_promotion_code": "請輸入促銷代碼",
    "apply_code": "兌換",
    "choose_any": "任選",
    "pieces": "件",
    "ntds": "元",
    "styles": "種樣式",
    "has": "共",
    "not_yet_eligible": "尚未符合",
    "need": "須滿",
    "to_used": "方可使用",
    "sold_out": "已售完",
    "pre_order": "預購",
    "voucher": "購物金",
    "use_voucher": '使用購物金',
    "promotion_code": "促銷代碼",
    "no_promotion_code": "您目前沒有可供使用的促銷代碼",
    "no_address": "尚未設定取貨地址",
    "no_favorite": "尚未設定最愛商品"
  },
  "recipient": {
    "title": "收件人資訊",
    "name": "真實姓名 (請填寫與證件相符之姓名)",
    "note": "備註",
    "delivery": "運送",
    "payment": "付款",
    "region": "縣市",
    "township": "鄉鎮市區",
    "zipcode": "郵遞區號",
    "address": "地址",
    "address_for_winning": "地址",
    "cellphone": "手機號碼",
    "same-as-address": "與收件地址相同",
    "tax_id": "需要統一編號",
    "buyer": "買受人",
    "carriers": "請輸入 '/' 開頭，共8碼之手機載具條碼編號",
    "egui": "電子發票",
    "normal_type": "E-mail",
    "carrier_type": "手機條碼",
    "no_payment_method": "無可用付款方式，請嘗試其他運送方式或調整購物車物品",
    "cannot_exceed": "超商取貨付款金額不得超過2萬元"
  },
  "purchaser": {
    "title": "訂購人資訊"
  },
  "payment": {
    "credit": "信用卡",
    "atm": "銀行轉帳",
    "cvs": "超商代碼",
    "inst": "分期付款",
    "cvs_cod": "超取付款",
    "Credit_3": "信用卡分期(三期)",
    "Credit_6": "信用卡分期(六期)",
    "Credit_9": "信用卡分期(九期)",
    "Credit_12": "信用卡分期(十二期)",
    "cod": "貨到付款"
  },
  "member": {
    "member_center": "會員中心",
    "sign_in_with": "請選擇會員登入方式",
    "profile": "我的個人資料",
    "order": "我的訂單",
    "change_password": "變更我的密碼",
    "address": "管理我的地址",
    "promotion_code": "我的促銷代碼",
    "voucher": "我的購物金",
    "favorite": "我的收藏",
    "qna": "我的問答",
    "vip": "會員等級",
    "sign_in_facebook": "Facebook 登入",
    "sign_in_line": "Line 登入"
  },
  "orders": {
    "checking": "查詢訂單",
    "your_email": "您的信箱",
    "number": "訂單編號",
    "total_price": "訂單金額",
    "status_text": "狀態",
    "no_orders": "您尚無任何訂單",
    "status":{
      "ordered": "已成立",
      "new": "新訂單",
      "canceled": "已取消",
      "processing": '處理中',
      "done": "已完成",
      "request": "審核中",
      "request_cancel": '取消審核中',
      "request_return": '退貨審核中',
      "returned": '已退貨',
    },
    "payment_status":{
      "payment": '付款',
      "waiting": '尚未付款',
      "confirming": '確認付款中',
      "paid": '已付款',
      "refunded": '已退款',
      "cod": '貨到付款'
    },
    "shipping_status":{
      "text": '運送',
      "ready": '準備出貨',
      "partial": '部分出貨',
      "shipped": "已出貨",
      "returned": '已退回',
      "arrived": '已到店',
      "received": '已取件'
    },
    "invoice_status":{
      "new": '未開立',
      "waiting": '等待財政部回應',
      "done": '已開立',
      "cancel_pending": '已排程作廢',
      "canceled": '已作廢'
    },
    "discount_type":{
      "target_price": "滿額折扣"
    },
    "payment_type":{
      "credit": '信用卡'
    },
    "shipping_type":{
      "regular": '宅配',
      "person": '面交',
      "cvs": '7-11 超商取貨',
      "cvs_fami": '全家超商取貨',
      "post": '郵寄',
      "international": '國際運送',
      "restricted": '指定配送地點'
    },
    "modal": {
      "18plus": '18+'
    }
  }
}