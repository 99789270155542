import React from 'react'
import ReactDOM from 'react-dom'
import i18n from '../../components/i18next/i18n'
import VariantAddCart from './variant_add_cart'
import TargetGift from './target_gift'
import {Luminous} from 'luminous-lightbox'
// import VariantModal from './variant_modal'
import {setCookie, readCookie} from '../../components/common'
const MyLuminous = Luminous

class ProductOption extends React.Component {
  constructor(props) {
    super(props)
    let cart_item = this.props.cart.find(item => item.product_id==this.props.product.id);
    let n_piece_group = {};
    (cart_item?.n_pieces || []).forEach(item => {
      this.props.product.n_piece_group.forEach(piece => {
        if (piece.variants_ids.includes(parseInt(item)))
          n_piece_group[piece.id] = parseInt(item)
      })
    })
    if (this.props.product.variants.length > 1) {
      let variants_stocks = this.props.product.variants.map(v => v.stock);
      this.state = {
        options: this.getOptions(this.props.product.variants),
        options_tree: this.buildOptionTree(this.props.product.variants),
        current_select_level: 1,
        option_1_selected: null,
        option_2_selected: null,
        option_3_selected: null,
        bundle_selected: null,
        bundle_collaspe: false,
        select_variant_warning: false,
        current_quantity: 1,
        left_stocks: Math.max(...variants_stocks),
        n_piece_group: n_piece_group,
        selected_gifts: this.props.selected_gifts
      }
    }
    if (this.props.product.variants.length == 1) {
      this.state = {
        options: this.getOptions(this.props.product.variants),
        options_tree: this.buildOptionTree(this.props.product.variants),
        current_select_level: 0,
        option_1_selected: this.props.product.variants[0].option_1,
        option_2_selected: this.props.product.variants[0].option_2,
        option_3_selected: this.props.product.variants[0].option_3,
        bundle_collaspe: false,
        select_variant_warning: false,
        current_quantity: 1,
        left_stocks: this.props.product.variants[0].stock,
        n_piece_group: n_piece_group,
        selected_gifts: this.props.selected_gifts
      }
    }
    this.cartItemCount = this.cartItemCount.bind(this)
    this.anchorToVariant = this.anchorToVariant.bind(this)
    this.onUpdateCart = this.onUpdateCart.bind(this)
    this.onOptionSelected = this.onOptionSelected.bind(this)
    this.onBundleVariantSelected = this.onBundleVariantSelected.bind(this)
    this.bundleCollapse = this.bundleCollapse.bind(this)
    this.findVariant = this.findVariant.bind(this)
    this.getSubtreeSum = this.getSubtreeSum.bind(this)
    this.buildOptionTree = this.buildOptionTree.bind(this)
    this.dynamicHash = this.dynamicHash.bind(this)
    this.addVariantOptions = this.addVariantOptions.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.getPriceRange = this.getPriceRange.bind(this)
    this.getMaxOriginalPrice = this.getMaxOriginalPrice.bind(this)
    this.otherItemCount = this.otherItemCount.bind(this)
    this.nl2br = this.nl2br.bind(this)
    this.onNPieceGroupOptionSelected = this.onNPieceGroupOptionSelected.bind(this)
    this.checkIfAllSelected = this.checkIfAllSelected.bind(this)
  }
  nl2br(text) {
    return text
  }
  componentDidMount() {
    if (this.refs.medium_photo) {
      this.luminous = new MyLuminous(this.refs.medium_photo);
    }

    (this.props.product.n_piece_group || []).map((item, index) =>{
      if (item.variants.length === 1) {
        this.onNPieceGroupOptionSelected(item.id, item.variants[0].id)
      }
    })
  }
  componentDidUpdate(prevProps) {
    if (this.refs.medium_photo && this.luminous) {
      this.luminous.destroy()
    }
    if (this.refs.medium_photo) {
      this.luminous = new MyLuminous(this.refs.medium_photo);
    }
    if (prevProps.selected_gifts !== this.props.selected_gifts)
      this.setState({selected_gifts: this.props.selected_gifts})
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {return};
  }
  // setFavorite() {
  //   let self = this;
  //   $.ajax('/members/set_favorite',
  //     {
  //       type: 'POST',
  //       data: {
  //         shop_customer: self.props.shop_customer.id,
  //         product_token: self.props.product.token,
  //         authenticity_token: self.props.authenticity_token
  //       },
  //       dataType: 'json',
  //       success: function (data) {
  //         if (data.success) {
  //           if (data.action === 'add')
  //             self.setState({ is_favorite: true })
  //           else if (data.action === 'remove')
  //             self.setState({ is_favorite: false })
  //           window.shop_notify(data.message)
  //         }
  //       }
  //     }
  //   )
  // }
  cartItemCount(cart) {
    let count = 0
    for (let variant of cart) {
      // if (variant.target_price_gift_id == undefined)
      count += variant.quantity
    }
    return count
  }
  otherItemCount(cart, variant_id) {
    let count = 0
    let product_id = this.props.product.variants[0].product_id
    for (let variant of cart) {
      if (variant.product_id == product_id && variant.variant_id != variant_id) {
        count += variant.quantity
      }
    }
    return count
  }
  anchorToVariant() {
    var selected_variant = this.findVariant(this.state)
    if (!selected_variant || !this.state.bundle_selected) {
      this.setState({ select_variant_warning: true })
      ReactDOM.findDOMNode(this.refs.variant_area).scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }
  onUpdateCart(selected_variant, quantity) {
    var self = this
    if (selected_variant) {
      this.props.updateCart(selected_variant, quantity, this.state.n_piece_group || {})
      $(".btn-ls-check-out div").animateCss("pulse")
      try {
        fbq('track', 'AddToCart', {
          content_name: selected_variant.title,
          content_ids: [selected_variant.sku],
          content_type: 'product',
          value: quantity * selected_variant.price,
          currency: 'TWD'
        });
      } catch (e) {
      }

      try {
        ga('send', 'event', '購物車', '加入購物車');
        gtag('event', 'add_to_cart', {
          "items": [
            {
              "id": selected_variant.sku,
              "name": selected_variant.title,
              "variant": selected_variant.title,
              "quantity": quantity,
              "price": quantity * selected_variant.price
            }
          ]
        });
      } catch (e) {
      }
      try{
        gtag_report_conversion();
      //   let price = this.props.cart.reduce((sum, x) => sum + x.price * x.quantity,0);
      //   gtag('event', 'conversion', {
      //     'send_to': 'AW-594643752/tsWbCI3j1d0BEKiWxpsC',
      //     'value': price ? price : quantity * selected_variant.price,
      //   });
      }catch(e){

      }
      jQuery.ajax({
        url: "/carts/modify.json",
        data: {
          variant_id: selected_variant.variant_id,
          bundle_id: selected_variant.bundle_id,
          n_pieces: Object.values(selected_variant.n_piece_group || {}),
          quantity: quantity,
          u: this.props.agent_token,
          authenticity_token: this.props.authenticity_token,
          product_token: this.props.product.product_token,
          shop_token: this.props.shop_token
        },
        type: "POST",
        success: function (data) {
          if (data.success) {
            if (!readCookie('cart_token'))
              setCookie('cart_token',data.cart_token)
            self.props.onCartSet(data.cart_token)
            try {
              fbq('track', 'AddToCart', {
                content_name: selected_variant.title,
                content_ids: [selected_variant.sku],
                content_type: 'product',
                value: quantity * selected_variant.price,
                currency: 'TWD'
              },{
                eventID: data.event_id
              });
            } catch (e) {
              console.log(e)
            }

            try {
              ga('send', 'event', '購物車', '加入購物車');
              gtag('event', 'add_to_cart', {
                "items": [
                  {
                    "id": selected_variant.sku,
                    "name": selected_variant.title,
                    "variant": selected_variant.title,
                    "quantity": quantity,
                    "price": quantity * selected_variant.price
                  }
                ]
              });
            } catch (e) {
              console.log(e)
            }
          }
        }
      })
    }
  }
  onOptionSelected(key, option) {
    let state = this.state
    let targetVariant
    let photo
    state[key] = this.state[key] == option ? '' : option
    if (key == "option_1_selected") {
      state['option_2_selected'] = ''
      state['option_3_selected'] = ''
      state.current_select_level = state[key] == '' ? 1 : 2
    }
    if (key == "option_2_selected") {
      state['option_3_selected'] = ''
      state.current_select_level = state[key] == '' ? 2 : 3
    }
    if (key == "option_3_selected") {
      state.current_select_level = state[key] == '' ? 3 : 4
    }
    state.select_variant_warning = false
    //reset lower level options
    targetVariant = this.findVariant(state)
    if (!!targetVariant && !!targetVariant.medium) {
      photo = targetVariant.medium
      state.left_stocks = targetVariant.stock
    } else if (state.current_select_level == 2 && this.state.options_tree[state["option_1_selected"]]) {
      photo = this.state.options_tree[state["option_1_selected"]].photo
      state.left_stocks = this.state.options_tree[state["option_1_selected"]].sum
    } else if (state.current_select_level == 3 && this.state.options_tree[this.state.option_1_selected]["option_2"][state["option_2_selected"]]) {
      photo = this.state.options_tree[state.option_1_selected]["option_2"][state["option_2_selected"]].photo
      state.left_stocks = this.state.options_tree[state.option_1_selected]["option_2"][state["option_2_selected"]].sum
    } else {
      photo = this.props.product.medium
      let variants_stocks = this.props.product.variants.map(v => v.stock);
      state.left_stocks =  Math.max(...variants_stocks)
    }
    state.bundle_selected = null
    if (targetVariant !== undefined) {
      let index = this.props.productPhotos && this.props.productPhotos.findIndex(elem => elem.photo.url==photo);
      if (index !== -1)
        this.props.onVariantPhotoChange(index, photo)
    }
    this.setState(state)
  }

  onBundleVariantSelected(bundle) {
    let state = this.state
    let photo
    if (this.props.product.variants.length > 1) {
      state.current_select_level = 1
      state.option_1_selected = null
      state.option_2_selected = null
      state.option_3_selected = null
    } else if (this.props.product.variants.length == 1) {
      state.current_select_level = 0
      state.option_1_selected = this.props.product.variants[0].option_1
      state.option_2_selected = this.props.product.variants[0].option_2
      state.option_3_selected = this.props.product.variants[0].option_3
    }
    if (state.bundle_selected && state.bundle_selected.bundle_id == bundle.bundle_id) {
      state.bundle_selected = null
      photo = this.props.product.medium
    } else {
      state.bundle_selected = bundle
      photo = bundle.medium
    }
    state.select_variant_warning = false
    //reset lower level options
    // 取得組合商品圖片

    this.props.onVariantPhotoChange(photo)
    this.setState(state)
  }
  bundleCollapse(){
    this.setState({bundle_collaspe: !this.state.bundle_collaspe})
  }
  findVariant(_state) {
    try {
      return this.state.options_tree[_state.option_1_selected]["option_2"][_state.option_2_selected]["option_3"][_state.option_3_selected]
    } catch (error) {
      return false
    }
  }
  getSubtreeSum(option_1, option_2, option_3) {
    try {
      if (option_1 != null) {
        if (option_2 != null) {
          if (option_3 != null) {
            return this.state.options_tree[option_1]["option_2"][option_2]["option_3"][option_3].stock
          }
          return this.state.options_tree[option_1]["option_2"][option_2].sum
        }
        return this.state.options_tree[option_1].sum
      }
    }
    catch (error) {
      return null
    }
  }

  getNpieceSubtreeSum(option_1, option_2, option_3, options_tree) {
    try {
      if (option_1 != null) {
        if (option_2 != null) {
          if (option_3 != null) {
            return options_tree[option_1]["option_2"][option_2]["option_3"][option_3].stock
          }
          return options_tree[option_1]["option_2"][option_2].sum
        }
        return options_tree[option_1].sum
      }
    }
    catch (error) {
      return null
    }
  }

  buildOptionTree(variants) {
    let options = {}
    for (let i = 0; i < variants.length; i++) {
      this.addVariantOptions(options, variants[i])
    }
    return options
  }
  dynamicHash(key, value) {
    var hash = {}
    hash[key] = value
    return hash
  }
  addVariantOptions(tree, variant) {
    if (tree[variant.option_1] == null) {
      tree[variant.option_1] = {
        photo: variant.medium,
        sum: variant.stock,
        option_2: this.dynamicHash(
          variant.option_2,
          {
            photo: variant.medium,
            sum: variant.stock,
            option_3: this.dynamicHash(variant.option_3, variant)
          }
        )
      }
    } else {
      tree[variant.option_1].sum = Math.max(tree[variant.option_1].sum, variant.stock)
      var _subtree = tree[variant.option_1]["option_2"]
      if (_subtree[variant.option_2] == null) {
        _subtree[variant.option_2] = {
          photo: variant.medium,
          sum: variant.stock,
          option_3: this.dynamicHash(variant.option_3, variant)
        }
      } else {
        _subtree[variant.option_2].sum = Math.max(_subtree[variant.option_2].sum, variant.stock)
        _subtree[variant.option_2]["option_3"][variant.option_3] = variant
      }
    }
  }
  getOptions(variants) {
    var option_1_array = []
    var option_2_array = []
    var option_3_array = []
    for (let i = 0; i < variants.length; i++) {
      var _option_1 = variants[i].option_1
      var _option_2 = variants[i].option_2
      var _option_3 = variants[i].option_3
      var _state
      if (option_1_array.indexOf(_option_1) === -1 && !!_option_1) {
        option_1_array.push(_option_1)
      }
      if (option_2_array.indexOf(_option_2) === -1 && !!_option_2) {
        option_2_array.push(_option_2)
      }
      if (option_3_array.indexOf(_option_3) === -1 && !!_option_3) {
        option_3_array.push(_option_3)
      }
    }
    return { option_1: option_1_array, option_2: option_2_array, option_3: option_3_array }
  }
  filterVariant(variants) {
    let _variants = variants
    let self = this
    if (!!self.state.option_1_selected) {
      _variants = _variants.filter(variant => { if (variant.option_1 == self.state.option_1_selected) return variant })
      if (!!self.state.option_2_selected) {
        _variants = _variants.filter(variant => { if (variant.option_2 == self.state.option_2_selected) return variant })
      }
    }
    return _variants
  }
  getCrosslinePriceRange(variants) {
    if (variants.length === 0)
      return this.props.product.price
    else {
      let min = variants[0].crossline_price
      let max = variants[0].crossline_price
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].crossline_price !== 0 && variants[i].crossline_price <= min) {
          min = variants[i].crossline_price
        }
        if (variants[i].crossline_price !== 0 && variants[i].crossline_price >= max) {
          max = variants[i].crossline_price
        }
      }
      return min == max ? min : min + " - " + max
    }
  }
  getPriceRange(variants) {
    if (variants.length === 0)
      return this.props.product.price
    else {
      let min = variants[0].price
      let max = variants[0].price
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].price <= min) {
          min = variants[i].price
        }
        if (variants[i].price >= max) {
          max = variants[i].price
        }
      }
      return min == max ? min : min + " - " + max
    }
  }
  getVipPriceRange(variants) {
    let vip_percentage = this.props.shop_vips.length > 0 && this.props.shop_vips[0].discount_percentage ? this.props.shop_vips[0].discount_percentage : 100
    if (variants.length === 0)
      return this.props.product.price*vip_percentage/100
    else {
      let min = variants[0].price
      let max = variants[0].price
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].price <= min) {
          min = variants[i].price
        }
        if (variants[i].price >= max) {
          max = variants[i].price
        }
      }

      return min == max ? Math.round(min*vip_percentage/100) : Math.round(min*vip_percentage/100) + " - " + Math.round(max*vip_percentage/100)
    }
  }
  getMaxOriginalPrice(variants) {
    let max = variants[0].original_price
    for (let variant of variants) {
      if (variant.original_price > max) {
        max = variant.original_price
      }
    }
    return max
  }
  onNPieceGroupOptionSelected(product_id, variant_id) {
    let n_piece_group = this.state.n_piece_group;
    n_piece_group[product_id] = variant_id;
    this.setState({ n_piece_group: n_piece_group },() => {
      this.checkIfAllSelected()
    });
  }
  checkIfAllSelected() {
    let products_count = this.props.product.n_piece_group.length;
    let selected_count = Object.keys(this.state.n_piece_group).length;
    // if (products_count == selected_count)
      // 可以點選選擇數量 => 結帳
  }

  mapping_id(original_gift) {
    let group = original_gift.reduce((previousValue, currentValue) => {
      previousValue[currentValue[1].id] = [...previousValue[currentValue[1].id] || [], currentValue]
      return previousValue;
    }, {});
    return group;
  }

  render() {
    const { product, productDescriptions, toggleGuide } = this.props
    let option_title
    let options_1 = []
    let options_2 = []
    let options_3 = []
    let add_to_cart
    let target_variant
    let preorder_text
    let add_cart_panel
    let quantity = 0
    let limit
    let sold_bar
    let option_1_html
    let option_2_html
    let option_3_html
    let bundle_html
    let n_piece_area
    let variant_modal
    let left_stocks = (this.props.product && this.props.product.variants.length === 1) ? this.props.product.variants[0].stock : this.state.left_stocks
    let check_out_btn = <a className="btn btn-black btn-ls-check-out btn-block" role="button" tabIndex="0" onClick={this.cartItemCount(this.props.cart) == 0 ? null : this.props.onCheckoutClicked}
      disabled={this.cartItemCount(this.props.cart) == 0}>
      <div><span className="d-none d-md-inline-block">{i18n.t('checkout')}</span> <span className="d-inline-block d-md-none">{i18n.t('checkout_sm')}</span> <span className="badge">{this.cartItemCount(this.props.cart)}</span></div>
    </a>
    if (this.props.product.variants[0].product_bundle_threshold && this.props.product.variants[0].product_bundle_threshold!= 0){
      bundle_html =
        <div className="variant-price bundle-price animated fadeInDown my-3">
        <span>{i18n.t('cart.choose_any')}  <b>{this.props.product.variants[0].product_bundle_threshold}</b> {i18n.t('cart.pieces')} <b> {this.props.product.variants[0].product_bundle_price}</b> {i18n.t('cart.ntds')}</span>
        </div>
    }
    // Ｎ件商品
    if (this.props.product.n_piece_group && this.props.product.n_piece_group.length > 0 ) {
      n_piece_area = this.props.product.n_piece_group.map((item, index) =>{
        let itemOptions = [];
        item.variants.map((variant, i) => {
          if (this.props.product.all_variants_id.includes(variant.id))
            itemOptions.push(<VariantButton
              product_id={item.id}
              variant_id={variant.id}
              stock_sum={variant.stock}
              allow_preorder={item.allow_preorder}
              is_selected={ this.state.n_piece_group ? (this.state.n_piece_group[item.id] == variant.id) : false}
              title={variant.title}
              key={i}
              option_type="option_1_selected"
              onNPieceGroupOptionSelected={this.onNPieceGroupOptionSelected}
            />);
        })
        return(
          <div key={index} className='n_piece_options-wrapper'>
            <h3 className="title">{item.title}</h3>
            <div className="options-group">
              {itemOptions}
            </div>
          </div>
        )
      })
    }
    if (this.props.product.variants.length > 1) {
      for (let i = 0; i < this.state.options["option_1"].length; i++) {
        var _option_1 = this.state.options["option_1"][i]
        if (this.getSubtreeSum(_option_1, null, null) != null) {
          options_1.push (
            <VariantButton stock_sum={this.getSubtreeSum(_option_1, null, null)}
              allow_preorder={this.props.allow_preorder}
              is_selected={this.state.option_1_selected == _option_1}
              title={_option_1}
              key={_option_1}
              option_type="option_1_selected"
              onOptionSelected={this.onOptionSelected} />
          )
        }
      }
      for (let i = 0; i < this.state.options["option_2"].length; i++) {
        var _option_2 = this.state.options["option_2"][i]
        // left_stocks = this.getSubtreeSum(this.state.option_1_selected, _option_2, null)
        if (this.getSubtreeSum(this.state.option_1_selected, _option_2, null) != null) {
          options_2.push (
            <VariantButton stock_sum={this.getSubtreeSum(this.state.option_1_selected, _option_2, null)}
              allow_preorder={this.props.allow_preorder}
              is_selected={this.state.option_2_selected == _option_2}
              title={_option_2}
              key={_option_2}
              option_type="option_2_selected"
              onOptionSelected={this.onOptionSelected} />
          )
        }
      }
      for (let i = 0; i < this.state.options["option_3"].length; i++) {
        var _option_3 = this.state.options["option_3"][i]
        // left_stocks = this.getSubtreeSum(this.state.option_1_selected, this.state.option_2_selected, _option_3)
        if (this.getSubtreeSum(this.state.option_1_selected, this.state.option_2_selected, _option_3) != null) {
          options_3.push (
            <VariantButton stock_sum={this.getSubtreeSum(this.state.option_1_selected, this.state.option_2_selected, _option_3)}
              allow_preorder={this.props.allow_preorder}
              is_selected={this.state.option_3_selected == _option_3}
              title={_option_3}
              key={_option_3}
              option_type="option_3_selected"
              onOptionSelected={this.onOptionSelected} />
          )
        }
      }
    }
    if(this.props.product.manual_shipping && this.props.product.start_sale_date && new Date(this.props.product.start_sale_date) > new Date()){
      add_to_cart =
        <div className="btn-wrapper only-one-button" id="addToCartBtnGroup">
          <a className="btn btn-brand-secondary btn-ls-add-to-cart please-select-variant" role="button" tabIndex="0">{new Date(this.props.product.start_sale_date).toLocaleString()}開放銷售</a>
        </div>
    }
    else {
    if(this.state.bundle_selected){
      add_to_cart =
        <div className="btn-wrapper" id="addToCartBtnGroup">
          {/* <a className="btn btn-brand-secondary btn-ls-add-to-cart" role="button" tabIndex="0" onClick={this.openModal}><span className="title">選擇數量</span><span className="price">${this.state.bundle_selected.price}</span></a> */}
          {check_out_btn}
        </div>
      for (let i = 0; i < this.props.cart.length; i++) {
        if (this.props.cart[i].bundle_id && this.props.cart[i].bundle_id == this.state.bundle_selected.bundle_id) {
          quantity = this.props.cart[i].quantity
          break
        }
      }
      if (!this.state.bundle_selected.allow_preorder) {
        limit = this.state.bundle_selected.stock
      }
      if (this.state.bundle_selected.limit_purchase && (limit > this.state.bundle_selected.limit_purchase || limit == null)) {
        limit = this.state.bundle_selected.limit_purchase
      }

      // add_cart_panel = <VariantAddCart
      //   product_title={this.state.bundle_selected.title}
      //   variant={this.state.bundle_selected}
      //   cart={this.props.cart}
      //   quantity={quantity}
      //   limit={limit}
      //   key={this.state.bundle_selected.bundle_id}
      //   updateVariantQuantity={this.onUpdateCart}/>

    }else{
      target_variant = this.findVariant(this.state)
      // 判斷選取樣式
      if (target_variant) {
        for (let i = 0; i < this.props.cart.length; i++) {
          if (this.props.cart[i].variant_id && this.props.cart[i].variant_id == target_variant.variant_id) {
            quantity = this.props.cart[i].quantity
            break
          }
        }
        // 限購 & 小於限購數量
        if (target_variant.limit_purchase && quantity >= target_variant.limit_purchase) {
          add_to_cart =
            <div className="btn-wrapper" id="addToCartBtnGroup">
              {check_out_btn}
            </div>
        }
        // 售完 & 無法預購
        else if (quantity >= target_variant.stock && !this.props.allow_preorder) {
          add_to_cart =
            <div className="btn-wrapper" id="addToCartBtnGroup">
              {check_out_btn}
            </div>
        } else {
          // 沒有開啟預購
          if (!this.props.allow_preorder) {
            limit = target_variant.stock
          }
          // 限購 & 小於限購
          if (target_variant.limit_purchase && (limit > target_variant.limit_purchase || limit == null)) {
            limit = target_variant.limit_purchase
          }
          // 總量限購 & 小於 (總量限購-其他商品樣式數量)
          if (this.props.product.global_limit && limit > (this.props.product.global_limit - this.otherItemCount(this.props.cart, target_variant.variant_id))) {
            limit = this.props.product.global_limit - this.otherItemCount(this.props.cart, target_variant.variant_id)
          }
          // 總量限購 & 大於等於 (總量限購-其他商品樣式數量)
          if (this.props.product.global_limit && (this.otherItemCount(this.props.cart, target_variant.variant_id) + quantity) >= this.props.product.global_limit) {
            add_to_cart =
              <div className="btn-wrapper" id="addToCartBtnGroup">
                {check_out_btn}
              </div>
          } else {
            if (!!target_variant.thumbnail) {
              add_to_cart =
                <div className="btn-wrapper has-thumbnail" id="addToCartBtnGroup">
                  {check_out_btn}
                  <div className="btn-thumbnail">
                    <a className="zoom-in" ref="medium_photo" href={target_variant.medium}>
                      <img className="img-fluid" src={target_variant.thumbnail} />
                    </a>
                  </div>
                </div>
            } else {
              add_to_cart =
                <div className="btn-wrapper" id="addToCartBtnGroup">
                  {check_out_btn}
                </div>
            }
          }
        }
      } else {
        // 判斷庫存
        if (left_stocks <= 0 && !this.props.allow_preorder)
          add_to_cart =
            <div className="btn-wrapper" id="addToCartBtnGroup">
              {check_out_btn}
            </div>
        else
          add_to_cart =
            <div className="btn-wrapper" id="addToCartBtnGroup">
              {/* 立即購買 */}
              <a className="btn btn-brand-secondary btn-ls-add-to-cart please-select-variant d-block d-lg-none w-100" role="button" tabIndex="0"
                 onClick={this.anchorToVariant}
                 >
                {i18n.t('buy_now')}
              </a>
              {check_out_btn}
            </div>
      }
      add_cart_panel = <VariantAddCart
                        product_title={this.props.product.title}
                        n_pieces_detail={this.props.findPiece(this.state.n_piece_group)}
                        variant={target_variant}
                        cart={this.props.cart}
                        quantity={quantity}
                        limit={limit}
                        key={target_variant?.variant_id}
                        n_piece_group={this.props.product.n_piece_group}
                        updateVariantQuantity={this.onUpdateCart}/>
    }
    }
    if (options_1.length > 0) {
      option_1_html = <OptionArea
        current_level={this.state.current_select_level == 1}
        level={1}
        option_title={this.props.product.option_1_title || '請選擇'}
        options={options_1}
        select_variant_warning={this.state.select_variant_warning}
      />
    }
    if (options_2.length > 0) {
      option_2_html = <OptionArea
        current_level={this.state.current_select_level == 2}
        option_title={this.props.product.option_2_title || '選擇次樣式'}
        options={options_2}
        select_variant_warning={this.state.select_variant_warning}
      />
    }
    if (options_3.length > 0) {
      option_3_html = <OptionArea
        current_level={this.state.current_select_level == 3}
        option_title={this.props.product.option_3_title || '選擇子樣式'}
        options={options_3}
        select_variant_warning={this.state.select_variant_warning}
      />
    }
    if (this.props.product.variants[0].stock <= 0 && this.props.product.allow_preorder) {
      // preorder_text = '(預購)'
      preorder_text = ''
    }

    // if (target_variant) {
    //   variant_modal = <VariantModal
    //     isOpen={this.state.isOpen}
    //     product_title={this.props.product.title}
    //     n_pieces_detail={this.props.findPiece(this.state.n_piece_group)}
    //     variant={target_variant}
    //     cart={this.props.cart}
    //     hideModal={this.hideModal}
    //     quantity={quantity}
    //     limit={limit}
    //     key={target_variant.variant_id}
    //     updateVariantQuantity={this.onUpdateCart}/>
    // }
    if (this.props.show_sold) {
      let percentage
      let total_stock = this.props.product.sold || 0
      for (let variant of this.props.product.variants) {
        total_stock += variant.stock || 0
      }
      if (total_stock != 0) {
        percentage = parseInt(100 * (this.props.product.sold || 0) / total_stock)
      } else {
        percentage = 0
      }
      sold_bar = (
        <div className="progress product-sold">
          <div className="progress-bar-text">已搶購 {this.props.product.sold} 件</div>
          <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: percentage + '%' }}></div>
        </div>
      )
    }
    let ShowBundleAmount = 3
    let bundle_variant_html = this.props.product.related_bundles.map((bundle, index )=>{
      if (bundle.stock < 0 ) {
        ShowBundleAmount++
      }
      return <BundleVariantButton  key={`bundle-${bundle.bundle_id}`} is_selected={this.state.bundle_selected && this.state.bundle_selected.bundle_id == bundle.bundle_id} onBundleVariantSelected={this.onBundleVariantSelected} bundle={bundle} isShow={this.state.bundle_collaspe ? true : ( index < ShowBundleAmount ? true : false )}/>
    })
    // 組合商品沒有庫存不顯示組合商品區塊(含標題)
    let bundleHasStock = false
    for (let i = 0; i < bundle_variant_html.length; i++) {
      if (bundle_variant_html[i].props.bundle.stock > 0 || bundle_variant_html[i].props.bundle.allow_preorder ) {
        bundleHasStock = true
        break
      }
    }
    let price_html = ''
    if(target_variant) {
      price_html = <VariantPriceLabel vip_percentage={this.props.product.vip_percentage} distribution_percentage={this.props.product.distribution_percentage} price={target_variant.price} crossline_price={target_variant.crossline_price} original_price={target_variant.original_price} vip_price={this.getVipPriceRange([target_variant])} current_customer={this.props.current_customer} left_stocks={left_stocks}/>
    } else if(this.state.bundle_selected) {
      price_html = <VariantPriceLabel vip_percentage={this.props.product.vip_percentage} distribution_percentage={this.props.product.distribution_percentage} price={this.state.bundle_selected.price} crossline_price={this.state.bundle_selected.crossline_price} current_customer={this.props.current_customer} left_stocks={left_stocks}/>
    } else {
      price_html = <VariantPriceLabel vip_percentage={this.props.product.vip_percentage} distribution_percentage={this.props.product.distribution_percentage} price={this.getPriceRange(this.filterVariant(this.props.product.variants))} vip_price={this.getVipPriceRange(this.filterVariant(this.props.product.variants))} crossline_price={this.getCrosslinePriceRange(this.filterVariant(this.props.product.variants))} original_price={this.getMaxOriginalPrice(this.props.product.variants)} current_customer={this.props.current_customer} left_stocks={left_stocks}/>
    }
    let product_spec = this.props.product.spec ? this.nl2br(this.props.product.spec) : null;

    let send_gifts = [];

    console.log("==== props in product_option.jsx:", this.props)
    //////// Only for JD!!!! ////////
    this.props.product.gifts.forEach(gc => { /// 多個促銷方案 /// 未考慮跨商品選購之贈品
      let target_item = this.props.cart.filter(item => this.props.product.variants_ids.includes(item.variant_id) && gc.restricted_variants.includes(item.variant_id));
      let target = (target_item || []).map(item => item.quantity).reduce((a, b) => a+b , 0);
      target = target===0 ? 1 : target

      if (gc.gift_type==="target_packs" && gc.is_multiple_add && !gc.is_multiple_send) { /////////// 滿件贈+累計累贈 ////////////
        let max_threshold = parseInt(gc.settings[gc.settings.length-1].threshold);
        let q = Math.floor(target/max_threshold); // 最高門檻數循環次數
        let r = target%max_threshold; // 循環後剩餘數
        if (q>0)
          send_gifts.push({
            campaign_token: gc.campaign_token,
            threshold: max_threshold,
            gift: gc.settings[gc.settings.length-1].gift,
            quantity: q
          });

        let target_thres_gifts = null;
        let thres = null;
        gc.settings.reverse().forEach((s) => {
          if (r >= s.threshold) {
            target_thres_gifts = s.gift;
          }
        })
        if (target_thres_gifts)
          send_gifts.push({
            campaign_token: gc.campaign_token,
            threshold: max_threshold,
            gift: gc.settings[gc.settings.length-1].gift,
            quantity: 1
          });
      }
      else if (gc.gift_type==="target_packs" && gc.is_multiple_add && gc.is_multiple_send) { /////////// 滿件贈+累計累贈 ////////////
        let max_threshold = parseInt(gc.settings[gc.settings.length-1].threshold);
        let target_thres_gifts=[];
        while(target >= 0) {
          let q=0;
          for (let i=0; i<gc.settings.length; ++i) {
            if (target >= parseInt(gc.settings[i].threshold)) {
              target_thres_gifts.push([gc.settings[i].threshold, gc.settings[i].gift])
            }
            else
              break;
          }
          target -= max_threshold;
        }
        Object.entries(this.mapping_id(target_thres_gifts)).forEach(([gift_id, threshold_gift_array]) => (
          send_gifts.push({
            campaign_token: gc.campaign_token,
            threshold: threshold_gift_array[0][0],
            gift: threshold_gift_array[0][1],
            quantity: threshold_gift_array.length
          })
        ))
      }
    })
    return (
      <div className="variant-wrapper" ref="variant_area">
        <h1 className="product-title" itemProp="name">{this.props.product.uid ? <span className="mr-1">{this.props.product.uid}</span> : null } {this.props.product.title} {preorder_text}</h1>
        {/* <div className="paid_much_alert text-right">
          <a href={`/products/${this.props.product.token}/paid_much_alert`} className="small text-link">賣貴通報</a>
        </div> */}
        {/* jdsgn 前台不顯示商品標籤
        <div className="tag-wrapper">
          {
            this.props.product.tags.map(tag =>{
              return <span className='tag tag-item'>{tag.title}</span>
            })
          }
        </div>
        */}
        {bundle_html}
        { target_variant ? (<VariantPriceLabel spec={product_spec} brands={this.props.product.brands} uid={this.props.product.uid} price_percentage={this.props.product.price_percentage} price={target_variant.price} crossline_price={target_variant.crossline_price} original_price={target_variant.original_price} vip_price={this.getVipPriceRange([target_variant])} current_customer={this.props.current_customer} left_stocks={left_stocks}/>
        ) : <VariantPriceLabel spec={product_spec} brands={this.props.product.brands} uid={this.props.product.uid} price_percentage={this.props.product.price_percentage} price={this.getPriceRange(this.filterVariant(this.props.product.variants))} vip_price={this.getVipPriceRange(this.filterVariant(this.props.product.variants))} original_price={this.getMaxOriginalPrice(this.props.product.variants)} crossline_price={this.getCrosslinePriceRange(this.filterVariant(this.props.product.variants))} current_customer={this.props.current_customer} left_stocks={left_stocks}/>}
        {/* {price_html} */}
        {sold_bar}
        {/* <div>
          <ul className='product-brand'>
            { this.props.product.brands.length > 0 ?
              <li>
                <b className="label">品牌: </b>
                <span className="text">
                  {
                    this.props.product.brands.map((brand) => {
                      return brand.title+' '
                    })
                  }
                </span>
              </li> : null
            }
            {this.props.product.uid ?
              <li>
                <b className="label">品番: </b>
                <span className="text">{this.props.product.uid}</span>
              </li>
              : null
            }
          </ul>
        </div> */}

        {/* target_variant && target_variant.sku ? <div className="product-sku" >{`商品貨號：${target_variant.sku}`}</div> : '' */}
        { target_variant && target_variant.stock <= 0 && this.props.shop_show_preorder && <div className="supplier_shipment mt-2">廠商出貨</div> }
        <div className="option-wrapper no-border">
          {/* Ｎ件商品樣式選取 */}
          {n_piece_area}
          {option_1_html}
          {option_2_html}
          {option_3_html}
          {
            this.props.product.product_size_card ?
            <div className="size-wrapper"><button className="guide-modal-btn btn btn-link " onClick={toggleGuide} type="button"><i className="fa fa-info-circle" aria-hidden="true"></i> {this.props.product.product_size_card.title}</button></div> : ''
          }
        </div>
        {add_cart_panel}
        {add_to_cart}
        {
          bundle_variant_html.length > 0 ?
          <div className="bundle-option-wrapper option-wrapper no-border">
            {
              bundleHasStock ?
              <h4 className="option-title-border"><span className="title">熱銷組合</span></h4>
              : null
            }
            { bundle_variant_html }
            { bundle_variant_html.length > ShowBundleAmount ?
              <div className="bundle_collapse-button"><button className={`btn btn-sm btn-block ${!this.state.bundle_collaspe? 'btn-gray' : 'btn-outline-secondary'}`} type='button' onClick={this.bundleCollapse}>{!this.state.bundle_collaspe ?  '顯示全部': '收合顯示'}</button></div>
              : null
            }
          </div>:null
        }

        {send_gifts.length > 0 && this.state.selected_gifts ?
          <div className="info-block mt-5">
            <h3 className="title mb-3">特別贈禮</h3>
            <p className="mb-3">購買<b className="text-dark px-2">{this.props.gift_campaigns[0].title}</b>1件即贈以下商品{send_gifts.length>1 ? "任選一件" : ""}</p>
            <div className="get_gift_content">
              {send_gifts.map((g, index) => (
                <TargetGift key={`${g.campaign_token}-${g.gift.id}`}
                  campaign_token={g.campaign_token}
                  quantity={g.quantity}
                  gift={g.gift}
                  index={index}
                  cart_token={this.props.cart_token}
                  shop_token={this.props.shop_token}
                  onUpdateCart={this.onUpdateCart}
                  onUpdateGifts={this.props.onUpdateGifts}
                  threshold={g.threshold}
                  // is_selected={true}
                  is_selected={this.state.selected_gifts[g.campaign_token] && this.state.selected_gifts[g.campaign_token][g.threshold] && this.state.selected_gifts[g.campaign_token][g.threshold].variant_id===g.gift.id}
                />
              ))}
            </div>
          </div> : null
        }
        {this.props.product.intro ?
          <div className="product-meta-wrapper pt-4">
            <h3 className="meta-title">商品簡述</h3>
            <div className="static-content">
              {this.props.product.intro}
            </div>
          </div> : null}
        {/* {variant_modal} */}

      </div>
    )
  }
}

class OptionArea extends React.Component {
  componentDidMount() {
    if (this.props.level != 1) {
      ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth', block: 'center' })
      // $('html, body').animate({scrollTop: ReactDOM.findDOMNode(this).offsetTop}, 500);
    }
  }
  render() {
    return <div className={'option-group' + (this.props.current_level ? ' select-variant-hint' : '') + (this.props.level == 1 ? ' first' : '')}>
      <p className={'option-title' + (this.props.select_variant_warning && this.props.current_level ? ' warning' : '')}>
        {this.props.option_title}</p>
      <div className="select-variant-hint-bg clearfix">
        {this.props.options}
      </div>
    </div>
  }
}

class VariantButton extends React.Component {
  constructor(props) {
    super(props)
    this.onSelected = this.onSelected.bind(this)
  }
  onSelected(option_type, title) {
    if (this.props.product_id && this.props.variant_id)
      this.props.onNPieceGroupOptionSelected(this.props.product_id, this.props.variant_id)
    else
      this.props.onOptionSelected(option_type, title)
  }
  render() {
    var clazz = 'btn btn-outline-default btn-option'
    if (this.props.stock_sum == null) {
      return null
    }
    if (this.props.is_selected) {
      clazz += ' active'
    }
    if (this.props.stock_sum <= 0) {
      if (this.props.allow_preorder) {
        return <a className={clazz} onClick={this.onSelected.bind(null, this.props.option_type, this.props.title)}>{this.props.title} ({i18n.t('cart.pre_order')})</a>
      } else {
        return <a className={clazz} disabled="disabled">{this.props.title} ({i18n.t('cart.sold_out')})</a>
      }
    }
    if(/^#[0-9A-F]{6}$/i.test(this.props.title.trim().substring(0,7))){
      return <a  className={`btn-outline-default btn-option colors-option ${this.props.is_selected ? 'selected': ''}`} onClick={this.onSelected.bind(null, this.props.option_type, this.props.title)}><span className='colors-wrapper'>
        <span className='color-button' style={{backgroundColor: this.props.title.trim().substring(0,7)}}>
        </span>
      </span>  {this.props.title.replace(/^#[0-9A-F]{6}/i, '').trim()}
      </a>
    }else{
      return <a className={clazz} onClick={this.onSelected.bind(null, this.props.option_type, this.props.title)}>{this.props.title}</a>
    }
  }
}

class BundleVariantButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      only_expandBundle: false
    }
    this.onSelected = this.onSelected.bind(this)
    this.handleExpand = this.handleExpand.bind(this)

  }
  onSelected() {
    this.props.onBundleVariantSelected(this.props.bundle)
  }
  handleExpand() {
    this.setState({ only_expandBundle: !this.state.only_expandBundle})
  }
  render() {
    var clazz = `btn btn-outline-default btn-bundle-option btn-block ${this.props.isShow ? '':'d-none'}`
    var content = ''
    if (this.state.only_expandBundle) {
      clazz += ' active'
      content = <ul className="bundle-content">
        {this.props.bundle.bundle_variants.map(bp => <li key={`${bp.title}x${bp.quantity}`}> <b>{bp.title}</b> X {bp.quantity}</li>)}
      </ul>
    }

    //庫存小於等於0
    if (this.props.bundle.stock <= 0) {
      //可接受預購
      if (this.props.bundle.allow_preorder) {
        return <div className={clazz} >
          <div className="bundle-variant-item preorder pr-sm-0">
            <div className="thumbnail-title-group d-flex align-items-center">
              <div className="thumbnail">
                {this.props.bundle.thumbnail ?
                  <img src={this.props.bundle.thumbnail} className="w-100" alt={this.props.bundle.title} />
                  :
                  <img className="img-fluid" src="/img/no-product-img.png" alt={this.props.bundle.title} itemProp="image" />
                }
              </div>
              <div className="bundle-variant-title"><div className="buy text-warning">購買</div> <strong> {this.props.bundle.title} (預購)</strong></div>
            </div>
            <div className="bundle-variant-price ml-md-auto">
              {this.props.bundle.original_price !== this.props.bundle.price ? <div className="original_price"><del className="price"><span className="currency">NT$</span>{this.props.bundle.original_price}</del></div> : null}
              <div className='selling_price'><span className="currency">NT$</span> <b className="price">{this.props.bundle.price}</b></div>
            </div>
            <a className='btn btn-warning btn-sm ml-auto ml-sm-2' onClick={this.onSelected} href="#0">購買</a>
            <div className='btn btn-sm btn-expand' onClick={this.handleExpand}><i className="icon icon-arrow-down"></i></div>
          </div>
          {content}
        </div>
      } else {
        return null
      }
    }
    else {
      return <div className={clazz} >
        <div className="bundle-variant-item pr-sm-0">
          <div className="thumbnail-title-group d-flex align-items-center">
            <div className="thumbnail">
            { this.props.bundle.thumbnail ?
              <img src={this.props.bundle.thumbnail} className="w-100" alt={this.props.bundle.title} />
              :
              <img className="img-fluid" src="/img/no-product-img.png" alt={this.props.bundle.title} itemProp="image" />
            }
            </div>
            <div className="bundle-variant-title"><div className="buy text-warning">購買</div> <strong> {this.props.bundle.title}</strong></div>
          </div>
          <div className="bundle-variant-price ml-md-auto">
            {this.props.bundle.original_price !== this.props.bundle.price ? <div className="original_price"><del className="price"><span className="currency">NT$</span>{this.props.bundle.original_price}</del></div> : null }
            <div className='selling_price'><span className="currency">NT$</span> <b className="price">{this.props.bundle.price}</b></div>
          </div>
          <a className='btn btn-warning btn-sm ml-auto ml-sm-2' onClick={this.onSelected} href="#0">購買</a>
          <div className='btn btn-sm btn-expand' onClick={this.handleExpand}><i className="icon icon-arrow-down"></i></div>
        </div>
        {content}
      </div>
    }
  }
}

// class VariantPriceLabel extends React.Component {
//   render() {
//     return (
//       <div className="product-price" itemProp="offers" itemScope itemType="http://schema.org/Offer">
//         {/* 一般顯示原價 */}
//         {this.props.crossline_price && this.props.crossline_price !== 0 ?
//           <div className="crossline-price">
//             <span className="small">原價: </span>
//             <del itemProp="crossline_price" content={this.props.crossline_price}><small className="currency mr-1" itemProp="priceCurrency" content="TWD">NT$</small>{this.props.crossline_price}</del>
//           </div> : null
//         }
//         {/* 業務顯示售價 */}
//         { (this.props.crossline_price === null || this.props.crossline_price === 0) && this.props.price_percentage < 100 ?
//           <div className="crossline-price">
//             <span className="small">原價: </span>
//             <del><small className="currency mr-1" itemProp="priceCurrency" content="TWD">NT$</small> {this.props.original_price}</del>
//           </div> : null
//         }
//         <h3 className="variant-price">
//           <span className="currency" itemProp="priceCurrency" content="TWD">NT$</span><span itemProp="price" content={this.props.price}>{this.props.price}</span>
//         </h3>

//       </div>
//     )
//   }
// }
class VariantPriceLabel extends React.Component {
  render() {
    return (
      <div className="product-price" itemProp="offers" itemScope itemType="http://schema.org/Offer">
        {this.props.spec ?
          <div>
            <div className="product-spec mb-3">
              <div dangerouslySetInnerHTML={{ __html: this.props.spec }}></div>
            </div>
            <ProductBrandsGroup key={this.props.uid} brands={this.props.brands} uid={this.props.uid} original_price={this.props.original_price} price={this.props.price} vip_price={this.props.vip_price} crossline_price={this.props.crossline_price} current_customer={this.props.current_customer} left_stocks={this.props.left_stocks}/>
          </div>
          :
          <ProductBrandsGroup key={this.props.uid} brands={this.props.brands} uid={this.props.uid} original_price={this.props.original_price} price={this.props.price} vip_price={this.props.vip_price} crossline_price={this.props.crossline_price} current_customer={this.props.current_customer} left_stocks={this.props.left_stocks}/>
        }
      </div>
    )
  }
}

class ProductBrandsGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vip_price: this.props.brands.find(brand => brand.id===97)===undefined ? this.props.vip_price : this.props.price
    }
  }

  render (){
    return (
      <div className="d-sm-flex align-items-end justify-content-between">
        { this.props.uid || (this.props.brands !== undefined && this.props.brands.length > 0) ?
          <ul className='product-brand'>
            {this.props.brands !== undefined && this.props.brands.length > 0 ?
              <li>
                <b className="label mr-2">品牌:</b>
                <span className="text">
                  {
                    this.props.brands.map((brand) => {
                      return (
                        <span key={brand.id}>
                          <a href={"/brands/"+brand.id}>
                            {brand.title + ' '}
                          </a>
                        </span>
                      )
                    })
                  }
                </span>
              </li> : null
            }
            {this.props.uid ?
              <li>
                <b className="label mr-2">品番:</b>
                <span className="text">{this.props.uid}</span>
              </li>
              : null
            }
          </ul> : null
        }
        <div className="product-price-group mt-2 mt-sm-0">
          {/* {
            this.props.current_customer===null
              ? <div className=" d-flex align-items-center">
                  <p className='small-text mr-2 mb-0'><i className="fa fa-info-circle mr-1"></i>登入會員可享<b>會員價</b></p>
                  <span className="currency ml-1" itemProp="priceCurrency" content="TWD">NT$</span>
                  <span className="vip-price" itemProp="price" content={this.state.vip_price}>{this.state.vip_price}</span>
                </div>
              : null
          } */}
          <div className="d-flex align-items-end">
            <h3 className={"sale-price mb-0 " + (this.props.brands.length > 0 || this.props.uid ? "ml-sm-auto" : null)}>
              {/* <span className="small-text mr-2">
                {this.props.current_customer ? '會員價' : '售價'}
              </span> */}
              <span className="currency" itemProp="priceCurrency" content="TWD">NT$</span>
              <span itemProp="price" content={this.props.price}>{this.props.price}</span>
            </h3>
            {/*{this.props.left_stocks > 0 ?
              <h3 className={"variant-price variant-stock ml-3"}>
                <span className="small-text mr-2">剩餘數量</span>
                <span>{this.props.left_stocks}</span>
              </h3> : null
            }*/}
            { this.props.crossline_price && this.props.crossline_price !== 0 && this.props.crossline_price !== this.props.price ?
              <div className="crossline-price ml-2">
                <del itemProp="crossline_price" content={this.props.crossline_price}><small className="currency mr-1" itemProp="priceCurrency" content="TWD">NT$</small>{this.props.crossline_price}</del>
              </div> : null
            }
          </div>


        </div>
      </div>
    )
  }
}

export default ProductOption