import React from 'react'
import htmlParser from 'html-react-parser'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class TextAndImage extends React.Component {

  convertStyle(text_element){
    let styles = text_element.styles ? JSON.parse(text_element.styles) : []
    let styles_string = ""
    for (const [key, value] of Object.entries(styles)) {
      let _key = (key == 'backgroundColor') ? 'background-color' : key
      styles_string += _key + ":" + value + ";"
    }
    styles=`${styles_string} ${text_element.background_photo_url ? "; background-image:url(" +text_element.background_photo_url+");  background-size: cover" : ''}`
    this.refs.textDiv.setAttribute('style', styles);
  }
}

class TextOnlyElement extends TextAndImage{
  componentDidMount(){
    this.convertStyle(this.props.layout.store_landing_elements[0])
  }
  render(){
    let layout = this.props.layout
    let text_element = layout.store_landing_elements[0] ? layout.store_landing_elements[0] : ""
    return <section className='ls-section section-limgrtxt' id={`lsElement-${layout.id}`}>
      <div className='container'>
        <div className='txtonly-content static-content' ref="textDiv">
          {htmlParser(text_element.content)}
        </div>
      </div>
    </section>
  }
}

class LeftImageRightTextElement extends TextAndImage{
  componentDidMount(){
    this.convertStyle(this.props.layout.store_landing_elements[1])
  }
  render(){
    let layout = this.props.layout
    let image_element = layout.store_landing_elements[0] ? layout.store_landing_elements[0] : ""
    let text_element = layout.store_landing_elements[1] ? layout.store_landing_elements[1] : ""
    return <section className='ls-section section-limgrtxt' id={`lsElement-${layout.id}`}>
      <div className='container'>
        <div className='row no-gutters'>
          <div className='col-sm-6 col-image'>
            {
              image_element
                ? <LazyLoadImage
                    height='100%'
                    width='100%'
                    wrapperClassName="img-fluid"
                    src={image_element.photo_url}
                  />
                : null
            }
          </div>
          <div className='col-sm-6 col-description' ref="textDiv">
            <div className='description-content' >
              {htmlParser(text_element.content)}
            </div>
          </div>
        </div>
      </div>
    </section>
  }
}

class RightImageLeftTextElement extends TextAndImage{
  componentDidMount(){
    this.convertStyle(this.props.layout.store_landing_elements[0])
  }
  render(){
    let layout = this.props.layout
    let image_element = layout.store_landing_elements[1] ? layout.store_landing_elements[1] : ""
    let text_element = layout.store_landing_elements[0] ? layout.store_landing_elements[0] : ""
    return <section className='ls-section section-rimgltxt' id={`lsElement-${layout.id}`}>
      <div className='container'>
        <div className='row no-gutters'>
          <div className='col-sm-6 col-description' ref="textDiv">
            <div className='description-content' >
              {htmlParser(text_element.content)}
            </div>
          </div>
          <div className='col-sm-6 col-image'>
            {
              image_element
                ? <LazyLoadImage
                    height='100%'
                    width='100%'
                    wrapperClassName="img-fluid"
                    src={image_element.photo_url}
                  />
                : null
            }
          </div>
        </div>
      </div>
    </section>
  }
}
export {TextAndImage, TextOnlyElement,LeftImageRightTextElement,RightImageLeftTextElement}