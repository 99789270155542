export function updateCartLink(cart) {
  let total = 0
  for (let i = 0; i < cart.length; i++) {
    // if (this.state.cart[i].target_price_gift_id === undefined)
    total += cart[i].quantity
  }
  const cart_links = document.getElementsByClassName('navbarCart')
  for(let i=0;i<cart_links.length;i++) {
    let cart_link = cart_links[i]
    const cartQuantity = cart_link.querySelectorAll('.badge')
    cart_link.classList.remove('animated');
    window.setTimeout((() => cart_link.classList.add('animated')), 300);
    cartQuantity[0].innerHTML = total
    if(total > 0) {
      cart_link.href = "/carts/new"
      cart_link.classList.remove('disabled');
      cartQuantity[0].classList.remove('empty');
    } else {
      cart_link.href = "#!"
      cart_link.classList.add('disabled');
      cartQuantity[0].classList.add('empty');
    }
  }
} 