// source: atom/member/first.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.pan.atom.member.Gender', null, global);
goog.exportSymbol('proto.pan.atom.member.Info', null, global);
goog.exportSymbol('proto.pan.atom.member.Object', null, global);
goog.exportSymbol('proto.pan.atom.member.Secure', null, global);
goog.exportSymbol('proto.pan.atom.member.Status', null, global);
goog.exportSymbol('proto.pan.atom.member.VirtualAddress', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.atom.member.Object = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.atom.member.Object, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.atom.member.Object.displayName = 'proto.pan.atom.member.Object';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.atom.member.Info = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pan.atom.member.Info.repeatedFields_, null);
};
goog.inherits(proto.pan.atom.member.Info, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.atom.member.Info.displayName = 'proto.pan.atom.member.Info';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.atom.member.VirtualAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.atom.member.VirtualAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.atom.member.VirtualAddress.displayName = 'proto.pan.atom.member.VirtualAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.atom.member.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.atom.member.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.atom.member.Status.displayName = 'proto.pan.atom.member.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pan.atom.member.Secure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pan.atom.member.Secure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pan.atom.member.Secure.displayName = 'proto.pan.atom.member.Secure';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.atom.member.Object.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.atom.member.Object.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.atom.member.Object} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.atom.member.Object.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    avatar: jspb.Message.getFieldWithDefault(msg, 4, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    groupName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    activateIp: jspb.Message.getFieldWithDefault(msg, 8, ""),
    activateDate: (f = msg.getActivateDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    phone: jspb.Message.getFieldWithDefault(msg, 10, ""),
    activate: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    displayName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 13, 0),
    vipExpireDate: (f = msg.getVipExpireDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.atom.member.Object}
 */
proto.pan.atom.member.Object.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.atom.member.Object;
  return proto.pan.atom.member.Object.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.atom.member.Object} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.atom.member.Object}
 */
proto.pan.atom.member.Object.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivateIp(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActivateDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActivate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 13:
      var value = /** @type {!proto.pan.atom.member.Gender} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setVipExpireDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.atom.member.Object.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.atom.member.Object.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.atom.member.Object} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.atom.member.Object.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getActivateIp();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getActivateDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getActivate();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getVipExpireDate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string object_id = 1;
 * @return {string}
 */
proto.pan.atom.member.Object.prototype.getObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.setObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 uid = 2;
 * @return {number}
 */
proto.pan.atom.member.Object.prototype.getUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.setUid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.pan.atom.member.Object.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string avatar = 4;
 * @return {string}
 */
proto.pan.atom.member.Object.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 group_id = 5;
 * @return {number}
 */
proto.pan.atom.member.Object.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string group_name = 6;
 * @return {string}
 */
proto.pan.atom.member.Object.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.pan.atom.member.Object.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string activate_ip = 8;
 * @return {string}
 */
proto.pan.atom.member.Object.prototype.getActivateIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.setActivateIp = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp activate_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pan.atom.member.Object.prototype.getActivateDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pan.atom.member.Object} returns this
*/
proto.pan.atom.member.Object.prototype.setActivateDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.clearActivateDate = function() {
  return this.setActivateDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pan.atom.member.Object.prototype.hasActivateDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string phone = 10;
 * @return {string}
 */
proto.pan.atom.member.Object.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool activate = 11;
 * @return {boolean}
 */
proto.pan.atom.member.Object.prototype.getActivate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.setActivate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string display_name = 12;
 * @return {string}
 */
proto.pan.atom.member.Object.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional Gender gender = 13;
 * @return {!proto.pan.atom.member.Gender}
 */
proto.pan.atom.member.Object.prototype.getGender = function() {
  return /** @type {!proto.pan.atom.member.Gender} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.pan.atom.member.Gender} value
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp vip_expire_date = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pan.atom.member.Object.prototype.getVipExpireDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pan.atom.member.Object} returns this
*/
proto.pan.atom.member.Object.prototype.setVipExpireDate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pan.atom.member.Object} returns this
 */
proto.pan.atom.member.Object.prototype.clearVipExpireDate = function() {
  return this.setVipExpireDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pan.atom.member.Object.prototype.hasVipExpireDate = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pan.atom.member.Info.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.atom.member.Info.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.atom.member.Info.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.atom.member.Info} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.atom.member.Info.toObject = function(includeInstance, msg) {
  var f, obj = {
    virtualAddrsList: jspb.Message.toObjectList(msg.getVirtualAddrsList(),
    proto.pan.atom.member.VirtualAddress.toObject, includeInstance),
    summary: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.atom.member.Info}
 */
proto.pan.atom.member.Info.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.atom.member.Info;
  return proto.pan.atom.member.Info.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.atom.member.Info} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.atom.member.Info}
 */
proto.pan.atom.member.Info.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pan.atom.member.VirtualAddress;
      reader.readMessage(value,proto.pan.atom.member.VirtualAddress.deserializeBinaryFromReader);
      msg.addVirtualAddrs(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.atom.member.Info.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.atom.member.Info.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.atom.member.Info} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.atom.member.Info.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVirtualAddrsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pan.atom.member.VirtualAddress.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated VirtualAddress virtual_addrs = 1;
 * @return {!Array<!proto.pan.atom.member.VirtualAddress>}
 */
proto.pan.atom.member.Info.prototype.getVirtualAddrsList = function() {
  return /** @type{!Array<!proto.pan.atom.member.VirtualAddress>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pan.atom.member.VirtualAddress, 1));
};


/**
 * @param {!Array<!proto.pan.atom.member.VirtualAddress>} value
 * @return {!proto.pan.atom.member.Info} returns this
*/
proto.pan.atom.member.Info.prototype.setVirtualAddrsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pan.atom.member.VirtualAddress=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pan.atom.member.VirtualAddress}
 */
proto.pan.atom.member.Info.prototype.addVirtualAddrs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pan.atom.member.VirtualAddress, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pan.atom.member.Info} returns this
 */
proto.pan.atom.member.Info.prototype.clearVirtualAddrsList = function() {
  return this.setVirtualAddrsList([]);
};


/**
 * optional string summary = 2;
 * @return {string}
 */
proto.pan.atom.member.Info.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.Info} returns this
 */
proto.pan.atom.member.Info.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.atom.member.VirtualAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.atom.member.VirtualAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.atom.member.VirtualAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.atom.member.VirtualAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    area: jspb.Message.getFieldWithDefault(msg, 1, ""),
    city: jspb.Message.getFieldWithDefault(msg, 2, ""),
    detail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    country: jspb.Message.getFieldWithDefault(msg, 4, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.atom.member.VirtualAddress}
 */
proto.pan.atom.member.VirtualAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.atom.member.VirtualAddress;
  return proto.pan.atom.member.VirtualAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.atom.member.VirtualAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.atom.member.VirtualAddress}
 */
proto.pan.atom.member.VirtualAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArea(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.atom.member.VirtualAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.atom.member.VirtualAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.atom.member.VirtualAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.atom.member.VirtualAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArea();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string area = 1;
 * @return {string}
 */
proto.pan.atom.member.VirtualAddress.prototype.getArea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.VirtualAddress} returns this
 */
proto.pan.atom.member.VirtualAddress.prototype.setArea = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city = 2;
 * @return {string}
 */
proto.pan.atom.member.VirtualAddress.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.VirtualAddress} returns this
 */
proto.pan.atom.member.VirtualAddress.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string detail = 3;
 * @return {string}
 */
proto.pan.atom.member.VirtualAddress.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.VirtualAddress} returns this
 */
proto.pan.atom.member.VirtualAddress.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country = 4;
 * @return {string}
 */
proto.pan.atom.member.VirtualAddress.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.VirtualAddress} returns this
 */
proto.pan.atom.member.VirtualAddress.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string zip = 5;
 * @return {string}
 */
proto.pan.atom.member.VirtualAddress.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.VirtualAddress} returns this
 */
proto.pan.atom.member.VirtualAddress.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.atom.member.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.atom.member.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.atom.member.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.atom.member.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.atom.member.Status}
 */
proto.pan.atom.member.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.atom.member.Status;
  return proto.pan.atom.member.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.atom.member.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.atom.member.Status}
 */
proto.pan.atom.member.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.atom.member.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.atom.member.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.atom.member.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.atom.member.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string group_name = 1;
 * @return {string}
 */
proto.pan.atom.member.Status.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.Status} returns this
 */
proto.pan.atom.member.Status.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pan.atom.member.Secure.prototype.toObject = function(opt_includeInstance) {
  return proto.pan.atom.member.Secure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pan.atom.member.Secure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.atom.member.Secure.toObject = function(includeInstance, msg) {
  var f, obj = {
    password: jspb.Message.getFieldWithDefault(msg, 1, ""),
    salt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expires: (f = msg.getExpires()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pan.atom.member.Secure}
 */
proto.pan.atom.member.Secure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pan.atom.member.Secure;
  return proto.pan.atom.member.Secure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pan.atom.member.Secure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pan.atom.member.Secure}
 */
proto.pan.atom.member.Secure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpires(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pan.atom.member.Secure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pan.atom.member.Secure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pan.atom.member.Secure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pan.atom.member.Secure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSalt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpires();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string password = 1;
 * @return {string}
 */
proto.pan.atom.member.Secure.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.Secure} returns this
 */
proto.pan.atom.member.Secure.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string salt = 2;
 * @return {string}
 */
proto.pan.atom.member.Secure.prototype.getSalt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pan.atom.member.Secure} returns this
 */
proto.pan.atom.member.Secure.prototype.setSalt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp expires = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pan.atom.member.Secure.prototype.getExpires = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.pan.atom.member.Secure} returns this
*/
proto.pan.atom.member.Secure.prototype.setExpires = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pan.atom.member.Secure} returns this
 */
proto.pan.atom.member.Secure.prototype.clearExpires = function() {
  return this.setExpires(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pan.atom.member.Secure.prototype.hasExpires = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.pan.atom.member.Gender = {
  GENDER_NOTSET: 0,
  MALE: 1,
  FEMALE: 2
};

goog.object.extend(exports, proto.pan.atom.member);
