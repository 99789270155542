import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Product extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      selectedVariantId: '',
    }
    this.sold_out = this.sold_out.bind(this)
    this.onClickAddToCart = this.onClickAddToCart.bind(this)
  }
  sold_out() {
    let sold_out = true
    this.props.product.variants.forEach(variant => sold_out &= variant.stock == 0)
    return sold_out
  }
  onClickAddToCart(){
    this.props.onCampaignAddToCart(this.state.selectedVariantId)
  }


  render() {
    let product_photo = this.props.product.photo.url != null ?
      <div className="product-img"><span>已售完</span>
        <LazyLoadImage
          height='100%'
          width='100%'
          wrapperClassName="img-fluid"
          src={this.props.product.photo.url} alt={this.props.product.title} /> </div>
      : <div className="product-img">
        <span>已售完</span>
        <LazyLoadImage
          height='100%'
          width='100%'
          wrapperClassName="img-fluid"
          src="/img/no-product-img.png"
          alt={this.props.product.title}
        />
      </div>
    return (
      <div className={"col-6 col-lg-4 col-xl-3 store-product"} >
        <div className={'collection-product-wrapper' + (this.sold_out() && !this.props.product.allow_preorder ? ' sold-out' : '')} >
          <figure className="collection-product">
            <div className="product-top">
              <a href={`/campaigns/${this.props.campaign.token}/products/${this.props.product.token}`}>
                <div className="badge-wrapper">
                  {this.props.product.end_sale_date ? <div className="badge sale">限時搶購</div> : ''}
                </div>
                {product_photo}
              </a>
            </div>
            <figcaption>
              <a href={`/campaigns/${this.props.campaign.token}/products/${this.props.product.token}`}>
                <h4 className="product-title">{this.props.product.title}</h4>
              </a>
              <div className="product-action mt-2">
                <select className='form-control' value={this.state.selectedVariantId} onChange={(e)=>this.setState({selectedVariantId: e.target.value})}>
                  <option value="" disabled='disabled'>請選擇樣式</option>
                  {
                    this.props.product.variants.map(variant=><option key={variant.id} value={variant.id}>{variant.title}</option>)
                  }
                </select>

                {
                  this.sold_out()
                    ? <a href="#0" className="btn add_to_cart disabled" onClick={null}><i className="icon icon-shopping-cart"></i><span className="text ml-1"><span className="d-none d-md-inline-block"></span>已售完 </span></a>
                    : <a href="#0" className="btn add_to_cart" onClick={this.onClickAddToCart}><i className="icon icon-shopping-cart"></i><span className="text ml-1"><span className="d-none d-md-inline-block">加入</span>購物車 </span></a>
                }
              </div>
            </figcaption>
          </figure>
        </div>
      </div >
    )
  }
}

export default Product