import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class TargetGift extends React.Component {
  constructor(props) {
    super(props)
    this.gift_checkbox = React.createRef();
    this.state =  {
      is_selected: this.props.is_selected
    }
    this.addGiftToCart = this.addGiftToCart.bind(this)
    this.onAddToCart = this.onAddToCart.bind(this)
  }
  addGiftToCart() {
    // let is_selected = event.target.checked;
    // this.setState({ is_selected: is_selected }, () => {
    //   if (this.state.is_selected)
    //     this.onAddToCart(true)
    //   else
    //     this.onAddToCart(false);
    // })
  }
  onAddToCart(is_add=true) {
    // let body = {
    //   variant_id: this.props.gift.id,
    //   threshold: this.props.threshold,
    //   quantity: is_add ? this.props.quantity : 0,
    //   gift_campaign: this.props.campaign_token,
    //   shop_token: this.props.shop_token,
    //   cart_token: this.props.cart_token,
    // }
    // let gift = (this.state.selected_gifts || []).find(g => g.variant_id===gift_id)
    // if (!!gift || this.props.quantity!==0) {
    //   fetch('/carts/modify.json',
    //   {
    //     method: 'POST',
    //     body: JSON.stringify(body),
    //     headers: {
    //       'X-CSRF-Token': this.props.authenticity_token,
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     },
    //     credentials: 'same-origin'
    //   }).then(res=>res.json()).then(data=>{
    //   })
    //   this.props.onUpdateGifts({
    //     campaign_token: this.props.campaign_token,
    //     threshold: this.props.threshold,
    //     variant_id: this.props.gift.id,
    //     quantity: is_add ? this.props.quantity : 0
    //   })
    // }
  }
  componentDidUpdate(prevProps){
    if (this.props.is_selected !== prevProps.is_selected)
      this.setState({ is_selected: this.props.is_selected });
  }
  render() {
    return (
      <>
        <div className="custom-control custom-checkbox gift_checkbox product_gift" >
          <input type="checkbox" className="custom-control-input" id={`free_gift_${this.props.gift.id}_${this.props.index}`}
            value={this.props.gift.id} onChange={this.addGiftToCart} key={Math.random()}
            checked={this.state.is_selected ? 'checked' : null} ref="gift_checkbox"
          />
          <label className="custom-control-label">
            <div className="thumbnail">
              <img className='w-100' src={this.props.gift.v_photo.thumbnail.url || '/img/no-thumb.png'} alt={this.props.gift.title} />
            </div>
            <div className='detail'>
              <h3 className="product-title">{this.props.gift.title}</h3>
              <p className="mb-0 quantity small text-muted">{`數量： ${this.props.quantity}`}</p>
            </div>

          </label>
        </div>
      </>
    )
  }
}

