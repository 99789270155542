import React from 'react'
import MemberLayout from './memberLayout'
import i18n from '../../components/i18next/i18n'

export default class MemberPromotionCode extends MemberLayout {
  render_inner() {
    let emptyContent = <div className="p-5 placeholder-image">
        <div className='icon'>
          <img src={this.props.placeholder_img_url} alt='no_promotion_code' className='w-100' />
        </div>
        <p className="mb-0 text-muted">{i18n.t('cart.no_promotion_code')}</p>
      </div>
    let promotionCodeContent = this.props.current_customer && this.props.my_promotion_codes.length !== 0 ? this.props.my_promotion_codes.map(
      (code, index) => {
        let codePrice, codeTime ;
        let codeBadge = [];
        // 顯示折扣類別
        switch (code.discount_type) {
          case "ratio":
            codePrice = (
              <div className="detail" key={code.code + "_ratio"}>
                <div className="precent_off">
                  <div className="percent">{code.discount}<small>%</small></div>
                  <div className="off">OFF</div>
                </div>
              </div>
            )
            break;
          case "fix_number":
            codePrice = (
              <div className="detail">
                <div className="price-discount">
                  <div className="price mb-1">{code.discount}</div>
                  <div className="text">折扣<span className="text-danger ml-1">NTD</span></div>
                </div>
              </div>
            )
            break;
          case "free_shipping":
            codePrice = (
              <div className="detail">
                <div className="price-discount"><div className="free-shopping">免運費</div></div>
              </div>
            )
            break;
          default:
            codePrice = (<div></div>)
            break;
        }
        // badge 顯示
        if (code.limit) {
          codeBadge.push(<span className='badge badge-danger mr-1' key={code.code + '_limit'}>限量</span>)
        }
        if (code.personal_limit) {
          codeBadge.push(<span className='badge badge-warning text-white mr-1' key={code.code + '_personal_limit'}>次數限制</span> )
        }
        if (code.end_date) {
          codeBadge.push(<span className='badge badge-success' key={code.code + '_time_limit'}>限時</span>)
        }
        // 顯示有效日期
        if (code.start_date || code.end_date) {
          codeTime = <li className="col-12">
            有效期間：
            {code.start_date ? code.start_date : '現在'}
            {code.end_date ? "~"+code.end_date : '以後'}
          </li>
        }
        // 顯示限定商品
        let limitProducts = ( code.products && code.products.length > 0 ) ? code.products.map(function (products, i) {
          return <a className="product_link" href={'/products/' + products.token} key={i}>{products.title}</a>;
          }
        ) : '全館商品'

        let codeUsed = (!code.is_all && code.is_public===null && code.personal_limit!==null)
          ? <div className="code-status d-flex d-md-block justify-content-between justify-content-lg-center align-items-center">
              <div className="sub_title small">剩餘</div>
              <div className="detail">
                <span className="fz-lg text-dark">
                  {this.props.personal_left.filter((item) => item.customer_id==this.props.current_customer.id && item.promotion_code_id==code.id)[0].personal_left}
                </span>
                <small className="ml-1">張</small>
              </div>
            </div>
          : <div className="code-status d-flex d-md-block justify-content-between justify-content-lg-center align-items-center">
              <div className="sub_title small">使用狀態</div>
              <div className="detail"><span className="text-dark">可使用</span></div>
            </div>
        return (
          <div className='order-detail-wrapper narrow-view order-history' key={code.code + index}>
            <div className='list-group list-group-flush promotion_code-group-item'>
              <div className="list-group-item d-flex flex-column flex-lg-row align-items-lg-center">
                <div className="code-price d-flex">
                  {codePrice}
                </div>
                <div className="code-head">
                  <div className="multiline-title mb-2 d-flex align-items-center">
                    <span className="mr-2">{code.title}</span>
                    {codeBadge}
                  </div>
                  <ul className="promotion_detail list-unstyled row mb-0">
                    <li className="col-12"> 適用訂單金額(以上)： {code.threshold}</li>
                    {codeTime}
                    <li className="col-12"> 代碼：<strong className="text-success">{code.code}</strong></li>
                    <li className="col-12">
                      適用商品：
                      {limitProducts}
                    </li>
                  </ul>
                </div>
                {codeUsed}
              </div>
            </div>
          </div>
        )
      }
    ) : emptyContent
    return (
      <>
        <div className="section-head-group d-flex align-items-center justify-content-center mb-3">
          <i className="mr-2" data-feather="tag"></i>
          <h1 className="section-subtitle mb-1 mb-md-0">{i18n.t("member.promotion_code")}</h1>
        </div>
        {promotionCodeContent}
      </>
    )
  }
}
