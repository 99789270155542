import React from 'react'
import ReactDOM from 'react-dom'
import CartTable from './cart_table'
import CheckoutForm from './checkout_form'
import i18n from '../../components/i18next/i18n'
import { updateCartLink } from './update_cart'

const TARGET_PRICE = "target_price";
const TARGET_PACKS = "target_packs";
class Checkout extends React.Component {
  constructor(props) {
    super(props)
    this.discount_price_input = React.createRef();

    let initShipping = {};
    let initPayment = {};
    if (this.props.shippings.length) {
      if (!!window.performance && window.performance.navigation.type === 2 && this.props.checkout_page !== "product" && (localStorage.getItem('shipping')=='twaddress' || localStorage.getItem('shipping')=='cvs')) {
        initShipping = this.props.shippings.find((_shipping) =>
          _shipping.shipping_id==localStorage.getItem('shipping_id'))
        localStorage.removeItem('shipping')
        localStorage.removeItem('shipping_id')
      }
      else {
        initShipping = {
          shipping_id: this.props.shippings[0].shipping_id,
          title: this.props.shippings[0].title,
          address_type: this.props.shippings[0].address_type,
          price: this.props.shippings[0].price,
          free_threshold: this.props.shippings[0].free_threshold
        }
      }
    }
    if (this.props.payments.length) {
      if (!!window.performance && window.performance.navigation.type === 2) {
        initPayment = this.props.payments.find((_payment) =>
          _payment.payment_id==localStorage.getItem('payment_id'))
        localStorage.removeItem('payment_gateway_code')
        localStorage.removeItem('payment_id')
      }
      else {
        initPayment = this.props.payments[0]
      }
    }
    this.state = {
      cart: this.props.cart_array,
      additional_purchases: this.props.additional_purchase_items.map(function (item) { item.quantity = 0; return item }),
      promotion_code: null,
      promotion_code_message: null,
      is_subscription: false,
      shipping: initShipping,
      money_input: 0,
      using_money: 0,
      // using_diamond: 0,
      // in_diamond_range: true
      payment: initPayment,

      voucher_input: 0,
      selected_gifts: this.props.gift_array||{},
      target_gift_campaigns: []
    }
    this.countCartItem = this.countCartItem.bind(this)
    this.getDiscounts = this.getDiscounts.bind(this)
    this.countShipping = this.countShipping.bind(this)
    this.countSubtotal = this.countSubtotal.bind(this)
    this.onShippingSelected = this.onShippingSelected.bind(this)
    this.onPaymentSelected = this.onPaymentSelected.bind(this)
    this.onCartChange = this.onCartChange.bind(this)
    this.onAdditionalPurchaseChange = this.onAdditionalPurchaseChange.bind(this)
    this.onPromotionCodeSet = this.onPromotionCodeSet.bind(this)
    this.freeShipping = this.freeShipping.bind(this)
    this.freeShippingBucket = this.freeShippingBucket.bind(this)
    this.onSubscriptionClicked = this.onSubscriptionClicked.bind(this)
    this.isInViewport = this.isInViewport.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    // this.onMoneySet = this.onMoneySet.bind(this)
    // this.usingDiamond = this.usingDiamond.bind(this)
    // this.totalDiscount = this.totalDiscount.bind(this)
    // this.onVoucherSet = this.onVoucherSet.bind(this)
    // this.maxUsableVoucher = this.maxUsableVoucher.bind(this)
    this.totalDiscount = this.totalDiscount.bind(this)
    // this.usingVoucher = this.usingVoucher.bind(this)
    this.onUpdateGifts = this.onUpdateGifts.bind(this)
    this.onUpdateGiftCampaigns = this.onUpdateGiftCampaigns.bind(this)
    this.countGifts = this.countGifts.bind(this)
    this.onAddGiftToCart = this.onAddGiftToCart.bind(this)
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {return};
  }
  onUpdateGifts(item, is_unmount) {
    if (this.props.onUpdateGifts)
      this.props.onUpdateGifts(item, is_unmount) // only for JD
    else {
      if (item.quantity!==0 && !!item.threshold) {
        this.state.selected_gifts[item.campaign_token] ||= {}
        this.state.selected_gifts[item.campaign_token][item.threshold] = {variant_id: item.variant_id, quantity: item.quantity};
      }
      else if (is_unmount && !!item.threshold) {
        this.state.selected_gifts[item.campaign_token] ||= {}
        this.state.selected_gifts[item.campaign_token][item.threshold] = {}
      }
      else
        this.state.selected_gifts[item.campaign_token] = {}
      this.setState({ selected_gifts: this.state.selected_gifts })
    }
  }

  onSubscriptionClicked(is_subscription) {
    this.setState({ is_subscription: is_subscription })
  }
  countCartItem() {
    var item_count = 0;
    $.each(this.state.cart, function (index, item) {
      item_count += item.quantity
    });
    return item_count
  }
  get_additional_shipping_fee() {
    let max = 0
    if (this.freeShipping()) {
      return 0;
    }
    for (let variant of this.state.cart) {
      if (parseInt(variant.additional_shipping_fee) > max && variant.quantity > 0) {
        max = parseInt(variant.additional_shipping_fee)
      }
    }
    return max
  }
  getDiscounts() {
    var discounts = [];
    var subtotal = this.countSubtotal().subtotal;

    if (this.props.target_price_discounts.length > 0) {
      let promotion_code_discount = this.state.promotion_code ? (this.state.promotion_code.discount_type ==='ratio' ? Math.round(subtotal*this.state.promotion_code.discount/100) : (this.state.promotion_code.discount_type ==='fix_number' ? this.state.promotion_code.discount : 0)) : 0
      let subscription_discount = this.state.is_subscription && this.props.subscriptions_discount > 0 ? (subtotal > 0 ? Math.round(subtotal*this.props.subscriptions_discount/100) : 0) : 0

      var _target_price_discount = null;
      for (var i = 0; i < this.props.target_price_discounts.length; i++) {
        if ((subtotal-promotion_code_discount-subscription_discount) >= this.props.target_price_discounts[i].threshold &&
          (_target_price_discount == null || _target_price_discount.threshold < this.props.target_price_discounts[i].threshold)) {
          _target_price_discount = this.props.target_price_discounts[i];
        }
      }
      if (_target_price_discount) {
        discounts.push({ title: _target_price_discount.title, amount: _target_price_discount.amount })
      }
    }
    if (this.state.is_subscription && this.props.subscriptions_discount > 0) {
      discounts.unshift({ title: '訂閱折扣', amount: subtotal > 0 ? Math.round(subtotal * (this.props.subscriptions_discount) / 100) : 0 })
    }
    return discounts;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cart_array !== this.props.cart_array) {
      this.setState({ cart: this.props.cart_array });
    }
    if (this.props.update_gifts) {
      this.onUpdateGiftCampaigns();
      this.props.setUpdateGifts(false);
    }
    if(prevProps.selected_gifts !== this.props.selected_gifts)
      this.setState({selected_gifts: this.props.selected_gifts})
  }

  freeShipping() {
    let is_free_shipping_promotion_code = null
    if(this.state.promotion_code){
      is_free_shipping_promotion_code = (this.state.promotion_code.discount_type == 'free_shipping')
    }
    return this.state.shipping.free_threshold != null && this.freeShippingBucket() >= this.state.shipping.free_threshold
        || this.countCartItem() == 0 || (is_free_shipping_promotion_code && this.countSubtotal().subtotal >= this.state.promotion_code.threshold)
  }
  freeShippingBucket() {
    let total_discount = this.totalDiscount()
    let count_sub_total = this.countSubtotal()
    let total_price_without_shipping = count_sub_total.subtotal - total_discount - count_sub_total.exclude_freeshipping_subtotal

    if (this.state.promotion_code) {
      if(this.state.promotion_code.discount_type == 'ratio'){
        total_price_without_shipping = (100-this.state.promotion_code.discount) * total_price_without_shipping / 100
      }else{
        total_price_without_shipping -= this.state.promotion_code.discount
      }
    }
    return total_price_without_shipping
  }
  countShipping() {
    return this.freeShipping() ? 0 : this.state.shipping.price;
  }
  countSubtotal() {
    var subtotal = 0;
    var bucket = {}
    let promotion_product_subtotal = 0
    let exclude_freeshipping_subtotal = 0

    $.each(this.state.cart, function (index, item) {
      if (!bucket[item.product_id]) {
        bucket[item.product_id] = { quantity: item.quantity, bundle_threshold: item.product_bundle_threshold, bundle_price: item.product_bundle_price,exclude_free_shipping:item.exclude_free_shipping }
      } else {
        bucket[item.product_id].quantity += item.quantity
      }
    });
    for (let item of this.state.cart) {
      if (!(bucket[item.product_id].bundle_threshold > 0 && bucket[item.product_id].quantity >= bucket[item.product_id].bundle_threshold)) {
        subtotal += item.price * item.quantity
        if(item.exclude_free_shipping){
          exclude_freeshipping_subtotal += item.price * item.quantity
        }
        if (this.state.promotion_code &&
          this.state.promotion_code.discount_type == 'ratio' &&
          this.state.promotion_code.product_ids.length > 0 &&
          this.state.promotion_code.product_ids.includes(item.product_id)
        ) {
          promotion_product_subtotal += item.price * item.quantity
        }
      }
    }
    for (let product in bucket) {
      if (bucket[product].bundle_threshold > 0 && bucket[product].quantity >= bucket[product].bundle_threshold) {
        let _price = Math.floor(bucket[product].quantity / bucket[product].bundle_threshold) * bucket[product].bundle_price +
          (bucket[product].quantity % bucket[product].bundle_threshold) * Math.ceil(bucket[product].bundle_price / bucket[product].bundle_threshold)
        subtotal += _price
        if(bucket[product].exclude_free_shipping){
          exclude_freeshipping_subtotal += _price
        }
        if (this.state.promotion_code &&
          this.state.promotion_code.discount_type == 'ratio' &&
          this.state.promotion_code.product_ids.length > 0 &&
          this.state.promotion_code.product_ids.includes(parseInt(product))
        ) {
          promotion_product_subtotal += _price
        }
      }
    }
    $.each(this.props.additional_purchase_items, function (index, item) {
      subtotal += item.price * item.quantity;
      if(item.exclude_free_shipping){
        exclude_freeshipping_subtotal += item.price * item.quantity;
      }
    });
    return {subtotal:subtotal,promotion_product_subtotal: promotion_product_subtotal,exclude_freeshipping_subtotal: exclude_freeshipping_subtotal};
  }
  onShippingSelected(shipping) {
    this.setState({ shipping: shipping }, () => {
      localStorage.setItem('shipping', shipping.address_type);
      localStorage.setItem('shipping_id', shipping.shipping_id);
    });
  }
  isInViewport(elem) {
    var bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };
  handleScroll(offset,btn){
    if ((window.scrollY > offset)) {
      btn.classList.add('d-none')
    } else {
      btn.classList.remove('d-none')
    }
  }
  onPaymentSelected(event) {
    let payment = this.props.payments.find(_payment => _payment.payment_id===parseInt(event.target.value))
    this.setState({ payment: payment }, () => {
      console.log(this.state.payment)
      localStorage.setItem('payment_gateway_code', payment.gateway_code);
      localStorage.setItem('payment_id', payment.payment_id);
    });
  }

  componentDidMount() {
    let self = this;
    //觀察購物車表單是否在視窗內
    const addToCartBtnGroup = document.querySelector('#addToCartBtnGroup')
    if(addToCartBtnGroup){
      var observer = new IntersectionObserver(entries => {
        entries.forEach(i => {
          if (i.isIntersecting) {
            addToCartBtnGroup.classList.add('cartform-insection')
          } else {
            addToCartBtnGroup.classList.remove('cartform-insection')
          }
        });
      }, {});
      observer.observe(ReactDOM.findDOMNode(self))
    }

    const items = JSON.parse(sessionStorage.getItem('additional_purchases')||'[]')
    for(let item of items)
      this.onAdditionalPurchaseChange(item.item_id, item.quantity || 0)

    this.onUpdateGiftCampaigns();
  }
  async onUpdateGiftCampaigns() {
    let target_gift_campaigns = await this.countGifts();
    this.setState({ 
      target_gift_campaigns: target_gift_campaigns
    });
  }
  mapping_threshold(original_setting) {
    let group = original_setting.reduce((previousValue, currentValue) => {
      previousValue[currentValue.threshold] = [...previousValue[currentValue.threshold] || [], currentValue]
      return previousValue;
    }, {});
    return group;
  }

  promotionCodeDiscount() {
    let subtotal = this.countSubtotal().subtotal;
    if (subtotal >= (this.state.promotion_code?.threshold || 0)) {
      if (this.state.promotion_code?.discount_type == "fix_number")
        return this.state.promotion_code?.discount;
      else if (this.state.promotion_code?.discount_type == "ratio")
        return Math.round(subtotal*this.state.promotion_code?.discount/100);
      else
        return 0;
    }
    else
      return 0;
  }
  countEachItemPrice() {
    return new Promise(async resolve => {
      let subtotal = this.countSubtotal().subtotal
      let minus_price = this.totalDiscount() + this.promotionCodeDiscount()
      if (minus_price > subtotal)
        minus_price = subtotal;

      let sub_minus_price = 0;
      // 考慮單品
      var bucket = {}
      $.each(this.state.cart, function (index, item) {
        if (!bucket[item.product_id]) {
          bucket[item.product_id] = { quantity: item.quantity, bundle_threshold: item.product_bundle_threshold, bundle_price: item.product_bundle_price,exclude_free_shipping:item.exclude_free_shipping }
        } else {
          bucket[item.product_id].quantity += item.quantity
        }
      });

      let bundle_product_id = []; // N件M元
      let _price = [];
      this.state.cart.filter(item => !item.bundle_id).forEach(item => {
        let product = item.product_id;
        if (!bundle_product_id.includes(product)) { // 已經考慮過這個商品
          let item_single_price
          let item_quantity
          // 考慮N件M元
          if (!!item.product_bundle_threshold && bucket[product].bundle_threshold > 0 && bucket[product].quantity >= bucket[product].bundle_threshold) {
            bundle_product_id.push(product)
            item_single_price = bucket[product].bundle_price/bucket[product].bundle_threshold;
            item_quantity = bucket[product].quantity;
          }
          else {
            item_single_price = item.price;
            item_quantity = item.quantity;
          }

          // 考慮負項
          let item_sub_minus_price = Math.floor(item_single_price*item_quantity*minus_price/subtotal);
          sub_minus_price += item_sub_minus_price
          _price.push({variant_id: item.variant_id, total_price: item_single_price*item_quantity - item_sub_minus_price});
        }
      })

      // 考慮加價購
      this.state.additional_purchases.forEach(item => {
        if (item.price*item.quantity !== 0) {
          let item_sub_minus_price = Math.floor(item.price*item.quantity*minus_price/subtotal);
          sub_minus_price += item_sub_minus_price;
          _price.push({variant_id: item.id, total_price: item.price*item.quantity - item_sub_minus_price});
        }
      });

      this.state.cart.filter(item => !!item.bundle_id).forEach(item => {
        // 考慮組合商品 // 因為後台 variants_array 和 bundles_array 分開算
        let bundle_variants_total_sale_price = item.bundle_variants.map(bundle_variant => bundle_variant.price*bundle_variant.quantity*item.quantity).reduce((a, b) => a+b , 0);

        // 組合商品的負項
        let item_sub_minus_price = Math.floor(item.price*item.quantity*minus_price/subtotal);
        sub_minus_price += item_sub_minus_price;
        // 組合商品扣除負項後的價錢
        let _bundle_price = [];
        let _total_sub_bundle_price = 0;
        
        let bundle_price = item.price*item.quantity - item_sub_minus_price;
        item.bundle_variants.forEach(bundle_variant => {
          let bundle_variant_price = Math.floor(bundle_price * bundle_variant.price*bundle_variant.quantity*item.quantity/bundle_variants_total_sale_price);
          _total_sub_bundle_price += bundle_variant_price;
          _bundle_price.push({bundle_id :item.bundle_id, variant_id: bundle_variant.variant_id, total_price: bundle_variant_price});
        })
        _bundle_price.forEach((_sub_bundle_price, index) => { // 解決小數點誤差
          if (_total_sub_bundle_price < bundle_price) {
            _bundle_price[index].total_price = _sub_bundle_price.total_price+1 // 補足組合商品差額
            _total_sub_bundle_price += 1;
          }
        })
        _price.push(..._bundle_price);
      });

      _price.forEach((_sub_price, index) => {
        if (sub_minus_price < minus_price) {
          _price[index].total_price = _sub_price.total_price-1 // 補足負項差額
          sub_minus_price += 1;
        }
      })
      resolve(_price)
    });
  }
  countEachItemPacks(gc) {
    return new Promise(async resolve => {
      // 考慮單品
      let target_item = this.state.cart.filter(item => gc.all_variants_ids.includes(item.variant_id));

      // 考慮加價購
      this.state.additional_purchases.forEach(item => {
        if (item.quantity!==0 && gc.all_variants_ids.includes(item.id))
          target_item.push({variant_id: item.id, quantity: item.quantity});
      });

      // 考慮組合商品
      this.state.cart.filter(item => !!item.bundle_id).forEach(item => {
        item.bundle_variants.forEach(variant => {
          if (gc.all_variants_ids.includes(variant.variant_id))
            target_item.push({bundle_id: item.bundle_id, variant_id: variant.variant_id, quantity: item.quantity*variant.quantity})
        });
      })
      resolve(target_item)
    });
  }
  countGifts() {
    return new Promise(resolve => {
      let gift_campaigns = [];
      this.props.gift_campaigns.forEach(async gc => { /// 多個促銷方案
        let setting = this.mapping_threshold(gc.gift_campaign_settings);
        let min_threshold = parseInt(Object.keys(setting)[0]);

        let target = 0;
        if (gc.gift_type === TARGET_PRICE) {
          let _price = await this.countEachItemPrice();
          console.log(_price)
          _price.forEach(_sub_price => {
            if (gc.all_variants_ids.includes(_sub_price.variant_id))
              target += _sub_price.total_price;
          })
        }
        else if (gc.gift_type === TARGET_PACKS) {
          let target_item = await this.countEachItemPacks(gc);
          console.log(target_item)
          target = (target_item || []).map(item => item.quantity).reduce((a, b) => a+b , 0);
        }
        console.log(gc.title, target, min_threshold)

        if (target >= min_threshold) {
          gift_campaigns.push( {
            gift_campaign: gc,
            setting: setting,
            target: target
          });
        }
        else {
          this.onAddGiftToCart(null, 0, gc.token);
        }
      })
      resolve(gift_campaigns);
    })
  }
  onAddGiftToCart(gift_id, quantity, campaign_token, threshold, is_unmount=false) {
    let selected_gifts = this.state.selected_gifts[campaign_token]||{}
    let selected_gift = selected_gifts ? selected_gifts[threshold] : {}
    if (quantity!==0 || (!!selected_gift && selected_gift.variant_id===gift_id) || gift_id==null) {
      let body = { 
        variant_id: gift_id,
        quantity: quantity,
        gift_campaign: campaign_token,
        threshold: threshold,
        shop_token: this.props.shop_token,
        cart_token: this.props.cart_token,
      }
      fetch('/carts/modify.json',
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      }).then(res=>res.json()).then(data=>{
      })
      this.onUpdateGifts({
        campaign_token: campaign_token,
        threshold: threshold,
        variant_id: gift_id,
        quantity: quantity
      }, is_unmount)
    }
  }
  onCartChange(cart) {
    let valid_additional_purchase = false
    if (this.props.onCartChange) {
      this.props.onCartChange(cart);
    }
    else {
      this.setState({cart: cart},
        () => updateCartLink(this.state.cart))
    }
    for (let item of this.state.additional_purchases) {
      valid_additional_purchase = false
      for (let variant of this.state.cart) {
        if (variant.quantity > 0 && (item.variants.includes(variant.variant_id) || item.additonal_purchase_accept_all) && item.stock > 0) {
          valid_additional_purchase = true
          break
        }
      }
      if (!valid_additional_purchase) {
        this.onAdditionalPurchaseChange(item.id, 0)
      }
    }
    if (this.state.promotion_code && this.state.promotion_code.product_ids.length > 0) {
      if (!this.state.promotion_code.product_ids.some(e => cart.map(i => i.quantity > 0 ? i.product_id : null).includes(e))) {
        this.setState({ promotion_code: null, promotion_code_message: null })
      }
    }
    this.setState({ cart: cart })
  }
  onAdditionalPurchaseChange(item_id, quantity) {
    let additional_purchases = this.state.additional_purchases
    for (const item of additional_purchases) {
      if (item.id == item_id) {
        item.quantity = quantity
        break
      }
    }
    this.setState({ additional_purchases: additional_purchases },()=>{
      let additional_purchases_json = []
      const existedPurchases = JSON.parse(sessionStorage.getItem('additional_purchases')||'[]')
      const findQty = (obj) => existedPurchases?.find(e => e.item_id == obj.id)?.quantity
      additional_purchases.forEach(function (obj) {
        let newObj = {
            'item_id': obj.id,
            'quantity': obj.quantity// === 0 ? findQty(obj) : obj.quantity
        };
        additional_purchases_json.push(newObj)
      });
      sessionStorage.setItem('additional_purchases', JSON.stringify(additional_purchases_json));
    })
    this.onUpdateGiftCampaigns()
  }
  onPromotionCodeSet(code,message) {
    this.setState({ promotion_code: code })
    console.log(code)
    console.log(message)
    if (code) {
      this.setState({
        promotion_code_message: <span className="text-success small">{i18n.t("cart.promocode_successfully")+ "(" + code.code + ")"}</span> })
    } else {
      this.setState({ promotion_code_message: <span className="text-danger small">{message || i18n.t("cart.no_such_code")}</span> })
    }
  }
  // onMoneySet(e) {
  //   this.setState({ money_input: e.currentTarget.value }, () => {
  //     let using_diamond = this.state.money_input*this.props.discount_ratio
  //     let total_price = this.countSubtotal().subtotal + this.countShipping() + this.get_additional_shipping_fee() - this.totalDiscount()
    
  //     if (using_diamond <= this.props.diamonds && using_diamond >= 0 && this.state.money_input <= total_price) {
  //       this.setState({ using_money: parseInt(this.state.money_input || 0), in_diamond_range: true }, 
  //         () => this.usingDiamond());
  //       this.discount_price_input.current.style.color = 'black';
  //     }
  //     else {
  //       this.setState({ using_money: parseInt(Math.min(total_price, Math.floor(this.props.diamonds/this.props.discount_ratio))), in_diamond_range: false },
  //         () => this.usingDiamond());
  //       this.discount_price_input.current.style.color = 'red';
  //     }
  //   });
  // } 
  // usingDiamond() {
  //   this.setState({ using_diamond: this.state.using_money*this.props.discount_ratio});
  // }

  totalDiscount(){
    let total_discount = 0
    for (let discount of this.getDiscounts()) {
      total_discount += discount.amount
    }
    return total_discount
  }
  render() {
    var source_input;
    var notify_input;
    console.log("CHECKOUT!")
    if (this.props.source) {
      source_input = <input name="source" type="hidden" value={this.props.source} />
    }
    if (this.props.email_notify) {
      notify_input = <input name="email_notify" type="hidden" value={this.props.email_notify} />
    }
    return (
      <div>
        <form id="cart-form" className="lsCheckoutContainer" acceptCharset="UTF-8" method="post">
          <div className="row">
            <input type="hidden" name="utf8" value="&#x2713;" />
            <CartTable cart={this.state.cart}
              shop_show_preorder={this.props.shop_show_preorder}
              subtotal={this.countSubtotal().subtotal}
              promotion_product_subtotal={this.countSubtotal().promotion_product_subtotal}
              discounts={this.getDiscounts()}
              shipping_fee={this.countShipping()}
              additional_shipping_fee={this.get_additional_shipping_fee()}
              shipping={this.state.shipping}
              onCartChange={this.onCartChange}
              onPromotionCodeSet={this.onPromotionCodeSet}
              shop_token={this.props.shop_token}
              authenticity_token={this.props.authenticity_token}
              cart_token={this.props.cart_token}
              agent_token={this.props.agent_token}
              promotion_code={this.state.promotion_code}
              promotion_code_message={this.state.promotion_code_message}
              my_promotion_codes={this.props.my_promotion_codes}
              additional_purchase_items={this.state.additional_purchases}
              onAdditionalPurchaseChange={this.onAdditionalPurchaseChange}
              promotion_code_available={this.props.promotion_code_available}
              target_price_discounts={this.props.target_price_discounts}
              // diamonds={this.props.diamonds}
              discount_ratio={this.props.discount_ratio}
              // onMoneySet={this.onMoneySet}
              // using_diamond={this.state.using_diamond}
              // money_input={this.state.money_input}
              // using_money={this.state.using_money}
              discountRef={this.discount_price_input}
              // in_diamond_range={this.state.in_diamond_range}
              // maxMoney={Math.floor(this.props.diamonds/this.props.discount_ratio)}
              current_customer={this.props.current_customer}
              // voucher_remain={this.props.voucher_remain}
              // voucher_rule={this.props.voucher_rule}
              // onVoucherSet={this.onVoucherSet}
              // usingVoucher={this.usingVoucher()}
              // maxUsableVoucher={this.maxUsableVoucher()}
              gift_campaigns={this.props.gift_campaigns}
              cart_info={this.props.cart_info}
              selected_gifts={this.state.selected_gifts}
              target_gift_campaigns={this.state.target_gift_campaigns}
              onUpdateGiftCampaigns={this.onUpdateGiftCampaigns}
              onAddGiftToCart={this.onAddGiftToCart}
              is_unmount={this.props.is_unmount}
              ref="cart_table" />
            <CheckoutForm authenticity_token={this.props.authenticity_token}
              restricted_address={this.props.restricted_address}
              onShippingSelected={this.onShippingSelected}
              onPaymentSelected={this.onPaymentSelected}
              shipping={this.state.shipping}
              cart={this.state.cart}
              cvs_info={this.props.cvs_info}
              cart_info={this.props.cart_info}
              invoice_enabled={this.props.invoice_enabled}
              promotion_code={this.state.promotion_code}
              customer={this.props.current_customer}
              subscriptions_enabled={this.props.subscriptions_enabled}
              subscriptions_credit_enabled={this.props.subscriptions_credit_enabled}
              subscriptions_cod_enabled={this.props.subscriptions_cod_enabled}
              subscriptions_periods={this.props.subscriptions_periods}
              subscriptions_interval={this.props.subscriptions_interval}
              subscriptions_discount={this.props.subscriptions_discount}
              onSubscriptionClicked={this.onSubscriptionClicked}
              is_subscription={this.state.is_subscription}
              customized_inputs={this.props.customized_inputs}
              additional_purchase_items={this.state.additional_purchases}
              gift_items={this.state.selected_gifts}
              payments={this.props.payments}
              payment={this.state.payment}
              total_price={this.countSubtotal().subtotal + this.countShipping() + this.get_additional_shipping_fee() - this.totalDiscount()}
              shippings={this.props.shippings}
              agent_token={this.props.agent_token}
              current_shipping={this.state.shipping}
              using_money={this.state.using_money}
              selectedShipping={this.state.shipping || this.props.shippings[0]}
              // usingVoucher={this.usingVoucher()}
              preview={this.props.preview}
              ref="checkout_form" />
            <input type="hidden" name="shop_token" value={this.props.shop_token} />
            <input type="hidden" name="cart_token" value={this.props.cart_token} />
            {source_input}
            {notify_input}
          </div>
        </form>
        <div id="liteshop-next-step-form-area" style={{ display: 'none' }}></div>
        <div id="cvs-select-form" style={{ display: 'none' }}></div>
      </div>
    )
  }
}
export default Checkout