import React from 'react'

import * as pan from '../jkf/general/member/first_pb'

import { MemberServicePromiseClient } from '../jkf/general/member/first_grpc_web_pb'
const panService = new MemberServicePromiseClient('https://jkf.hare200.com/gapi')


const SIGNIN = 'signin'
const REGISTER = 'register'
const VALIDATE = 'validate'
export default class SignIn extends React.Component {

  constructor(props) {
    super(props)
    this.goToNextInput = this.goToNextInput.bind(this)
    this.onSmsKeyDown = this.onSmsKeyDown.bind(this)
    this.onSmsFocus = this.onSmsFocus.bind(this)
    this.signStep = this.signStep.bind(this)
    this.onSmsSent = this.onSmsSent.bind(this)
    this.startCount = this.startCount.bind(this)
    this.checkExistence = this.checkExistence.bind(this)
    this.signInWithUsername = this.signInWithUsername.bind(this)
    this.goPhase = this.goPhase.bind(this)
    this.verifyAquiredPhone = this.verifyAquiredPhone.bind(this)
    this.previousStep = this.previousStep.bind(this)
    this.stepView = this.stepView.bind(this)
    this.resetTelErrMsg = this.resetTelErrMsg.bind(this)
    this.isValidPhone = this.isValidPhone.bind(this)
    this.verifyPin = this.verifyPin.bind(this)
    this.updateName = this.updateName.bind(this)
    this.doneLogin = this.doneLogin.bind(this)
    this.resetFlow = this.resetFlow.bind(this)
    this.confirmSMS = this.confirmSMS.bind(this)
    this.getMe = this.getMe.bind(this)
    this.resetCountDown = this.resetCountDown.bind(this)
    this.switchVerifyBtns = this.switchVerifyBtns.bind(this)
    this.showSmsErrorMsg = this.showSmsErrorMsg.bind(this)
    this.expCallback = this.expCallback.bind(this)
    this.state = {
      session_token: '',
      currentStepID: 'inputPhone',
      currentFlow: SIGNIN,
      phone: '',
      modalTitle: '登入',
      feedbackID: '',
      feedbackMessage: '',
      resetGrecaptcha: false,
      renderGrecaptcha: false,
      telErrorMsg: '',
      telIsValid: false,
      telErrorMap: ["不符合手機號碼格式", "請輸入手機國碼", "輸入號碼太短", "輸入號碼太長", "不符合手機號碼格式"],
      smsVerifyMsg: '',
      userAccoutInput: {
        inValid: false
      },
      verifyNameMsg: '',
      agreeTerms: true
    }
  }
  resetFlow(){
    this.setState({
      currentStepID: 'inputPhone',
      currentFlow: SIGNIN,
      session_token: '',
      feedbackMessage: '',
      phone: '',
      smsVerifyMsg: '',
      userAccoutInput: {
        inValid: false
      },
      verifyNameMsg: ''
    })
    if (this.state.resetGrecaptcha) {
      grecaptcha.reset()
      this.setState({ resetGrecaptcha: false})
    }
    this.resetTelErrMsg();
    console.log("resetFlow");
  }
  componentDidMount() {
    let _this = this
    $('#signInModal').on('shown.bs.modal', function () {
      _this.setState({ renderGrecaptcha: true })
    })
  }
  componentDidUpdate() {
    var phoneInput = document.querySelector("#phoneInput");
    if(phoneInput && !this.intlTel ){
      this.setState({ telIsValid: false })
      this.intlTel = window.intlTelInput(phoneInput, {
        initialCountry: 'tw',
        preferredCountries: ['tw', 'us'],
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js",
      });
      window.intlTel = this.intlTel
      phoneInput.addEventListener('keyup', this.isValidPhone);
    }else if(!phoneInput){
      this.intlTel = null
    }
    var smsbox = $('#smsVerifycontainer');
    if (smsbox) {
      smsbox.on('keyup', 'input', this.goToNextInput);
      smsbox.on('keydown', 'input', this.onSmsKeyDown);
      smsbox.on('click', 'input', this.onSmsFocus);
    }
    try{
      if (document.getElementsByClassName('g-recaptcha')[0]) {
        grecaptcha.render(document.getElementsByClassName('g-recaptcha')[0], { 'expired-callback': this.expCallback})
      }
    }catch(e){
      console.log(e);
    }
  }
  expCallback(){
    grecaptcha.reset();
  }
  resetTelErrMsg() {
    this.setState({ telErrorMsg: '', telIsValid: false })
    console.log("resetTelErrMsg");
    let phoneInput = document.querySelector("#phoneInput") ,
        validMsg = document.querySelector('#validMsg')
    if (phoneInput) {
      phoneInput.classList.remove("is-valid", "is-invalid")
    }
    if (validMsg) {
      validMsg.classList.remove("d-block")
    }
  }
  isValidPhone(){
    let validMsg = document.querySelector('#validMsg')
    this.resetTelErrMsg();
    if (phoneInput.value.trim()) {
      if (this.intlTel.isValidNumber()) {
        phoneInput.classList.remove("is-invalid");
        phoneInput.classList.add("is-valid");
        validMsg.classList.remove("d-block", "invalid-feedback");
        validMsg.classList.add('d-block', 'valid-feedback');
        this.setState({ telErrorMsg: '符合手機號碼格式', telIsValid: true })
      } else {
        phoneInput.classList.remove("is-valid");
        phoneInput.classList.add("is-invalid");
        validMsg.classList.remove("d-block", "valid-feedback");
        validMsg.classList.add('d-block', 'invalid-feedback');
        let errorCode = this.intlTel.getValidationError();
        this.setState({ telErrorMsg: this.state.telErrorMap[errorCode], telIsValid: false })
      }
    }
  }
  signInWithUsername() {
    let loginRequestSignInWithPassword = new pan.LoginRequest()
    loginRequestSignInWithPassword.setPhase(pan.LoginRequest.Phase.PHASE_INPUT_CODE)
    loginRequestSignInWithPassword.setPhaseSessionToken(this.state.session_token)
    loginRequestSignInWithPassword.setPhaseInputCodeValue(document.getElementsByName('jkf_password')[0].value)
    if (this.state.has_secure_question) {
      loginRequestSignInWithPassword.setPhaseInputCodeQuesIndex(document.getElementsByName('jkf_security_question_index')[0].value)
      loginRequestSignInWithPassword.setPhaseInputCodeQuesValue(document.getElementsByName('jkf_security_question_answer')[0].value)
    }
    panService.login(loginRequestSignInWithPassword).then(e => {
      window.p2 = e
      this.doneLogin()
    }).catch(r => {
      // SKY - TODO: Show Error
      this.setState({ feedbackID: 'forgetPassword', feedbackMessage: '登入失敗' })
      let error_code = r.code
      console.log(error_code)
      grecaptcha.reset()
    })
  }

  getMe(){
    let accessTokenRequest = new pan.AccessTokenRequest()
    accessTokenRequest.setAuthToken(this.state.auth_token)
    console.log(this.state.auth_token)
    panService.accessToken(accessTokenRequest).then(accessTokenResponse => {
      window.accessTokenResponse = accessTokenResponse
      let accessToken = accessTokenResponse.getAccessToken()
      console.log(accessToken)
      this.setState({ access_token: accessToken })
      let meRequest = new pan.MeRequest()
      meRequest.setAccessToken(accessToken)
      panService.me(meRequest).then(meResponse => {
        window.meResponse = meResponse
        this.setState({display_name: meResponse.getUser().getDisplayName()})
        if (meResponse.getUser().getPhone()) {
          createCookie('jkf-login', 1, 1)
          window.location = `/sign_in_jkf?access_token=${accessToken}`
        } else {
          this.signStep('aquirePhone')
          this.setState({ currentFlow: VALIDATE, modalTitle: '驗證手機號碼' })
        }
      }).catch(e => console.log(e))
    }).catch(e => console.log(e))

  }
  doneLogin(){
    let doneRequest = new pan.LoginRequest()
    doneRequest.setPhase(pan.LoginRequest.Phase.PHASE_DONE)
    doneRequest.setPhaseSessionToken(this.state.session_token)
    panService.login(doneRequest).then(doneResponse => {
      let auth_token = doneResponse.getPhaseDoneAuthToken()
      window.doneResponse = doneResponse
      this.setState({ auth_token: auth_token },
        () => window.localStorage.setItem('auth_token', auth_token))
      this.getMe()
    })
  }
  goPhase(phase) {
    switch (phase) {
      case 1:
        this.signStep('inputPhone')
        break
      case 2:
        this.signStep('signinAccount')
        break
      case 3:
        this.signStep('confirmNumber')
        break
    }
  }
  confirmSMS(){
    console.log("confirmSMS")
    this.verifyAquiredPhone()
  }
  verifyPin(){
    console.log("verifyPin");
    console.log(this.state.agreeTerms)
    if (!this.state.agreeTerms) {
      alert('請先閱讀並勾選同意服務條款');
      return false;
    }
    if(this.state.currentFlow == SIGNIN){
      // 登入
      console.log("SIGNIN");
      let sendPinRequest = new pan.LoginRequest()
      sendPinRequest.setPhase(pan.LoginRequest.Phase.PHASE_INPUT_CODE)
      sendPinRequest.setPhaseSessionToken(this.state.session_token)
      sendPinRequest.setPhaseInputCodeValue($('#smsVerifyCode').val())
      console.log(sendPinRequest)
      panService.login(sendPinRequest).then(sendPinResponse=>{
        window.sendPinResponse = sendPinResponse
        this.setState({session_token: sendPinResponse.getPhaseSessionToken()})
        if(sendPinResponse.getNextPhase()==pan.LoginRequest.Phase.PHASE_INPUT_NAME){
          this.signStep('verifyName')
        }else{
          this.doneLogin()
        }
        console.log(window.sendPinResponse);
      }).catch(r=>{
          // GENERAL_DEFAULT | 0 |  |
          // | FAILED | 1001 |  |
          // | SESSION_INVALID | 1002 |  |
          // | CODE_INVALID | 1003 |  |
          // | CODE_PENDING_10 | 1004 |  |
          // | CODE_PENDING | 1005 |  |
          // | NOT_FOUND | 1006 |  |
        console.log(r.code);
        console.log(r);
        console.log("登入");
        switch (r.code) {
          case 1002:
            console.log('SESSION_INVALID');
            this.showSmsErrorMsg('認證碼過期，請重發驗證碼')
            this.switchVerifyBtns('verify')
            break;
          case 1003:
            console.log('CODE_INVALID');
            this.showSmsErrorMsg('驗證碼錯誤，請確認簡訊重新輸入')
            break;
          case 1004:
            console.log('CODE_PENDING_10');
            this.showSmsErrorMsg('請十分鐘後再試')
            break;
          case 1005:
            console.log('CODE_PENDING')
            this.showSmsErrorMsg('此驗證碼已失效，請重發驗證碼')
            this.switchVerifyBtns('verify')
            break;
          default:
            console.log("FAILED" + r.code)
            this.showSmsErrorMsg('驗證失敗')
            this.switchVerifyBtns('back')
            break;
        }
        // SKY - TODO: Show Error
      })
    }else if(this.state.currentFlow == REGISTER){
      // 註冊
      console.log("REGISTER");
      let sendPinRequest = new pan.RegisterRequest()
      sendPinRequest.setPhase(pan.RegisterRequest.Phase.PHASE_INPUT_CODE)
      sendPinRequest.setPhaseSessionToken(this.state.session_token)
      sendPinRequest.setPhaseInputCodeValue($('#smsVerifyCode').val())
      console.log('sendPinRequest')
      console.log(sendPinRequest)
      console.log($('#smsVerifyCode').val())
      panService.register(sendPinRequest).then(sendPinResponse=>{
        window.sendPinResponse = sendPinResponse
        this.setState({session_token: sendPinResponse.getPhaseSessionToken()})
        if(sendPinResponse.getNextPhase()==pan.RegisterRequest.Phase.PHASE_INPUT_NAME){
          this.signStep('verifyName')
        }else{
          this.doneLogin()
        }
      }).catch(r=>{
        console.log(r)
        this.showSmsErrorMsg('驗證失敗')
        switch (r.code) {
          case 1001:
            this.showSmsErrorMsg('驗證失敗')
            break;
          case 1002:
            console.log('EXISTING_MEMBER')
            this.showSmsErrorMsg('此帳號已經註冊過，請重新登入')
            this.switchVerifyBtns('back')
            break;
          case 1003:
            console.log("SESSION_INVALID");
            this.showSmsErrorMsg('認証過期，請重新登入')
            this.switchVerifyBtns('back')
            break;
          case 1004:
            console.log("CODE_INVALID");
            this.showSmsErrorMsg('驗證碼錯誤，請重新輸入')
            break;
          case 1005:
            console.log("NAME_INVALID");
            this.showSmsErrorMsg('名稱錯誤，請重新嘗試登入')
            this.switchVerifyBtns('back')
            break;
          case 1006:
            console.log("NAME_EXISTING");
            this.showSmsErrorMsg('名稱已經存在，請重新登入')
            this.switchVerifyBtns('back')
            break;
          case 1007:
            console.log("CODE_PENDING_10");
            this.showSmsErrorMsg('請等待10分鐘後，重新嘗試')
            break;
          case 1008:
            console.log("CODE_PENDING");
            this.showSmsErrorMsg('簡訊發送已達上限，請稍後再試')
            break;
          default:
            console.log("FAILED");
            this.showSmsErrorMsg('驗證失敗，請重新嘗試登入')
            this.switchVerifyBtns('back')
            break;
        }
          // | Name | Number | Description |
          //   | ---- | ------ | ----------- |
          //   | GENERAL_DEFAULT | 0 |  |
          //   | FAILED | 1001 |  |
          //   | EXISTING_MEMBER | 1002 |  |
          //   | SESSION_INVALID | 1003 |  |
          //   | CODE_INVALID | 1004 |  |
          //   | NAME_INVALID | 1005 |  |
          //   | NAME_EXISTING | 1006 |  |
          //   | CODE_PENDING_10 | 1007 |  |
          //   | CODE_PENDING | 1008 |  |
        // SKY - TODO: Show Error
      })
    }
    else{
      // 補電話
      let sendPinRequest = new pan.ValidatePhoneRequest()
      sendPinRequest.setPhase(pan.ValidatePhoneRequest.Phase.PHASE_INPUT_CODE)
      sendPinRequest.setPhaseSessionToken(this.state.session_token)
      sendPinRequest.setAccessToken(this.state.access_token)
      sendPinRequest.setPhaseInputCodeValue($('#smsVerifyCode').val())
      panService.validatePhone(sendPinRequest).then(sendPinResponse=>{
        window.sendPinResponse = sendPinResponse
        if(sendPinResponse.getNextPhase()==pan.ValidatePhoneRequest.Phase.PHASE_INPUT_NAME){
          this.signStep('verifyName')
        }else{
          let doneRequest = new pan.ValidatePhoneRequest()
          doneRequest.setPhase(pan.ValidatePhoneRequest.Phase.PHASE_DONE)
          doneRequest.setPhaseSessionToken(this.state.session_token)
          doneRequest.setAccessToken(this.state.access_token)
          panService.validatePhone(doneRequest).then(doneResponse => {
            window.doneResponse = doneResponse
          })
        }
      }).catch(r=>{
        console.log(r)
        this.showSmsErrorMsg('驗證失敗，請重新輸入驗證碼')
        // SKY - TODO: Show Error
      })
    }
  }
  showSmsErrorMsg(msg){
    const vfymsg = document.getElementById('smsVerifyMsgDiv')
    vfymsg ? (vfymsg.textContent = msg) : null
  }
  updateName(){
    $('#check_exist').prop('disabled', 'disabled')
    if(this.state.currentFlow == VALIDATE){
      let updateNameRequest = new pan.ValidatePhoneRequest()
      updateNameRequest.setPhase(pan.ValidatePhoneRequest.Phase.PHASE_INPUT_NAME)
      updateNameRequest.setPhaseSessionToken(this.state.session_token)
      updateNameRequest.setAccessToken(this.state.access_token)
      updateNameRequest.setPhaseInputNameValue(this.state.display_name)
      panService.validatePhone(updateNameRequest).then(updateNameResponse=>{
        window.updateNameResponse = updateNameResponse
        let doneRequest = new pan.ValidatePhoneRequest()
        this.setState({session_token: updateNameResponse.getPhaseSessionToken()})
        doneRequest.setPhase(pan.ValidatePhoneRequest.Phase.PHASE_DONE)
        doneRequest.setPhaseSessionToken(this.state.session_token)
        doneRequest.setAccessToken(this.state.access_token)
        console.log(updateNameRequest)
        console.log(updateNameResponse)
        console.log(updateNameResponse.getNextPhase())
        console.log(doneRequest)
        panService.validatePhone(doneRequest).then(doneUpdateNameResponse => {
          // this.doneLogin()
          console.log(doneUpdateNameResponse);
          this.getMe()
        })
      }).catch(r=>{
        console.log(r)
        this.setState({ verifyNameMsg: '此名字已被使用' })
        $('#check_exist').removeAttr('disabled')
        document.getElementById('userName').className = 'form-control form-control-lg text-center is-invalid'
         // SKY - TODO: Show Error
      })
    }else{
      let updateNameRequest = new pan.RegisterRequest()
      updateNameRequest.setPhase(pan.RegisterRequest.Phase.PHASE_INPUT_NAME)
      updateNameRequest.setPhaseSessionToken(this.state.session_token)
      updateNameRequest.setPhaseInputNameValue(this.state.display_name)
      panService.register(updateNameRequest).then(updateNameResponse=>{
        window.updateNameResponse = updateNameResponse
        let doneRequest = new pan.RegisterRequest()
        this.setState({session_token: updateNameResponse.getPhaseSessionToken()})
        doneRequest.setPhase(pan.RegisterRequest.Phase.PHASE_DONE)
        doneRequest.setPhaseSessionToken(this.state.session_token)
        panService.register(doneRequest).then(doneUpdateNameResponse => {
          window.doneUpdateNameResponse = doneUpdateNameResponse
          console.log(doneUpdateNameResponse)
          this.setState({auth_token: doneUpdateNameResponse.getPhaseDoneAuthToken()},
            () => window.localStorage.setItem('auth_token', this.state.auth_token))
          this.getMe()
        })
      }).catch(r=>{
        console.log(r)
        this.setState({ verifyNameMsg: '此名字已被使用' })
        $('#check_exist').removeAttr('disabled')
        document.getElementById('userName').className = 'form-control form-control-lg text-center is-invalid'
         // SKY - TODO: Show Error
      })
    }
  }

  verifyAquiredPhone() {
    if(this.state.currentFlow == VALIDATE){
      // 舊帳號驗證手機
      let phone = this.intlTel.getNumber(intlTelInputUtils.numberFormat.NATIONAL).replace(/\s/g, "");
      let country = this.intlTel.getSelectedCountryData().iso2.toLocaleUpperCase()
      this.setState({ phone: phone, country: country })

      let validatePhoneRequest = new pan.ValidatePhoneRequest()
      validatePhoneRequest.setAccessToken(this.state.access_token)
      validatePhoneRequest.setPhaseCheckExistenceValue(phone)
      validatePhoneRequest.setPhaseCheckExistenceCountryCode(country)
      validatePhoneRequest.setPhase(pan.ValidatePhoneRequest.Phase.PHASE_CHECK_EXISTENCE)
      validatePhoneRequest.setPhaseSessionValid(grecaptcha.getResponse())
      console.log(validatePhoneRequest)
      panService.validatePhone(validatePhoneRequest).then(vResponse => {
        console.log(vResponse.getPhaseSessionToken())
        this.setState({session_token: vResponse.getPhaseSessionToken()})
        let aquirePinRequest = new pan.ValidatePhoneRequest()
        aquirePinRequest.setPhase(pan.ValidatePhoneRequest.Phase.PHASE_INPUT_CODE)
        aquirePinRequest.setPhaseSessionToken(this.state.session_token)
        aquirePinRequest.setAccessToken(this.state.access_token)
        panService.validatePhone(aquirePinRequest).then(aquirePinResponse=>{
          window.aquirePinRequest = aquirePinRequest
          console.log(aquirePinRequest)
          this.signStep('smsVerify')
          this.onSmsSent()
        })
      }).catch(r => {
        console.log(r)
        // this.setState({ smsVerifyMsg: '驗證失敗' })
        console.log("r ----->"+r)
        let phoneInput = document.querySelector("#phoneInput"),
            validMsg = document.querySelector('#validMsg'),
            backButton = document.querySelector('#backButton')
        if (phoneInput) {
          phoneInput.classList.remove("is-valid");
          phoneInput.classList.add("is-invalid");
        }
        if (validMsg) {
          validMsg.classList.remove("d-block", "valid-feedback");
          validMsg.classList.add('d-block', 'invalid-feedback');
        }
        if (backButton) {
          backButton.classList.remove("d-none");
        }
        switch (r.code) {
          case 1002:
            this.setState({ telErrorMsg: '此門號已註冊過，請以其他門號驗證，或重新以此門號登入JKF帳號', telIsValid: false })
            break;
          default:
            this.setState({ telErrorMsg: '手機驗證失敗，請嘗試重新登入', telIsValid: false })
            break;
        }
        // SKY - TODO: Show Error
      })
    }else if(this.state.currentFlow == SIGNIN){
      let loginAquirePinRequest = new pan.LoginRequest()
      loginAquirePinRequest.setPhase(pan.LoginRequest.Phase.PHASE_INPUT_CODE)
      loginAquirePinRequest.setPhaseSessionToken(this.state.session_token)
      panService.login(loginAquirePinRequest).then(aquirePinResponse=>{
        window.aquirePinResponse = aquirePinResponse
        console.log(aquirePinResponse)
        this.signStep('smsVerify')
        this.onSmsSent()
      }).catch(r=>{
        console.log(r)
        this.showSmsErrorMsg("簡訊發送已達上限，請稍等10分鐘再試")
         // SKY - TODO: Show Error
      })

    }else{
      // Register
      // 新帳號驗證手機
      console.log("GO Register")
      let registerPhoneRequest = new pan.RegisterRequest()
      registerPhoneRequest.setPhase(pan.RegisterRequest.Phase.PHASE_CHECK_EXISTENCE)
      registerPhoneRequest.setPhaseSessionToken(this.state.session_token)
      registerPhoneRequest.setPhaseCheckExistenceValue(this.state.phone)
      registerPhoneRequest.setPhaseCheckExistenceCountryCode(this.state.country)
      panService.register(registerPhoneRequest).then(vResponse => {
        console.log(vResponse)
        window.vResponse = vResponse
        this.setState({session_token: vResponse.getPhaseSessionToken()})
        let registerSendSmsRequest = new pan.RegisterRequest()
        registerSendSmsRequest.setPhase(pan.RegisterRequest.Phase.PHASE_INPUT_CODE)
        registerSendSmsRequest.setPhaseSessionToken(this.state.session_token)
        panService.register(registerSendSmsRequest).then(res => {
          window.res = res
          console.log(res)
          this.signStep('smsVerify')
          this.onSmsSent()
        })
      }).catch(r=>{
        console.log(r)
        // SKY: handle error
      })
    }
  }
  checkExistence() {
    $('#check_exist').prop('disabled', 'disabled')
    if (grecaptcha.getResponse()) {
      let sessionRequest = new pan.LoginRequest()
      sessionRequest.setPhase(pan.LoginRequest.Phase.PHASE_DEFAULT)
      sessionRequest.setPhaseSessionValid(grecaptcha.getResponse())
      panService.login(sessionRequest).then(sessionResponse=>{
        console.log(sessionResponse)
        window.sessionResponse = sessionResponse
        this.setState({session_token: sessionResponse.getPhaseSessionToken()})
        let jkf_useraccount = document.getElementsByName('jkf-useraccount')[0].value
        this.setState({ jkf_useraccount: jkf_useraccount })

        let loginRequest = new pan.LoginRequest()
        loginRequest.setPhase(pan.LoginRequest.Phase.PHASE_CHECK_EXISTENCE)
        loginRequest.setPhaseCheckExistenceValue(jkf_useraccount)
        loginRequest.setPhaseCheckExistenceCountryCode(window.country_cod)
        loginRequest.setPhaseSessionToken(this.state.session_token)
        panService.login(loginRequest).then(p => {
          window.p = p
          this.setState({ session_token: p.getPhaseSessionToken() })
          if (p.getPhaseCheckExistenceLoginType() == 1) {
            // Phone
            this.setState({ currentFlow: SIGNIN, phone: jkf_useraccount, modalTitle: '登入'})
            // this.setState({phone: jkf_useraccount})
            this.signStep("doubleCheckPhone")
          } else if (p.getPhaseCheckExistenceLoginType() == 2) {
            // USERNAME
            this.goPhase(p.getNextPhase())
            this.setState({ has_secure_question: p.getPhaseCheckExistenceQuestion() })
          } else {
            console.log("ERROR!")
          }
          console.log(p)
        }).catch(r => {
          console.log(r)
          let error_code = r.code
          grecaptcha.reset()
          if (error_code == 1006) {
            this.setState({ currentFlow: REGISTER, modalTitle: '註冊'})
            if(jkf_useraccount.match(/^\+{0,1}[0-9-]+$/gm)){
              this.setState({phone: jkf_useraccount})
            }
            this.signStep("confirmNumber")
          } else {
            // SKY - TODO: Show Error
            console.log(e);
            this.setState({ verifyNameMsg: '此名字已被使用' })
            $('#check_exist').removeAttr('disabled')
          }
        })
      })
    }
  }
  switchVerifyBtns(key){
    const verifvBtn = document.getElementById('verifyPinBtn')
    const fillBtn = document.getElementById('fillPinBtn')
    const smsBtn = document.getElementById('resendSmsBtn')
    const backBtn = document.getElementById('backBtn')
    switch (key) {
      case "init":
        if (fillBtn) {
          verifvBtn.classList.add('d-none')
          smsBtn.classList.add('d-none')
          backBtn.classList.add('d-none')
        }
        if (fillBtn.classList.contains('d-none')) {
          fillBtn.classList.remove('d-none')
        }
        break;
      case "verify":
        if (fillBtn) {
          fillBtn.classList.add('d-none')
          smsBtn.classList.add('d-none')
          backBtn.classList.add('d-none')
        }
        if (verifvBtn.classList.contains('d-none')) {
          verifvBtn.classList.remove('d-none')
        }
        break;
      case "resend":
        if (fillBtn) {
          fillBtn.classList.add('d-none')
          verifvBtn.classList.add('d-none')
          backBtn.classList.add('d-none')
        }
        if (smsBtn.classList.contains('d-none')) {
          smsBtn.classList.remove('d-none')
        }
        break;
      case "back":
        if (fillBtn) {
          fillBtn.classList.add('d-none')
          verifvBtn.classList.add('d-none')
          smsBtn.classList.add('d-none')
        }
        if (backBtn.classList.contains('d-none')) {
          backBtn.classList.remove('d-none')
        }
        break;
      default:
        break;
    }
  }
  goToNextInput(e) {
    let smscode = '',
        readytoVerify = false,
        key = e.which,
        t = $(e.target),
        prevInput = t.prev('input'),
        sib = t.next('input');
    this.initVerifyButtons;
    this.switchVerifyBtns('init')
    $('.verifycode-input').each(function (index) {
      if ( !$(this).val() == '' ){
        smscode = smscode + $(this).val().toString()
      }
    });
    this.showSmsErrorMsg('')
    console.log(readytoVerify);
    if (smscode.length == 6) {
      readytoVerify = true
    } else {
      readytoVerify = false
    }
    if (readytoVerify) {
      $('#smsVerifyCode').val(smscode)
      console.log($('#smsVerifyCode').val());
      this.switchVerifyBtns('verify')
      // Enter
      if (key == 13) {
        this.verifyPin()
      }
      //返回鍵
      if (key == 8) {
        t.val('')
        t.select().focus();
        this.switchVerifyBtns('init')
      }
    } else {
      //返回鍵
      if (key == 8) {
        t.val('')
        prevInput.val('')
        prevInput.select().focus();
        this.switchVerifyBtns('init')
      }
      // 輸入數字
      if (key != 9 && (key < 48 || (key > 57 && key < 96) || key > 105)) {
        e.preventDefault();
        return false;
      }
      // Tab鍵
      if (key === 9) {
        return true;
      }
      if (!sib || !sib.length) {
        sib = smsbox.find('input').eq(0);
      }
      //避免輸入太快 input 沒有記錄到值
      t.val(e.key)
      if (t.val() !== '') {
        sib.select().focus();
      }
      this.switchVerifyBtns('init')
    }
  }
  onSmsKeyDown(e) {
    var key = e.which;
    if (key === 9 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
      return true;
    }
    e.preventDefault();
    return false;
  }
  onSmsFocus(e) {
    $(e.target).select();
  }
  signStep(toID) {
    if (this.state.currentStepID) {
      this.setState({ previousStepID: this.state.currentStepID })
    }
    this.setState({ currentStepID: toID })
  }
  previousStep() {
    console.log(this.state)
    this.setState({ feedbackID: '' })
    if (this.state.resetGrecaptcha) {
      grecaptcha.reset()
    }
    if (this.state.previousStepID) {
      this.signStep(this.state.previousStepID)
    }
  }
  onSmsSent() {
    this.startCount(120);
  }
  startCount(limit) {
    this.switchVerifyBtns('init')
    const target = document.getElementById('CountTimeLeft')
    var timerInterval = null;
    let timeLeft = limit
    timerInterval = setInterval(() => {
      timeLeft = timeLeft - 1
      target.textContent = timeLeft
      if ( timeLeft == 0) {
        this.switchVerifyBtns('resend')
        clearInterval(timerInterval);
      }
    }, 1000);
  }
  resetCountDown(){
    this.switchVerifyBtns('init')
    this.startCount(120);
    this.showSmsErrorMsg('')
    // const vfymsg = document.getElementById('smsVerifyMsgDiv')
    // if (vfymsg) {
    //   vfymsg.textContent = ''
    // }
  }
  stepView() {
    console.log(`step: ${this.state.currentStepID}`)
    switch (this.state.currentStepID) {
      case 'inputPhone':
        // { this.setState({ renderGrecaptcha: true }); console.log('renderGrecaptcha')}
        return <div className="modal-body" key="inputPhone" >
          <div className="modal-form-wrapper form-row">
            <div className="col-sm-10 mx-auto">
              <div className="form-group mb-4">
                <input className={'form-control form-control-lg text-center' + (this.state.userAccoutInput.inValid ? ' is-invalid' : '')} id="phone-input" name="jkf-useraccount" placeholder="輸入你的手機號碼 / JKF帳號" required="required" type="text" />
                {this.state.feedbackID === 'notFound' ? <div className="invalid-feedback">
                  {this.state.feedbackMessage}
                </div> : ''}
              </div>
              {this.state.renderGrecaptcha ? <div className="g-recaptcha" data-sitekey="6LfxHOIUAAAAAJ-E2oORT8_zgG3Ia0QM1sg9Pe2s"></div> : '' }
            </div>
            <div className="col-sm-10 mx-auto btn-wrapper mt-2">
              <div className="mb-3">
                <button className="btn btn-brand-secondary btn-block btn-rounded" onClick={() => this.checkExistence()} type="button">下一步</button>
              </div>
            </div>
          </div>
        </div>
      case 'confirmNumber':
        return <div className="modal-body" key="confirmNumber" >
        <div className="modal-form-wrapper form-row">
          <div className="col-sm-10 mx-auto">
            <div className="form-group form-group-text">
              <p className="font-weight-bold text-center mb-3">
                請確認你的手機號碼以及<br />使用國家是否正確
              </p>
            </div>
            <div className="form-group form-group-text my-5">
              <input className="form-control" id="phoneInput" name="jkf_phone" type="tel" value={this.state.phone} onChange={(e)=>{this.setState({phone: e.target.value})}} />
              <div id="validMsg" className="d-none mt-2 mb-4 text-center">{this.state.telErrorMsg}</div>
            </div>
          </div>
          <div className="col-sm-10 mx-auto btn-wrapper pb-4 mt-2">
            {this.state.telIsValid ?
              <button className="btn btn-brand-secondary btn-block btn-rounded" onClick={() => {
                let phone = this.intlTel.getNumber(intlTelInputUtils.numberFormat.NATIONAL).replace(/\s/g, "");
                let country = this.intlTel.getSelectedCountryData().iso2.toLocaleUpperCase()
                this.setState({ phone: phone, country: country })
                this.signStep('doubleCheckPhone')
              }} type="button">下一步</button>
              :
              <button className="btn btn-brand-secondary btn-block btn-rounded" onClick={this.isValidPhone}>驗證手機</button>
            }
          </div>
        </div>
      </div>
      case 'doubleCheckPhone':
        return <div className="modal-body" id="doubleCheckPhone" >
        <div className="modal-form-wrapper form-row">
          <div className="col-sm-10 mx-auto">
            <div className="form-group form-group-text">
              <p className="font-weight-bold text-center mb-3">
                請確認你的手機號碼是否輸入正確<br />我們將傳送簡訊到以下手機號碼
              </p>
            </div>
            <div className="form-group form-group-text my-5">
              <p className="font-weight-bold text-center phone-number">
                {this.state.phone}
              </p>
            </div>
            <div id="smsVerifyMsgDiv" className="text-danger text-center mb-3"> {this.state.smsVerifyMsg}</div>
          </div>
          <div className="col-sm-10 mx-auto btn-wrapper pb-4 mt-2">
            <div className="mb-3">
              <button className="btn btn-brand-secondary btn-block btn-rounded" onClick={() => this.confirmSMS()} type="submit">確認無誤，寄送驗證碼</button>
            </div>
            <button className="btn btn-brand-secondary btn-block btn-rounded" onClick={() => this.previousStep()} type="button">上一步</button>
          </div>
        </div>
      </div>
      case 'smsVerify':
        return <div className="modal-body" key="smsVerify" >
        <form name="smsVerify">
          <div className="modal-form-wrapper form-row">
            <div className="col-sm-10 mx-auto">
              <div className="form-group form-group-text mb-5">
                <p className="font-weight-bold text-center phone-number">
                  {this.state.phone}
                </p>
              </div>
              <div className="sms-verify-wrapper" id="smsVerifycontainer">
                <input className="form-control verifycode-input" maxLength="1" min="0" name="code0" pattern="[0-9]{1}" size="1" type="tel" autoFocus/><input className="form-control verifycode-input" maxLength="1" min="0" name="code1" pattern="[0-9]{1}" size="1" type="tel" /><input className="form-control verifycode-input" maxLength="1" min="0" name="code2" pattern="[0-9]{1}" size="1" type="tel" /><input className="form-control verifycode-input" maxLength="1" min="0" name="code3" pattern="[0-9]{1}" size="1" type="tel" /><input className="form-control verifycode-input" maxLength="1" min="0" name="code4" pattern="[0-9]{1}" size="1" type="tel" /><input className="form-control verifycode-input" maxLength="1" min="0" name="code5" pattern="[0-9]{1}" size="1" type="tel" />
                <input id="smsVerifyCode" name="smsVerifyCode" type="hidden" />
              </div>

              <div id="smsVerifyMsgDiv" className="text-danger text-center mb-3"> {this.state.smsVerifyMsg}</div>

              <div className="form-group form-inline">
                <div className="custom-control custom-checkbox">
                  <input defaultChecked="true" className="custom-control-input" id="customCheck1" type="checkbox" onChange={e => this.setState({ agreeTerms: e.target.checked}) } /><label className="custom-control-label" htmlFor="customCheck1">我已閱讀並同意</label>
                </div>
                <a className="link ml-2" href="/terms-of-service" target="_blank">服務條款</a><span>。</span>
              </div>
            </div>
            <div className="col-sm-10 mx-auto btn-wrapper pb-4 mt-2">
              <div className="mb-3" id="verifyPinBtnWrap">
                <button id="verifyPinBtn" className="btn btn-brand-secondary btn-block btn-rounded" onClick={() => this.verifyPin()} type="button">送出</button>
                <button id="fillPinBtn" className="btn btn-secondary btn-block btn-rounded mt-0" type="button" disabled="disabled">請填寫驗證碼</button>
                <button id="resendSmsBtn" className="btn btn-secondary btn-block btn-rounded mt-0 d-none" type="button" onClick={() => { this.verifyPin(); this.resetCountDown()} }>重送驗證碼</button>
                <button id="backBtn" className="btn btn-secondary btn-block btn-rounded mt-0 d-none" type="button" onClick={() => { this.signStep('inputPhone'); this.setState({ renderGrecaptcha: true })}}>返回驗證帳號</button>
              </div>
              <div className="mb-3">
                <button className="btn btn-secondary btn-block btn-rounded" id="verifyCodeCountdown" type="button" >驗證碼有效時間<span id="CountTimeLeft" className="px-2"> </span>秒</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      case 'typeName':
        return <div className="modal-body" key="typeName">
        <div className="modal-form-wrapper form-row">
          <div className="col-sm-10 mx-auto">
            <div className="form-group">
              <p className="text-center">
                JKF帳號名稱
              </p>
            </div>
            <div className="form-group pb-5">
              <input id="userName"  className="form-control form-control-lg text-center" name="username" placeholder="*輸入你想要的名字" required="required" type="text" />
              <div id="userNameMsg" className="invalid-feedback text-center mt-3">
                {this.state.verifyNameMsg}
              </div>
            </div>
          </div>
          <div className="col-sm-10 mx-auto btn-wrapper pb-4 mt-5">
            <button className="btn btn-brand-secondary btn-block btn-rounded" type="submit">送出</button>
          </div>
        </div>
      </div>
      case 'signinAccount':
        return <div className="modal-body" key="signinAccount">
        <div className="modal-form-wrapper form-row">
          <div className="col-sm-10 mx-auto">
            <div className="form-group form-group-text">
              <p className="font-weight-bold text-center">
                {this.state.jkf_useraccount}
              </p>
            </div>
            <div className="form-group mt-5">
              <input className="form-control form-control-lg text-center" name="jkf_password" placeholder="輸入登入密碼" required="required" type="password" />
            </div>
            {this.state.has_secure_question ? <div>
              <div className="form-group">
                <select className="form-control form-control-lg text-center" name="jkf_security_question_index" placeholder="安全提問" required="required">
                  <option value>安全提問(未設定請忽略)</option>
                  <option value="1">母親的名字</option>
                  <option value="2">爺爺的名字</option>
                  <option value="3">父親出生的城市</option>
                  <option value="4">您其中一位老師的名字</option>
                  <option value="5">您個人計算機的型號</option>
                  <option value="6">您最喜歡的餐館名稱</option>
                  <option value="7">駕駛執照最後四位數字</option>
                </select>
              </div>
              <div className="form-group">
                <input className="form-control form-control-lg text-center" name="jkf_security_question_answer" placeholder="安全提問答案" required="required" type="text" />
              </div>
            </div> : ''}
            {this.state.feedbackID === 'forgetPassword' ? <div id="forgetPassword" className="form-group form-control-wrapper text-center pt-5">
              <div className="d-inline-block px-3">
                <div className="text-danger">{this.state.feedbackMessage}</div>
              </div>
              <div className="d-inline-block px-3">
                  <a className="text-dark text-link" href="https://www.jkforum.net/member.php?mod=logging&action=login&viewlostpw=1&referer=https://jdsgn.com/">忘記密碼?</a>
              </div>
            </div> : ''}
          </div>
          <div className="col-sm-10 mx-auto btn-wrapper pb-4 mt-2">
            <div className="mb-3">
              <button className="btn btn-brand-secondary btn-block btn-rounded" onClick={() => { this.signInWithUsername() }} type="button">送出</button>
              {/* <button className="btn btn-brand-secondary btn-block btn-rounded" onClick={() => this.signStep('signinAccount', 'phoneInputNumber')} type="button">送出</button> */}
            </div>
              <button className="btn btn-brand-secondary btn-block btn-rounded" onClick={() => { this.setState({ resetGrecaptcha: true }); this.previousStep()}} type="button">上一步</button>
          </div>
        </div>
      </div>
      case 'aquirePhone':
        return <div className="modal-body" key="aquirePhone">
        <div className="modal-form-wrapper form-row">
          <div className="col-sm-10 mx-auto">
            <div className="form-group mb-4">
              <input className="form-control form-control-lg" id="phoneInput" name="cellphone" placeholder="輸入你的手機號碼" required="required" type="tel" />
              <div id="validMsg" className="d-none mt-2 text-center">{this.state.telErrorMsg}</div>
            </div>
            <div className="g-recaptcha" data-sitekey="6LfxHOIUAAAAAJ-E2oORT8_zgG3Ia0QM1sg9Pe2s"></div>
          </div>
          <div className="col-sm-10 mx-auto btn-wrapper pb-4 mt-2">
            <button id="verifyPhoneButton" className="btn btn-brand-secondary btn-block btn-rounded" onClick={() => this.verifyAquiredPhone()} type="button">下一步</button>
              <button id="backButton" className="d-none btn btn-brand-secondary btn-block btn-rounded" onClick={() => { this.signStep('inputPhone'); this.setState({ renderGrecaptcha: true, resetGrecaptcha: true, modalTitle: '登入' }) }} type="button">重新登入</button>
          </div>
        </div>
      </div>
      case 'verifyName':
        return <div className="modal-body" key="verifyName">
        <div className="modal-form-wrapper form-row">
          <div className="col-sm-10 mx-auto">
            <div className="form-group">
              <p className="text-center">
                JKF帳號名稱
              </p>
            </div>
            <div className="form-group pb-5">
              <input id="userName" className="form-control form-control-lg text-center" name="username" placeholder="*輸入你想要的名字" required="required" type="text" defaultValue={this.state.display_name} onChange={e=>this.setState({display_name:e.target.value})}/>
              <div id="userNameMsg" className="invalid-feedback text-center mt-3">
                {this.state.verifyNameMsg}
                {/* 此名字已被使用 */}
              </div>
            </div>
          </div>
          <div className="col-sm-10 mx-auto btn-wrapper pb-4 mt-5">
            <button className="btn btn-brand-secondary btn-block btn-rounded" id="check_exist" type="submit" onClick={()=> this.updateName()}>送出</button>
          </div>
        </div>
      </div>
    }
  }
  render() {
    return (
      <div>
        <div aria-hidden="true" aria-labelledby="signInModalLabel" className="modal fade" data-backdrop="static" data-keyboard="false" id="signInModal" role="dialog" tabIndex="-1">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-center w-100 text-uppercase" id="signInModalLabel">{this.state.modalTitle}</h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={()=>this.resetFlow()}><i className="icon icon-cancel"></i></button>
              </div>
              {this.stepView()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
