import React from 'react'
import i18n from '../../components/i18next/i18n'
import Sidebar from "react-sidebar"
import StoreMenu from './store_menu'

export default class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      docked: false
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  onSetSidebarOpen(open) {
    let body = document.body
    this.setState({ sidebarOpen: open }, ()=>{
      if (open) {
        body.classList.add("open_menu")
      } else {
        body.classList.remove("open_menu")
      }
    })

  }
  render() {
    let content = (<>
      <nav className="drawer-nav" role="navigation">
        <div className="drawer_header">
          {!!this.props.current_customer ? <div className='user_name nav-link text-white'>{this.props.current_shop_customer?.shop_vip?.title || this.props.current_customer?.name || ''}</div> : <a href="#!" onClick={() => this.onSetSidebarOpen(false)} data-target="#signInModal" data-toggle="modal" className="menu-link member-link"><i className="icon icon-user mr-1"></i>{i18n.t('signup_signin')}</a>}
          <button className="menu-toggler close-mobile-menu" type='button' onClick={() => this.onSetSidebarOpen(false)}>
            <div className="icon">
              <span></span><span></span><span></span>
            </div>
          </button>
        </div>
        <div className="mobile-navbar">
          <ul className="mb-0">
            <StoreMenu menu_items={this.props.menu_items} is_mobile={true} />
          </ul>
          {!!this.props.current_customer ?
          <ul className="shopging-navibar mb-0">
            <li className="nav-item has-submenu">
              <a href="#!" className="nav-link submenu-toggle" data-toggle="collapse" href="#collapseMenu_Member" role="button" aria-expanded={!!this.props.current_customer ? true : false} aria-controls="collapseMenu_Member">
                <span className="text">{i18n.t('member.member_center')}</span>
              </a>
              <ul id='collapseMenu_Member' className='secondary-menu collapse show' role="menu">
                <li className="secondary-item">
                  <a href="/store_members/profile" className="menu-link shoppingcart">{i18n.t('member.profile')}</a>
                </li>
                <li className="secondary-item">
                  <a href="/store_members/order" className="menu-link shoppingcart">{i18n.t('member.order')}</a>
                </li>
                <li className="secondary-item">
                  <a href="/store_members/promotion_code" className="menu-link shoppingcart">{i18n.t('member.promotion_code')}</a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="/customers/sign_out" className="nav-link" data-method="delete">{i18n.t('logout')}</a>
            </li>
          </ul> : null
          }
        </div>
      </nav>
    </>)
    return (
      <Sidebar
        sidebar={content}
        open={this.state.sidebarOpen}
        docked={this.state.docked}
        onSetOpen={this.onSetSidebarOpen}
        rootId='mobile_menu-container'
        sidebarId='mobile_menu-sidebar'
        contentId='mobile_menu-content'
        overlayId='mobile_menu-layout'
      >
        <button onClick={() => this.onSetSidebarOpen(true)} className='menu-toggler open-mobile-menu'>
          <div className="icon">
            <span></span><span></span><span></span>
          </div>
        </button>
      </Sidebar>
    );
  }
}