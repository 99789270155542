import React from 'react'
import StoreCollection from './collection'

export default class StoreCollectionsMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collections: null,
      parent_menu_id: null,
      selected_menu_id: 0,
      selected_menu_title: null
    }
    this.setCollections = this.setCollections.bind(this)
  }
  componentDidMount() {
    if (this.props.selected)
      this.setCollections(this.props.selected)
    else
      this.setCollections(this.props.collection)
  }
  setCollections(item) {
    let collections = [];
    if (item.children && item.children.length > 0) {
      item.children.forEach(function(sub_item) {
        if (sub_item.children && sub_item.children.length > 0) {
          sub_item.children.forEach(function(sub_sub_item) {
            if (sub_sub_item.menu_type == 'collection')
              collections.push(sub_sub_item.collection_id);
          });
        }
        if (sub_item.menu_type == 'collection')
          collections.push(sub_item.collection_id);
      });
    }
    if (item.menu_type == 'collection')
      collections.push(item.collection_id);
    this.setState({
      collections: collections.filter((elem, index) => collections.indexOf(elem)===index),
      selected_menu_id: item.id,
      selected_menu_title: item.title
    });
    if (this.state.parent_menu_id === null)
      this.setState({ parent_menu_id: item.id })
  }
  render() {
    let menu_items = [this.props.collection].map((item, index) => {
      if(item.children.length > 0) {
        let children = item.children.map((sub_item, sub_item_index) => {
          if (sub_item.children.length > 0) {
            let grandchildren = sub_item.children.map((sub_sub_item, sub_sub_item_index) => {
              let cp = this.props.collection_products.find(c => c.token===sub_sub_item.collection_token);

              let link = window.location.href;
              link = link.replace(/&page=[0-9]+/g, '').replace(/\?page=[0-9]+&/g, '?').replace(/(\?|&)page=[0-9]+/g, '')
              link = link.replace(/&selected=[0-9]+/g, '').replace(/\?selected=[0-9]+&/g, '?').replace(/(\?|&)selected=[0-9]+/g, '').replace('#0', '').replace('#!', '')
              link += (link.includes('?') ? ('&selected='+sub_sub_item.id) : ('?selected='+sub_sub_item.id))

              return (
                <li className="secondary-item" key={sub_sub_item_index} >
                  <a href={link} className={"menu-link secondary " + (this.state.selected_menu_id===sub_sub_item.id ? 'active' : '')}
                    onClick={event => this.setCollections(sub_sub_item)}
                  >
                    <span className="text">{sub_sub_item.title} {cp ? cp.products_count : 0}</span>
                  </a>
                </li>
              )
            });

            let link = window.location.href;
            link = link.replace(/&page=[0-9]+/g, '').replace(/\?page=[0-9]+&/g, '?').replace(/(\?|&)page=[0-9]+/g, '')
            link = link.replace(/&selected=[0-9]+/g, '').replace(/\?selected=[0-9]+&/g, '?').replace(/(\?|&)selected=[0-9]+/g, '').replace('#0', '').replace('#!', '')
            link += (link.includes('?') ? ('&selected='+sub_item.id) : ('?selected='+sub_item.id))

            return (
              <li className="menu-item has-submenu" key={sub_item_index}>
                <h5 aria-expanded="false" aria-haspopup="true" className="menu-title">
                  <a href={link} className={"menu-title " + (this.state.selected_menu_id===sub_item.id ? 'active' : '')}
                    onClick={event => this.setCollections(sub_item)}
                  >{sub_item.title}</a>
                </h5>
                <ul className="secondary-menu" role="menu">
                  {grandchildren}
                </ul>
              </li>
            )
          }
          else {

            let link = window.location.href;
            link = link.replace(/&page=[0-9]+/g, '').replace(/\?page=[0-9]+&/g, '?').replace(/(\?|&)page=[0-9]+/g, '')
            link = link.replace(/&selected=[0-9]+/g, '').replace(/\?selected=[0-9]+&/g, '?').replace(/(\?|&)selected=[0-9]+/g, '').replace('#0', '').replace('#!', '')
            link += (link.includes('?') ? ('&selected='+sub_item.id) : ('?selected='+sub_item.id))

            return (
              <li className="menu-item" key={sub_item_index} >
                <h5 aria-expanded="false" aria-haspopup="true" className="menu-title">
                  <a href={link} className={"menu-title " + (this.state.selected_menu_id===sub_item.id ? 'active' : '')}
                    onClick={event => this.setCollections(sub_item)}
                  >
                    <span className="text">{sub_item.title}</span>
                  </a>
                </h5>
              </li>
            )
          }
        });
        return (
          <div>
            {children}
          </div>
        )
        // return (
        //   <li className="menu-item has-submenu" key={index}>
        //     <h5 aria-expanded="false" aria-haspopup="true" className="menu-title">
        //       <a href="#!" className={"menu-title " + (this.state.selected_menu_id===item.id ? 'active' : '')}
        //         onClick={event => this.setCollections(item)}
        //       >{item.title}</a>
        //     </h5>
        //     <ul className="secondary-menu" role="menu">
        //       {children}
        //     </ul>
        //   </li>
        // )
      } else {
        // return (
        //   <li className="menu-item" key={index}>
        //     <a href="#!" className={"menu-title " + (this.state.selected_menu_id===item.id ? 'active' : '')}
        //       onClick={event => this.setCollections(item)}
        //     >{item.title}</a>
        //   </li>
        // )
      }
    })

    let collections = null;
    if (this.state.collections !== null)
      collections = <StoreCollection title={this.props.collection.title} parent_menu_id={this.state.parent_menu_id} selected_menu_id={this.state.selected_menu_id} selected_menu_title={this.state.selected_menu_title} collections={this.state.collections} is_login={this.props.is_login} distribution={this.props.distribution} title_show={false} limit="30" distribution_percentage={this.props.distribution_percentage} vip_percentage={this.props.vip_percentage} agent_token={this.props.agent_token} shop_vips={this.props.shop_vips} />

    return (
      <div className="ls-store-collection " id="lsStoreCollection">
        <div className="collection-section-container">
          <div className="collection-row page-main-content">
            <main className="collecion-content">
              {collections}
            </main>
          </div>
        </div>
      </div>
    )
  }
}