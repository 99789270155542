import React from 'react'
import MemberAside from './memberAside'

export default class MemberLayout extends React.Component {

  render() {
    return (
      <div id={this.props.current_page} className="member-center-page collection-row">
        <div className="container">
          <div className="member-container d-flex flex-wrap">
            <MemberAside currentPage={this.props.current_page} isStore={this.props.isStore}/>
            <main className={"member-main-content "+ this.props.current_page}>
              <div className="membership-container">
                {this.render_inner()}
              </div>
            </main>
          </div>
        </div>
      </div>
      )
  }
}