import React from 'react'

export default class LowestPrice extends React.Component {
  getToday() {
    let today = new Date();
    today = [ today.getFullYear(),
              this.rjust(today.getMonth()+1),
              this.rjust(today.getDate())].join(' / ');
    return today;
  }
  rjust(num) {
    return num < 10 ? '0'+num : num;
  }
  render() {
    return(
      <div className="ls-store-collection ">
        <div className="collection-row page-main-content">
          <div className='container'>
            <hgroup className="section-hgroup">
              <h2 className="section-title">最新報價</h2>
            </hgroup>
            <div className="table-lowest_price-wrapper">
              {/*<div className='date-wrapper'>
                <div className="update_date text-right">{"更新日期："+this.getToday()}</div>
              </div>*/}
              {/* {tables} */}
              <div className="">
                <img src="/img/DM20210908.png" alt="" className="w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}