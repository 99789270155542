import React from 'react'
import TargetGift from './target_gift'
import i18n from '../../components/i18next/i18n'

const TARGET_PRICE = "target_price";
const TARGET_PACKS = "target_packs";
export default class TargetGiftsCampaignSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      is_selected: true
    }
    this.onAddGiftToCart = this.onAddGiftToCart.bind(this)
  }
  componentDidUpdate(prevProps) {
    console.log(this.props.is_unmount)
    if (prevProps.is_unmount!==this.props.is_unmount && !this.props.is_unmount)
      this.setState({ is_selected: false })
  }
  onAddGiftToCart(gift_id, quantity, campaign_token, threshold, is_unmount=false) {
    this.setState({ is_selected: !is_unmount && quantity!==0 })
    this.props.onAddGiftToCart(gift_id, quantity, campaign_token, threshold, is_unmount)
  }
  mapping_threshold(original_gift) {
    let group = original_gift.reduce((previousValue, currentValue) => {
      previousValue[currentValue.threshold] = [...previousValue[currentValue.threshold] || [], currentValue]
      return previousValue;
    }, {});
    return group;
  }

  render() {
    let { is_multiple_add, is_multiple_send, target } = this.props;
    let target_gifts = [];
    let setting_array = Object.entries(this.props.setting);

    if (is_multiple_add && is_multiple_send) {
      // 累計累贈
      let max_threshold = parseInt(setting_array[setting_array.length-1][0]);
      while(target >= 0) {
        for (let i=0; i<setting_array.length; ++i) {
          if (target >= parseInt(setting_array[i][0])) {
            target_gifts.push(...setting_array[i][1]);
          }
          else
            break;
        }
        target -= max_threshold;
      }
    }
    else if (!is_multiple_add && is_multiple_send) {
      // 不累計累贈
      let max_threshold = parseInt(setting_array[setting_array.length-1][0]);
      for (let i=0; i<setting_array.length; ++i) {
        if (target >= parseInt(setting_array[i][0]))
          target_gifts.push(...setting_array[i][1]);
        else
          break;
      }
    }
    else if (is_multiple_add && !is_multiple_send) {
      // 累計不累贈
      let max_threshold = parseInt(setting_array[setting_array.length-1][0]);
      let q = Math.floor(target/max_threshold); // 最高門檻數循環次數
      let r = target%max_threshold; // 循環後剩餘數

      for (let i=0; i<q; ++i)
        target_gifts.push(...setting_array[setting_array.length-1][1]);

      let target_thres_gifts = null;
      for (let i=0; i<setting_array.length; ++i) {
        if (r >= parseInt(setting_array[i][0])) {
          target_thres_gifts = setting_array[i][1];
        }
      }
      if (target_thres_gifts)
        target_gifts.push(...target_thres_gifts);
    }
    else if (!is_multiple_add && !is_multiple_send) {
      // 不累計不累贈
      for (let i=0; i<setting_array.length; ++i) {
        if (target >= parseInt(setting_array[i][0])) {
          target_gifts = setting_array[i][1];
        }
      }
    }
    return (
      <div className="campaign_gift_group">
        {this.props.campaign_title? <h5 className="section-subtitle">{this.props.campaign_title}</h5> : null}
        {
          Object.entries(this.mapping_threshold(target_gifts)).map(([threshold, gift_threshold_array], index) => (
            <TargetGiftThreshold key={`${this.props.campaign_token}-${threshold}`} index={index}
              gift_threshold_array={gift_threshold_array}
              threshold={parseInt(threshold)}
              threshold_txt={"（指定商品滿"+threshold+(this.props.gift_type===TARGET_PRICE ? "元" : "件")+"）"}
              selected_gift={Object.entries(this.props.selected_gift||{}).length!==0 ? (this.props.selected_gift[parseInt(threshold)]||{}) : null}
              onAddGiftToCart={this.onAddGiftToCart}
              campaign_token={this.props.campaign_token}
              is_selected={this.state.is_selected}
            />
          ))
        }
      </div>
    )
  }
}

class TargetGiftThreshold extends React.Component {
  mapping_gift_quantity(original_gift) {
    let group = original_gift.reduce((previousValue, currentValue) => {
      previousValue[currentValue.gift.id] = [...previousValue[currentValue.gift.id] || [], currentValue]
      return previousValue;
    }, {});
    return group;
  }

  render() {
    let index = 0;
    return (
      <div className="threshold_group">
        <div className="group-title"><i className="fa fa-arrow-circle-down" aria-hidden="true"></i> 以下任選一件{this.props.threshold_txt}</div>
        {
          Object.entries(this.mapping_gift_quantity(this.props.gift_threshold_array)).map(([gift_id, gift_array]) => {
            let quantity = Math.min(gift_array.length||0, gift_array[0].gift.stock);
            let target_gift = <TargetGift key={`${this.props.campaign_token}-${gift_id}`} index={index}
                quantity={quantity}
                threshold={this.props.threshold}
                gift={gift_array[0].gift}
                onAddGiftToCart={this.props.onAddGiftToCart}
                is_selected={this.props.selected_gift!==null ? (!!this.props.selected_gift.variant_id ? this.props.selected_gift.variant_id===parseInt(gift_id) : index===0) : (this.props.is_selected ? index===0 : false)}
                campaign_token={this.props.campaign_token}
              />
            if (quantity > 0) ++index;
            return target_gift;
          })
        }
      </div>
    )
  }
}
