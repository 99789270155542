import React from 'react'
import Vcode from 'react-vcode'

export default class LowestPriceAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = { v_code: null };
  }
  componentDidMount() {
    let self = this;
    $('form#paid-much-alert').on('submit', function(e) {
      console.log('eee')
      if ($('#vCode').val() !== self.state.v_code) {
        window.store_alert('驗證碼錯誤');
        $("#vcode").click();
        return false;
      }
    });
  }
  render() {
    let exclude_vip_brand = 97;
    let min_price = this.props.product.brands.find(brand => brand.id===exclude_vip_brand)===undefined ? Math.round(this.props.product.min_price*this.props.vip_percentage/100) : this.props.product.min_price
    let max_price = this.props.product.brands.find(brand => brand.id===exclude_vip_brand)===undefined ? Math.round(this.props.product.max_price*this.props.vip_percentage/100) : this.props.product.max_price
    return(
      <div className="expensive-container">
        <div className="container">
          <div className="expensive-panel">
            <div className='panel-header'>
              <h2>賣貴通報</h2>
            </div>
            <div className="panel-body">
              <div className="product-container">
                <div className='product-thumbnail'>
                  <img className="img-fluid" src={this.props.product.photo_url ? this.props.product.photo_url : '/img/no-product-img.png'} alt={this.props.product.title} />
                </div>
                <div className='product-detail'>
                  <form id="paid-much-alert" method='post' action='/paid_much_alert' acceptCharset="UTF-8">
                    <input name="authenticity_token" value={this.props.authenticity_token} type="hidden"/>
                    <input name="paid_much[store_domain]" value={window.location.hostname} type="hidden"/>
                    <input name="paid_much[product_token]" value={this.props.product.token} type="hidden"/>
                    <div className="detail-list">
                      <div className="dt-title">商品名稱：</div>
                      <div className="dt-value">{this.props.product.title}</div>
                    </div>
                    <div className="d-md-flex align-items-center">
                      <div className="detail-list">
                        <div className="dt-title">販售通路：</div>
                        <div className="dt-value">網路通路</div>
                      </div>
                      <div className="detail-list ml-md-3">
                        <div className="dt-title">會員價：</div>
                        <div className="dt-value price-group"><small className='currency'>NT$</small><span className="price-value">{min_price===max_price ? min_price : min_price+"~"+max_price}</span></div>
                      </div>
                    </div>
                    <div className="detail-list">
                      <label htmlFor="channelStoreName" className="dt-title col-form-label">商店名稱：</label>
                      <div className="dt-value flex-grow"><input name="paid_much[store_name]" type="text" className="form-control" id="channelStoreName" placeholder="請輸入購物網站商店名稱" required /></div>
                    </div>
                    <div className="detail-list">
                      <label htmlFor="channelProductUrl" className="dt-title col-form-label">商品網址：</label>
                      <div className="dt-value flex-grow"><input name="paid_much[store_url]" type="url" className="form-control" id="channelProductUrl" placeholder="請輸入同上述購物網站的商品連結" required /></div>
                    </div>
                    <div className="detail-list">
                      <label htmlFor="channelProductPrice" className="dt-title col-form-label">商品售價：</label>
                      <div className="dt-value flex-grow">
                        <div className="d-md-flex align-items-center">
                          <input name="paid_much[price]" type="number" min="0" className="form-control price-input" id="channelProductPrice" placeholder="" required />
                          <span className='ml-2'>元</span>
                          <span className="text-muted ml-2 d-none d-md-inline-block"> (限填寫阿拉伯數字)</span>
                        </div>
                      </div>
                      <div className="text-muted ml-2 d-block d-md-none"> (限填寫阿拉伯數字)</div>
                    </div>
                    <div className="detail-list">
                      <label htmlFor="channelProductUrl" className="dt-title col-form-label">聯絡信箱：</label>
                      <div className="dt-value flex-grow"><input name="paid_much[contact_email]" type="email" className="form-control" id="channelProductUrl" placeholder="請輸入您的E-Mail" required /></div>
                    </div>
                    <div>
                      <p className="text-muted mb-0">1.請提供商品或相同組合之比價結果。如為滿額活動折抵或因貨源等級差別之售價將不列入參考</p>
                      <p className="text-muted">2.非常感謝您使用通報功能，讓JDSGN 購物網有成長進步空間。</p>
                    </div>
                    <div className="btn-wrapper">
                      <div className="d-md-flex align-items-center">
                        <label htmlFor="vCode" className="dt-title col-form-label">驗證碼：</label>
                        <div className="dt-value flex-grow">
                          <input className="form-control" id="vCode" required/>
                        </div>
                        <Vcode id="vcode" length={6} onChange={v => this.setState({v_code: v})}/>
                      </div>
                      <div className="mt-4">
                        <button type="submit" className="btn btn-submit">確認送出</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}