import React from 'react'
import Product from './product'

class Campaign extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      products:[]
    }
    this.fetchProducts = this.fetchProducts.bind(this)
    this.onCampaignAddToCart = this.onCampaignAddToCart.bind(this)
  }

  componentDidMount(){
    this.fetchProducts()
  }

  onCampaignAddToCart(variant_id){
    this.props.onAddToCart(variant_id,this.props.campaign.token)
  }

  fetchProducts(){
    let url = `/api/v1/campaigns/${this.props.campaign.token}/products.json?offset=${this.state.products.length}`
    console.log(url)
    console.log("GO FETCH!")
    fetch(url).then(res=>res.json()).then(data=>{
      console.log(data)
      this.setState({products: [...this.state.products,...data.products], total_count: data.total_count})
    })
  }


  render(){
    let { campaign } = this.props
    return <div>
      <h1> {campaign.title}  </h1>
      <div className='row'>
        {
          this.state.products.map(product=><Product product={product} campaign={this.props.campaign} onCampaignAddToCart={this.onCampaignAddToCart} key={product.token}></Product>)
        }
      </div>
    </div>
  }
}

export default Campaign