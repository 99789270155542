import React from 'react'
import MemberLayout from './memberLayout'
import i18n from '../../components/i18next/i18n'

export default class MemberQna extends MemberLayout {
  render_inner() {
    return (
      <>
        <div className="section-head-group mb-3">
          <h1 className="section-subtitle mb-1 mb-md-0 text-center">{i18n.t("member.qna")}</h1>
        </div>

        <div className="mamber-detail-wrapper order-detail-wrapper">
          <div className="list-group list-group-flush">
            <div className="list-group-item list-group-item-action d-flex flex-column flex-lg-row align-items-lg-center order-qna">
              <div className="item-title">您的提問</div>
              <div className="item-detail">
                <p>我有問題</p>
                <span className="time-stamp">
                  2021/05/13 13:58:03
                </span>
              </div>
            </div>
            <div className="list-group-item list-group-item-action d-flex flex-column flex-lg-row align-items-lg-center order-qna shop-owner">
              <div className='item-title'> <i aria-hidden="true" className="fa fa-commenting"></i> 商家回覆</div>
              <div className="item-detail">
                <p>說來聽聽...</p>
                <span className="time-stamp">2021/05/13 13:59:22</span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}