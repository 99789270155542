import React from 'react'
import { isEqual } from 'lodash'
import MemberLayout from './memberLayout'
import i18n from '@/components/i18next/i18n'
import OrderCard from '@/bundles/member/OrderCard'

const orderStatus = {
  new: i18n.t("orders.status.ordered"),       // 已成立
  done: i18n.t("orders.status.done"),         // 已完成
  canceled: i18n.t("orders.status.canceled"), // 已取消
  request_return: i18n.t("orders.status.request_return"), // 申請退貨
  returned: i18n.t("orders.status.returned"),             // 已退貨
  request_cancel: i18n.t("orders.status.request"),        // 已退貨
  processing:  i18n.t("orders.shipping_status.ready")     // 出貨中
}

export default class MemberOrder extends MemberLayout {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  render_inner() {
    let emptyContent = <div className="p-5 placeholder-image">
      <div className='icon'>
        <img src={this.props.placeholder_img_url} alt={i18n.t('orders.no_orders')} className='w-100' />
      </div>
      <p className="mb-0 text-muted">{i18n.t('orders.no_orders')}</p>
    </div>

    return (
      <>
        <div className="section-head-group d-flex align-items-center justify-content-center mb-3">
          <i className="mr-2" data-feather="shopping-bag"></i>
          <h1 className="section-subtitle mb-1 mb-md-0">{i18n.t("member.order")}</h1>
        </div>

        { this.props.current_customer && !isEqual(this.props.orders.length, 0) ?
          this.props.orders.map((order, index) => <OrderCard defaultPhoto={this.props.placeholder_no_product}
                                                             order={order} index={index} key={`order-${index}`}
                                                             openStatus={isEqual(index, 0)} orderStatus={orderStatus[order.status] ?? ''}
                                                  />) : emptyContent }
      </>
    )
  }
}
