import React from 'react'
import Modal from 'react-modal'
import YouTube from 'react-youtube';
import { isEqual } from 'lodash';
import Slider from "react-slick";

const getURLParameters = url =>
  (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
    (a, v) => (
      (a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a
    ),
    {}
  );
/* find youtube id */
const getYoutubeId = url => getURLParameters(url)?.v
const playIcon = <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.2" d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="black" /><path d="M41.175 28.6677L21.111 40.248C20.0234 40.875 18.667 40.0945 18.667 38.8405V15.6287C18.667 14.3747 20.0234 13.5941 21.111 14.2211L41.175 25.8014C42.2754 26.4412 42.2754 28.0279 41.175 28.6677Z" fill="white" /></svg>

function SlickNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button onClick={onClick} className="slick-arrow slide-arrow next-arrow"><i className="fa fa-chevron-right"></i></button>
  );
}

function SlickPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button onClick={onClick} className="slick-arrow slick-arrow prev-arrow"><i className="fa fa-chevron-left"></i></button>
  );
}

class ProductGalleryModal extends React.Component {
  constructor(props) {
    super(props)
    this.videoOnReady = this.videoOnReady.bind(this);
    this.hideGalleryModal = this.hideGalleryModal.bind(this);
    this.checkVideoPlay = this.checkVideoPlay.bind(this);
    this.state = {
      video: {},
      nav1: null,
      nav2: null
    }
  }
  hideGalleryModal() {
    this.props.hideModal()
    this.setState({ video: {} })
  }
  videoOnReady(event) {
    // access to player in all event handlers via event.target
    this.setState({
      video: event.target
    })
  }
  componentDidMount(){
  }
  componentDidUpdate(prevProps, prevState) {
    // 初始化設定 Ref 綁定互為導覽 Slider
    if (this.state.nav1 == null && this.slider1 != undefined) {
      this.setState({
        nav1: this.slider1,
        nav2: this.slider2
      });
    }
    // 關閉modal，清除 Ref
    if ( !this.props.isOpen && this.state.nav1 != null && this.state.nav2 != null) {
      this.setState({
        nav1: null,
        nav2: null
      });
    }
    // 開啟 modal 後讀取上一層 sliderIndex 設定到 Gallery Slider
    if (this.props.isOpen && this.state.nav1 != null ) {
      const $silder = this.state.nav1
      $silder.slickGoTo(this.props.sliderIndex ? this.props.sliderIndex : 0)
    }
  }
  checkVideoPlay(current){
    if (current == 0) {
      Object.keys(this.state.video).length !== 0 && this.state.video.playVideo()
    } else {
      Object.keys(this.state.video).length !== 0 && this.state.video.pauseVideo()
    }
  }
  // https://developers.google.com/youtube/player_parameters
  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 5
      }
    };
    let gallerySettings = {
      dots: false,
      infinite: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: this.state.nav2 ? this.state.nav2 : null ,
      nextArrow: <SlickNextArrow />,
      prevArrow: <SlickPrevArrow />,
      afterChange: (current, next) => this.checkVideoPlay(current)
    };
    let navSiderSettings = {
      dots: false,
      infinite: false,
      speed: 800,
      slidesToShow: 8,
      slidesToScroll: 1,
      focusOnSelect: true,
      asNavFor: this.state.nav1 ? this.state.nav1 : null ,
      nextArrow: <SlickNextArrow />,
      prevArrow: <SlickPrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Modal className="product_gallery-modal LSmodal modal-dialog-centered" id="productGalleryModal" overlayClassName="LSmodalOverlay" isOpen={this.props.isOpen} onRequestClose={this.hideGalleryModal} ariaHideApp={false} >
        <div className="modal-content">
          <div className="modal-body product-content-wrapper">
            {this.props.productPhotos.length > 0 ?
              <div className="product-gallery-container">
                <div className="modal_gallery-slider" id="modalGallerySlider">
                  <Slider ref={slider => (this.slider1 = slider)} {...gallerySettings}>
                    {this.props.productPhotos.map((product_photo, index) =>
                      <div key={index} className='item'>
                        { product_photo.photo?.video ?
                          <YouTube videoId={getYoutubeId(product_photo.photo.video)} opts={opts} containerClassName={'embed-responsive embed-responsive-video'} onReady={this.videoOnReady} /> :
                          <div className="img-ratio-pdimg"><img src={product_photo.photo.url} className="w-100" /></div>
                        }
                      </div> )
                    }
                  </Slider>
                </div>

                <div className="modal_gallery-thumbnail">
                  <Slider ref={slider => (this.slider2 = slider)} {...navSiderSettings}>
                    {this.props.productPhotos.map((product_photo, index) => {
                        return (
                          <div key={index} className='item' data-slick-index={index} >
                            { product_photo.photo.video ? <div className="video-icon">{playIcon}</div> : null}
                            <img src={product_photo.photo.thumbnail.url} className="w-100"/>
                          </div>
                        )
                      })
                    }
                  </Slider>
                </div>
              </div> : null
            }
          </div>
        </div>
      </Modal>
    )
  }
}

export default ProductGalleryModal