import React from 'react'
import i18n from '../../components/i18next/i18n'

export default class MemberAside extends React.Component {

  render() {
    return (
      <aside className="asider-menu sidebar d-none d-lg-block">
        <h1 className="asider-title">{i18n.t('member.member_center')}</h1>
        <ul className="menu-lists">
          <li className="menu-item">
            <a href={(this.props.isStore ? "/store_members" : "/shop_members")+"/profile"} className={"menu-link " + (this.props.currentPage == "MemberProfile" ? 'active': '' )}>{i18n.t("member.profile")}</a>
          </li>
          <li className="menu-item">
            <a href={(this.props.isStore ? "/store_members" : "/shop_members")+"/order"} className={"menu-link " + (this.props.currentPage == "MemberOrder" ? 'active': '' )}>{i18n.t("member.order")}</a>
          </li>
          {/*<li className="menu-item">
            <a href={(this.props.isStore ? "/store_members" : "/shop_members")+"/change_password"} className={"menu-link " + (this.props.currentPage == "MemberChangePassword" ? 'active': '' )}>{i18n.t("member.change_password")}</a>
          </li>*/}
          {/*<li className="menu-item">
            <a href={(this.props.isStore ? "/store_members" : "/shop_members")+"/address"} className={"menu-link " + (this.props.currentPage == "MemberAddress" ? 'active': '' )}>{i18n.t("member.address")}</a>
          </li>*/}
          {
            this.props.isStore 
              ? <li className="menu-item">
                  <a href="/store_members/promotion_code" className={"menu-link " + (this.props.currentPage == "MemberPromotionCode" ? 'active': '' )}>{i18n.t("member.promotion_code")}</a>
                </li>
              : null
          }
          {/* <li className="menu-item">
            <a href={(this.props.isStore ? "/store_members" : "/shop_members")+"/voucher"} className={"menu-link " + (this.props.currentPage == "MemberVoucher" ? 'active': '' )}>{i18n.t("member.voucher")}</a>
          </li> */}
          {/*{
            this.props.isStore
              ? <li className="menu-item">
                  <a href="/store_members/favorite" className={"menu-link " + (this.props.currentPage == "MemberFavorite" ? 'active': '' )}>{i18n.t("member.favorite")}</a>
                </li>
              : null
          }*/}
          {/*<li className="menu-item">
            <a href={(this.props.isStore ? "/store_members" : "/shop_members")+"/qna"} className={"menu-link " + (this.props.currentPage == "MemberQna" ? 'active': '' )}>{i18n.t("member.qna")}</a>
          </li>*/}
        </ul>
      </aside>
    )
  }
}
