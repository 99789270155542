import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_translation from './locales/en/translation';
import zhtw_translation from './locales/zhtw/translation';
import { initReactI18next } from "react-i18next";


const resources = {
  en: {
    translation: en_translation
  },
  zhtw: {
    translation: zhtw_translation
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    keySeparator: '.',
    defaultNS: 'translation',   // default namespace (needs no prefix on calling t)
    lng: "zhtw",                // 預設語言
    fallbackLng: 'zhtw',        // 如果當前切換的語言沒有對應的翻譯則使用這個語言，
    preload: ['zhtw', 'en'],
    lowerCaseLng: true,
    initImmediate: false,       // 等待載入後再進行初始化 *重要
    debug: true,
    interpolation: {
      escapeValue: false,       // not needed for react as it escapes by default
    },
    react: {
      wait: true
    },
    detection: {
      order: ['querystring', 'cookie', 'navigator'],
      // keys or params to lookup language from
      lookupQuerystring: 'locale',
      lookupCookie: 'i18next',
      // cache user language on
      caches: ['localStorage', 'cookie'],
    }
  })
  .then(function (t) {
    console.log('------i18n init done-------');
  });
export default i18n;