import React from "react";
import Timeline from "@/bundles/member/Timeline";

const NO_RECORD = "No Record"
const formattedShippingStatus = (order, shipment, isShipped) => {
  const mustExistStatus = [
    { status: '訂單成立', time: order.status_recordd.new },
    { status: '準備出貨', time: order.status_recordd?.processing }
  ]

  const mustExistShippingStatus =
    { status: '已出貨', time: isShipped ? (shipment.shipping_status_recordd?.shipped || order.shipping_status_recordd?.shipped || order.shipping_status_recordd?.partial || NO_RECORD) : null };
  if (mustExistShippingStatus.time !== null && (order.status_recordd?.processing===undefined || order.status_recordd?.processing===NO_RECORD)) {
    let index = mustExistStatus.findIndex(s => s.status==='準備出貨');
    mustExistStatus[index] = {status: '準備出貨', time: mustExistShippingStatus.time}
  }

  const optionalShipmentStatus = [
    { status: '已到店', time: shipment.shipping_status_recordd?.arrived || order.shipping_status_recordd?.arrived || null },
    { status: '已取件', time: shipment.shipping_status_recordd?.received || order.shipping_status_recordd?.received || null },
    { status: '已退回', time: shipment.shipping_status_recordd?.returned || order.shipping_status_recordd?.returned || null }
  ].filter(item => item.time !== null)
  if (optionalShipmentStatus.length > 0 && mustExistShippingStatus.time === null) {
    mustExistShippingStatus.time = NO_RECORD;
  }

  return [...mustExistStatus, mustExistShippingStatus, ...optionalShipmentStatus]
}
/* 貨運狀態時間線 */
export default (props) => <Timeline statuses={formattedShippingStatus(props.order, props.shipment, props.isShipped)}/>
