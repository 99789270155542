import React from 'react'
import AdditionalPurchase from './additional_purchase'
import i18n from '../../components/i18next/i18n'

class AdditionalPurchaseContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expand: false,
      items: this.props.items.map(i=>{return {id: i.id,quantity: 0}})
    }
    this.onAdditionalPurchaseChange = this.onAdditionalPurchaseChange.bind(this)
  }
  onAdditionalPurchaseChange(id,quantity){
    let items = this.state.items
    for(let item of items){
      if(item.id == id){
        item.quantity = quantity
        break
      }
    }
    this.setState({items:items})
    this.props.onAdditionalPurchaseChange(id,quantity)
  }
  render(){
    let notSelected = this.props.items.filter(i=>i.quantity == 0)
    return <div className="cart-item-group">
        {
          this.props.items.map(item => {
            const items = JSON.parse(sessionStorage.getItem('additional_purchases')||'[]')
            const quantity = items?.find(e => e.item_id === item.id)?.quantity ?? 0
            return <AdditionalPurchase item={item} key={item.id} quantity={quantity}
                                       onAdditionalPurchaseChange={this.onAdditionalPurchaseChange}
                                       expand={this.state.expand || this.props.items.length == 1 || notSelected.length == 1} />
          })
        }
        {
          notSelected.length > 1  ?
          this.state.expand ? <a href="javascript:void(0)" className="btn btn-sm btn-light-gray btn-block" onClick={() => { this.setState({ expand: false }) }}><i className="fa fa-caret-up mr-2" aria-hidden="true"></i>{i18n.t("cart.click_collapse")}</a> :
            <div className="cart-item-list">
              <div className="item-thumbnail thumbnail-group">
                  {notSelected.slice(0, 4).filter(i => i.thumbnail).map(i => <img src={i.thumbnail} key={i.thumbnail} className="img-fluid"></img>)}
              </div>
              <div className="item-detail">
                <div className="variant-title additional">{this.props.group_title}</div>

                <div className="variant-price mb-0">
                  <span className="currency">NT$</span>
                  {Math.min(...notSelected.map(i => i.price))}
                  <small className="mx-2">~</small>
                  <span className="currency">NT$</span>
                  {Math.max(...notSelected.map(i => i.price))}
                </div>
                <a href="javascript:void(0)" className="option-name text-warning mb-2 mb-md-0" onClick={() => { this.setState({ expand: true }) }}>{this.props.items.length != notSelected.length ? '看其他' : i18n.t('cart.has')}<b className="mx-1">{notSelected.length}</b>{i18n.t('cart.styles')}</a>

                <a href="javascript:void(0)" className="select-quantity form-control additional" onClick={() => { this.setState({ expand: true }) }}>{i18n.t("cart.more_style")}</a>
              </div>
            </div> : ''
        }

      </div>
  }
}
export default AdditionalPurchaseContainer